import Dialog, { DialogProps, SizeVariant } from '../dialog'
import React, { useEffect, useState } from 'react'
import Button, {
  ButtonVariant,
  ClickContext
} from '../../buttons/button/button'

interface ConfirmationDialogProps extends DialogProps {
  confirmButtonVariant?: ButtonVariant
  message: string
  onCancel: () => void
  onConfirm: () => void
  cancelText?: string
  confirmText?: string
  asyncConfirmation?: boolean
  isDisabled?: boolean
  isSubmitting?: boolean
  onClose?: (byXMark?: boolean) => void
  clickcontext?: ClickContext
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  confirmButtonVariant = ButtonVariant.DEFAULT,
  open,
  message,
  onCancel,
  onConfirm,
  setOpen,
  title,
  children,
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  asyncConfirmation,
  isDisabled,
  isSubmitting,
  clickcontext,
  onClose
}) => {
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (submitting && open) {
      setSubmitting(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Dialog
      open={open}
      title={title}
      setOpen={setOpen}
      size={SizeVariant.LARGE}
      onClose={onClose}
    >
      <div className="flex flex-col gap-7">
        <div>{message}</div>
        <div>{children}</div>
        <div className="flex justify-end gap-2">
          <Button
            styleVariant={ButtonVariant.SECONDARY}
            onClick={onCancel}
            clickcontext={clickcontext}
          >
            {cancelText}
          </Button>
          <Button
            styleVariant={confirmButtonVariant}
            onClick={() => {
              setSubmitting(true)
              onConfirm()
            }}
            disabled={(asyncConfirmation && submitting) || isDisabled}
            loading={isSubmitting}
            clickcontext={clickcontext}
          >
            {confirmText}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default ConfirmationDialog
