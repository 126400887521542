export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  date: { input: any; output: any; }
  float8: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** An object with globally unique ID */
export type Node = {
  /** A globally unique identifier */
  id: Scalars['ID']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor: Scalars['String']['output'];
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "axe_authors" */
export type Axe_Authors = Node & {
  __typename?: 'axe_authors';
  /** An array relationship */
  axe: Array<Axes>;
  /** An aggregate relationship */
  axe_aggregate: Axes_Aggregate;
  /** An array relationship connection */
  axe_connection: AxesConnection;
  axe_id: Scalars['uuid']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['ID']['output'];
  organizationId?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  subOrganization: Array<Sub_Organizations>;
  subOrganizationId?: Maybe<Scalars['uuid']['output']>;
  /** An aggregate relationship */
  subOrganization_aggregate: Sub_Organizations_Aggregate;
  /** An array relationship connection */
  subOrganization_connection: Sub_OrganizationsConnection;
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  user: Array<Users>;
  /** An aggregate relationship */
  user_aggregate: Users_Aggregate;
  /** An array relationship connection */
  user_connection: UsersConnection;
  user_id: Scalars['String']['output'];
};


/** columns and relationships of "axe_authors" */
export type Axe_AuthorsAxeArgs = {
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};


/** columns and relationships of "axe_authors" */
export type Axe_AuthorsAxe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};


/** columns and relationships of "axe_authors" */
export type Axe_AuthorsAxe_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};


/** columns and relationships of "axe_authors" */
export type Axe_AuthorsSubOrganizationArgs = {
  distinct_on?: InputMaybe<Array<Sub_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sub_Organizations_Order_By>>;
  where?: InputMaybe<Sub_Organizations_Bool_Exp>;
};


/** columns and relationships of "axe_authors" */
export type Axe_AuthorsSubOrganization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sub_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sub_Organizations_Order_By>>;
  where?: InputMaybe<Sub_Organizations_Bool_Exp>;
};


/** columns and relationships of "axe_authors" */
export type Axe_AuthorsSubOrganization_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Sub_Organizations_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sub_Organizations_Order_By>>;
  where?: InputMaybe<Sub_Organizations_Bool_Exp>;
};


/** columns and relationships of "axe_authors" */
export type Axe_AuthorsUserArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "axe_authors" */
export type Axe_AuthorsUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "axe_authors" */
export type Axe_AuthorsUser_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** A Relay connection object on "axe_authors" */
export type Axe_AuthorsConnection = {
  __typename?: 'axe_authorsConnection';
  edges: Array<Axe_AuthorsEdge>;
  pageInfo: PageInfo;
};

export type Axe_AuthorsEdge = {
  __typename?: 'axe_authorsEdge';
  cursor: Scalars['String']['output'];
  node: Axe_Authors;
};

/** aggregated selection of "axe_authors" */
export type Axe_Authors_Aggregate = {
  __typename?: 'axe_authors_aggregate';
  aggregate?: Maybe<Axe_Authors_Aggregate_Fields>;
  nodes: Array<Axe_Authors>;
};

export type Axe_Authors_Aggregate_Bool_Exp = {
  count?: InputMaybe<Axe_Authors_Aggregate_Bool_Exp_Count>;
};

export type Axe_Authors_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Axe_Authors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Authors_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "axe_authors" */
export type Axe_Authors_Aggregate_Fields = {
  __typename?: 'axe_authors_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Axe_Authors_Max_Fields>;
  min?: Maybe<Axe_Authors_Min_Fields>;
};


/** aggregate fields of "axe_authors" */
export type Axe_Authors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Axe_Authors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "axe_authors" */
export type Axe_Authors_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Axe_Authors_Max_Order_By>;
  min?: InputMaybe<Axe_Authors_Min_Order_By>;
};

/** input type for inserting array relation for remote table "axe_authors" */
export type Axe_Authors_Arr_Rel_Insert_Input = {
  data: Array<Axe_Authors_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Axe_Authors_On_Conflict>;
};

/** Boolean expression to filter rows from the table "axe_authors". All fields are combined with a logical 'AND'. */
export type Axe_Authors_Bool_Exp = {
  _and?: InputMaybe<Array<Axe_Authors_Bool_Exp>>;
  _not?: InputMaybe<Axe_Authors_Bool_Exp>;
  _or?: InputMaybe<Array<Axe_Authors_Bool_Exp>>;
  axe?: InputMaybe<Axes_Bool_Exp>;
  axe_aggregate?: InputMaybe<Axes_Aggregate_Bool_Exp>;
  axe_id?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  subOrganization?: InputMaybe<Sub_Organizations_Bool_Exp>;
  subOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
  subOrganization_aggregate?: InputMaybe<Sub_Organizations_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "axe_authors" */
export enum Axe_Authors_Constraint {
  /** unique or primary key constraint on columns "id" */
  AxeAuthorsPkey = 'axe_authors_pkey'
}

/** input type for inserting data into table "axe_authors" */
export type Axe_Authors_Insert_Input = {
  axe?: InputMaybe<Axes_Arr_Rel_Insert_Input>;
  axe_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  subOrganization?: InputMaybe<Sub_Organizations_Arr_Rel_Insert_Input>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Axe_Authors_Max_Fields = {
  __typename?: 'axe_authors_max_fields';
  axe_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  subOrganizationId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "axe_authors" */
export type Axe_Authors_Max_Order_By = {
  axe_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Axe_Authors_Min_Fields = {
  __typename?: 'axe_authors_min_fields';
  axe_id?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  subOrganizationId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "axe_authors" */
export type Axe_Authors_Min_Order_By = {
  axe_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "axe_authors" */
export type Axe_Authors_Mutation_Response = {
  __typename?: 'axe_authors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Axe_Authors>;
};

/** on_conflict condition type for table "axe_authors" */
export type Axe_Authors_On_Conflict = {
  constraint: Axe_Authors_Constraint;
  update_columns?: Array<Axe_Authors_Update_Column>;
  where?: InputMaybe<Axe_Authors_Bool_Exp>;
};

/** Ordering options when selecting data from "axe_authors". */
export type Axe_Authors_Order_By = {
  axe_aggregate?: InputMaybe<Axes_Aggregate_Order_By>;
  axe_id?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
  subOrganization_aggregate?: InputMaybe<Sub_Organizations_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: axe_authors */
export type Axe_Authors_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "axe_authors" */
export enum Axe_Authors_Select_Column {
  /** column name */
  AxeId = 'axe_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "axe_authors" */
export type Axe_Authors_Set_Input = {
  axe_id?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "axe_authors" */
export enum Axe_Authors_Update_Column {
  /** column name */
  AxeId = 'axe_id',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'user_id'
}

export type Axe_Authors_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Axe_Authors_Set_Input>;
  /** filter the rows which have to be updated */
  where: Axe_Authors_Bool_Exp;
};

/** columns and relationships of "axe_legs" */
export type Axe_Legs = Node & {
  __typename?: 'axe_legs';
  /** An array relationship */
  axe: Array<Axes>;
  axeId: Scalars['uuid']['output'];
  /** An aggregate relationship */
  axe_aggregate: Axes_Aggregate;
  /** An array relationship connection */
  axe_connection: AxesConnection;
  baseCurrDepo?: Maybe<Scalars['float8']['output']>;
  buySell?: Maybe<Enum_Axe_Buysell_Enum>;
  callPut?: Maybe<Enum_Axe_Callput_Enum>;
  ccyPair: Enum_Axe_Ccypair_Enum;
  createdAt: Scalars['timestamptz']['output'];
  cut?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['date']['output']>;
  delta?: Maybe<Scalars['bigint']['output']>;
  depoCcy1?: Maybe<Scalars['float8']['output']>;
  depoCcy1DayCount?: Maybe<Enum_Axe_Depoccy1daycount_Enum>;
  depoCcy2?: Maybe<Scalars['float8']['output']>;
  depoCcy2DayCount?: Maybe<Enum_Axe_Depoccy2daycount_Enum>;
  /** An object relationship */
  expiryCut?: Maybe<Expiry_Cuts>;
  expiryDate?: Maybe<Scalars['date']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  forwardPoints?: Maybe<Scalars['float8']['output']>;
  gamma?: Maybe<Scalars['float8']['output']>;
  hedgeType?: Maybe<Enum_Axe_Hedgetype_Enum>;
  id: Scalars['ID']['output'];
  minimumNotionalAmount?: Maybe<Scalars['bigint']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  notionalCurrency?: Maybe<Enum_Currency_Notional_Enum>;
  orderIndex?: Maybe<Scalars['Int']['output']>;
  premium: Scalars['Int']['output'];
  premiumCurrency?: Maybe<Enum_Currency_Premium_Enum>;
  premiumDate?: Maybe<Scalars['date']['output']>;
  premiumType?: Maybe<Enum_Axe_Premiumtype_Enum>;
  pricingCurrDepo?: Maybe<Scalars['float8']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  product?: Maybe<Enum_Axe_Product_Enum>;
  spot?: Maybe<Scalars['float8']['output']>;
  spotDate?: Maybe<Scalars['date']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  swaps?: Maybe<Scalars['float8']['output']>;
  tenor?: Maybe<Enum_Tenor_Enum>;
  updatedAt: Scalars['timestamptz']['output'];
  vega?: Maybe<Scalars['float8']['output']>;
  volatility?: Maybe<Scalars['float8']['output']>;
};


/** columns and relationships of "axe_legs" */
export type Axe_LegsAxeArgs = {
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};


/** columns and relationships of "axe_legs" */
export type Axe_LegsAxe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};


/** columns and relationships of "axe_legs" */
export type Axe_LegsAxe_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};

/** A Relay connection object on "axe_legs" */
export type Axe_LegsConnection = {
  __typename?: 'axe_legsConnection';
  edges: Array<Axe_LegsEdge>;
  pageInfo: PageInfo;
};

export type Axe_LegsEdge = {
  __typename?: 'axe_legsEdge';
  cursor: Scalars['String']['output'];
  node: Axe_Legs;
};

/** aggregated selection of "axe_legs" */
export type Axe_Legs_Aggregate = {
  __typename?: 'axe_legs_aggregate';
  aggregate?: Maybe<Axe_Legs_Aggregate_Fields>;
  nodes: Array<Axe_Legs>;
};

export type Axe_Legs_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Axe_Legs_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Axe_Legs_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Axe_Legs_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Axe_Legs_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Axe_Legs_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Axe_Legs_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Axe_Legs_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Axe_Legs_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Axe_Legs_Aggregate_Bool_Exp_Var_Samp>;
};

export type Axe_Legs_Aggregate_Bool_Exp_Avg = {
  arguments: Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Legs_Aggregate_Bool_Exp_Corr = {
  arguments: Axe_Legs_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Legs_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Axe_Legs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Axe_Legs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Axe_Legs_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Axe_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Axe_Legs_Aggregate_Bool_Exp_Max = {
  arguments: Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Legs_Aggregate_Bool_Exp_Min = {
  arguments: Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Legs_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Legs_Aggregate_Bool_Exp_Sum = {
  arguments: Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Legs_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "axe_legs" */
export type Axe_Legs_Aggregate_Fields = {
  __typename?: 'axe_legs_aggregate_fields';
  avg?: Maybe<Axe_Legs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Axe_Legs_Max_Fields>;
  min?: Maybe<Axe_Legs_Min_Fields>;
  stddev?: Maybe<Axe_Legs_Stddev_Fields>;
  stddev_pop?: Maybe<Axe_Legs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Axe_Legs_Stddev_Samp_Fields>;
  sum?: Maybe<Axe_Legs_Sum_Fields>;
  var_pop?: Maybe<Axe_Legs_Var_Pop_Fields>;
  var_samp?: Maybe<Axe_Legs_Var_Samp_Fields>;
  variance?: Maybe<Axe_Legs_Variance_Fields>;
};


/** aggregate fields of "axe_legs" */
export type Axe_Legs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Axe_Legs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "axe_legs" */
export type Axe_Legs_Aggregate_Order_By = {
  avg?: InputMaybe<Axe_Legs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Axe_Legs_Max_Order_By>;
  min?: InputMaybe<Axe_Legs_Min_Order_By>;
  stddev?: InputMaybe<Axe_Legs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Axe_Legs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Axe_Legs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Axe_Legs_Sum_Order_By>;
  var_pop?: InputMaybe<Axe_Legs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Axe_Legs_Var_Samp_Order_By>;
  variance?: InputMaybe<Axe_Legs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "axe_legs" */
export type Axe_Legs_Arr_Rel_Insert_Input = {
  data: Array<Axe_Legs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Axe_Legs_On_Conflict>;
};

/** columns and relationships of "axe_legs_audit" */
export type Axe_Legs_Audit = Node & {
  __typename?: 'axe_legs_audit';
  axeAuditId: Scalars['uuid']['output'];
  axeCreatedAt?: Maybe<Scalars['timestamptz']['output']>;
  axeId: Scalars['uuid']['output'];
  axeLegId: Scalars['uuid']['output'];
  axeUpdatedAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  axes_audit: Axes_Audit;
  baseCurrDepo?: Maybe<Scalars['float8']['output']>;
  buySell?: Maybe<Scalars['String']['output']>;
  callPut?: Maybe<Scalars['String']['output']>;
  ccyPair: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  cut?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['date']['output']>;
  delta?: Maybe<Scalars['bigint']['output']>;
  depoCcy1?: Maybe<Scalars['float8']['output']>;
  depoCcy1DayCount?: Maybe<Scalars['String']['output']>;
  depoCcy2?: Maybe<Scalars['float8']['output']>;
  depoCcy2DayCount?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  expiryCut?: Maybe<Expiry_Cuts>;
  expiryDate?: Maybe<Scalars['date']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  forwardPoints?: Maybe<Scalars['float8']['output']>;
  gamma?: Maybe<Scalars['float8']['output']>;
  hedgeType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  minimumNotionalAmount?: Maybe<Scalars['bigint']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  notionalCurrency?: Maybe<Scalars['String']['output']>;
  premium: Scalars['Int']['output'];
  premiumCurrency?: Maybe<Scalars['String']['output']>;
  premiumDate?: Maybe<Scalars['date']['output']>;
  pricingCurrDepo?: Maybe<Scalars['float8']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  spot?: Maybe<Scalars['float8']['output']>;
  spotDate?: Maybe<Scalars['date']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  swaps?: Maybe<Scalars['float8']['output']>;
  tenor?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  vega?: Maybe<Scalars['float8']['output']>;
  volatility?: Maybe<Scalars['float8']['output']>;
};

/** A Relay connection object on "axe_legs_audit" */
export type Axe_Legs_AuditConnection = {
  __typename?: 'axe_legs_auditConnection';
  edges: Array<Axe_Legs_AuditEdge>;
  pageInfo: PageInfo;
};

export type Axe_Legs_AuditEdge = {
  __typename?: 'axe_legs_auditEdge';
  cursor: Scalars['String']['output'];
  node: Axe_Legs_Audit;
};

/** aggregated selection of "axe_legs_audit" */
export type Axe_Legs_Audit_Aggregate = {
  __typename?: 'axe_legs_audit_aggregate';
  aggregate?: Maybe<Axe_Legs_Audit_Aggregate_Fields>;
  nodes: Array<Axe_Legs_Audit>;
};

export type Axe_Legs_Audit_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Axe_Legs_Audit_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Axe_Legs_Audit_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Axe_Legs_Audit_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Axe_Legs_Audit_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Axe_Legs_Audit_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Axe_Legs_Audit_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Axe_Legs_Audit_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Axe_Legs_Audit_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Axe_Legs_Audit_Aggregate_Bool_Exp_Var_Samp>;
};

export type Axe_Legs_Audit_Aggregate_Bool_Exp_Avg = {
  arguments: Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Legs_Audit_Aggregate_Bool_Exp_Corr = {
  arguments: Axe_Legs_Audit_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Legs_Audit_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Axe_Legs_Audit_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Axe_Legs_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Axe_Legs_Audit_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Axe_Legs_Audit_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Legs_Audit_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Axe_Legs_Audit_Aggregate_Bool_Exp_Max = {
  arguments: Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Legs_Audit_Aggregate_Bool_Exp_Min = {
  arguments: Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Legs_Audit_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Legs_Audit_Aggregate_Bool_Exp_Sum = {
  arguments: Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Legs_Audit_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "axe_legs_audit" */
export type Axe_Legs_Audit_Aggregate_Fields = {
  __typename?: 'axe_legs_audit_aggregate_fields';
  avg?: Maybe<Axe_Legs_Audit_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Axe_Legs_Audit_Max_Fields>;
  min?: Maybe<Axe_Legs_Audit_Min_Fields>;
  stddev?: Maybe<Axe_Legs_Audit_Stddev_Fields>;
  stddev_pop?: Maybe<Axe_Legs_Audit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Axe_Legs_Audit_Stddev_Samp_Fields>;
  sum?: Maybe<Axe_Legs_Audit_Sum_Fields>;
  var_pop?: Maybe<Axe_Legs_Audit_Var_Pop_Fields>;
  var_samp?: Maybe<Axe_Legs_Audit_Var_Samp_Fields>;
  variance?: Maybe<Axe_Legs_Audit_Variance_Fields>;
};


/** aggregate fields of "axe_legs_audit" */
export type Axe_Legs_Audit_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Axe_Legs_Audit_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "axe_legs_audit" */
export type Axe_Legs_Audit_Aggregate_Order_By = {
  avg?: InputMaybe<Axe_Legs_Audit_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Axe_Legs_Audit_Max_Order_By>;
  min?: InputMaybe<Axe_Legs_Audit_Min_Order_By>;
  stddev?: InputMaybe<Axe_Legs_Audit_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Axe_Legs_Audit_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Axe_Legs_Audit_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Axe_Legs_Audit_Sum_Order_By>;
  var_pop?: InputMaybe<Axe_Legs_Audit_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Axe_Legs_Audit_Var_Samp_Order_By>;
  variance?: InputMaybe<Axe_Legs_Audit_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "axe_legs_audit" */
export type Axe_Legs_Audit_Arr_Rel_Insert_Input = {
  data: Array<Axe_Legs_Audit_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Axe_Legs_Audit_On_Conflict>;
};

/** aggregate avg on columns */
export type Axe_Legs_Audit_Avg_Fields = {
  __typename?: 'axe_legs_audit_avg_fields';
  baseCurrDepo?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  depoCcy1?: Maybe<Scalars['Float']['output']>;
  depoCcy2?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  forwardPoints?: Maybe<Scalars['Float']['output']>;
  gamma?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingCurrDepo?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
  vega?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "axe_legs_audit" */
export type Axe_Legs_Audit_Avg_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "axe_legs_audit". All fields are combined with a logical 'AND'. */
export type Axe_Legs_Audit_Bool_Exp = {
  _and?: InputMaybe<Array<Axe_Legs_Audit_Bool_Exp>>;
  _not?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Axe_Legs_Audit_Bool_Exp>>;
  axeAuditId?: InputMaybe<Uuid_Comparison_Exp>;
  axeCreatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  axeId?: InputMaybe<Uuid_Comparison_Exp>;
  axeLegId?: InputMaybe<Uuid_Comparison_Exp>;
  axeUpdatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  axes_audit?: InputMaybe<Axes_Audit_Bool_Exp>;
  baseCurrDepo?: InputMaybe<Float8_Comparison_Exp>;
  buySell?: InputMaybe<String_Comparison_Exp>;
  callPut?: InputMaybe<String_Comparison_Exp>;
  ccyPair?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  cut?: InputMaybe<String_Comparison_Exp>;
  deliveryDate?: InputMaybe<Date_Comparison_Exp>;
  delta?: InputMaybe<Bigint_Comparison_Exp>;
  depoCcy1?: InputMaybe<Float8_Comparison_Exp>;
  depoCcy1DayCount?: InputMaybe<String_Comparison_Exp>;
  depoCcy2?: InputMaybe<Float8_Comparison_Exp>;
  depoCcy2DayCount?: InputMaybe<String_Comparison_Exp>;
  expiryCut?: InputMaybe<Expiry_Cuts_Bool_Exp>;
  expiryDate?: InputMaybe<Date_Comparison_Exp>;
  forward?: InputMaybe<Float8_Comparison_Exp>;
  forwardPoints?: InputMaybe<Float8_Comparison_Exp>;
  gamma?: InputMaybe<Float8_Comparison_Exp>;
  hedgeType?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  minimumNotionalAmount?: InputMaybe<Bigint_Comparison_Exp>;
  notional?: InputMaybe<Bigint_Comparison_Exp>;
  notionalCurrency?: InputMaybe<String_Comparison_Exp>;
  premium?: InputMaybe<Int_Comparison_Exp>;
  premiumCurrency?: InputMaybe<String_Comparison_Exp>;
  premiumDate?: InputMaybe<Date_Comparison_Exp>;
  pricingCurrDepo?: InputMaybe<Float8_Comparison_Exp>;
  pricingVolatility?: InputMaybe<Float8_Comparison_Exp>;
  product?: InputMaybe<String_Comparison_Exp>;
  spot?: InputMaybe<Float8_Comparison_Exp>;
  spotDate?: InputMaybe<Date_Comparison_Exp>;
  strike?: InputMaybe<Float8_Comparison_Exp>;
  swaps?: InputMaybe<Float8_Comparison_Exp>;
  tenor?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vega?: InputMaybe<Float8_Comparison_Exp>;
  volatility?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "axe_legs_audit" */
export enum Axe_Legs_Audit_Constraint {
  /** unique or primary key constraint on columns "id" */
  AxeLegsAuditPkey = 'axe_legs_audit_pkey'
}

/** input type for incrementing numeric columns in table "axe_legs_audit" */
export type Axe_Legs_Audit_Inc_Input = {
  baseCurrDepo?: InputMaybe<Scalars['float8']['input']>;
  delta?: InputMaybe<Scalars['bigint']['input']>;
  depoCcy1?: InputMaybe<Scalars['float8']['input']>;
  depoCcy2?: InputMaybe<Scalars['float8']['input']>;
  forward?: InputMaybe<Scalars['float8']['input']>;
  forwardPoints?: InputMaybe<Scalars['float8']['input']>;
  gamma?: InputMaybe<Scalars['float8']['input']>;
  minimumNotionalAmount?: InputMaybe<Scalars['bigint']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  pricingCurrDepo?: InputMaybe<Scalars['float8']['input']>;
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
  spot?: InputMaybe<Scalars['float8']['input']>;
  strike?: InputMaybe<Scalars['float8']['input']>;
  swaps?: InputMaybe<Scalars['float8']['input']>;
  vega?: InputMaybe<Scalars['float8']['input']>;
  volatility?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "axe_legs_audit" */
export type Axe_Legs_Audit_Insert_Input = {
  axeAuditId?: InputMaybe<Scalars['uuid']['input']>;
  axeCreatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  axeLegId?: InputMaybe<Scalars['uuid']['input']>;
  axeUpdatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  axes_audit?: InputMaybe<Axes_Audit_Obj_Rel_Insert_Input>;
  baseCurrDepo?: InputMaybe<Scalars['float8']['input']>;
  buySell?: InputMaybe<Scalars['String']['input']>;
  callPut?: InputMaybe<Scalars['String']['input']>;
  ccyPair?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  cut?: InputMaybe<Scalars['String']['input']>;
  deliveryDate?: InputMaybe<Scalars['date']['input']>;
  delta?: InputMaybe<Scalars['bigint']['input']>;
  depoCcy1?: InputMaybe<Scalars['float8']['input']>;
  depoCcy1DayCount?: InputMaybe<Scalars['String']['input']>;
  depoCcy2?: InputMaybe<Scalars['float8']['input']>;
  depoCcy2DayCount?: InputMaybe<Scalars['String']['input']>;
  expiryCut?: InputMaybe<Expiry_Cuts_Obj_Rel_Insert_Input>;
  expiryDate?: InputMaybe<Scalars['date']['input']>;
  forward?: InputMaybe<Scalars['float8']['input']>;
  forwardPoints?: InputMaybe<Scalars['float8']['input']>;
  gamma?: InputMaybe<Scalars['float8']['input']>;
  hedgeType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minimumNotionalAmount?: InputMaybe<Scalars['bigint']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  notionalCurrency?: InputMaybe<Scalars['String']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  premiumCurrency?: InputMaybe<Scalars['String']['input']>;
  premiumDate?: InputMaybe<Scalars['date']['input']>;
  pricingCurrDepo?: InputMaybe<Scalars['float8']['input']>;
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
  product?: InputMaybe<Scalars['String']['input']>;
  spot?: InputMaybe<Scalars['float8']['input']>;
  spotDate?: InputMaybe<Scalars['date']['input']>;
  strike?: InputMaybe<Scalars['float8']['input']>;
  swaps?: InputMaybe<Scalars['float8']['input']>;
  tenor?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  vega?: InputMaybe<Scalars['float8']['input']>;
  volatility?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Axe_Legs_Audit_Max_Fields = {
  __typename?: 'axe_legs_audit_max_fields';
  axeAuditId?: Maybe<Scalars['uuid']['output']>;
  axeCreatedAt?: Maybe<Scalars['timestamptz']['output']>;
  axeId?: Maybe<Scalars['uuid']['output']>;
  axeLegId?: Maybe<Scalars['uuid']['output']>;
  axeUpdatedAt?: Maybe<Scalars['timestamptz']['output']>;
  baseCurrDepo?: Maybe<Scalars['float8']['output']>;
  buySell?: Maybe<Scalars['String']['output']>;
  callPut?: Maybe<Scalars['String']['output']>;
  ccyPair?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  cut?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['date']['output']>;
  delta?: Maybe<Scalars['bigint']['output']>;
  depoCcy1?: Maybe<Scalars['float8']['output']>;
  depoCcy1DayCount?: Maybe<Scalars['String']['output']>;
  depoCcy2?: Maybe<Scalars['float8']['output']>;
  depoCcy2DayCount?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['date']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  forwardPoints?: Maybe<Scalars['float8']['output']>;
  gamma?: Maybe<Scalars['float8']['output']>;
  hedgeType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['bigint']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  notionalCurrency?: Maybe<Scalars['String']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  premiumCurrency?: Maybe<Scalars['String']['output']>;
  premiumDate?: Maybe<Scalars['date']['output']>;
  pricingCurrDepo?: Maybe<Scalars['float8']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  spot?: Maybe<Scalars['float8']['output']>;
  spotDate?: Maybe<Scalars['date']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  swaps?: Maybe<Scalars['float8']['output']>;
  tenor?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  vega?: Maybe<Scalars['float8']['output']>;
  volatility?: Maybe<Scalars['float8']['output']>;
};

/** order by max() on columns of table "axe_legs_audit" */
export type Axe_Legs_Audit_Max_Order_By = {
  axeAuditId?: InputMaybe<Order_By>;
  axeCreatedAt?: InputMaybe<Order_By>;
  axeId?: InputMaybe<Order_By>;
  axeLegId?: InputMaybe<Order_By>;
  axeUpdatedAt?: InputMaybe<Order_By>;
  baseCurrDepo?: InputMaybe<Order_By>;
  buySell?: InputMaybe<Order_By>;
  callPut?: InputMaybe<Order_By>;
  ccyPair?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cut?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy1DayCount?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  depoCcy2DayCount?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  hedgeType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  notionalCurrency?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  premiumCurrency?: InputMaybe<Order_By>;
  premiumDate?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  product?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  spotDate?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  tenor?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Axe_Legs_Audit_Min_Fields = {
  __typename?: 'axe_legs_audit_min_fields';
  axeAuditId?: Maybe<Scalars['uuid']['output']>;
  axeCreatedAt?: Maybe<Scalars['timestamptz']['output']>;
  axeId?: Maybe<Scalars['uuid']['output']>;
  axeLegId?: Maybe<Scalars['uuid']['output']>;
  axeUpdatedAt?: Maybe<Scalars['timestamptz']['output']>;
  baseCurrDepo?: Maybe<Scalars['float8']['output']>;
  buySell?: Maybe<Scalars['String']['output']>;
  callPut?: Maybe<Scalars['String']['output']>;
  ccyPair?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  cut?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['date']['output']>;
  delta?: Maybe<Scalars['bigint']['output']>;
  depoCcy1?: Maybe<Scalars['float8']['output']>;
  depoCcy1DayCount?: Maybe<Scalars['String']['output']>;
  depoCcy2?: Maybe<Scalars['float8']['output']>;
  depoCcy2DayCount?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['date']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  forwardPoints?: Maybe<Scalars['float8']['output']>;
  gamma?: Maybe<Scalars['float8']['output']>;
  hedgeType?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['bigint']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  notionalCurrency?: Maybe<Scalars['String']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  premiumCurrency?: Maybe<Scalars['String']['output']>;
  premiumDate?: Maybe<Scalars['date']['output']>;
  pricingCurrDepo?: Maybe<Scalars['float8']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  spot?: Maybe<Scalars['float8']['output']>;
  spotDate?: Maybe<Scalars['date']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  swaps?: Maybe<Scalars['float8']['output']>;
  tenor?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  vega?: Maybe<Scalars['float8']['output']>;
  volatility?: Maybe<Scalars['float8']['output']>;
};

/** order by min() on columns of table "axe_legs_audit" */
export type Axe_Legs_Audit_Min_Order_By = {
  axeAuditId?: InputMaybe<Order_By>;
  axeCreatedAt?: InputMaybe<Order_By>;
  axeId?: InputMaybe<Order_By>;
  axeLegId?: InputMaybe<Order_By>;
  axeUpdatedAt?: InputMaybe<Order_By>;
  baseCurrDepo?: InputMaybe<Order_By>;
  buySell?: InputMaybe<Order_By>;
  callPut?: InputMaybe<Order_By>;
  ccyPair?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cut?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy1DayCount?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  depoCcy2DayCount?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  hedgeType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  notionalCurrency?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  premiumCurrency?: InputMaybe<Order_By>;
  premiumDate?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  product?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  spotDate?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  tenor?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "axe_legs_audit" */
export type Axe_Legs_Audit_Mutation_Response = {
  __typename?: 'axe_legs_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Axe_Legs_Audit>;
};

/** on_conflict condition type for table "axe_legs_audit" */
export type Axe_Legs_Audit_On_Conflict = {
  constraint: Axe_Legs_Audit_Constraint;
  update_columns?: Array<Axe_Legs_Audit_Update_Column>;
  where?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
};

/** Ordering options when selecting data from "axe_legs_audit". */
export type Axe_Legs_Audit_Order_By = {
  axeAuditId?: InputMaybe<Order_By>;
  axeCreatedAt?: InputMaybe<Order_By>;
  axeId?: InputMaybe<Order_By>;
  axeLegId?: InputMaybe<Order_By>;
  axeUpdatedAt?: InputMaybe<Order_By>;
  axes_audit?: InputMaybe<Axes_Audit_Order_By>;
  baseCurrDepo?: InputMaybe<Order_By>;
  buySell?: InputMaybe<Order_By>;
  callPut?: InputMaybe<Order_By>;
  ccyPair?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cut?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy1DayCount?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  depoCcy2DayCount?: InputMaybe<Order_By>;
  expiryCut?: InputMaybe<Expiry_Cuts_Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  hedgeType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  notionalCurrency?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  premiumCurrency?: InputMaybe<Order_By>;
  premiumDate?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  product?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  spotDate?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  tenor?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** primary key columns input for table: axe_legs_audit */
export type Axe_Legs_Audit_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "axe_legs_audit" */
export enum Axe_Legs_Audit_Select_Column {
  /** column name */
  AxeAuditId = 'axeAuditId',
  /** column name */
  AxeCreatedAt = 'axeCreatedAt',
  /** column name */
  AxeId = 'axeId',
  /** column name */
  AxeLegId = 'axeLegId',
  /** column name */
  AxeUpdatedAt = 'axeUpdatedAt',
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  BuySell = 'buySell',
  /** column name */
  CallPut = 'callPut',
  /** column name */
  CcyPair = 'ccyPair',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Cut = 'cut',
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  Delta = 'delta',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy1DayCount = 'depoCcy1DayCount',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  DepoCcy2DayCount = 'depoCcy2DayCount',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  HedgeType = 'hedgeType',
  /** column name */
  Id = 'id',
  /** column name */
  MinimumNotionalAmount = 'minimumNotionalAmount',
  /** column name */
  Notional = 'notional',
  /** column name */
  NotionalCurrency = 'notionalCurrency',
  /** column name */
  Premium = 'premium',
  /** column name */
  PremiumCurrency = 'premiumCurrency',
  /** column name */
  PremiumDate = 'premiumDate',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Product = 'product',
  /** column name */
  Spot = 'spot',
  /** column name */
  SpotDate = 'spotDate',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Tenor = 'tenor',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_audit_aggregate_bool_exp_avg_arguments_columns" columns of table "axe_legs_audit" */
export enum Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_audit_aggregate_bool_exp_corr_arguments_columns" columns of table "axe_legs_audit" */
export enum Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_audit_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "axe_legs_audit" */
export enum Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_audit_aggregate_bool_exp_max_arguments_columns" columns of table "axe_legs_audit" */
export enum Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_audit_aggregate_bool_exp_min_arguments_columns" columns of table "axe_legs_audit" */
export enum Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_audit_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "axe_legs_audit" */
export enum Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_audit_aggregate_bool_exp_sum_arguments_columns" columns of table "axe_legs_audit" */
export enum Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_audit_aggregate_bool_exp_var_samp_arguments_columns" columns of table "axe_legs_audit" */
export enum Axe_Legs_Audit_Select_Column_Axe_Legs_Audit_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** input type for updating data in table "axe_legs_audit" */
export type Axe_Legs_Audit_Set_Input = {
  axeAuditId?: InputMaybe<Scalars['uuid']['input']>;
  axeCreatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  axeLegId?: InputMaybe<Scalars['uuid']['input']>;
  axeUpdatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  baseCurrDepo?: InputMaybe<Scalars['float8']['input']>;
  buySell?: InputMaybe<Scalars['String']['input']>;
  callPut?: InputMaybe<Scalars['String']['input']>;
  ccyPair?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  cut?: InputMaybe<Scalars['String']['input']>;
  deliveryDate?: InputMaybe<Scalars['date']['input']>;
  delta?: InputMaybe<Scalars['bigint']['input']>;
  depoCcy1?: InputMaybe<Scalars['float8']['input']>;
  depoCcy1DayCount?: InputMaybe<Scalars['String']['input']>;
  depoCcy2?: InputMaybe<Scalars['float8']['input']>;
  depoCcy2DayCount?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['date']['input']>;
  forward?: InputMaybe<Scalars['float8']['input']>;
  forwardPoints?: InputMaybe<Scalars['float8']['input']>;
  gamma?: InputMaybe<Scalars['float8']['input']>;
  hedgeType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minimumNotionalAmount?: InputMaybe<Scalars['bigint']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  notionalCurrency?: InputMaybe<Scalars['String']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  premiumCurrency?: InputMaybe<Scalars['String']['input']>;
  premiumDate?: InputMaybe<Scalars['date']['input']>;
  pricingCurrDepo?: InputMaybe<Scalars['float8']['input']>;
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
  product?: InputMaybe<Scalars['String']['input']>;
  spot?: InputMaybe<Scalars['float8']['input']>;
  spotDate?: InputMaybe<Scalars['date']['input']>;
  strike?: InputMaybe<Scalars['float8']['input']>;
  swaps?: InputMaybe<Scalars['float8']['input']>;
  tenor?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  vega?: InputMaybe<Scalars['float8']['input']>;
  volatility?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Axe_Legs_Audit_Stddev_Fields = {
  __typename?: 'axe_legs_audit_stddev_fields';
  baseCurrDepo?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  depoCcy1?: Maybe<Scalars['Float']['output']>;
  depoCcy2?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  forwardPoints?: Maybe<Scalars['Float']['output']>;
  gamma?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingCurrDepo?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
  vega?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "axe_legs_audit" */
export type Axe_Legs_Audit_Stddev_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Axe_Legs_Audit_Stddev_Pop_Fields = {
  __typename?: 'axe_legs_audit_stddev_pop_fields';
  baseCurrDepo?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  depoCcy1?: Maybe<Scalars['Float']['output']>;
  depoCcy2?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  forwardPoints?: Maybe<Scalars['Float']['output']>;
  gamma?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingCurrDepo?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
  vega?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "axe_legs_audit" */
export type Axe_Legs_Audit_Stddev_Pop_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Axe_Legs_Audit_Stddev_Samp_Fields = {
  __typename?: 'axe_legs_audit_stddev_samp_fields';
  baseCurrDepo?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  depoCcy1?: Maybe<Scalars['Float']['output']>;
  depoCcy2?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  forwardPoints?: Maybe<Scalars['Float']['output']>;
  gamma?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingCurrDepo?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
  vega?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "axe_legs_audit" */
export type Axe_Legs_Audit_Stddev_Samp_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Axe_Legs_Audit_Sum_Fields = {
  __typename?: 'axe_legs_audit_sum_fields';
  baseCurrDepo?: Maybe<Scalars['float8']['output']>;
  delta?: Maybe<Scalars['bigint']['output']>;
  depoCcy1?: Maybe<Scalars['float8']['output']>;
  depoCcy2?: Maybe<Scalars['float8']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  forwardPoints?: Maybe<Scalars['float8']['output']>;
  gamma?: Maybe<Scalars['float8']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['bigint']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  pricingCurrDepo?: Maybe<Scalars['float8']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  spot?: Maybe<Scalars['float8']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  swaps?: Maybe<Scalars['float8']['output']>;
  vega?: Maybe<Scalars['float8']['output']>;
  volatility?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "axe_legs_audit" */
export type Axe_Legs_Audit_Sum_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** update columns of table "axe_legs_audit" */
export enum Axe_Legs_Audit_Update_Column {
  /** column name */
  AxeAuditId = 'axeAuditId',
  /** column name */
  AxeCreatedAt = 'axeCreatedAt',
  /** column name */
  AxeId = 'axeId',
  /** column name */
  AxeLegId = 'axeLegId',
  /** column name */
  AxeUpdatedAt = 'axeUpdatedAt',
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  BuySell = 'buySell',
  /** column name */
  CallPut = 'callPut',
  /** column name */
  CcyPair = 'ccyPair',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Cut = 'cut',
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  Delta = 'delta',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy1DayCount = 'depoCcy1DayCount',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  DepoCcy2DayCount = 'depoCcy2DayCount',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  HedgeType = 'hedgeType',
  /** column name */
  Id = 'id',
  /** column name */
  MinimumNotionalAmount = 'minimumNotionalAmount',
  /** column name */
  Notional = 'notional',
  /** column name */
  NotionalCurrency = 'notionalCurrency',
  /** column name */
  Premium = 'premium',
  /** column name */
  PremiumCurrency = 'premiumCurrency',
  /** column name */
  PremiumDate = 'premiumDate',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Product = 'product',
  /** column name */
  Spot = 'spot',
  /** column name */
  SpotDate = 'spotDate',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Tenor = 'tenor',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

export type Axe_Legs_Audit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Axe_Legs_Audit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Axe_Legs_Audit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Axe_Legs_Audit_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Axe_Legs_Audit_Var_Pop_Fields = {
  __typename?: 'axe_legs_audit_var_pop_fields';
  baseCurrDepo?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  depoCcy1?: Maybe<Scalars['Float']['output']>;
  depoCcy2?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  forwardPoints?: Maybe<Scalars['Float']['output']>;
  gamma?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingCurrDepo?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
  vega?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "axe_legs_audit" */
export type Axe_Legs_Audit_Var_Pop_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Axe_Legs_Audit_Var_Samp_Fields = {
  __typename?: 'axe_legs_audit_var_samp_fields';
  baseCurrDepo?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  depoCcy1?: Maybe<Scalars['Float']['output']>;
  depoCcy2?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  forwardPoints?: Maybe<Scalars['Float']['output']>;
  gamma?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingCurrDepo?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
  vega?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "axe_legs_audit" */
export type Axe_Legs_Audit_Var_Samp_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Axe_Legs_Audit_Variance_Fields = {
  __typename?: 'axe_legs_audit_variance_fields';
  baseCurrDepo?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  depoCcy1?: Maybe<Scalars['Float']['output']>;
  depoCcy2?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  forwardPoints?: Maybe<Scalars['Float']['output']>;
  gamma?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingCurrDepo?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
  vega?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "axe_legs_audit" */
export type Axe_Legs_Audit_Variance_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** aggregate avg on columns */
export type Axe_Legs_Avg_Fields = {
  __typename?: 'axe_legs_avg_fields';
  baseCurrDepo?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  depoCcy1?: Maybe<Scalars['Float']['output']>;
  depoCcy2?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  forwardPoints?: Maybe<Scalars['Float']['output']>;
  gamma?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingCurrDepo?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
  vega?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "axe_legs" */
export type Axe_Legs_Avg_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "axe_legs". All fields are combined with a logical 'AND'. */
export type Axe_Legs_Bool_Exp = {
  _and?: InputMaybe<Array<Axe_Legs_Bool_Exp>>;
  _not?: InputMaybe<Axe_Legs_Bool_Exp>;
  _or?: InputMaybe<Array<Axe_Legs_Bool_Exp>>;
  axe?: InputMaybe<Axes_Bool_Exp>;
  axeId?: InputMaybe<Uuid_Comparison_Exp>;
  axe_aggregate?: InputMaybe<Axes_Aggregate_Bool_Exp>;
  baseCurrDepo?: InputMaybe<Float8_Comparison_Exp>;
  buySell?: InputMaybe<Enum_Axe_Buysell_Enum_Comparison_Exp>;
  callPut?: InputMaybe<Enum_Axe_Callput_Enum_Comparison_Exp>;
  ccyPair?: InputMaybe<Enum_Axe_Ccypair_Enum_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  cut?: InputMaybe<String_Comparison_Exp>;
  deliveryDate?: InputMaybe<Date_Comparison_Exp>;
  delta?: InputMaybe<Bigint_Comparison_Exp>;
  depoCcy1?: InputMaybe<Float8_Comparison_Exp>;
  depoCcy1DayCount?: InputMaybe<Enum_Axe_Depoccy1daycount_Enum_Comparison_Exp>;
  depoCcy2?: InputMaybe<Float8_Comparison_Exp>;
  depoCcy2DayCount?: InputMaybe<Enum_Axe_Depoccy2daycount_Enum_Comparison_Exp>;
  expiryCut?: InputMaybe<Expiry_Cuts_Bool_Exp>;
  expiryDate?: InputMaybe<Date_Comparison_Exp>;
  forward?: InputMaybe<Float8_Comparison_Exp>;
  forwardPoints?: InputMaybe<Float8_Comparison_Exp>;
  gamma?: InputMaybe<Float8_Comparison_Exp>;
  hedgeType?: InputMaybe<Enum_Axe_Hedgetype_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  minimumNotionalAmount?: InputMaybe<Bigint_Comparison_Exp>;
  notional?: InputMaybe<Bigint_Comparison_Exp>;
  notionalCurrency?: InputMaybe<Enum_Currency_Notional_Enum_Comparison_Exp>;
  orderIndex?: InputMaybe<Int_Comparison_Exp>;
  premium?: InputMaybe<Int_Comparison_Exp>;
  premiumCurrency?: InputMaybe<Enum_Currency_Premium_Enum_Comparison_Exp>;
  premiumDate?: InputMaybe<Date_Comparison_Exp>;
  premiumType?: InputMaybe<Enum_Axe_Premiumtype_Enum_Comparison_Exp>;
  pricingCurrDepo?: InputMaybe<Float8_Comparison_Exp>;
  pricingVolatility?: InputMaybe<Float8_Comparison_Exp>;
  product?: InputMaybe<Enum_Axe_Product_Enum_Comparison_Exp>;
  spot?: InputMaybe<Float8_Comparison_Exp>;
  spotDate?: InputMaybe<Date_Comparison_Exp>;
  strike?: InputMaybe<Float8_Comparison_Exp>;
  swaps?: InputMaybe<Float8_Comparison_Exp>;
  tenor?: InputMaybe<Enum_Tenor_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  vega?: InputMaybe<Float8_Comparison_Exp>;
  volatility?: InputMaybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "axe_legs" */
export enum Axe_Legs_Constraint {
  /** unique or primary key constraint on columns "id" */
  LegsPkey = 'legs_pkey',
  /** unique or primary key constraint on columns "axeId", "orderIndex" */
  UniqueOrderPerAxe = 'unique_order_per_axe'
}

/** input type for incrementing numeric columns in table "axe_legs" */
export type Axe_Legs_Inc_Input = {
  baseCurrDepo?: InputMaybe<Scalars['float8']['input']>;
  delta?: InputMaybe<Scalars['bigint']['input']>;
  depoCcy1?: InputMaybe<Scalars['float8']['input']>;
  depoCcy2?: InputMaybe<Scalars['float8']['input']>;
  forward?: InputMaybe<Scalars['float8']['input']>;
  forwardPoints?: InputMaybe<Scalars['float8']['input']>;
  gamma?: InputMaybe<Scalars['float8']['input']>;
  minimumNotionalAmount?: InputMaybe<Scalars['bigint']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  orderIndex?: InputMaybe<Scalars['Int']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  pricingCurrDepo?: InputMaybe<Scalars['float8']['input']>;
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
  spot?: InputMaybe<Scalars['float8']['input']>;
  strike?: InputMaybe<Scalars['float8']['input']>;
  swaps?: InputMaybe<Scalars['float8']['input']>;
  vega?: InputMaybe<Scalars['float8']['input']>;
  volatility?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "axe_legs" */
export type Axe_Legs_Insert_Input = {
  axe?: InputMaybe<Axes_Arr_Rel_Insert_Input>;
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  baseCurrDepo?: InputMaybe<Scalars['float8']['input']>;
  buySell?: InputMaybe<Enum_Axe_Buysell_Enum>;
  callPut?: InputMaybe<Enum_Axe_Callput_Enum>;
  ccyPair?: InputMaybe<Enum_Axe_Ccypair_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  cut?: InputMaybe<Scalars['String']['input']>;
  deliveryDate?: InputMaybe<Scalars['date']['input']>;
  delta?: InputMaybe<Scalars['bigint']['input']>;
  depoCcy1?: InputMaybe<Scalars['float8']['input']>;
  depoCcy1DayCount?: InputMaybe<Enum_Axe_Depoccy1daycount_Enum>;
  depoCcy2?: InputMaybe<Scalars['float8']['input']>;
  depoCcy2DayCount?: InputMaybe<Enum_Axe_Depoccy2daycount_Enum>;
  expiryCut?: InputMaybe<Expiry_Cuts_Obj_Rel_Insert_Input>;
  expiryDate?: InputMaybe<Scalars['date']['input']>;
  forward?: InputMaybe<Scalars['float8']['input']>;
  forwardPoints?: InputMaybe<Scalars['float8']['input']>;
  gamma?: InputMaybe<Scalars['float8']['input']>;
  hedgeType?: InputMaybe<Enum_Axe_Hedgetype_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minimumNotionalAmount?: InputMaybe<Scalars['bigint']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  notionalCurrency?: InputMaybe<Enum_Currency_Notional_Enum>;
  orderIndex?: InputMaybe<Scalars['Int']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  premiumCurrency?: InputMaybe<Enum_Currency_Premium_Enum>;
  premiumDate?: InputMaybe<Scalars['date']['input']>;
  premiumType?: InputMaybe<Enum_Axe_Premiumtype_Enum>;
  pricingCurrDepo?: InputMaybe<Scalars['float8']['input']>;
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
  product?: InputMaybe<Enum_Axe_Product_Enum>;
  spot?: InputMaybe<Scalars['float8']['input']>;
  spotDate?: InputMaybe<Scalars['date']['input']>;
  strike?: InputMaybe<Scalars['float8']['input']>;
  swaps?: InputMaybe<Scalars['float8']['input']>;
  tenor?: InputMaybe<Enum_Tenor_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  vega?: InputMaybe<Scalars['float8']['input']>;
  volatility?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate max on columns */
export type Axe_Legs_Max_Fields = {
  __typename?: 'axe_legs_max_fields';
  axeId?: Maybe<Scalars['uuid']['output']>;
  baseCurrDepo?: Maybe<Scalars['float8']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  cut?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['date']['output']>;
  delta?: Maybe<Scalars['bigint']['output']>;
  depoCcy1?: Maybe<Scalars['float8']['output']>;
  depoCcy2?: Maybe<Scalars['float8']['output']>;
  expiryDate?: Maybe<Scalars['date']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  forwardPoints?: Maybe<Scalars['float8']['output']>;
  gamma?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['bigint']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  orderIndex?: Maybe<Scalars['Int']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  premiumDate?: Maybe<Scalars['date']['output']>;
  pricingCurrDepo?: Maybe<Scalars['float8']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  spot?: Maybe<Scalars['float8']['output']>;
  spotDate?: Maybe<Scalars['date']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  swaps?: Maybe<Scalars['float8']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  vega?: Maybe<Scalars['float8']['output']>;
  volatility?: Maybe<Scalars['float8']['output']>;
};

/** order by max() on columns of table "axe_legs" */
export type Axe_Legs_Max_Order_By = {
  axeId?: InputMaybe<Order_By>;
  baseCurrDepo?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cut?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  premiumDate?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  spotDate?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Axe_Legs_Min_Fields = {
  __typename?: 'axe_legs_min_fields';
  axeId?: Maybe<Scalars['uuid']['output']>;
  baseCurrDepo?: Maybe<Scalars['float8']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  cut?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['date']['output']>;
  delta?: Maybe<Scalars['bigint']['output']>;
  depoCcy1?: Maybe<Scalars['float8']['output']>;
  depoCcy2?: Maybe<Scalars['float8']['output']>;
  expiryDate?: Maybe<Scalars['date']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  forwardPoints?: Maybe<Scalars['float8']['output']>;
  gamma?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['bigint']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  orderIndex?: Maybe<Scalars['Int']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  premiumDate?: Maybe<Scalars['date']['output']>;
  pricingCurrDepo?: Maybe<Scalars['float8']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  spot?: Maybe<Scalars['float8']['output']>;
  spotDate?: Maybe<Scalars['date']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  swaps?: Maybe<Scalars['float8']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  vega?: Maybe<Scalars['float8']['output']>;
  volatility?: Maybe<Scalars['float8']['output']>;
};

/** order by min() on columns of table "axe_legs" */
export type Axe_Legs_Min_Order_By = {
  axeId?: InputMaybe<Order_By>;
  baseCurrDepo?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cut?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  premiumDate?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  spotDate?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "axe_legs" */
export type Axe_Legs_Mutation_Response = {
  __typename?: 'axe_legs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Axe_Legs>;
};

/** on_conflict condition type for table "axe_legs" */
export type Axe_Legs_On_Conflict = {
  constraint: Axe_Legs_Constraint;
  update_columns?: Array<Axe_Legs_Update_Column>;
  where?: InputMaybe<Axe_Legs_Bool_Exp>;
};

/** Ordering options when selecting data from "axe_legs". */
export type Axe_Legs_Order_By = {
  axeId?: InputMaybe<Order_By>;
  axe_aggregate?: InputMaybe<Axes_Aggregate_Order_By>;
  baseCurrDepo?: InputMaybe<Order_By>;
  buySell?: InputMaybe<Order_By>;
  callPut?: InputMaybe<Order_By>;
  ccyPair?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cut?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy1DayCount?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  depoCcy2DayCount?: InputMaybe<Order_By>;
  expiryCut?: InputMaybe<Expiry_Cuts_Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  hedgeType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  notionalCurrency?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  premiumCurrency?: InputMaybe<Order_By>;
  premiumDate?: InputMaybe<Order_By>;
  premiumType?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  product?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  spotDate?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  tenor?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** primary key columns input for table: axe_legs */
export type Axe_Legs_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "axe_legs" */
export enum Axe_Legs_Select_Column {
  /** column name */
  AxeId = 'axeId',
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  BuySell = 'buySell',
  /** column name */
  CallPut = 'callPut',
  /** column name */
  CcyPair = 'ccyPair',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Cut = 'cut',
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  Delta = 'delta',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy1DayCount = 'depoCcy1DayCount',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  DepoCcy2DayCount = 'depoCcy2DayCount',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  HedgeType = 'hedgeType',
  /** column name */
  Id = 'id',
  /** column name */
  MinimumNotionalAmount = 'minimumNotionalAmount',
  /** column name */
  Notional = 'notional',
  /** column name */
  NotionalCurrency = 'notionalCurrency',
  /** column name */
  OrderIndex = 'orderIndex',
  /** column name */
  Premium = 'premium',
  /** column name */
  PremiumCurrency = 'premiumCurrency',
  /** column name */
  PremiumDate = 'premiumDate',
  /** column name */
  PremiumType = 'premiumType',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Product = 'product',
  /** column name */
  Spot = 'spot',
  /** column name */
  SpotDate = 'spotDate',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Tenor = 'tenor',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_aggregate_bool_exp_avg_arguments_columns" columns of table "axe_legs" */
export enum Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_aggregate_bool_exp_corr_arguments_columns" columns of table "axe_legs" */
export enum Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "axe_legs" */
export enum Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_aggregate_bool_exp_max_arguments_columns" columns of table "axe_legs" */
export enum Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_aggregate_bool_exp_min_arguments_columns" columns of table "axe_legs" */
export enum Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "axe_legs" */
export enum Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_aggregate_bool_exp_sum_arguments_columns" columns of table "axe_legs" */
export enum Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** select "axe_legs_aggregate_bool_exp_var_samp_arguments_columns" columns of table "axe_legs" */
export enum Axe_Legs_Select_Column_Axe_Legs_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

/** input type for updating data in table "axe_legs" */
export type Axe_Legs_Set_Input = {
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  baseCurrDepo?: InputMaybe<Scalars['float8']['input']>;
  buySell?: InputMaybe<Enum_Axe_Buysell_Enum>;
  callPut?: InputMaybe<Enum_Axe_Callput_Enum>;
  ccyPair?: InputMaybe<Enum_Axe_Ccypair_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  cut?: InputMaybe<Scalars['String']['input']>;
  deliveryDate?: InputMaybe<Scalars['date']['input']>;
  delta?: InputMaybe<Scalars['bigint']['input']>;
  depoCcy1?: InputMaybe<Scalars['float8']['input']>;
  depoCcy1DayCount?: InputMaybe<Enum_Axe_Depoccy1daycount_Enum>;
  depoCcy2?: InputMaybe<Scalars['float8']['input']>;
  depoCcy2DayCount?: InputMaybe<Enum_Axe_Depoccy2daycount_Enum>;
  expiryDate?: InputMaybe<Scalars['date']['input']>;
  forward?: InputMaybe<Scalars['float8']['input']>;
  forwardPoints?: InputMaybe<Scalars['float8']['input']>;
  gamma?: InputMaybe<Scalars['float8']['input']>;
  hedgeType?: InputMaybe<Enum_Axe_Hedgetype_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minimumNotionalAmount?: InputMaybe<Scalars['bigint']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  notionalCurrency?: InputMaybe<Enum_Currency_Notional_Enum>;
  orderIndex?: InputMaybe<Scalars['Int']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  premiumCurrency?: InputMaybe<Enum_Currency_Premium_Enum>;
  premiumDate?: InputMaybe<Scalars['date']['input']>;
  premiumType?: InputMaybe<Enum_Axe_Premiumtype_Enum>;
  pricingCurrDepo?: InputMaybe<Scalars['float8']['input']>;
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
  product?: InputMaybe<Enum_Axe_Product_Enum>;
  spot?: InputMaybe<Scalars['float8']['input']>;
  spotDate?: InputMaybe<Scalars['date']['input']>;
  strike?: InputMaybe<Scalars['float8']['input']>;
  swaps?: InputMaybe<Scalars['float8']['input']>;
  tenor?: InputMaybe<Enum_Tenor_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  vega?: InputMaybe<Scalars['float8']['input']>;
  volatility?: InputMaybe<Scalars['float8']['input']>;
};

/** aggregate stddev on columns */
export type Axe_Legs_Stddev_Fields = {
  __typename?: 'axe_legs_stddev_fields';
  baseCurrDepo?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  depoCcy1?: Maybe<Scalars['Float']['output']>;
  depoCcy2?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  forwardPoints?: Maybe<Scalars['Float']['output']>;
  gamma?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingCurrDepo?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
  vega?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "axe_legs" */
export type Axe_Legs_Stddev_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Axe_Legs_Stddev_Pop_Fields = {
  __typename?: 'axe_legs_stddev_pop_fields';
  baseCurrDepo?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  depoCcy1?: Maybe<Scalars['Float']['output']>;
  depoCcy2?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  forwardPoints?: Maybe<Scalars['Float']['output']>;
  gamma?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingCurrDepo?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
  vega?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "axe_legs" */
export type Axe_Legs_Stddev_Pop_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Axe_Legs_Stddev_Samp_Fields = {
  __typename?: 'axe_legs_stddev_samp_fields';
  baseCurrDepo?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  depoCcy1?: Maybe<Scalars['Float']['output']>;
  depoCcy2?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  forwardPoints?: Maybe<Scalars['Float']['output']>;
  gamma?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingCurrDepo?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
  vega?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "axe_legs" */
export type Axe_Legs_Stddev_Samp_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Axe_Legs_Sum_Fields = {
  __typename?: 'axe_legs_sum_fields';
  baseCurrDepo?: Maybe<Scalars['float8']['output']>;
  delta?: Maybe<Scalars['bigint']['output']>;
  depoCcy1?: Maybe<Scalars['float8']['output']>;
  depoCcy2?: Maybe<Scalars['float8']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  forwardPoints?: Maybe<Scalars['float8']['output']>;
  gamma?: Maybe<Scalars['float8']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['bigint']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  orderIndex?: Maybe<Scalars['Int']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  pricingCurrDepo?: Maybe<Scalars['float8']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  spot?: Maybe<Scalars['float8']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  swaps?: Maybe<Scalars['float8']['output']>;
  vega?: Maybe<Scalars['float8']['output']>;
  volatility?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "axe_legs" */
export type Axe_Legs_Sum_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** update columns of table "axe_legs" */
export enum Axe_Legs_Update_Column {
  /** column name */
  AxeId = 'axeId',
  /** column name */
  BaseCurrDepo = 'baseCurrDepo',
  /** column name */
  BuySell = 'buySell',
  /** column name */
  CallPut = 'callPut',
  /** column name */
  CcyPair = 'ccyPair',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Cut = 'cut',
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  Delta = 'delta',
  /** column name */
  DepoCcy1 = 'depoCcy1',
  /** column name */
  DepoCcy1DayCount = 'depoCcy1DayCount',
  /** column name */
  DepoCcy2 = 'depoCcy2',
  /** column name */
  DepoCcy2DayCount = 'depoCcy2DayCount',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Forward = 'forward',
  /** column name */
  ForwardPoints = 'forwardPoints',
  /** column name */
  Gamma = 'gamma',
  /** column name */
  HedgeType = 'hedgeType',
  /** column name */
  Id = 'id',
  /** column name */
  MinimumNotionalAmount = 'minimumNotionalAmount',
  /** column name */
  Notional = 'notional',
  /** column name */
  NotionalCurrency = 'notionalCurrency',
  /** column name */
  OrderIndex = 'orderIndex',
  /** column name */
  Premium = 'premium',
  /** column name */
  PremiumCurrency = 'premiumCurrency',
  /** column name */
  PremiumDate = 'premiumDate',
  /** column name */
  PremiumType = 'premiumType',
  /** column name */
  PricingCurrDepo = 'pricingCurrDepo',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Product = 'product',
  /** column name */
  Spot = 'spot',
  /** column name */
  SpotDate = 'spotDate',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  Tenor = 'tenor',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  Vega = 'vega',
  /** column name */
  Volatility = 'volatility'
}

export type Axe_Legs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Axe_Legs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Axe_Legs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Axe_Legs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Axe_Legs_Var_Pop_Fields = {
  __typename?: 'axe_legs_var_pop_fields';
  baseCurrDepo?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  depoCcy1?: Maybe<Scalars['Float']['output']>;
  depoCcy2?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  forwardPoints?: Maybe<Scalars['Float']['output']>;
  gamma?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingCurrDepo?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
  vega?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "axe_legs" */
export type Axe_Legs_Var_Pop_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Axe_Legs_Var_Samp_Fields = {
  __typename?: 'axe_legs_var_samp_fields';
  baseCurrDepo?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  depoCcy1?: Maybe<Scalars['Float']['output']>;
  depoCcy2?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  forwardPoints?: Maybe<Scalars['Float']['output']>;
  gamma?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingCurrDepo?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
  vega?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "axe_legs" */
export type Axe_Legs_Var_Samp_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Axe_Legs_Variance_Fields = {
  __typename?: 'axe_legs_variance_fields';
  baseCurrDepo?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  depoCcy1?: Maybe<Scalars['Float']['output']>;
  depoCcy2?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  forwardPoints?: Maybe<Scalars['Float']['output']>;
  gamma?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingCurrDepo?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
  vega?: Maybe<Scalars['Float']['output']>;
  volatility?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "axe_legs" */
export type Axe_Legs_Variance_Order_By = {
  baseCurrDepo?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  depoCcy1?: InputMaybe<Order_By>;
  depoCcy2?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  forwardPoints?: InputMaybe<Order_By>;
  gamma?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingCurrDepo?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  vega?: InputMaybe<Order_By>;
  volatility?: InputMaybe<Order_By>;
};

/** columns and relationships of "axe_tier_pricing" */
export type Axe_Tier_Pricing = Node & {
  __typename?: 'axe_tier_pricing';
  /** An array relationship */
  axe: Array<Axes>;
  axeId: Scalars['uuid']['output'];
  /** An aggregate relationship */
  axe_aggregate: Axes_Aggregate;
  /** An array relationship connection */
  axe_connection: AxesConnection;
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['ID']['output'];
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  tier: Enum_Tiers_Enum;
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "axe_tier_pricing" */
export type Axe_Tier_PricingAxeArgs = {
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};


/** columns and relationships of "axe_tier_pricing" */
export type Axe_Tier_PricingAxe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};


/** columns and relationships of "axe_tier_pricing" */
export type Axe_Tier_PricingAxe_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};

/** A Relay connection object on "axe_tier_pricing" */
export type Axe_Tier_PricingConnection = {
  __typename?: 'axe_tier_pricingConnection';
  edges: Array<Axe_Tier_PricingEdge>;
  pageInfo: PageInfo;
};

export type Axe_Tier_PricingEdge = {
  __typename?: 'axe_tier_pricingEdge';
  cursor: Scalars['String']['output'];
  node: Axe_Tier_Pricing;
};

/** aggregated selection of "axe_tier_pricing" */
export type Axe_Tier_Pricing_Aggregate = {
  __typename?: 'axe_tier_pricing_aggregate';
  aggregate?: Maybe<Axe_Tier_Pricing_Aggregate_Fields>;
  nodes: Array<Axe_Tier_Pricing>;
};

export type Axe_Tier_Pricing_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Axe_Tier_Pricing_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Axe_Tier_Pricing_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Axe_Tier_Pricing_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Axe_Tier_Pricing_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Axe_Tier_Pricing_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Axe_Tier_Pricing_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Axe_Tier_Pricing_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Axe_Tier_Pricing_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Axe_Tier_Pricing_Aggregate_Bool_Exp_Var_Samp>;
};

export type Axe_Tier_Pricing_Aggregate_Bool_Exp_Avg = {
  arguments: Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Tier_Pricing_Aggregate_Bool_Exp_Corr = {
  arguments: Axe_Tier_Pricing_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Tier_Pricing_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Axe_Tier_Pricing_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Axe_Tier_Pricing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Axe_Tier_Pricing_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Axe_Tier_Pricing_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Tier_Pricing_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Axe_Tier_Pricing_Aggregate_Bool_Exp_Max = {
  arguments: Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Tier_Pricing_Aggregate_Bool_Exp_Min = {
  arguments: Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Tier_Pricing_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Tier_Pricing_Aggregate_Bool_Exp_Sum = {
  arguments: Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Axe_Tier_Pricing_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "axe_tier_pricing" */
export type Axe_Tier_Pricing_Aggregate_Fields = {
  __typename?: 'axe_tier_pricing_aggregate_fields';
  avg?: Maybe<Axe_Tier_Pricing_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Axe_Tier_Pricing_Max_Fields>;
  min?: Maybe<Axe_Tier_Pricing_Min_Fields>;
  stddev?: Maybe<Axe_Tier_Pricing_Stddev_Fields>;
  stddev_pop?: Maybe<Axe_Tier_Pricing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Axe_Tier_Pricing_Stddev_Samp_Fields>;
  sum?: Maybe<Axe_Tier_Pricing_Sum_Fields>;
  var_pop?: Maybe<Axe_Tier_Pricing_Var_Pop_Fields>;
  var_samp?: Maybe<Axe_Tier_Pricing_Var_Samp_Fields>;
  variance?: Maybe<Axe_Tier_Pricing_Variance_Fields>;
};


/** aggregate fields of "axe_tier_pricing" */
export type Axe_Tier_Pricing_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Axe_Tier_Pricing_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Aggregate_Order_By = {
  avg?: InputMaybe<Axe_Tier_Pricing_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Axe_Tier_Pricing_Max_Order_By>;
  min?: InputMaybe<Axe_Tier_Pricing_Min_Order_By>;
  stddev?: InputMaybe<Axe_Tier_Pricing_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Axe_Tier_Pricing_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Axe_Tier_Pricing_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Axe_Tier_Pricing_Sum_Order_By>;
  var_pop?: InputMaybe<Axe_Tier_Pricing_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Axe_Tier_Pricing_Var_Samp_Order_By>;
  variance?: InputMaybe<Axe_Tier_Pricing_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Arr_Rel_Insert_Input = {
  data: Array<Axe_Tier_Pricing_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Axe_Tier_Pricing_On_Conflict>;
};

/** aggregate avg on columns */
export type Axe_Tier_Pricing_Avg_Fields = {
  __typename?: 'axe_tier_pricing_avg_fields';
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Avg_Order_By = {
  pricingVolatility?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "axe_tier_pricing". All fields are combined with a logical 'AND'. */
export type Axe_Tier_Pricing_Bool_Exp = {
  _and?: InputMaybe<Array<Axe_Tier_Pricing_Bool_Exp>>;
  _not?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
  _or?: InputMaybe<Array<Axe_Tier_Pricing_Bool_Exp>>;
  axe?: InputMaybe<Axes_Bool_Exp>;
  axeId?: InputMaybe<Uuid_Comparison_Exp>;
  axe_aggregate?: InputMaybe<Axes_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  pricingVolatility?: InputMaybe<Float8_Comparison_Exp>;
  tier?: InputMaybe<Enum_Tiers_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "axe_tier_pricing" */
export enum Axe_Tier_Pricing_Constraint {
  /** unique or primary key constraint on columns "id" */
  AxeTierPricingPkey = 'axe_tier_pricing_pkey'
}

/** input type for incrementing numeric columns in table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Inc_Input = {
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Insert_Input = {
  axe?: InputMaybe<Axes_Arr_Rel_Insert_Input>;
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
  tier?: InputMaybe<Enum_Tiers_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Axe_Tier_Pricing_Max_Fields = {
  __typename?: 'axe_tier_pricing_max_fields';
  axeId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Max_Order_By = {
  axeId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Axe_Tier_Pricing_Min_Fields = {
  __typename?: 'axe_tier_pricing_min_fields';
  axeId?: Maybe<Scalars['uuid']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Min_Order_By = {
  axeId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Mutation_Response = {
  __typename?: 'axe_tier_pricing_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Axe_Tier_Pricing>;
};

/** input type for inserting object relation for remote table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Obj_Rel_Insert_Input = {
  data: Axe_Tier_Pricing_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Axe_Tier_Pricing_On_Conflict>;
};

/** on_conflict condition type for table "axe_tier_pricing" */
export type Axe_Tier_Pricing_On_Conflict = {
  constraint: Axe_Tier_Pricing_Constraint;
  update_columns?: Array<Axe_Tier_Pricing_Update_Column>;
  where?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
};

/** Ordering options when selecting data from "axe_tier_pricing". */
export type Axe_Tier_Pricing_Order_By = {
  axeId?: InputMaybe<Order_By>;
  axe_aggregate?: InputMaybe<Axes_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  tier?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: axe_tier_pricing */
export type Axe_Tier_Pricing_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "axe_tier_pricing" */
export enum Axe_Tier_Pricing_Select_Column {
  /** column name */
  AxeId = 'axeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Tier = 'tier',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "axe_tier_pricing_aggregate_bool_exp_avg_arguments_columns" columns of table "axe_tier_pricing" */
export enum Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** select "axe_tier_pricing_aggregate_bool_exp_corr_arguments_columns" columns of table "axe_tier_pricing" */
export enum Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** select "axe_tier_pricing_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "axe_tier_pricing" */
export enum Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** select "axe_tier_pricing_aggregate_bool_exp_max_arguments_columns" columns of table "axe_tier_pricing" */
export enum Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** select "axe_tier_pricing_aggregate_bool_exp_min_arguments_columns" columns of table "axe_tier_pricing" */
export enum Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** select "axe_tier_pricing_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "axe_tier_pricing" */
export enum Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** select "axe_tier_pricing_aggregate_bool_exp_sum_arguments_columns" columns of table "axe_tier_pricing" */
export enum Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** select "axe_tier_pricing_aggregate_bool_exp_var_samp_arguments_columns" columns of table "axe_tier_pricing" */
export enum Axe_Tier_Pricing_Select_Column_Axe_Tier_Pricing_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** input type for updating data in table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Set_Input = {
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
  tier?: InputMaybe<Enum_Tiers_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Axe_Tier_Pricing_Stddev_Fields = {
  __typename?: 'axe_tier_pricing_stddev_fields';
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Stddev_Order_By = {
  pricingVolatility?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Axe_Tier_Pricing_Stddev_Pop_Fields = {
  __typename?: 'axe_tier_pricing_stddev_pop_fields';
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Stddev_Pop_Order_By = {
  pricingVolatility?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Axe_Tier_Pricing_Stddev_Samp_Fields = {
  __typename?: 'axe_tier_pricing_stddev_samp_fields';
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Stddev_Samp_Order_By = {
  pricingVolatility?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Axe_Tier_Pricing_Sum_Fields = {
  __typename?: 'axe_tier_pricing_sum_fields';
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Sum_Order_By = {
  pricingVolatility?: InputMaybe<Order_By>;
};

/** update columns of table "axe_tier_pricing" */
export enum Axe_Tier_Pricing_Update_Column {
  /** column name */
  AxeId = 'axeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Tier = 'tier',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Axe_Tier_Pricing_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Axe_Tier_Pricing_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Axe_Tier_Pricing_Set_Input>;
  /** filter the rows which have to be updated */
  where: Axe_Tier_Pricing_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Axe_Tier_Pricing_Var_Pop_Fields = {
  __typename?: 'axe_tier_pricing_var_pop_fields';
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Var_Pop_Order_By = {
  pricingVolatility?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Axe_Tier_Pricing_Var_Samp_Fields = {
  __typename?: 'axe_tier_pricing_var_samp_fields';
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Var_Samp_Order_By = {
  pricingVolatility?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Axe_Tier_Pricing_Variance_Fields = {
  __typename?: 'axe_tier_pricing_variance_fields';
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "axe_tier_pricing" */
export type Axe_Tier_Pricing_Variance_Order_By = {
  pricingVolatility?: InputMaybe<Order_By>;
};

/** columns and relationships of "axes" */
export type Axes = Node & {
  __typename?: 'axes';
  /** An array relationship */
  axe_authors: Array<Axe_Authors>;
  /** An aggregate relationship */
  axe_authors_aggregate: Axe_Authors_Aggregate;
  /** An array relationship connection */
  axe_authors_connection: Axe_AuthorsConnection;
  /** An array relationship */
  axe_legs: Array<Axe_Legs>;
  /** An aggregate relationship */
  axe_legs_aggregate: Axe_Legs_Aggregate;
  /** An array relationship connection */
  axe_legs_connection: Axe_LegsConnection;
  /** An array relationship */
  axes_axe_tier_pricings: Array<Axe_Tier_Pricing>;
  /** An aggregate relationship */
  axes_axe_tier_pricings_aggregate: Axe_Tier_Pricing_Aggregate;
  /** An array relationship connection */
  axes_axe_tier_pricings_connection: Axe_Tier_PricingConnection;
  createdAt: Scalars['timestamptz']['output'];
  filled: Scalars['Int']['output'];
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  inRFQ?: Maybe<Scalars['Boolean']['output']>;
  paused?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Enum_Axe_State_Enum>;
  tradedNotional?: Maybe<Scalars['bigint']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "axes" */
export type AxesAxe_AuthorsArgs = {
  distinct_on?: InputMaybe<Array<Axe_Authors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Authors_Order_By>>;
  where?: InputMaybe<Axe_Authors_Bool_Exp>;
};


/** columns and relationships of "axes" */
export type AxesAxe_Authors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Axe_Authors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Authors_Order_By>>;
  where?: InputMaybe<Axe_Authors_Bool_Exp>;
};


/** columns and relationships of "axes" */
export type AxesAxe_Authors_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axe_Authors_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Authors_Order_By>>;
  where?: InputMaybe<Axe_Authors_Bool_Exp>;
};


/** columns and relationships of "axes" */
export type AxesAxe_LegsArgs = {
  distinct_on?: InputMaybe<Array<Axe_Legs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Legs_Order_By>>;
  where?: InputMaybe<Axe_Legs_Bool_Exp>;
};


/** columns and relationships of "axes" */
export type AxesAxe_Legs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Axe_Legs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Legs_Order_By>>;
  where?: InputMaybe<Axe_Legs_Bool_Exp>;
};


/** columns and relationships of "axes" */
export type AxesAxe_Legs_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axe_Legs_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Legs_Order_By>>;
  where?: InputMaybe<Axe_Legs_Bool_Exp>;
};


/** columns and relationships of "axes" */
export type AxesAxes_Axe_Tier_PricingsArgs = {
  distinct_on?: InputMaybe<Array<Axe_Tier_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Tier_Pricing_Order_By>>;
  where?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
};


/** columns and relationships of "axes" */
export type AxesAxes_Axe_Tier_Pricings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Axe_Tier_Pricing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Tier_Pricing_Order_By>>;
  where?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
};


/** columns and relationships of "axes" */
export type AxesAxes_Axe_Tier_Pricings_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axe_Tier_Pricing_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Tier_Pricing_Order_By>>;
  where?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
};

/** A Relay connection object on "axes" */
export type AxesConnection = {
  __typename?: 'axesConnection';
  edges: Array<AxesEdge>;
  pageInfo: PageInfo;
};

export type AxesEdge = {
  __typename?: 'axesEdge';
  cursor: Scalars['String']['output'];
  node: Axes;
};

/** aggregated selection of "axes" */
export type Axes_Aggregate = {
  __typename?: 'axes_aggregate';
  aggregate?: Maybe<Axes_Aggregate_Fields>;
  nodes: Array<Axes>;
};

export type Axes_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Axes_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Axes_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Axes_Aggregate_Bool_Exp_Count>;
};

export type Axes_Aggregate_Bool_Exp_Bool_And = {
  arguments: Axes_Select_Column_Axes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Axes_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Axes_Select_Column_Axes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axes_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Axes_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Axes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Axes_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "axes" */
export type Axes_Aggregate_Fields = {
  __typename?: 'axes_aggregate_fields';
  avg?: Maybe<Axes_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Axes_Max_Fields>;
  min?: Maybe<Axes_Min_Fields>;
  stddev?: Maybe<Axes_Stddev_Fields>;
  stddev_pop?: Maybe<Axes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Axes_Stddev_Samp_Fields>;
  sum?: Maybe<Axes_Sum_Fields>;
  var_pop?: Maybe<Axes_Var_Pop_Fields>;
  var_samp?: Maybe<Axes_Var_Samp_Fields>;
  variance?: Maybe<Axes_Variance_Fields>;
};


/** aggregate fields of "axes" */
export type Axes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Axes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "axes" */
export type Axes_Aggregate_Order_By = {
  avg?: InputMaybe<Axes_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Axes_Max_Order_By>;
  min?: InputMaybe<Axes_Min_Order_By>;
  stddev?: InputMaybe<Axes_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Axes_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Axes_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Axes_Sum_Order_By>;
  var_pop?: InputMaybe<Axes_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Axes_Var_Samp_Order_By>;
  variance?: InputMaybe<Axes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "axes" */
export type Axes_Arr_Rel_Insert_Input = {
  data: Array<Axes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Axes_On_Conflict>;
};

/** columns and relationships of "axes_audit" */
export type Axes_Audit = Node & {
  __typename?: 'axes_audit';
  axeCreatedAt?: Maybe<Scalars['timestamptz']['output']>;
  axeId: Scalars['uuid']['output'];
  axeUpdatedAt?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  axe_authors: Array<Axe_Authors>;
  /** An aggregate relationship */
  axe_authors_aggregate: Axe_Authors_Aggregate;
  /** An array relationship connection */
  axe_authors_connection: Axe_AuthorsConnection;
  /** An array relationship */
  axe_legs_audits: Array<Axe_Legs_Audit>;
  /** An aggregate relationship */
  axe_legs_audits_aggregate: Axe_Legs_Audit_Aggregate;
  /** An array relationship connection */
  axe_legs_audits_connection: Axe_Legs_AuditConnection;
  createdAt: Scalars['timestamptz']['output'];
  filled?: Maybe<Scalars['Int']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  paused?: Maybe<Scalars['Boolean']['output']>;
  state?: Maybe<Enum_Axe_State_Enum>;
  subOrganizationId?: Maybe<Scalars['uuid']['output']>;
  tradedNotional?: Maybe<Scalars['bigint']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "axes_audit" */
export type Axes_AuditAxe_AuthorsArgs = {
  distinct_on?: InputMaybe<Array<Axe_Authors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Authors_Order_By>>;
  where?: InputMaybe<Axe_Authors_Bool_Exp>;
};


/** columns and relationships of "axes_audit" */
export type Axes_AuditAxe_Authors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Axe_Authors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Authors_Order_By>>;
  where?: InputMaybe<Axe_Authors_Bool_Exp>;
};


/** columns and relationships of "axes_audit" */
export type Axes_AuditAxe_Authors_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axe_Authors_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Authors_Order_By>>;
  where?: InputMaybe<Axe_Authors_Bool_Exp>;
};


/** columns and relationships of "axes_audit" */
export type Axes_AuditAxe_Legs_AuditsArgs = {
  distinct_on?: InputMaybe<Array<Axe_Legs_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Legs_Audit_Order_By>>;
  where?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
};


/** columns and relationships of "axes_audit" */
export type Axes_AuditAxe_Legs_Audits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Axe_Legs_Audit_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Legs_Audit_Order_By>>;
  where?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
};


/** columns and relationships of "axes_audit" */
export type Axes_AuditAxe_Legs_Audits_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axe_Legs_Audit_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Legs_Audit_Order_By>>;
  where?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
};

/** A Relay connection object on "axes_audit" */
export type Axes_AuditConnection = {
  __typename?: 'axes_auditConnection';
  edges: Array<Axes_AuditEdge>;
  pageInfo: PageInfo;
};

export type Axes_AuditEdge = {
  __typename?: 'axes_auditEdge';
  cursor: Scalars['String']['output'];
  node: Axes_Audit;
};

/** Boolean expression to filter rows from the table "axes_audit". All fields are combined with a logical 'AND'. */
export type Axes_Audit_Bool_Exp = {
  _and?: InputMaybe<Array<Axes_Audit_Bool_Exp>>;
  _not?: InputMaybe<Axes_Audit_Bool_Exp>;
  _or?: InputMaybe<Array<Axes_Audit_Bool_Exp>>;
  axeCreatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  axeId?: InputMaybe<Uuid_Comparison_Exp>;
  axeUpdatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  axe_authors?: InputMaybe<Axe_Authors_Bool_Exp>;
  axe_authors_aggregate?: InputMaybe<Axe_Authors_Aggregate_Bool_Exp>;
  axe_legs_audits?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
  axe_legs_audits_aggregate?: InputMaybe<Axe_Legs_Audit_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  filled?: InputMaybe<Int_Comparison_Exp>;
  hidden?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  paused?: InputMaybe<Boolean_Comparison_Exp>;
  state?: InputMaybe<Enum_Axe_State_Enum_Comparison_Exp>;
  subOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
  tradedNotional?: InputMaybe<Bigint_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "axes_audit" */
export enum Axes_Audit_Constraint {
  /** unique or primary key constraint on columns "id" */
  AxesAuditPkey = 'axes_audit_pkey'
}

/** input type for incrementing numeric columns in table "axes_audit" */
export type Axes_Audit_Inc_Input = {
  filled?: InputMaybe<Scalars['Int']['input']>;
  tradedNotional?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "axes_audit" */
export type Axes_Audit_Insert_Input = {
  axeCreatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  axeUpdatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  axe_authors?: InputMaybe<Axe_Authors_Arr_Rel_Insert_Input>;
  axe_legs_audits?: InputMaybe<Axe_Legs_Audit_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  filled?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  paused?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Enum_Axe_State_Enum>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  tradedNotional?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** response of any mutation on the table "axes_audit" */
export type Axes_Audit_Mutation_Response = {
  __typename?: 'axes_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Axes_Audit>;
};

/** input type for inserting object relation for remote table "axes_audit" */
export type Axes_Audit_Obj_Rel_Insert_Input = {
  data: Axes_Audit_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Axes_Audit_On_Conflict>;
};

/** on_conflict condition type for table "axes_audit" */
export type Axes_Audit_On_Conflict = {
  constraint: Axes_Audit_Constraint;
  update_columns?: Array<Axes_Audit_Update_Column>;
  where?: InputMaybe<Axes_Audit_Bool_Exp>;
};

/** Ordering options when selecting data from "axes_audit". */
export type Axes_Audit_Order_By = {
  axeCreatedAt?: InputMaybe<Order_By>;
  axeId?: InputMaybe<Order_By>;
  axeUpdatedAt?: InputMaybe<Order_By>;
  axe_authors_aggregate?: InputMaybe<Axe_Authors_Aggregate_Order_By>;
  axe_legs_audits_aggregate?: InputMaybe<Axe_Legs_Audit_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  filled?: InputMaybe<Order_By>;
  hidden?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  paused?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
  tradedNotional?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: axes_audit */
export type Axes_Audit_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "axes_audit" */
export enum Axes_Audit_Select_Column {
  /** column name */
  AxeCreatedAt = 'axeCreatedAt',
  /** column name */
  AxeId = 'axeId',
  /** column name */
  AxeUpdatedAt = 'axeUpdatedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Filled = 'filled',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  Paused = 'paused',
  /** column name */
  State = 'state',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  TradedNotional = 'tradedNotional',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "axes_audit" */
export type Axes_Audit_Set_Input = {
  axeCreatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  axeUpdatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  filled?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  paused?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Enum_Axe_State_Enum>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  tradedNotional?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "axes_audit" */
export enum Axes_Audit_Update_Column {
  /** column name */
  AxeCreatedAt = 'axeCreatedAt',
  /** column name */
  AxeId = 'axeId',
  /** column name */
  AxeUpdatedAt = 'axeUpdatedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Filled = 'filled',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  Paused = 'paused',
  /** column name */
  State = 'state',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  TradedNotional = 'tradedNotional',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Axes_Audit_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Axes_Audit_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Axes_Audit_Set_Input>;
  /** filter the rows which have to be updated */
  where: Axes_Audit_Bool_Exp;
};

/** aggregate avg on columns */
export type Axes_Avg_Fields = {
  __typename?: 'axes_avg_fields';
  filled?: Maybe<Scalars['Float']['output']>;
  tradedNotional?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "axes" */
export type Axes_Avg_Order_By = {
  filled?: InputMaybe<Order_By>;
  tradedNotional?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "axes". All fields are combined with a logical 'AND'. */
export type Axes_Bool_Exp = {
  _and?: InputMaybe<Array<Axes_Bool_Exp>>;
  _not?: InputMaybe<Axes_Bool_Exp>;
  _or?: InputMaybe<Array<Axes_Bool_Exp>>;
  axe_authors?: InputMaybe<Axe_Authors_Bool_Exp>;
  axe_authors_aggregate?: InputMaybe<Axe_Authors_Aggregate_Bool_Exp>;
  axe_legs?: InputMaybe<Axe_Legs_Bool_Exp>;
  axe_legs_aggregate?: InputMaybe<Axe_Legs_Aggregate_Bool_Exp>;
  axes_axe_tier_pricings?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
  axes_axe_tier_pricings_aggregate?: InputMaybe<Axe_Tier_Pricing_Aggregate_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  filled?: InputMaybe<Int_Comparison_Exp>;
  hidden?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inRFQ?: InputMaybe<Boolean_Comparison_Exp>;
  paused?: InputMaybe<Boolean_Comparison_Exp>;
  state?: InputMaybe<Enum_Axe_State_Enum_Comparison_Exp>;
  tradedNotional?: InputMaybe<Bigint_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "axes" */
export enum Axes_Constraint {
  /** unique or primary key constraint on columns "id" */
  AxesPkey = 'axes_pkey'
}

/** input type for incrementing numeric columns in table "axes" */
export type Axes_Inc_Input = {
  filled?: InputMaybe<Scalars['Int']['input']>;
  tradedNotional?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "axes" */
export type Axes_Insert_Input = {
  axe_authors?: InputMaybe<Axe_Authors_Arr_Rel_Insert_Input>;
  axe_legs?: InputMaybe<Axe_Legs_Arr_Rel_Insert_Input>;
  axes_axe_tier_pricings?: InputMaybe<Axe_Tier_Pricing_Arr_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  filled?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inRFQ?: InputMaybe<Scalars['Boolean']['input']>;
  paused?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Enum_Axe_State_Enum>;
  tradedNotional?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Axes_Max_Fields = {
  __typename?: 'axes_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  filled?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  tradedNotional?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "axes" */
export type Axes_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  filled?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tradedNotional?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Axes_Min_Fields = {
  __typename?: 'axes_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  filled?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  tradedNotional?: Maybe<Scalars['bigint']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "axes" */
export type Axes_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  filled?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tradedNotional?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "axes" */
export type Axes_Mutation_Response = {
  __typename?: 'axes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Axes>;
};

/** on_conflict condition type for table "axes" */
export type Axes_On_Conflict = {
  constraint: Axes_Constraint;
  update_columns?: Array<Axes_Update_Column>;
  where?: InputMaybe<Axes_Bool_Exp>;
};

/** Ordering options when selecting data from "axes". */
export type Axes_Order_By = {
  axe_authors_aggregate?: InputMaybe<Axe_Authors_Aggregate_Order_By>;
  axe_legs_aggregate?: InputMaybe<Axe_Legs_Aggregate_Order_By>;
  axes_axe_tier_pricings_aggregate?: InputMaybe<Axe_Tier_Pricing_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  filled?: InputMaybe<Order_By>;
  hidden?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inRFQ?: InputMaybe<Order_By>;
  paused?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  tradedNotional?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: axes */
export type Axes_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "axes" */
export enum Axes_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Filled = 'filled',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  InRfq = 'inRFQ',
  /** column name */
  Paused = 'paused',
  /** column name */
  State = 'state',
  /** column name */
  TradedNotional = 'tradedNotional',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "axes_aggregate_bool_exp_bool_and_arguments_columns" columns of table "axes" */
export enum Axes_Select_Column_Axes_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Hidden = 'hidden',
  /** column name */
  InRfq = 'inRFQ',
  /** column name */
  Paused = 'paused'
}

/** select "axes_aggregate_bool_exp_bool_or_arguments_columns" columns of table "axes" */
export enum Axes_Select_Column_Axes_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Hidden = 'hidden',
  /** column name */
  InRfq = 'inRFQ',
  /** column name */
  Paused = 'paused'
}

/** input type for updating data in table "axes" */
export type Axes_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  filled?: InputMaybe<Scalars['Int']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inRFQ?: InputMaybe<Scalars['Boolean']['input']>;
  paused?: InputMaybe<Scalars['Boolean']['input']>;
  state?: InputMaybe<Enum_Axe_State_Enum>;
  tradedNotional?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Axes_Stddev_Fields = {
  __typename?: 'axes_stddev_fields';
  filled?: Maybe<Scalars['Float']['output']>;
  tradedNotional?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "axes" */
export type Axes_Stddev_Order_By = {
  filled?: InputMaybe<Order_By>;
  tradedNotional?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Axes_Stddev_Pop_Fields = {
  __typename?: 'axes_stddev_pop_fields';
  filled?: Maybe<Scalars['Float']['output']>;
  tradedNotional?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "axes" */
export type Axes_Stddev_Pop_Order_By = {
  filled?: InputMaybe<Order_By>;
  tradedNotional?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Axes_Stddev_Samp_Fields = {
  __typename?: 'axes_stddev_samp_fields';
  filled?: Maybe<Scalars['Float']['output']>;
  tradedNotional?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "axes" */
export type Axes_Stddev_Samp_Order_By = {
  filled?: InputMaybe<Order_By>;
  tradedNotional?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Axes_Sum_Fields = {
  __typename?: 'axes_sum_fields';
  filled?: Maybe<Scalars['Int']['output']>;
  tradedNotional?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "axes" */
export type Axes_Sum_Order_By = {
  filled?: InputMaybe<Order_By>;
  tradedNotional?: InputMaybe<Order_By>;
};

/** update columns of table "axes" */
export enum Axes_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Filled = 'filled',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  InRfq = 'inRFQ',
  /** column name */
  Paused = 'paused',
  /** column name */
  State = 'state',
  /** column name */
  TradedNotional = 'tradedNotional',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Axes_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Axes_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Axes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Axes_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Axes_Var_Pop_Fields = {
  __typename?: 'axes_var_pop_fields';
  filled?: Maybe<Scalars['Float']['output']>;
  tradedNotional?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "axes" */
export type Axes_Var_Pop_Order_By = {
  filled?: InputMaybe<Order_By>;
  tradedNotional?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Axes_Var_Samp_Fields = {
  __typename?: 'axes_var_samp_fields';
  filled?: Maybe<Scalars['Float']['output']>;
  tradedNotional?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "axes" */
export type Axes_Var_Samp_Order_By = {
  filled?: InputMaybe<Order_By>;
  tradedNotional?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Axes_Variance_Fields = {
  __typename?: 'axes_variance_fields';
  filled?: Maybe<Scalars['Float']['output']>;
  tradedNotional?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "axes" */
export type Axes_Variance_Order_By = {
  filled?: InputMaybe<Order_By>;
  tradedNotional?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "create_users_queue" */
export type Create_Users_Queue = Node & {
  __typename?: 'create_users_queue';
  createdAt: Scalars['timestamp']['output'];
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
  role: Scalars['String']['output'];
  subOrganizationId: Scalars['uuid']['output'];
};

/** A Relay connection object on "create_users_queue" */
export type Create_Users_QueueConnection = {
  __typename?: 'create_users_queueConnection';
  edges: Array<Create_Users_QueueEdge>;
  pageInfo: PageInfo;
};

export type Create_Users_QueueEdge = {
  __typename?: 'create_users_queueEdge';
  cursor: Scalars['String']['output'];
  node: Create_Users_Queue;
};

/** Boolean expression to filter rows from the table "create_users_queue". All fields are combined with a logical 'AND'. */
export type Create_Users_Queue_Bool_Exp = {
  _and?: InputMaybe<Array<Create_Users_Queue_Bool_Exp>>;
  _not?: InputMaybe<Create_Users_Queue_Bool_Exp>;
  _or?: InputMaybe<Array<Create_Users_Queue_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  fullName?: InputMaybe<String_Comparison_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  subOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "create_users_queue" */
export enum Create_Users_Queue_Constraint {
  /** unique or primary key constraint on columns "email" */
  CreateUsersQueuePkey = 'create_users_queue_pkey'
}

/** input type for inserting data into table "create_users_queue" */
export type Create_Users_Queue_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
};

/** response of any mutation on the table "create_users_queue" */
export type Create_Users_Queue_Mutation_Response = {
  __typename?: 'create_users_queue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Create_Users_Queue>;
};

/** on_conflict condition type for table "create_users_queue" */
export type Create_Users_Queue_On_Conflict = {
  constraint: Create_Users_Queue_Constraint;
  update_columns?: Array<Create_Users_Queue_Update_Column>;
  where?: InputMaybe<Create_Users_Queue_Bool_Exp>;
};

/** Ordering options when selecting data from "create_users_queue". */
export type Create_Users_Queue_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  fullName?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: create_users_queue */
export type Create_Users_Queue_Pk_Columns_Input = {
  email: Scalars['String']['input'];
};

/** select columns of table "create_users_queue" */
export enum Create_Users_Queue_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'fullName',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Role = 'role',
  /** column name */
  SubOrganizationId = 'subOrganizationId'
}

/** input type for updating data in table "create_users_queue" */
export type Create_Users_Queue_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "create_users_queue" */
export enum Create_Users_Queue_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'fullName',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Role = 'role',
  /** column name */
  SubOrganizationId = 'subOrganizationId'
}

export type Create_Users_Queue_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Create_Users_Queue_Set_Input>;
  /** filter the rows which have to be updated */
  where: Create_Users_Queue_Bool_Exp;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** columns and relationships of "enum_axe_buysell" */
export type Enum_Axe_Buysell = Node & {
  __typename?: 'enum_axe_buysell';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_axe_buysell" */
export type Enum_Axe_BuysellConnection = {
  __typename?: 'enum_axe_buysellConnection';
  edges: Array<Enum_Axe_BuysellEdge>;
  pageInfo: PageInfo;
};

export type Enum_Axe_BuysellEdge = {
  __typename?: 'enum_axe_buysellEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Axe_Buysell;
};

/** Boolean expression to filter rows from the table "enum_axe_buysell". All fields are combined with a logical 'AND'. */
export type Enum_Axe_Buysell_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Axe_Buysell_Bool_Exp>>;
  _not?: InputMaybe<Enum_Axe_Buysell_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Axe_Buysell_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_axe_buysell" */
export enum Enum_Axe_Buysell_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAxeBuysellPkey = 'enum_axe_buysell_pkey'
}

export enum Enum_Axe_Buysell_Enum {
  Buy = 'BUY',
  Sell = 'SELL'
}

/** Boolean expression to compare columns of type "enum_axe_buysell_enum". All fields are combined with logical 'AND'. */
export type Enum_Axe_Buysell_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Axe_Buysell_Enum>;
  _in?: InputMaybe<Array<Enum_Axe_Buysell_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Axe_Buysell_Enum>;
  _nin?: InputMaybe<Array<Enum_Axe_Buysell_Enum>>;
};

/** input type for inserting data into table "enum_axe_buysell" */
export type Enum_Axe_Buysell_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_axe_buysell" */
export type Enum_Axe_Buysell_Mutation_Response = {
  __typename?: 'enum_axe_buysell_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Axe_Buysell>;
};

/** on_conflict condition type for table "enum_axe_buysell" */
export type Enum_Axe_Buysell_On_Conflict = {
  constraint: Enum_Axe_Buysell_Constraint;
  update_columns?: Array<Enum_Axe_Buysell_Update_Column>;
  where?: InputMaybe<Enum_Axe_Buysell_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_axe_buysell". */
export type Enum_Axe_Buysell_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_axe_buysell */
export type Enum_Axe_Buysell_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_axe_buysell" */
export enum Enum_Axe_Buysell_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_axe_buysell" */
export type Enum_Axe_Buysell_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_axe_buysell" */
export enum Enum_Axe_Buysell_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Axe_Buysell_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Axe_Buysell_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Axe_Buysell_Bool_Exp;
};

/** columns and relationships of "enum_axe_callput" */
export type Enum_Axe_Callput = Node & {
  __typename?: 'enum_axe_callput';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_axe_callput" */
export type Enum_Axe_CallputConnection = {
  __typename?: 'enum_axe_callputConnection';
  edges: Array<Enum_Axe_CallputEdge>;
  pageInfo: PageInfo;
};

export type Enum_Axe_CallputEdge = {
  __typename?: 'enum_axe_callputEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Axe_Callput;
};

/** Boolean expression to filter rows from the table "enum_axe_callput". All fields are combined with a logical 'AND'. */
export type Enum_Axe_Callput_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Axe_Callput_Bool_Exp>>;
  _not?: InputMaybe<Enum_Axe_Callput_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Axe_Callput_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_axe_callput" */
export enum Enum_Axe_Callput_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAxeCallputPkey = 'enum_axe_callput_pkey'
}

export enum Enum_Axe_Callput_Enum {
  Call = 'CALL',
  Put = 'PUT'
}

/** Boolean expression to compare columns of type "enum_axe_callput_enum". All fields are combined with logical 'AND'. */
export type Enum_Axe_Callput_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Axe_Callput_Enum>;
  _in?: InputMaybe<Array<Enum_Axe_Callput_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Axe_Callput_Enum>;
  _nin?: InputMaybe<Array<Enum_Axe_Callput_Enum>>;
};

/** input type for inserting data into table "enum_axe_callput" */
export type Enum_Axe_Callput_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_axe_callput" */
export type Enum_Axe_Callput_Mutation_Response = {
  __typename?: 'enum_axe_callput_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Axe_Callput>;
};

/** on_conflict condition type for table "enum_axe_callput" */
export type Enum_Axe_Callput_On_Conflict = {
  constraint: Enum_Axe_Callput_Constraint;
  update_columns?: Array<Enum_Axe_Callput_Update_Column>;
  where?: InputMaybe<Enum_Axe_Callput_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_axe_callput". */
export type Enum_Axe_Callput_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_axe_callput */
export type Enum_Axe_Callput_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_axe_callput" */
export enum Enum_Axe_Callput_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_axe_callput" */
export type Enum_Axe_Callput_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_axe_callput" */
export enum Enum_Axe_Callput_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Axe_Callput_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Axe_Callput_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Axe_Callput_Bool_Exp;
};

/** columns and relationships of "enum_axe_ccypair" */
export type Enum_Axe_Ccypair = Node & {
  __typename?: 'enum_axe_ccypair';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_axe_ccypair" */
export type Enum_Axe_CcypairConnection = {
  __typename?: 'enum_axe_ccypairConnection';
  edges: Array<Enum_Axe_CcypairEdge>;
  pageInfo: PageInfo;
};

export type Enum_Axe_CcypairEdge = {
  __typename?: 'enum_axe_ccypairEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Axe_Ccypair;
};

/** Boolean expression to filter rows from the table "enum_axe_ccypair". All fields are combined with a logical 'AND'. */
export type Enum_Axe_Ccypair_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Axe_Ccypair_Bool_Exp>>;
  _not?: InputMaybe<Enum_Axe_Ccypair_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Axe_Ccypair_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_axe_ccypair" */
export enum Enum_Axe_Ccypair_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAxeCcypairPkey = 'enum_axe_ccypair_pkey'
}

export enum Enum_Axe_Ccypair_Enum {
  Audcad = 'AUDCAD',
  Audjpy = 'AUDJPY',
  Audnzd = 'AUDNZD',
  Audusd = 'AUDUSD',
  Brljpy = 'BRLJPY',
  Brlmxn = 'BRLMXN',
  Cadchf = 'CADCHF',
  Cadjpy = 'CADJPY',
  Chfjpy = 'CHFJPY',
  Chfnok = 'CHFNOK',
  Chfsek = 'CHFSEK',
  Cnhjpy = 'CNHJPY',
  Euraud = 'EURAUD',
  Eurbrl = 'EURBRL',
  Eurcad = 'EURCAD',
  Eurchf = 'EURCHF',
  Eurcnh = 'EURCNH',
  Eurczk = 'EURCZK',
  Eurdkk = 'EURDKK',
  Eurgbp = 'EURGBP',
  Eurhkd = 'EURHKD',
  Eurhuf = 'EURHUF',
  Euridr = 'EURIDR',
  Eurinr = 'EURINR',
  Eurjpy = 'EURJPY',
  Eurkrw = 'EURKRW',
  Eurmxn = 'EURMXN',
  Eurmyr = 'EURMYR',
  Eurnok = 'EURNOK',
  Eurnzd = 'EURNZD',
  Eurphp = 'EURPHP',
  Eurpln = 'EURPLN',
  Eurrub = 'EURRUB',
  Eursek = 'EURSEK',
  Eursgd = 'EURSGD',
  Eurtry = 'EURTRY',
  Eurtwd = 'EURTWD',
  Eurusd = 'EURUSD',
  Eurzar = 'EURZAR',
  Gbpaud = 'GBPAUD',
  Gbpbrl = 'GBPBRL',
  Gbpcad = 'GBPCAD',
  Gbpchf = 'GBPCHF',
  Gbphkd = 'GBPHKD',
  Gbpjpy = 'GBPJPY',
  Gbpnok = 'GBPNOK',
  Gbpnzd = 'GBPNZD',
  Gbpsek = 'GBPSEK',
  Gbpusd = 'GBPUSD',
  Hkdjpy = 'HKDJPY',
  Jpykrw = 'JPYKRW',
  Mxnjpy = 'MXNJPY',
  Nokjpy = 'NOKJPY',
  Noksek = 'NOKSEK',
  Nzdcad = 'NZDCAD',
  Nzdjpy = 'NZDJPY',
  Nzdusd = 'NZDUSD',
  Sekjpy = 'SEKJPY',
  Tryjpy = 'TRYJPY',
  Tryzar = 'TRYZAR',
  Usdbrl = 'USDBRL',
  Usdcad = 'USDCAD',
  Usdchf = 'USDCHF',
  Usdclp = 'USDCLP',
  Usdcnh = 'USDCNH',
  Usdcop = 'USDCOP',
  Usdczk = 'USDCZK',
  Usdhkd = 'USDHKD',
  Usdhuf = 'USDHUF',
  Usdidr = 'USDIDR',
  Usdils = 'USDILS',
  Usdinr = 'USDINR',
  Usdjpy = 'USDJPY',
  Usdkrw = 'USDKRW',
  Usdmxn = 'USDMXN',
  Usdmyr = 'USDMYR',
  Usdnok = 'USDNOK',
  Usdphp = 'USDPHP',
  Usdpln = 'USDPLN',
  Usdrub = 'USDRUB',
  Usdsek = 'USDSEK',
  Usdsgd = 'USDSGD',
  Usdtry = 'USDTRY',
  Usdtwd = 'USDTWD',
  Usdzar = 'USDZAR'
}

/** Boolean expression to compare columns of type "enum_axe_ccypair_enum". All fields are combined with logical 'AND'. */
export type Enum_Axe_Ccypair_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Axe_Ccypair_Enum>;
  _in?: InputMaybe<Array<Enum_Axe_Ccypair_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Axe_Ccypair_Enum>;
  _nin?: InputMaybe<Array<Enum_Axe_Ccypair_Enum>>;
};

/** input type for inserting data into table "enum_axe_ccypair" */
export type Enum_Axe_Ccypair_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_axe_ccypair" */
export type Enum_Axe_Ccypair_Mutation_Response = {
  __typename?: 'enum_axe_ccypair_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Axe_Ccypair>;
};

/** on_conflict condition type for table "enum_axe_ccypair" */
export type Enum_Axe_Ccypair_On_Conflict = {
  constraint: Enum_Axe_Ccypair_Constraint;
  update_columns?: Array<Enum_Axe_Ccypair_Update_Column>;
  where?: InputMaybe<Enum_Axe_Ccypair_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_axe_ccypair". */
export type Enum_Axe_Ccypair_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_axe_ccypair */
export type Enum_Axe_Ccypair_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_axe_ccypair" */
export enum Enum_Axe_Ccypair_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_axe_ccypair" */
export type Enum_Axe_Ccypair_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_axe_ccypair" */
export enum Enum_Axe_Ccypair_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Axe_Ccypair_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Axe_Ccypair_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Axe_Ccypair_Bool_Exp;
};

/** columns and relationships of "enum_axe_depoccy1daycount" */
export type Enum_Axe_Depoccy1daycount = Node & {
  __typename?: 'enum_axe_depoccy1daycount';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_axe_depoccy1daycount" */
export type Enum_Axe_Depoccy1daycountConnection = {
  __typename?: 'enum_axe_depoccy1daycountConnection';
  edges: Array<Enum_Axe_Depoccy1daycountEdge>;
  pageInfo: PageInfo;
};

export type Enum_Axe_Depoccy1daycountEdge = {
  __typename?: 'enum_axe_depoccy1daycountEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Axe_Depoccy1daycount;
};

/** Boolean expression to filter rows from the table "enum_axe_depoccy1daycount". All fields are combined with a logical 'AND'. */
export type Enum_Axe_Depoccy1daycount_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Axe_Depoccy1daycount_Bool_Exp>>;
  _not?: InputMaybe<Enum_Axe_Depoccy1daycount_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Axe_Depoccy1daycount_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_axe_depoccy1daycount" */
export enum Enum_Axe_Depoccy1daycount_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAxeDepoccy1daycountPkey = 'enum_axe_depoccy1daycount_pkey'
}

export enum Enum_Axe_Depoccy1daycount_Enum {
  Act_360 = 'ACT_360',
  Act_365 = 'ACT_365'
}

/** Boolean expression to compare columns of type "enum_axe_depoccy1daycount_enum". All fields are combined with logical 'AND'. */
export type Enum_Axe_Depoccy1daycount_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Axe_Depoccy1daycount_Enum>;
  _in?: InputMaybe<Array<Enum_Axe_Depoccy1daycount_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Axe_Depoccy1daycount_Enum>;
  _nin?: InputMaybe<Array<Enum_Axe_Depoccy1daycount_Enum>>;
};

/** input type for inserting data into table "enum_axe_depoccy1daycount" */
export type Enum_Axe_Depoccy1daycount_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_axe_depoccy1daycount" */
export type Enum_Axe_Depoccy1daycount_Mutation_Response = {
  __typename?: 'enum_axe_depoccy1daycount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Axe_Depoccy1daycount>;
};

/** on_conflict condition type for table "enum_axe_depoccy1daycount" */
export type Enum_Axe_Depoccy1daycount_On_Conflict = {
  constraint: Enum_Axe_Depoccy1daycount_Constraint;
  update_columns?: Array<Enum_Axe_Depoccy1daycount_Update_Column>;
  where?: InputMaybe<Enum_Axe_Depoccy1daycount_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_axe_depoccy1daycount". */
export type Enum_Axe_Depoccy1daycount_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_axe_depoccy1daycount */
export type Enum_Axe_Depoccy1daycount_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_axe_depoccy1daycount" */
export enum Enum_Axe_Depoccy1daycount_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_axe_depoccy1daycount" */
export type Enum_Axe_Depoccy1daycount_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_axe_depoccy1daycount" */
export enum Enum_Axe_Depoccy1daycount_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Axe_Depoccy1daycount_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Axe_Depoccy1daycount_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Axe_Depoccy1daycount_Bool_Exp;
};

/** columns and relationships of "enum_axe_depoccy2daycount" */
export type Enum_Axe_Depoccy2daycount = Node & {
  __typename?: 'enum_axe_depoccy2daycount';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_axe_depoccy2daycount" */
export type Enum_Axe_Depoccy2daycountConnection = {
  __typename?: 'enum_axe_depoccy2daycountConnection';
  edges: Array<Enum_Axe_Depoccy2daycountEdge>;
  pageInfo: PageInfo;
};

export type Enum_Axe_Depoccy2daycountEdge = {
  __typename?: 'enum_axe_depoccy2daycountEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Axe_Depoccy2daycount;
};

/** Boolean expression to filter rows from the table "enum_axe_depoccy2daycount". All fields are combined with a logical 'AND'. */
export type Enum_Axe_Depoccy2daycount_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Axe_Depoccy2daycount_Bool_Exp>>;
  _not?: InputMaybe<Enum_Axe_Depoccy2daycount_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Axe_Depoccy2daycount_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_axe_depoccy2daycount" */
export enum Enum_Axe_Depoccy2daycount_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAxeDepoccy2daycountPkey = 'enum_axe_depoccy2daycount_pkey'
}

export enum Enum_Axe_Depoccy2daycount_Enum {
  Act_360 = 'ACT_360',
  Act_365 = 'ACT_365'
}

/** Boolean expression to compare columns of type "enum_axe_depoccy2daycount_enum". All fields are combined with logical 'AND'. */
export type Enum_Axe_Depoccy2daycount_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Axe_Depoccy2daycount_Enum>;
  _in?: InputMaybe<Array<Enum_Axe_Depoccy2daycount_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Axe_Depoccy2daycount_Enum>;
  _nin?: InputMaybe<Array<Enum_Axe_Depoccy2daycount_Enum>>;
};

/** input type for inserting data into table "enum_axe_depoccy2daycount" */
export type Enum_Axe_Depoccy2daycount_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_axe_depoccy2daycount" */
export type Enum_Axe_Depoccy2daycount_Mutation_Response = {
  __typename?: 'enum_axe_depoccy2daycount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Axe_Depoccy2daycount>;
};

/** on_conflict condition type for table "enum_axe_depoccy2daycount" */
export type Enum_Axe_Depoccy2daycount_On_Conflict = {
  constraint: Enum_Axe_Depoccy2daycount_Constraint;
  update_columns?: Array<Enum_Axe_Depoccy2daycount_Update_Column>;
  where?: InputMaybe<Enum_Axe_Depoccy2daycount_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_axe_depoccy2daycount". */
export type Enum_Axe_Depoccy2daycount_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_axe_depoccy2daycount */
export type Enum_Axe_Depoccy2daycount_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_axe_depoccy2daycount" */
export enum Enum_Axe_Depoccy2daycount_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_axe_depoccy2daycount" */
export type Enum_Axe_Depoccy2daycount_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_axe_depoccy2daycount" */
export enum Enum_Axe_Depoccy2daycount_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Axe_Depoccy2daycount_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Axe_Depoccy2daycount_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Axe_Depoccy2daycount_Bool_Exp;
};

/** columns and relationships of "enum_axe_hedgetype" */
export type Enum_Axe_Hedgetype = Node & {
  __typename?: 'enum_axe_hedgetype';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_axe_hedgetype" */
export type Enum_Axe_HedgetypeConnection = {
  __typename?: 'enum_axe_hedgetypeConnection';
  edges: Array<Enum_Axe_HedgetypeEdge>;
  pageInfo: PageInfo;
};

export type Enum_Axe_HedgetypeEdge = {
  __typename?: 'enum_axe_hedgetypeEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Axe_Hedgetype;
};

/** Boolean expression to filter rows from the table "enum_axe_hedgetype". All fields are combined with a logical 'AND'. */
export type Enum_Axe_Hedgetype_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Axe_Hedgetype_Bool_Exp>>;
  _not?: InputMaybe<Enum_Axe_Hedgetype_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Axe_Hedgetype_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_axe_hedgetype" */
export enum Enum_Axe_Hedgetype_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAxeHedgetypePkey = 'enum_axe_hedgetype_pkey'
}

export enum Enum_Axe_Hedgetype_Enum {
  Ndf = 'NDF',
  Forward = 'forward',
  Spot = 'spot'
}

/** Boolean expression to compare columns of type "enum_axe_hedgetype_enum". All fields are combined with logical 'AND'. */
export type Enum_Axe_Hedgetype_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Axe_Hedgetype_Enum>;
  _in?: InputMaybe<Array<Enum_Axe_Hedgetype_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Axe_Hedgetype_Enum>;
  _nin?: InputMaybe<Array<Enum_Axe_Hedgetype_Enum>>;
};

/** input type for inserting data into table "enum_axe_hedgetype" */
export type Enum_Axe_Hedgetype_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_axe_hedgetype" */
export type Enum_Axe_Hedgetype_Mutation_Response = {
  __typename?: 'enum_axe_hedgetype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Axe_Hedgetype>;
};

/** on_conflict condition type for table "enum_axe_hedgetype" */
export type Enum_Axe_Hedgetype_On_Conflict = {
  constraint: Enum_Axe_Hedgetype_Constraint;
  update_columns?: Array<Enum_Axe_Hedgetype_Update_Column>;
  where?: InputMaybe<Enum_Axe_Hedgetype_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_axe_hedgetype". */
export type Enum_Axe_Hedgetype_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_axe_hedgetype */
export type Enum_Axe_Hedgetype_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_axe_hedgetype" */
export enum Enum_Axe_Hedgetype_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_axe_hedgetype" */
export type Enum_Axe_Hedgetype_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_axe_hedgetype" */
export enum Enum_Axe_Hedgetype_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Axe_Hedgetype_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Axe_Hedgetype_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Axe_Hedgetype_Bool_Exp;
};

/** columns and relationships of "enum_axe_premiumtype" */
export type Enum_Axe_Premiumtype = Node & {
  __typename?: 'enum_axe_premiumtype';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_axe_premiumtype" */
export type Enum_Axe_PremiumtypeConnection = {
  __typename?: 'enum_axe_premiumtypeConnection';
  edges: Array<Enum_Axe_PremiumtypeEdge>;
  pageInfo: PageInfo;
};

export type Enum_Axe_PremiumtypeEdge = {
  __typename?: 'enum_axe_premiumtypeEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Axe_Premiumtype;
};

/** Boolean expression to filter rows from the table "enum_axe_premiumtype". All fields are combined with a logical 'AND'. */
export type Enum_Axe_Premiumtype_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Axe_Premiumtype_Bool_Exp>>;
  _not?: InputMaybe<Enum_Axe_Premiumtype_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Axe_Premiumtype_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_axe_premiumtype" */
export enum Enum_Axe_Premiumtype_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAxePremiumtypePkey = 'enum_axe_premiumtype_pkey'
}

export enum Enum_Axe_Premiumtype_Enum {
  Forward = 'forward',
  Spot = 'spot'
}

/** Boolean expression to compare columns of type "enum_axe_premiumtype_enum". All fields are combined with logical 'AND'. */
export type Enum_Axe_Premiumtype_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Axe_Premiumtype_Enum>;
  _in?: InputMaybe<Array<Enum_Axe_Premiumtype_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Axe_Premiumtype_Enum>;
  _nin?: InputMaybe<Array<Enum_Axe_Premiumtype_Enum>>;
};

/** input type for inserting data into table "enum_axe_premiumtype" */
export type Enum_Axe_Premiumtype_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_axe_premiumtype" */
export type Enum_Axe_Premiumtype_Mutation_Response = {
  __typename?: 'enum_axe_premiumtype_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Axe_Premiumtype>;
};

/** on_conflict condition type for table "enum_axe_premiumtype" */
export type Enum_Axe_Premiumtype_On_Conflict = {
  constraint: Enum_Axe_Premiumtype_Constraint;
  update_columns?: Array<Enum_Axe_Premiumtype_Update_Column>;
  where?: InputMaybe<Enum_Axe_Premiumtype_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_axe_premiumtype". */
export type Enum_Axe_Premiumtype_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_axe_premiumtype */
export type Enum_Axe_Premiumtype_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_axe_premiumtype" */
export enum Enum_Axe_Premiumtype_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_axe_premiumtype" */
export type Enum_Axe_Premiumtype_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_axe_premiumtype" */
export enum Enum_Axe_Premiumtype_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Axe_Premiumtype_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Axe_Premiumtype_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Axe_Premiumtype_Bool_Exp;
};

/** columns and relationships of "enum_axe_product" */
export type Enum_Axe_Product = Node & {
  __typename?: 'enum_axe_product';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_axe_product" */
export type Enum_Axe_ProductConnection = {
  __typename?: 'enum_axe_productConnection';
  edges: Array<Enum_Axe_ProductEdge>;
  pageInfo: PageInfo;
};

export type Enum_Axe_ProductEdge = {
  __typename?: 'enum_axe_productEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Axe_Product;
};

/** Boolean expression to filter rows from the table "enum_axe_product". All fields are combined with a logical 'AND'. */
export type Enum_Axe_Product_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Axe_Product_Bool_Exp>>;
  _not?: InputMaybe<Enum_Axe_Product_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Axe_Product_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_axe_product" */
export enum Enum_Axe_Product_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAxeProductPkey = 'enum_axe_product_pkey'
}

export enum Enum_Axe_Product_Enum {
  Vanilla = 'vanilla'
}

/** Boolean expression to compare columns of type "enum_axe_product_enum". All fields are combined with logical 'AND'. */
export type Enum_Axe_Product_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Axe_Product_Enum>;
  _in?: InputMaybe<Array<Enum_Axe_Product_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Axe_Product_Enum>;
  _nin?: InputMaybe<Array<Enum_Axe_Product_Enum>>;
};

/** input type for inserting data into table "enum_axe_product" */
export type Enum_Axe_Product_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_axe_product" */
export type Enum_Axe_Product_Mutation_Response = {
  __typename?: 'enum_axe_product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Axe_Product>;
};

/** on_conflict condition type for table "enum_axe_product" */
export type Enum_Axe_Product_On_Conflict = {
  constraint: Enum_Axe_Product_Constraint;
  update_columns?: Array<Enum_Axe_Product_Update_Column>;
  where?: InputMaybe<Enum_Axe_Product_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_axe_product". */
export type Enum_Axe_Product_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_axe_product */
export type Enum_Axe_Product_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_axe_product" */
export enum Enum_Axe_Product_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_axe_product" */
export type Enum_Axe_Product_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_axe_product" */
export enum Enum_Axe_Product_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Axe_Product_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Axe_Product_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Axe_Product_Bool_Exp;
};

/** columns and relationships of "enum_axe_state" */
export type Enum_Axe_State = Node & {
  __typename?: 'enum_axe_state';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_axe_state" */
export type Enum_Axe_StateConnection = {
  __typename?: 'enum_axe_stateConnection';
  edges: Array<Enum_Axe_StateEdge>;
  pageInfo: PageInfo;
};

export type Enum_Axe_StateEdge = {
  __typename?: 'enum_axe_stateEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Axe_State;
};

/** Boolean expression to filter rows from the table "enum_axe_state". All fields are combined with a logical 'AND'. */
export type Enum_Axe_State_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Axe_State_Bool_Exp>>;
  _not?: InputMaybe<Enum_Axe_State_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Axe_State_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_axe_state" */
export enum Enum_Axe_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumAxeStatePkey = 'enum_axe_state_pkey'
}

export enum Enum_Axe_State_Enum {
  Deleted = 'DELETED',
  Expired = 'EXPIRED',
  Live = 'LIVE',
  SuspendedByAdmin = 'SUSPENDED_BY_ADMIN',
  SuspendedByUser = 'SUSPENDED_BY_USER',
  Traded = 'TRADED'
}

/** Boolean expression to compare columns of type "enum_axe_state_enum". All fields are combined with logical 'AND'. */
export type Enum_Axe_State_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Axe_State_Enum>;
  _in?: InputMaybe<Array<Enum_Axe_State_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Axe_State_Enum>;
  _nin?: InputMaybe<Array<Enum_Axe_State_Enum>>;
};

/** input type for inserting data into table "enum_axe_state" */
export type Enum_Axe_State_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_axe_state" */
export type Enum_Axe_State_Mutation_Response = {
  __typename?: 'enum_axe_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Axe_State>;
};

/** on_conflict condition type for table "enum_axe_state" */
export type Enum_Axe_State_On_Conflict = {
  constraint: Enum_Axe_State_Constraint;
  update_columns?: Array<Enum_Axe_State_Update_Column>;
  where?: InputMaybe<Enum_Axe_State_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_axe_state". */
export type Enum_Axe_State_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_axe_state */
export type Enum_Axe_State_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_axe_state" */
export enum Enum_Axe_State_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_axe_state" */
export type Enum_Axe_State_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_axe_state" */
export enum Enum_Axe_State_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Axe_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Axe_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Axe_State_Bool_Exp;
};

/** columns and relationships of "enum_color_scheme" */
export type Enum_Color_Scheme = Node & {
  __typename?: 'enum_color_scheme';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_color_scheme" */
export type Enum_Color_SchemeConnection = {
  __typename?: 'enum_color_schemeConnection';
  edges: Array<Enum_Color_SchemeEdge>;
  pageInfo: PageInfo;
};

export type Enum_Color_SchemeEdge = {
  __typename?: 'enum_color_schemeEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Color_Scheme;
};

/** Boolean expression to filter rows from the table "enum_color_scheme". All fields are combined with a logical 'AND'. */
export type Enum_Color_Scheme_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Color_Scheme_Bool_Exp>>;
  _not?: InputMaybe<Enum_Color_Scheme_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Color_Scheme_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_color_scheme" */
export enum Enum_Color_Scheme_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumColorSchemePkey = 'enum_color_scheme_pkey'
}

export enum Enum_Color_Scheme_Enum {
  Dark = 'DARK',
  Light = 'LIGHT'
}

/** Boolean expression to compare columns of type "enum_color_scheme_enum". All fields are combined with logical 'AND'. */
export type Enum_Color_Scheme_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Color_Scheme_Enum>;
  _in?: InputMaybe<Array<Enum_Color_Scheme_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Color_Scheme_Enum>;
  _nin?: InputMaybe<Array<Enum_Color_Scheme_Enum>>;
};

/** input type for inserting data into table "enum_color_scheme" */
export type Enum_Color_Scheme_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_color_scheme" */
export type Enum_Color_Scheme_Mutation_Response = {
  __typename?: 'enum_color_scheme_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Color_Scheme>;
};

/** on_conflict condition type for table "enum_color_scheme" */
export type Enum_Color_Scheme_On_Conflict = {
  constraint: Enum_Color_Scheme_Constraint;
  update_columns?: Array<Enum_Color_Scheme_Update_Column>;
  where?: InputMaybe<Enum_Color_Scheme_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_color_scheme". */
export type Enum_Color_Scheme_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_color_scheme */
export type Enum_Color_Scheme_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_color_scheme" */
export enum Enum_Color_Scheme_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_color_scheme" */
export type Enum_Color_Scheme_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_color_scheme" */
export enum Enum_Color_Scheme_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Color_Scheme_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Color_Scheme_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Color_Scheme_Bool_Exp;
};

/** columns and relationships of "enum_currency_notional" */
export type Enum_Currency_Notional = Node & {
  __typename?: 'enum_currency_notional';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_currency_notional" */
export type Enum_Currency_NotionalConnection = {
  __typename?: 'enum_currency_notionalConnection';
  edges: Array<Enum_Currency_NotionalEdge>;
  pageInfo: PageInfo;
};

export type Enum_Currency_NotionalEdge = {
  __typename?: 'enum_currency_notionalEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Currency_Notional;
};

/** Boolean expression to filter rows from the table "enum_currency_notional". All fields are combined with a logical 'AND'. */
export type Enum_Currency_Notional_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Currency_Notional_Bool_Exp>>;
  _not?: InputMaybe<Enum_Currency_Notional_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Currency_Notional_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_currency_notional" */
export enum Enum_Currency_Notional_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumCurrencyNotionalPkey = 'enum_currency_notional_pkey'
}

export enum Enum_Currency_Notional_Enum {
  Aud = 'AUD',
  Brl = 'BRL',
  Cad = 'CAD',
  Chf = 'CHF',
  Clp = 'CLP',
  Cnh = 'CNH',
  Cop = 'COP',
  Czk = 'CZK',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Jpy = 'JPY',
  Krw = 'KRW',
  Mxn = 'MXN',
  Myr = 'MYR',
  Nok = 'NOK',
  Nzd = 'NZD',
  Php = 'PHP',
  Pln = 'PLN',
  Ron = 'RON',
  Rub = 'RUB',
  Sek = 'SEK',
  Sgd = 'SGD',
  Try = 'TRY',
  Twd = 'TWD',
  Usd = 'USD',
  Zar = 'ZAR'
}

/** Boolean expression to compare columns of type "enum_currency_notional_enum". All fields are combined with logical 'AND'. */
export type Enum_Currency_Notional_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Currency_Notional_Enum>;
  _in?: InputMaybe<Array<Enum_Currency_Notional_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Currency_Notional_Enum>;
  _nin?: InputMaybe<Array<Enum_Currency_Notional_Enum>>;
};

/** input type for inserting data into table "enum_currency_notional" */
export type Enum_Currency_Notional_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_currency_notional" */
export type Enum_Currency_Notional_Mutation_Response = {
  __typename?: 'enum_currency_notional_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Currency_Notional>;
};

/** on_conflict condition type for table "enum_currency_notional" */
export type Enum_Currency_Notional_On_Conflict = {
  constraint: Enum_Currency_Notional_Constraint;
  update_columns?: Array<Enum_Currency_Notional_Update_Column>;
  where?: InputMaybe<Enum_Currency_Notional_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_currency_notional". */
export type Enum_Currency_Notional_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_currency_notional */
export type Enum_Currency_Notional_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_currency_notional" */
export enum Enum_Currency_Notional_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_currency_notional" */
export type Enum_Currency_Notional_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_currency_notional" */
export enum Enum_Currency_Notional_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Currency_Notional_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Currency_Notional_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Currency_Notional_Bool_Exp;
};

/** columns and relationships of "enum_currency_premium" */
export type Enum_Currency_Premium = Node & {
  __typename?: 'enum_currency_premium';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_currency_premium" */
export type Enum_Currency_PremiumConnection = {
  __typename?: 'enum_currency_premiumConnection';
  edges: Array<Enum_Currency_PremiumEdge>;
  pageInfo: PageInfo;
};

export type Enum_Currency_PremiumEdge = {
  __typename?: 'enum_currency_premiumEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Currency_Premium;
};

/** Boolean expression to filter rows from the table "enum_currency_premium". All fields are combined with a logical 'AND'. */
export type Enum_Currency_Premium_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Currency_Premium_Bool_Exp>>;
  _not?: InputMaybe<Enum_Currency_Premium_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Currency_Premium_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_currency_premium" */
export enum Enum_Currency_Premium_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumCurrencyPremiumPkey = 'enum_currency_premium_pkey'
}

export enum Enum_Currency_Premium_Enum {
  Aud = 'AUD',
  Brl = 'BRL',
  Cad = 'CAD',
  Chf = 'CHF',
  Clp = 'CLP',
  Cnh = 'CNH',
  Cop = 'COP',
  Czk = 'CZK',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Jpy = 'JPY',
  Krw = 'KRW',
  Mxn = 'MXN',
  Myr = 'MYR',
  Nok = 'NOK',
  Nzd = 'NZD',
  Php = 'PHP',
  Pln = 'PLN',
  Ron = 'RON',
  Rub = 'RUB',
  Sek = 'SEK',
  Sgd = 'SGD',
  Try = 'TRY',
  Twd = 'TWD',
  Usd = 'USD',
  Zar = 'ZAR'
}

/** Boolean expression to compare columns of type "enum_currency_premium_enum". All fields are combined with logical 'AND'. */
export type Enum_Currency_Premium_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Currency_Premium_Enum>;
  _in?: InputMaybe<Array<Enum_Currency_Premium_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Currency_Premium_Enum>;
  _nin?: InputMaybe<Array<Enum_Currency_Premium_Enum>>;
};

/** input type for inserting data into table "enum_currency_premium" */
export type Enum_Currency_Premium_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_currency_premium" */
export type Enum_Currency_Premium_Mutation_Response = {
  __typename?: 'enum_currency_premium_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Currency_Premium>;
};

/** on_conflict condition type for table "enum_currency_premium" */
export type Enum_Currency_Premium_On_Conflict = {
  constraint: Enum_Currency_Premium_Constraint;
  update_columns?: Array<Enum_Currency_Premium_Update_Column>;
  where?: InputMaybe<Enum_Currency_Premium_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_currency_premium". */
export type Enum_Currency_Premium_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_currency_premium */
export type Enum_Currency_Premium_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_currency_premium" */
export enum Enum_Currency_Premium_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_currency_premium" */
export type Enum_Currency_Premium_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_currency_premium" */
export enum Enum_Currency_Premium_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Currency_Premium_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Currency_Premium_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Currency_Premium_Bool_Exp;
};

/** columns and relationships of "enum_instrument_type" */
export type Enum_Instrument_Type = Node & {
  __typename?: 'enum_instrument_type';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_instrument_type" */
export type Enum_Instrument_TypeConnection = {
  __typename?: 'enum_instrument_typeConnection';
  edges: Array<Enum_Instrument_TypeEdge>;
  pageInfo: PageInfo;
};

export type Enum_Instrument_TypeEdge = {
  __typename?: 'enum_instrument_typeEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Instrument_Type;
};

/** Boolean expression to filter rows from the table "enum_instrument_type". All fields are combined with a logical 'AND'. */
export type Enum_Instrument_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Instrument_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Instrument_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Instrument_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_instrument_type" */
export enum Enum_Instrument_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumInstrumentTypePkey = 'enum_instrument_type_pkey'
}

export enum Enum_Instrument_Type_Enum {
  Forward = 'forward',
  Fxoption = 'fxoption',
  Ndf = 'ndf'
}

/** Boolean expression to compare columns of type "enum_instrument_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Instrument_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Instrument_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Instrument_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Instrument_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Instrument_Type_Enum>>;
};

/** input type for inserting data into table "enum_instrument_type" */
export type Enum_Instrument_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_instrument_type" */
export type Enum_Instrument_Type_Mutation_Response = {
  __typename?: 'enum_instrument_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Instrument_Type>;
};

/** on_conflict condition type for table "enum_instrument_type" */
export type Enum_Instrument_Type_On_Conflict = {
  constraint: Enum_Instrument_Type_Constraint;
  update_columns?: Array<Enum_Instrument_Type_Update_Column>;
  where?: InputMaybe<Enum_Instrument_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_instrument_type". */
export type Enum_Instrument_Type_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_instrument_type */
export type Enum_Instrument_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_instrument_type" */
export enum Enum_Instrument_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_instrument_type" */
export type Enum_Instrument_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_instrument_type" */
export enum Enum_Instrument_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Instrument_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Instrument_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Instrument_Type_Bool_Exp;
};

/** columns and relationships of "enum_notification_channel" */
export type Enum_Notification_Channel = Node & {
  __typename?: 'enum_notification_channel';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_notification_channel" */
export type Enum_Notification_ChannelConnection = {
  __typename?: 'enum_notification_channelConnection';
  edges: Array<Enum_Notification_ChannelEdge>;
  pageInfo: PageInfo;
};

export type Enum_Notification_ChannelEdge = {
  __typename?: 'enum_notification_channelEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Notification_Channel;
};

/** Boolean expression to filter rows from the table "enum_notification_channel". All fields are combined with a logical 'AND'. */
export type Enum_Notification_Channel_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Notification_Channel_Bool_Exp>>;
  _not?: InputMaybe<Enum_Notification_Channel_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Notification_Channel_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_notification_channel" */
export enum Enum_Notification_Channel_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumNotificationChannelPkey = 'enum_notification_channel_pkey'
}

/** input type for inserting data into table "enum_notification_channel" */
export type Enum_Notification_Channel_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_notification_channel" */
export type Enum_Notification_Channel_Mutation_Response = {
  __typename?: 'enum_notification_channel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Notification_Channel>;
};

/** on_conflict condition type for table "enum_notification_channel" */
export type Enum_Notification_Channel_On_Conflict = {
  constraint: Enum_Notification_Channel_Constraint;
  update_columns?: Array<Enum_Notification_Channel_Update_Column>;
  where?: InputMaybe<Enum_Notification_Channel_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_notification_channel". */
export type Enum_Notification_Channel_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_notification_channel */
export type Enum_Notification_Channel_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_notification_channel" */
export enum Enum_Notification_Channel_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_notification_channel" */
export type Enum_Notification_Channel_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_notification_channel" */
export enum Enum_Notification_Channel_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Notification_Channel_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Notification_Channel_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Notification_Channel_Bool_Exp;
};

/** columns and relationships of "enum_notification_type" */
export type Enum_Notification_Type = Node & {
  __typename?: 'enum_notification_type';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_notification_type" */
export type Enum_Notification_TypeConnection = {
  __typename?: 'enum_notification_typeConnection';
  edges: Array<Enum_Notification_TypeEdge>;
  pageInfo: PageInfo;
};

export type Enum_Notification_TypeEdge = {
  __typename?: 'enum_notification_typeEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Notification_Type;
};

/** Boolean expression to filter rows from the table "enum_notification_type". All fields are combined with a logical 'AND'. */
export type Enum_Notification_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Notification_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Notification_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Notification_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_notification_type" */
export enum Enum_Notification_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumNotificationTypePkey = 'enum_notification_type_pkey'
}

export enum Enum_Notification_Type_Enum {
  Admin = 'ADMIN',
  Global = 'GLOBAL',
  Organization = 'ORGANIZATION',
  Personal = 'PERSONAL',
  SubOrganization = 'SUB_ORGANIZATION'
}

/** Boolean expression to compare columns of type "enum_notification_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Notification_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Notification_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Notification_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Notification_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Notification_Type_Enum>>;
};

/** input type for inserting data into table "enum_notification_type" */
export type Enum_Notification_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_notification_type" */
export type Enum_Notification_Type_Mutation_Response = {
  __typename?: 'enum_notification_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Notification_Type>;
};

/** on_conflict condition type for table "enum_notification_type" */
export type Enum_Notification_Type_On_Conflict = {
  constraint: Enum_Notification_Type_Constraint;
  update_columns?: Array<Enum_Notification_Type_Update_Column>;
  where?: InputMaybe<Enum_Notification_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_notification_type". */
export type Enum_Notification_Type_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_notification_type */
export type Enum_Notification_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_notification_type" */
export enum Enum_Notification_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_notification_type" */
export type Enum_Notification_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_notification_type" */
export enum Enum_Notification_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Notification_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Notification_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Notification_Type_Bool_Exp;
};

/** columns and relationships of "enum_ptrd_callput" */
export type Enum_Ptrd_Callput = Node & {
  __typename?: 'enum_ptrd_callput';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_ptrd_callput" */
export type Enum_Ptrd_CallputConnection = {
  __typename?: 'enum_ptrd_callputConnection';
  edges: Array<Enum_Ptrd_CallputEdge>;
  pageInfo: PageInfo;
};

export type Enum_Ptrd_CallputEdge = {
  __typename?: 'enum_ptrd_callputEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Ptrd_Callput;
};

/** Boolean expression to filter rows from the table "enum_ptrd_callput". All fields are combined with a logical 'AND'. */
export type Enum_Ptrd_Callput_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Ptrd_Callput_Bool_Exp>>;
  _not?: InputMaybe<Enum_Ptrd_Callput_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Ptrd_Callput_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_ptrd_callput" */
export enum Enum_Ptrd_Callput_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumPtrdCallputPkey = 'enum_ptrd_callput_pkey'
}

export enum Enum_Ptrd_Callput_Enum {
  Call = 'CALL',
  Othr = 'OTHR',
  Put = 'PUT'
}

/** Boolean expression to compare columns of type "enum_ptrd_callput_enum". All fields are combined with logical 'AND'. */
export type Enum_Ptrd_Callput_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Ptrd_Callput_Enum>;
  _in?: InputMaybe<Array<Enum_Ptrd_Callput_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Ptrd_Callput_Enum>;
  _nin?: InputMaybe<Array<Enum_Ptrd_Callput_Enum>>;
};

/** input type for inserting data into table "enum_ptrd_callput" */
export type Enum_Ptrd_Callput_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_ptrd_callput" */
export type Enum_Ptrd_Callput_Mutation_Response = {
  __typename?: 'enum_ptrd_callput_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Ptrd_Callput>;
};

/** on_conflict condition type for table "enum_ptrd_callput" */
export type Enum_Ptrd_Callput_On_Conflict = {
  constraint: Enum_Ptrd_Callput_Constraint;
  update_columns?: Array<Enum_Ptrd_Callput_Update_Column>;
  where?: InputMaybe<Enum_Ptrd_Callput_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_ptrd_callput". */
export type Enum_Ptrd_Callput_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_ptrd_callput */
export type Enum_Ptrd_Callput_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_ptrd_callput" */
export enum Enum_Ptrd_Callput_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_ptrd_callput" */
export type Enum_Ptrd_Callput_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_ptrd_callput" */
export enum Enum_Ptrd_Callput_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Ptrd_Callput_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Ptrd_Callput_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Ptrd_Callput_Bool_Exp;
};

/** columns and relationships of "enum_quote_side_type" */
export type Enum_Quote_Side_Type = Node & {
  __typename?: 'enum_quote_side_type';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_quote_side_type" */
export type Enum_Quote_Side_TypeConnection = {
  __typename?: 'enum_quote_side_typeConnection';
  edges: Array<Enum_Quote_Side_TypeEdge>;
  pageInfo: PageInfo;
};

export type Enum_Quote_Side_TypeEdge = {
  __typename?: 'enum_quote_side_typeEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Quote_Side_Type;
};

/** Boolean expression to filter rows from the table "enum_quote_side_type". All fields are combined with a logical 'AND'. */
export type Enum_Quote_Side_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Quote_Side_Type_Bool_Exp>>;
  _not?: InputMaybe<Enum_Quote_Side_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Quote_Side_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_quote_side_type" */
export enum Enum_Quote_Side_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumQuoteSideTypePkey = 'enum_quote_side_type_pkey'
}

export enum Enum_Quote_Side_Type_Enum {
  Bid = 'BID',
  Empty = 'EMPTY',
  Offer = 'OFFER'
}

/** Boolean expression to compare columns of type "enum_quote_side_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Quote_Side_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Quote_Side_Type_Enum>;
  _in?: InputMaybe<Array<Enum_Quote_Side_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Quote_Side_Type_Enum>;
  _nin?: InputMaybe<Array<Enum_Quote_Side_Type_Enum>>;
};

/** input type for inserting data into table "enum_quote_side_type" */
export type Enum_Quote_Side_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_quote_side_type" */
export type Enum_Quote_Side_Type_Mutation_Response = {
  __typename?: 'enum_quote_side_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Quote_Side_Type>;
};

/** on_conflict condition type for table "enum_quote_side_type" */
export type Enum_Quote_Side_Type_On_Conflict = {
  constraint: Enum_Quote_Side_Type_Constraint;
  update_columns?: Array<Enum_Quote_Side_Type_Update_Column>;
  where?: InputMaybe<Enum_Quote_Side_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_quote_side_type". */
export type Enum_Quote_Side_Type_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_quote_side_type */
export type Enum_Quote_Side_Type_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_quote_side_type" */
export enum Enum_Quote_Side_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_quote_side_type" */
export type Enum_Quote_Side_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_quote_side_type" */
export enum Enum_Quote_Side_Type_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Quote_Side_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Quote_Side_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Quote_Side_Type_Bool_Exp;
};

/** columns and relationships of "enum_rfq_option_reason" */
export type Enum_Rfq_Option_Reason = Node & {
  __typename?: 'enum_rfq_option_reason';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_rfq_option_reason" */
export type Enum_Rfq_Option_ReasonConnection = {
  __typename?: 'enum_rfq_option_reasonConnection';
  edges: Array<Enum_Rfq_Option_ReasonEdge>;
  pageInfo: PageInfo;
};

export type Enum_Rfq_Option_ReasonEdge = {
  __typename?: 'enum_rfq_option_reasonEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Rfq_Option_Reason;
};

/** Boolean expression to filter rows from the table "enum_rfq_option_reason". All fields are combined with a logical 'AND'. */
export type Enum_Rfq_Option_Reason_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Rfq_Option_Reason_Bool_Exp>>;
  _not?: InputMaybe<Enum_Rfq_Option_Reason_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Rfq_Option_Reason_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_rfq_option_reason" */
export enum Enum_Rfq_Option_Reason_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumRfqOptionReasonPkey = 'enum_rfq_option_reason_pkey'
}

export enum Enum_Rfq_Option_Reason_Enum {
  Expired = 'EXPIRED',
  OrderNotLatestQuote = 'ORDER_NOT_LATEST_QUOTE',
  UserCancelled = 'USER_CANCELLED'
}

/** Boolean expression to compare columns of type "enum_rfq_option_reason_enum". All fields are combined with logical 'AND'. */
export type Enum_Rfq_Option_Reason_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Rfq_Option_Reason_Enum>;
  _in?: InputMaybe<Array<Enum_Rfq_Option_Reason_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Rfq_Option_Reason_Enum>;
  _nin?: InputMaybe<Array<Enum_Rfq_Option_Reason_Enum>>;
};

/** input type for inserting data into table "enum_rfq_option_reason" */
export type Enum_Rfq_Option_Reason_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_rfq_option_reason" */
export type Enum_Rfq_Option_Reason_Mutation_Response = {
  __typename?: 'enum_rfq_option_reason_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Rfq_Option_Reason>;
};

/** on_conflict condition type for table "enum_rfq_option_reason" */
export type Enum_Rfq_Option_Reason_On_Conflict = {
  constraint: Enum_Rfq_Option_Reason_Constraint;
  update_columns?: Array<Enum_Rfq_Option_Reason_Update_Column>;
  where?: InputMaybe<Enum_Rfq_Option_Reason_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_rfq_option_reason". */
export type Enum_Rfq_Option_Reason_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_rfq_option_reason */
export type Enum_Rfq_Option_Reason_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_rfq_option_reason" */
export enum Enum_Rfq_Option_Reason_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_rfq_option_reason" */
export type Enum_Rfq_Option_Reason_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_rfq_option_reason" */
export enum Enum_Rfq_Option_Reason_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Rfq_Option_Reason_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Rfq_Option_Reason_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Rfq_Option_Reason_Bool_Exp;
};

/** columns and relationships of "enum_rfq_status" */
export type Enum_Rfq_Status = Node & {
  __typename?: 'enum_rfq_status';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_rfq_status" */
export type Enum_Rfq_StatusConnection = {
  __typename?: 'enum_rfq_statusConnection';
  edges: Array<Enum_Rfq_StatusEdge>;
  pageInfo: PageInfo;
};

export type Enum_Rfq_StatusEdge = {
  __typename?: 'enum_rfq_statusEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Rfq_Status;
};

/** Boolean expression to filter rows from the table "enum_rfq_status". All fields are combined with a logical 'AND'. */
export type Enum_Rfq_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Rfq_Status_Bool_Exp>>;
  _not?: InputMaybe<Enum_Rfq_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Rfq_Status_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_rfq_status" */
export enum Enum_Rfq_Status_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumRfqStatusPkey = 'enum_rfq_status_pkey'
}

export enum Enum_Rfq_Status_Enum {
  Done = 'Done',
  NewOrder = 'NewOrder',
  NoQuote = 'NoQuote',
  OrderReject = 'OrderReject',
  Quote = 'Quote',
  QuoteCancel = 'QuoteCancel',
  QuoteRequest = 'QuoteRequest',
  QuoteRequestAck = 'QuoteRequestAck',
  QuoteRequestCancel = 'QuoteRequestCancel',
  QuoteRequestReject = 'QuoteRequestReject'
}

/** Boolean expression to compare columns of type "enum_rfq_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Rfq_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Rfq_Status_Enum>;
  _in?: InputMaybe<Array<Enum_Rfq_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Rfq_Status_Enum>;
  _nin?: InputMaybe<Array<Enum_Rfq_Status_Enum>>;
};

/** input type for inserting data into table "enum_rfq_status" */
export type Enum_Rfq_Status_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_rfq_status" */
export type Enum_Rfq_Status_Mutation_Response = {
  __typename?: 'enum_rfq_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Rfq_Status>;
};

/** on_conflict condition type for table "enum_rfq_status" */
export type Enum_Rfq_Status_On_Conflict = {
  constraint: Enum_Rfq_Status_Constraint;
  update_columns?: Array<Enum_Rfq_Status_Update_Column>;
  where?: InputMaybe<Enum_Rfq_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_rfq_status". */
export type Enum_Rfq_Status_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_rfq_status */
export type Enum_Rfq_Status_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_rfq_status" */
export enum Enum_Rfq_Status_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_rfq_status" */
export type Enum_Rfq_Status_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_rfq_status" */
export enum Enum_Rfq_Status_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Rfq_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Rfq_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Rfq_Status_Bool_Exp;
};

/** columns and relationships of "enum_tenor" */
export type Enum_Tenor = Node & {
  __typename?: 'enum_tenor';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_tenor" */
export type Enum_TenorConnection = {
  __typename?: 'enum_tenorConnection';
  edges: Array<Enum_TenorEdge>;
  pageInfo: PageInfo;
};

export type Enum_TenorEdge = {
  __typename?: 'enum_tenorEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Tenor;
};

/** Boolean expression to filter rows from the table "enum_tenor". All fields are combined with a logical 'AND'. */
export type Enum_Tenor_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Tenor_Bool_Exp>>;
  _not?: InputMaybe<Enum_Tenor_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Tenor_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_tenor" */
export enum Enum_Tenor_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumTenorPkey = 'enum_tenor_pkey'
}

export enum Enum_Tenor_Enum {
  ON = 'O_N',
  T_1M = 't_1M',
  T_1W = 't_1W',
  T_1Y = 't_1Y',
  T_2D = 't_2D',
  T_2M = 't_2M',
  T_2W = 't_2W',
  T_2Y = 't_2Y',
  T_3D = 't_3D',
  T_3M = 't_3M',
  T_3W = 't_3W',
  T_3Y = 't_3Y',
  T_4D = 't_4D',
  T_4M = 't_4M',
  T_4Y = 't_4Y',
  T_5D = 't_5D',
  T_5M = 't_5M',
  T_5Y = 't_5Y',
  T_6D = 't_6D',
  T_6M = 't_6M',
  T_6Y = 't_6Y',
  T_7M = 't_7M',
  T_7Y = 't_7Y',
  T_8M = 't_8M',
  T_8Y = 't_8Y',
  T_9M = 't_9M',
  T_9Y = 't_9Y',
  T_10M = 't_10M',
  T_10Y = 't_10Y',
  T_11M = 't_11M'
}

/** Boolean expression to compare columns of type "enum_tenor_enum". All fields are combined with logical 'AND'. */
export type Enum_Tenor_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Tenor_Enum>;
  _in?: InputMaybe<Array<Enum_Tenor_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Tenor_Enum>;
  _nin?: InputMaybe<Array<Enum_Tenor_Enum>>;
};

/** input type for inserting data into table "enum_tenor" */
export type Enum_Tenor_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_tenor" */
export type Enum_Tenor_Mutation_Response = {
  __typename?: 'enum_tenor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Tenor>;
};

/** on_conflict condition type for table "enum_tenor" */
export type Enum_Tenor_On_Conflict = {
  constraint: Enum_Tenor_Constraint;
  update_columns?: Array<Enum_Tenor_Update_Column>;
  where?: InputMaybe<Enum_Tenor_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_tenor". */
export type Enum_Tenor_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_tenor */
export type Enum_Tenor_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_tenor" */
export enum Enum_Tenor_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_tenor" */
export type Enum_Tenor_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_tenor" */
export enum Enum_Tenor_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Tenor_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Tenor_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Tenor_Bool_Exp;
};

/** columns and relationships of "enum_tiers" */
export type Enum_Tiers = Node & {
  __typename?: 'enum_tiers';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_tiers" */
export type Enum_TiersConnection = {
  __typename?: 'enum_tiersConnection';
  edges: Array<Enum_TiersEdge>;
  pageInfo: PageInfo;
};

export type Enum_TiersEdge = {
  __typename?: 'enum_tiersEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Tiers;
};

/** Boolean expression to filter rows from the table "enum_tiers". All fields are combined with a logical 'AND'. */
export type Enum_Tiers_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Tiers_Bool_Exp>>;
  _not?: InputMaybe<Enum_Tiers_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Tiers_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_tiers" */
export enum Enum_Tiers_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumTiersPkey = 'enum_tiers_pkey'
}

export enum Enum_Tiers_Enum {
  T_1 = 't_1',
  T_2 = 't_2',
  T_3 = 't_3'
}

/** Boolean expression to compare columns of type "enum_tiers_enum". All fields are combined with logical 'AND'. */
export type Enum_Tiers_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Tiers_Enum>;
  _in?: InputMaybe<Array<Enum_Tiers_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Tiers_Enum>;
  _nin?: InputMaybe<Array<Enum_Tiers_Enum>>;
};

/** input type for inserting data into table "enum_tiers" */
export type Enum_Tiers_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_tiers" */
export type Enum_Tiers_Mutation_Response = {
  __typename?: 'enum_tiers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Tiers>;
};

/** on_conflict condition type for table "enum_tiers" */
export type Enum_Tiers_On_Conflict = {
  constraint: Enum_Tiers_Constraint;
  update_columns?: Array<Enum_Tiers_Update_Column>;
  where?: InputMaybe<Enum_Tiers_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_tiers". */
export type Enum_Tiers_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_tiers */
export type Enum_Tiers_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_tiers" */
export enum Enum_Tiers_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_tiers" */
export type Enum_Tiers_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_tiers" */
export enum Enum_Tiers_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Tiers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Tiers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Tiers_Bool_Exp;
};

/** columns and relationships of "enum_users_axe_suspended_state" */
export type Enum_Users_Axe_Suspended_State = Node & {
  __typename?: 'enum_users_axe_suspended_state';
  id: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** A Relay connection object on "enum_users_axe_suspended_state" */
export type Enum_Users_Axe_Suspended_StateConnection = {
  __typename?: 'enum_users_axe_suspended_stateConnection';
  edges: Array<Enum_Users_Axe_Suspended_StateEdge>;
  pageInfo: PageInfo;
};

export type Enum_Users_Axe_Suspended_StateEdge = {
  __typename?: 'enum_users_axe_suspended_stateEdge';
  cursor: Scalars['String']['output'];
  node: Enum_Users_Axe_Suspended_State;
};

/** Boolean expression to filter rows from the table "enum_users_axe_suspended_state". All fields are combined with a logical 'AND'. */
export type Enum_Users_Axe_Suspended_State_Bool_Exp = {
  _and?: InputMaybe<Array<Enum_Users_Axe_Suspended_State_Bool_Exp>>;
  _not?: InputMaybe<Enum_Users_Axe_Suspended_State_Bool_Exp>;
  _or?: InputMaybe<Array<Enum_Users_Axe_Suspended_State_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "enum_users_axe_suspended_state" */
export enum Enum_Users_Axe_Suspended_State_Constraint {
  /** unique or primary key constraint on columns "value" */
  EnumUsersAxeSuspendedStatePkey = 'enum_users_axe_suspended_state_pkey'
}

export enum Enum_Users_Axe_Suspended_State_Enum {
  SuspendedByAdmin = 'SUSPENDED_BY_ADMIN',
  SuspendedByUser = 'SUSPENDED_BY_USER',
  SuspendedByUserAndAdmin = 'SUSPENDED_BY_USER_AND_ADMIN'
}

/** Boolean expression to compare columns of type "enum_users_axe_suspended_state_enum". All fields are combined with logical 'AND'. */
export type Enum_Users_Axe_Suspended_State_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Enum_Users_Axe_Suspended_State_Enum>;
  _in?: InputMaybe<Array<Enum_Users_Axe_Suspended_State_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Enum_Users_Axe_Suspended_State_Enum>;
  _nin?: InputMaybe<Array<Enum_Users_Axe_Suspended_State_Enum>>;
};

/** input type for inserting data into table "enum_users_axe_suspended_state" */
export type Enum_Users_Axe_Suspended_State_Insert_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "enum_users_axe_suspended_state" */
export type Enum_Users_Axe_Suspended_State_Mutation_Response = {
  __typename?: 'enum_users_axe_suspended_state_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Enum_Users_Axe_Suspended_State>;
};

/** on_conflict condition type for table "enum_users_axe_suspended_state" */
export type Enum_Users_Axe_Suspended_State_On_Conflict = {
  constraint: Enum_Users_Axe_Suspended_State_Constraint;
  update_columns?: Array<Enum_Users_Axe_Suspended_State_Update_Column>;
  where?: InputMaybe<Enum_Users_Axe_Suspended_State_Bool_Exp>;
};

/** Ordering options when selecting data from "enum_users_axe_suspended_state". */
export type Enum_Users_Axe_Suspended_State_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: enum_users_axe_suspended_state */
export type Enum_Users_Axe_Suspended_State_Pk_Columns_Input = {
  value: Scalars['String']['input'];
};

/** select columns of table "enum_users_axe_suspended_state" */
export enum Enum_Users_Axe_Suspended_State_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "enum_users_axe_suspended_state" */
export type Enum_Users_Axe_Suspended_State_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "enum_users_axe_suspended_state" */
export enum Enum_Users_Axe_Suspended_State_Update_Column {
  /** column name */
  Value = 'value'
}

export type Enum_Users_Axe_Suspended_State_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enum_Users_Axe_Suspended_State_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enum_Users_Axe_Suspended_State_Bool_Exp;
};

/** columns and relationships of "expiry_cuts" */
export type Expiry_Cuts = Node & {
  __typename?: 'expiry_cuts';
  IANATimezone: Scalars['String']['output'];
  city: Scalars['String']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['timestamp']['output'];
  id: Scalars['ID']['output'];
  time: Scalars['String']['output'];
  updatedAt: Scalars['timestamp']['output'];
};

/** A Relay connection object on "expiry_cuts" */
export type Expiry_CutsConnection = {
  __typename?: 'expiry_cutsConnection';
  edges: Array<Expiry_CutsEdge>;
  pageInfo: PageInfo;
};

export type Expiry_CutsEdge = {
  __typename?: 'expiry_cutsEdge';
  cursor: Scalars['String']['output'];
  node: Expiry_Cuts;
};

/** Boolean expression to filter rows from the table "expiry_cuts". All fields are combined with a logical 'AND'. */
export type Expiry_Cuts_Bool_Exp = {
  IANATimezone?: InputMaybe<String_Comparison_Exp>;
  _and?: InputMaybe<Array<Expiry_Cuts_Bool_Exp>>;
  _not?: InputMaybe<Expiry_Cuts_Bool_Exp>;
  _or?: InputMaybe<Array<Expiry_Cuts_Bool_Exp>>;
  city?: InputMaybe<String_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamp_Comparison_Exp>;
  time?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "expiry_cuts" */
export enum Expiry_Cuts_Constraint {
  /** unique or primary key constraint on columns "code" */
  ExpiryCutsPkey = 'expiry_cuts_pkey'
}

/** input type for inserting data into table "expiry_cuts" */
export type Expiry_Cuts_Insert_Input = {
  IANATimezone?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** response of any mutation on the table "expiry_cuts" */
export type Expiry_Cuts_Mutation_Response = {
  __typename?: 'expiry_cuts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Expiry_Cuts>;
};

/** input type for inserting object relation for remote table "expiry_cuts" */
export type Expiry_Cuts_Obj_Rel_Insert_Input = {
  data: Expiry_Cuts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Expiry_Cuts_On_Conflict>;
};

/** on_conflict condition type for table "expiry_cuts" */
export type Expiry_Cuts_On_Conflict = {
  constraint: Expiry_Cuts_Constraint;
  update_columns?: Array<Expiry_Cuts_Update_Column>;
  where?: InputMaybe<Expiry_Cuts_Bool_Exp>;
};

/** Ordering options when selecting data from "expiry_cuts". */
export type Expiry_Cuts_Order_By = {
  IANATimezone?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  time?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: expiry_cuts */
export type Expiry_Cuts_Pk_Columns_Input = {
  code: Scalars['String']['input'];
};

/** select columns of table "expiry_cuts" */
export enum Expiry_Cuts_Select_Column {
  /** column name */
  IanaTimezone = 'IANATimezone',
  /** column name */
  City = 'city',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Time = 'time',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "expiry_cuts" */
export type Expiry_Cuts_Set_Input = {
  IANATimezone?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamp']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "expiry_cuts" */
export enum Expiry_Cuts_Update_Column {
  /** column name */
  IanaTimezone = 'IANATimezone',
  /** column name */
  City = 'city',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Time = 'time',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Expiry_Cuts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Expiry_Cuts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Expiry_Cuts_Bool_Exp;
};

/** columns and relationships of "fcaFeedbackMonitor" */
export type FcaFeedbackMonitor = {
  __typename?: 'fcaFeedbackMonitor';
  createdAt: Scalars['timestamptz']['output'];
  feedbackName?: Maybe<Scalars['String']['output']>;
  reportName: Scalars['String']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};

/** Boolean expression to filter rows from the table "fcaFeedbackMonitor". All fields are combined with a logical 'AND'. */
export type FcaFeedbackMonitor_Bool_Exp = {
  _and?: InputMaybe<Array<FcaFeedbackMonitor_Bool_Exp>>;
  _not?: InputMaybe<FcaFeedbackMonitor_Bool_Exp>;
  _or?: InputMaybe<Array<FcaFeedbackMonitor_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  feedbackName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  reportName?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "fcaFeedbackMonitor" */
export type FcaFeedbackMonitor_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  feedbackName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  reportName?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** response of any mutation on the table "fcaFeedbackMonitor" */
export type FcaFeedbackMonitor_Mutation_Response = {
  __typename?: 'fcaFeedbackMonitor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<FcaFeedbackMonitor>;
};

/** input type for updating data in table "fcaFeedbackMonitor" */
export type FcaFeedbackMonitor_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  feedbackName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  reportName?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

export type FcaFeedbackMonitor_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FcaFeedbackMonitor_Set_Input>;
  /** filter the rows which have to be updated */
  where: FcaFeedbackMonitor_Bool_Exp;
};

/** columns and relationships of "filters" */
export type Filters = Node & {
  __typename?: 'filters';
  axeAuthors?: Maybe<Scalars['jsonb']['output']>;
  buySell?: Maybe<Scalars['jsonb']['output']>;
  ccyPair?: Maybe<Scalars['jsonb']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  delta?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['ID']['output'];
  inactiveFilters?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  organizationId?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Scalars['jsonb']['output']>;
  tenor?: Maybe<Scalars['jsonb']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  userId: Scalars['String']['output'];
};


/** columns and relationships of "filters" */
export type FiltersAxeAuthorsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "filters" */
export type FiltersBuySellArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "filters" */
export type FiltersCcyPairArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "filters" */
export type FiltersDeltaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "filters" */
export type FiltersProductArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "filters" */
export type FiltersTenorArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** A Relay connection object on "filters" */
export type FiltersConnection = {
  __typename?: 'filtersConnection';
  edges: Array<FiltersEdge>;
  pageInfo: PageInfo;
};

export type FiltersEdge = {
  __typename?: 'filtersEdge';
  cursor: Scalars['String']['output'];
  node: Filters;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Filters_Append_Input = {
  axeAuthors?: InputMaybe<Scalars['jsonb']['input']>;
  buySell?: InputMaybe<Scalars['jsonb']['input']>;
  ccyPair?: InputMaybe<Scalars['jsonb']['input']>;
  delta?: InputMaybe<Scalars['jsonb']['input']>;
  product?: InputMaybe<Scalars['jsonb']['input']>;
  tenor?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "filters". All fields are combined with a logical 'AND'. */
export type Filters_Bool_Exp = {
  _and?: InputMaybe<Array<Filters_Bool_Exp>>;
  _not?: InputMaybe<Filters_Bool_Exp>;
  _or?: InputMaybe<Array<Filters_Bool_Exp>>;
  axeAuthors?: InputMaybe<Jsonb_Comparison_Exp>;
  buySell?: InputMaybe<Jsonb_Comparison_Exp>;
  ccyPair?: InputMaybe<Jsonb_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  delta?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inactiveFilters?: InputMaybe<String_Array_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  product?: InputMaybe<Jsonb_Comparison_Exp>;
  tenor?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "filters" */
export enum Filters_Constraint {
  /** unique or primary key constraint on columns "id" */
  FiltersPkey = 'filters_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Filters_Delete_At_Path_Input = {
  axeAuthors?: InputMaybe<Array<Scalars['String']['input']>>;
  buySell?: InputMaybe<Array<Scalars['String']['input']>>;
  ccyPair?: InputMaybe<Array<Scalars['String']['input']>>;
  delta?: InputMaybe<Array<Scalars['String']['input']>>;
  product?: InputMaybe<Array<Scalars['String']['input']>>;
  tenor?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Filters_Delete_Elem_Input = {
  axeAuthors?: InputMaybe<Scalars['Int']['input']>;
  buySell?: InputMaybe<Scalars['Int']['input']>;
  ccyPair?: InputMaybe<Scalars['Int']['input']>;
  delta?: InputMaybe<Scalars['Int']['input']>;
  product?: InputMaybe<Scalars['Int']['input']>;
  tenor?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Filters_Delete_Key_Input = {
  axeAuthors?: InputMaybe<Scalars['String']['input']>;
  buySell?: InputMaybe<Scalars['String']['input']>;
  ccyPair?: InputMaybe<Scalars['String']['input']>;
  delta?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Scalars['String']['input']>;
  tenor?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "filters" */
export type Filters_Insert_Input = {
  axeAuthors?: InputMaybe<Scalars['jsonb']['input']>;
  buySell?: InputMaybe<Scalars['jsonb']['input']>;
  ccyPair?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  delta?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inactiveFilters?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Scalars['jsonb']['input']>;
  tenor?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "filters" */
export type Filters_Mutation_Response = {
  __typename?: 'filters_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Filters>;
};

/** on_conflict condition type for table "filters" */
export type Filters_On_Conflict = {
  constraint: Filters_Constraint;
  update_columns?: Array<Filters_Update_Column>;
  where?: InputMaybe<Filters_Bool_Exp>;
};

/** Ordering options when selecting data from "filters". */
export type Filters_Order_By = {
  axeAuthors?: InputMaybe<Order_By>;
  buySell?: InputMaybe<Order_By>;
  ccyPair?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inactiveFilters?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  product?: InputMaybe<Order_By>;
  tenor?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: filters */
export type Filters_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Filters_Prepend_Input = {
  axeAuthors?: InputMaybe<Scalars['jsonb']['input']>;
  buySell?: InputMaybe<Scalars['jsonb']['input']>;
  ccyPair?: InputMaybe<Scalars['jsonb']['input']>;
  delta?: InputMaybe<Scalars['jsonb']['input']>;
  product?: InputMaybe<Scalars['jsonb']['input']>;
  tenor?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "filters" */
export enum Filters_Select_Column {
  /** column name */
  AxeAuthors = 'axeAuthors',
  /** column name */
  BuySell = 'buySell',
  /** column name */
  CcyPair = 'ccyPair',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Delta = 'delta',
  /** column name */
  Id = 'id',
  /** column name */
  InactiveFilters = 'inactiveFilters',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Product = 'product',
  /** column name */
  Tenor = 'tenor',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "filters" */
export type Filters_Set_Input = {
  axeAuthors?: InputMaybe<Scalars['jsonb']['input']>;
  buySell?: InputMaybe<Scalars['jsonb']['input']>;
  ccyPair?: InputMaybe<Scalars['jsonb']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  delta?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inactiveFilters?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  product?: InputMaybe<Scalars['jsonb']['input']>;
  tenor?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "filters" */
export enum Filters_Update_Column {
  /** column name */
  AxeAuthors = 'axeAuthors',
  /** column name */
  BuySell = 'buySell',
  /** column name */
  CcyPair = 'ccyPair',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Delta = 'delta',
  /** column name */
  Id = 'id',
  /** column name */
  InactiveFilters = 'inactiveFilters',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Product = 'product',
  /** column name */
  Tenor = 'tenor',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Filters_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Filters_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Filters_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Filters_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Filters_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Filters_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Filters_Set_Input>;
  /** filter the rows which have to be updated */
  where: Filters_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** columns and relationships of "funds" */
export type Funds = Node & {
  __typename?: 'funds';
  id: Scalars['ID']['output'];
  lei: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organisationId: Scalars['String']['output'];
};

/** A Relay connection object on "funds" */
export type FundsConnection = {
  __typename?: 'fundsConnection';
  edges: Array<FundsEdge>;
  pageInfo: PageInfo;
};

export type FundsEdge = {
  __typename?: 'fundsEdge';
  cursor: Scalars['String']['output'];
  node: Funds;
};

/** Boolean expression to filter rows from the table "funds". All fields are combined with a logical 'AND'. */
export type Funds_Bool_Exp = {
  _and?: InputMaybe<Array<Funds_Bool_Exp>>;
  _not?: InputMaybe<Funds_Bool_Exp>;
  _or?: InputMaybe<Array<Funds_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lei?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organisationId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "funds" */
export enum Funds_Constraint {
  /** unique or primary key constraint on columns "id" */
  FundsPkey = 'funds_pkey'
}

/** input type for inserting data into table "funds" */
export type Funds_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  lei?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organisationId?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "funds" */
export type Funds_Mutation_Response = {
  __typename?: 'funds_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Funds>;
};

/** input type for inserting object relation for remote table "funds" */
export type Funds_Obj_Rel_Insert_Input = {
  data: Funds_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Funds_On_Conflict>;
};

/** on_conflict condition type for table "funds" */
export type Funds_On_Conflict = {
  constraint: Funds_Constraint;
  update_columns?: Array<Funds_Update_Column>;
  where?: InputMaybe<Funds_Bool_Exp>;
};

/** Ordering options when selecting data from "funds". */
export type Funds_Order_By = {
  id?: InputMaybe<Order_By>;
  lei?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organisationId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: funds */
export type Funds_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "funds" */
export enum Funds_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Lei = 'lei',
  /** column name */
  Name = 'name',
  /** column name */
  OrganisationId = 'organisationId'
}

/** input type for updating data in table "funds" */
export type Funds_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  lei?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organisationId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "funds" */
export enum Funds_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Lei = 'lei',
  /** column name */
  Name = 'name',
  /** column name */
  OrganisationId = 'organisationId'
}

export type Funds_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Funds_Set_Input>;
  /** filter the rows which have to be updated */
  where: Funds_Bool_Exp;
};

/** columns and relationships of "give_up_requests" */
export type Give_Up_Requests = Node & {
  __typename?: 'give_up_requests';
  email: Scalars['String']['output'];
  fundName: Scalars['String']['output'];
  giveupName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** A Relay connection object on "give_up_requests" */
export type Give_Up_RequestsConnection = {
  __typename?: 'give_up_requestsConnection';
  edges: Array<Give_Up_RequestsEdge>;
  pageInfo: PageInfo;
};

export type Give_Up_RequestsEdge = {
  __typename?: 'give_up_requestsEdge';
  cursor: Scalars['String']['output'];
  node: Give_Up_Requests;
};

/** Boolean expression to filter rows from the table "give_up_requests". All fields are combined with a logical 'AND'. */
export type Give_Up_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Give_Up_Requests_Bool_Exp>>;
  _not?: InputMaybe<Give_Up_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Give_Up_Requests_Bool_Exp>>;
  email?: InputMaybe<String_Comparison_Exp>;
  fundName?: InputMaybe<String_Comparison_Exp>;
  giveupName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "give_up_requests" */
export enum Give_Up_Requests_Constraint {
  /** unique or primary key constraint on columns "id" */
  GiveUpRequestsPkey = 'give_up_requests_pkey'
}

/** input type for inserting data into table "give_up_requests" */
export type Give_Up_Requests_Insert_Input = {
  email?: InputMaybe<Scalars['String']['input']>;
  fundName?: InputMaybe<Scalars['String']['input']>;
  giveupName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** response of any mutation on the table "give_up_requests" */
export type Give_Up_Requests_Mutation_Response = {
  __typename?: 'give_up_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Give_Up_Requests>;
};

/** on_conflict condition type for table "give_up_requests" */
export type Give_Up_Requests_On_Conflict = {
  constraint: Give_Up_Requests_Constraint;
  update_columns?: Array<Give_Up_Requests_Update_Column>;
  where?: InputMaybe<Give_Up_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "give_up_requests". */
export type Give_Up_Requests_Order_By = {
  email?: InputMaybe<Order_By>;
  fundName?: InputMaybe<Order_By>;
  giveupName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: give_up_requests */
export type Give_Up_Requests_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "give_up_requests" */
export enum Give_Up_Requests_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  FundName = 'fundName',
  /** column name */
  GiveupName = 'giveupName',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "give_up_requests" */
export type Give_Up_Requests_Set_Input = {
  email?: InputMaybe<Scalars['String']['input']>;
  fundName?: InputMaybe<Scalars['String']['input']>;
  giveupName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "give_up_requests" */
export enum Give_Up_Requests_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  FundName = 'fundName',
  /** column name */
  GiveupName = 'giveupName',
  /** column name */
  Id = 'id'
}

export type Give_Up_Requests_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Give_Up_Requests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Give_Up_Requests_Bool_Exp;
};

/** columns and relationships of "give_ups" */
export type Give_Ups = Node & {
  __typename?: 'give_ups';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lei: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  organizations: Array<Give_Ups_Organizations>;
  /** An aggregate relationship */
  organizations_aggregate: Give_Ups_Organizations_Aggregate;
  /** An array relationship connection */
  organizations_connection: Give_Ups_OrganizationsConnection;
};


/** columns and relationships of "give_ups" */
export type Give_UpsOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Give_Ups_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Give_Ups_Organizations_Order_By>>;
  where?: InputMaybe<Give_Ups_Organizations_Bool_Exp>;
};


/** columns and relationships of "give_ups" */
export type Give_UpsOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Give_Ups_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Give_Ups_Organizations_Order_By>>;
  where?: InputMaybe<Give_Ups_Organizations_Bool_Exp>;
};


/** columns and relationships of "give_ups" */
export type Give_UpsOrganizations_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Give_Ups_Organizations_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Give_Ups_Organizations_Order_By>>;
  where?: InputMaybe<Give_Ups_Organizations_Bool_Exp>;
};

/** A Relay connection object on "give_ups" */
export type Give_UpsConnection = {
  __typename?: 'give_upsConnection';
  edges: Array<Give_UpsEdge>;
  pageInfo: PageInfo;
};

export type Give_UpsEdge = {
  __typename?: 'give_upsEdge';
  cursor: Scalars['String']['output'];
  node: Give_Ups;
};

/** Boolean expression to filter rows from the table "give_ups". All fields are combined with a logical 'AND'. */
export type Give_Ups_Bool_Exp = {
  _and?: InputMaybe<Array<Give_Ups_Bool_Exp>>;
  _not?: InputMaybe<Give_Ups_Bool_Exp>;
  _or?: InputMaybe<Array<Give_Ups_Bool_Exp>>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  lei?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organizations?: InputMaybe<Give_Ups_Organizations_Bool_Exp>;
  organizations_aggregate?: InputMaybe<Give_Ups_Organizations_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "give_ups" */
export enum Give_Ups_Constraint {
  /** unique or primary key constraint on columns "email" */
  GiveUpsEmailKey = 'give_ups_email_key',
  /** unique or primary key constraint on columns "id" */
  GiveUpsPkey = 'give_ups_pkey'
}

/** input type for inserting data into table "give_ups" */
export type Give_Ups_Insert_Input = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lei?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizations?: InputMaybe<Give_Ups_Organizations_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "give_ups" */
export type Give_Ups_Mutation_Response = {
  __typename?: 'give_ups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Give_Ups>;
};

/** input type for inserting object relation for remote table "give_ups" */
export type Give_Ups_Obj_Rel_Insert_Input = {
  data: Give_Ups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Give_Ups_On_Conflict>;
};

/** on_conflict condition type for table "give_ups" */
export type Give_Ups_On_Conflict = {
  constraint: Give_Ups_Constraint;
  update_columns?: Array<Give_Ups_Update_Column>;
  where?: InputMaybe<Give_Ups_Bool_Exp>;
};

/** Ordering options when selecting data from "give_ups". */
export type Give_Ups_Order_By = {
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lei?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organizations_aggregate?: InputMaybe<Give_Ups_Organizations_Aggregate_Order_By>;
};

/** columns and relationships of "give_ups_organizations" */
export type Give_Ups_Organizations = Node & {
  __typename?: 'give_ups_organizations';
  /** An object relationship */
  giveup?: Maybe<Give_Ups>;
  giveupId: Scalars['uuid']['output'];
  id: Scalars['ID']['output'];
  /** An object relationship */
  organization: Organizations;
  organizationId: Scalars['String']['output'];
};

/** A Relay connection object on "give_ups_organizations" */
export type Give_Ups_OrganizationsConnection = {
  __typename?: 'give_ups_organizationsConnection';
  edges: Array<Give_Ups_OrganizationsEdge>;
  pageInfo: PageInfo;
};

export type Give_Ups_OrganizationsEdge = {
  __typename?: 'give_ups_organizationsEdge';
  cursor: Scalars['String']['output'];
  node: Give_Ups_Organizations;
};

/** aggregated selection of "give_ups_organizations" */
export type Give_Ups_Organizations_Aggregate = {
  __typename?: 'give_ups_organizations_aggregate';
  aggregate?: Maybe<Give_Ups_Organizations_Aggregate_Fields>;
  nodes: Array<Give_Ups_Organizations>;
};

export type Give_Ups_Organizations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Give_Ups_Organizations_Aggregate_Bool_Exp_Count>;
};

export type Give_Ups_Organizations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Give_Ups_Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Give_Ups_Organizations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "give_ups_organizations" */
export type Give_Ups_Organizations_Aggregate_Fields = {
  __typename?: 'give_ups_organizations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Give_Ups_Organizations_Max_Fields>;
  min?: Maybe<Give_Ups_Organizations_Min_Fields>;
};


/** aggregate fields of "give_ups_organizations" */
export type Give_Ups_Organizations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Give_Ups_Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "give_ups_organizations" */
export type Give_Ups_Organizations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Give_Ups_Organizations_Max_Order_By>;
  min?: InputMaybe<Give_Ups_Organizations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "give_ups_organizations" */
export type Give_Ups_Organizations_Arr_Rel_Insert_Input = {
  data: Array<Give_Ups_Organizations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Give_Ups_Organizations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "give_ups_organizations". All fields are combined with a logical 'AND'. */
export type Give_Ups_Organizations_Bool_Exp = {
  _and?: InputMaybe<Array<Give_Ups_Organizations_Bool_Exp>>;
  _not?: InputMaybe<Give_Ups_Organizations_Bool_Exp>;
  _or?: InputMaybe<Array<Give_Ups_Organizations_Bool_Exp>>;
  giveup?: InputMaybe<Give_Ups_Bool_Exp>;
  giveupId?: InputMaybe<Uuid_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "give_ups_organizations" */
export enum Give_Ups_Organizations_Constraint {
  /** unique or primary key constraint on columns "giveupId", "organizationId" */
  GiveUpsOrganizationsPkey = 'give_ups_organizations_pkey'
}

/** input type for inserting data into table "give_ups_organizations" */
export type Give_Ups_Organizations_Insert_Input = {
  giveup?: InputMaybe<Give_Ups_Obj_Rel_Insert_Input>;
  giveupId?: InputMaybe<Scalars['uuid']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Give_Ups_Organizations_Max_Fields = {
  __typename?: 'give_ups_organizations_max_fields';
  giveupId?: Maybe<Scalars['uuid']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "give_ups_organizations" */
export type Give_Ups_Organizations_Max_Order_By = {
  giveupId?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Give_Ups_Organizations_Min_Fields = {
  __typename?: 'give_ups_organizations_min_fields';
  giveupId?: Maybe<Scalars['uuid']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "give_ups_organizations" */
export type Give_Ups_Organizations_Min_Order_By = {
  giveupId?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "give_ups_organizations" */
export type Give_Ups_Organizations_Mutation_Response = {
  __typename?: 'give_ups_organizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Give_Ups_Organizations>;
};

/** on_conflict condition type for table "give_ups_organizations" */
export type Give_Ups_Organizations_On_Conflict = {
  constraint: Give_Ups_Organizations_Constraint;
  update_columns?: Array<Give_Ups_Organizations_Update_Column>;
  where?: InputMaybe<Give_Ups_Organizations_Bool_Exp>;
};

/** Ordering options when selecting data from "give_ups_organizations". */
export type Give_Ups_Organizations_Order_By = {
  giveup?: InputMaybe<Give_Ups_Order_By>;
  giveupId?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organizationId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: give_ups_organizations */
export type Give_Ups_Organizations_Pk_Columns_Input = {
  giveupId: Scalars['uuid']['input'];
  organizationId: Scalars['String']['input'];
};

/** select columns of table "give_ups_organizations" */
export enum Give_Ups_Organizations_Select_Column {
  /** column name */
  GiveupId = 'giveupId',
  /** column name */
  OrganizationId = 'organizationId'
}

/** input type for updating data in table "give_ups_organizations" */
export type Give_Ups_Organizations_Set_Input = {
  giveupId?: InputMaybe<Scalars['uuid']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "give_ups_organizations" */
export enum Give_Ups_Organizations_Update_Column {
  /** column name */
  GiveupId = 'giveupId',
  /** column name */
  OrganizationId = 'organizationId'
}

export type Give_Ups_Organizations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Give_Ups_Organizations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Give_Ups_Organizations_Bool_Exp;
};

/** primary key columns input for table: give_ups */
export type Give_Ups_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "give_ups" */
export enum Give_Ups_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Lei = 'lei',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "give_ups" */
export type Give_Ups_Set_Input = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  lei?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "give_ups" */
export enum Give_Ups_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Lei = 'lei',
  /** column name */
  Name = 'name'
}

export type Give_Ups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Give_Ups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Give_Ups_Bool_Exp;
};

/** columns and relationships of "instrumentReferenceData" */
export type InstrumentReferenceData = Node & {
  __typename?: 'instrumentReferenceData';
  callPut?: Maybe<Enum_Ptrd_Callput_Enum>;
  cfi?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  deliveryType?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['date']['output']>;
  fullName: Scalars['String']['output'];
  fxType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instrumentType?: Maybe<Enum_Instrument_Type_Enum>;
  isin: Scalars['String']['output'];
  isinTradingVenue?: Maybe<Scalars['String']['output']>;
  issuer?: Maybe<Scalars['String']['output']>;
  notionalCurrency?: Maybe<Scalars['String']['output']>;
  otherNotionalCurrency?: Maybe<Scalars['String']['output']>;
  relevantTradingVenue?: Maybe<Scalars['String']['output']>;
  shortName: Scalars['String']['output'];
  terminationDate?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
};

/** A Relay connection object on "instrumentReferenceData" */
export type InstrumentReferenceDataConnection = {
  __typename?: 'instrumentReferenceDataConnection';
  edges: Array<InstrumentReferenceDataEdge>;
  pageInfo: PageInfo;
};

export type InstrumentReferenceDataEdge = {
  __typename?: 'instrumentReferenceDataEdge';
  cursor: Scalars['String']['output'];
  node: InstrumentReferenceData;
};

/** Boolean expression to filter rows from the table "instrumentReferenceData". All fields are combined with a logical 'AND'. */
export type InstrumentReferenceData_Bool_Exp = {
  _and?: InputMaybe<Array<InstrumentReferenceData_Bool_Exp>>;
  _not?: InputMaybe<InstrumentReferenceData_Bool_Exp>;
  _or?: InputMaybe<Array<InstrumentReferenceData_Bool_Exp>>;
  callPut?: InputMaybe<Enum_Ptrd_Callput_Enum_Comparison_Exp>;
  cfi?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deliveryType?: InputMaybe<String_Comparison_Exp>;
  expiryDate?: InputMaybe<Date_Comparison_Exp>;
  fullName?: InputMaybe<String_Comparison_Exp>;
  fxType?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  instrumentType?: InputMaybe<Enum_Instrument_Type_Enum_Comparison_Exp>;
  isin?: InputMaybe<String_Comparison_Exp>;
  isinTradingVenue?: InputMaybe<String_Comparison_Exp>;
  issuer?: InputMaybe<String_Comparison_Exp>;
  notionalCurrency?: InputMaybe<String_Comparison_Exp>;
  otherNotionalCurrency?: InputMaybe<String_Comparison_Exp>;
  relevantTradingVenue?: InputMaybe<String_Comparison_Exp>;
  shortName?: InputMaybe<String_Comparison_Exp>;
  terminationDate?: InputMaybe<String_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "instrumentReferenceData" */
export enum InstrumentReferenceData_Constraint {
  /** unique or primary key constraint on columns "id" */
  InstrumentReferenceDataPkey = 'instrumentReferenceData_pkey'
}

/** input type for inserting data into table "instrumentReferenceData" */
export type InstrumentReferenceData_Insert_Input = {
  callPut?: InputMaybe<Enum_Ptrd_Callput_Enum>;
  cfi?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deliveryType?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['date']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fxType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instrumentType?: InputMaybe<Enum_Instrument_Type_Enum>;
  isin?: InputMaybe<Scalars['String']['input']>;
  isinTradingVenue?: InputMaybe<Scalars['String']['input']>;
  issuer?: InputMaybe<Scalars['String']['input']>;
  notionalCurrency?: InputMaybe<Scalars['String']['input']>;
  otherNotionalCurrency?: InputMaybe<Scalars['String']['input']>;
  relevantTradingVenue?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  terminationDate?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** response of any mutation on the table "instrumentReferenceData" */
export type InstrumentReferenceData_Mutation_Response = {
  __typename?: 'instrumentReferenceData_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<InstrumentReferenceData>;
};

/** on_conflict condition type for table "instrumentReferenceData" */
export type InstrumentReferenceData_On_Conflict = {
  constraint: InstrumentReferenceData_Constraint;
  update_columns?: Array<InstrumentReferenceData_Update_Column>;
  where?: InputMaybe<InstrumentReferenceData_Bool_Exp>;
};

/** Ordering options when selecting data from "instrumentReferenceData". */
export type InstrumentReferenceData_Order_By = {
  callPut?: InputMaybe<Order_By>;
  cfi?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deliveryType?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  fullName?: InputMaybe<Order_By>;
  fxType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instrumentType?: InputMaybe<Order_By>;
  isin?: InputMaybe<Order_By>;
  isinTradingVenue?: InputMaybe<Order_By>;
  issuer?: InputMaybe<Order_By>;
  notionalCurrency?: InputMaybe<Order_By>;
  otherNotionalCurrency?: InputMaybe<Order_By>;
  relevantTradingVenue?: InputMaybe<Order_By>;
  shortName?: InputMaybe<Order_By>;
  terminationDate?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: instrumentReferenceData */
export type InstrumentReferenceData_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "instrumentReferenceData" */
export enum InstrumentReferenceData_Select_Column {
  /** column name */
  CallPut = 'callPut',
  /** column name */
  Cfi = 'cfi',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryType = 'deliveryType',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  FullName = 'fullName',
  /** column name */
  FxType = 'fxType',
  /** column name */
  Id = 'id',
  /** column name */
  InstrumentType = 'instrumentType',
  /** column name */
  Isin = 'isin',
  /** column name */
  IsinTradingVenue = 'isinTradingVenue',
  /** column name */
  Issuer = 'issuer',
  /** column name */
  NotionalCurrency = 'notionalCurrency',
  /** column name */
  OtherNotionalCurrency = 'otherNotionalCurrency',
  /** column name */
  RelevantTradingVenue = 'relevantTradingVenue',
  /** column name */
  ShortName = 'shortName',
  /** column name */
  TerminationDate = 'terminationDate',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "instrumentReferenceData" */
export type InstrumentReferenceData_Set_Input = {
  callPut?: InputMaybe<Enum_Ptrd_Callput_Enum>;
  cfi?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deliveryType?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['date']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  fxType?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instrumentType?: InputMaybe<Enum_Instrument_Type_Enum>;
  isin?: InputMaybe<Scalars['String']['input']>;
  isinTradingVenue?: InputMaybe<Scalars['String']['input']>;
  issuer?: InputMaybe<Scalars['String']['input']>;
  notionalCurrency?: InputMaybe<Scalars['String']['input']>;
  otherNotionalCurrency?: InputMaybe<Scalars['String']['input']>;
  relevantTradingVenue?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  terminationDate?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "instrumentReferenceData" */
export enum InstrumentReferenceData_Update_Column {
  /** column name */
  CallPut = 'callPut',
  /** column name */
  Cfi = 'cfi',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryType = 'deliveryType',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  FullName = 'fullName',
  /** column name */
  FxType = 'fxType',
  /** column name */
  Id = 'id',
  /** column name */
  InstrumentType = 'instrumentType',
  /** column name */
  Isin = 'isin',
  /** column name */
  IsinTradingVenue = 'isinTradingVenue',
  /** column name */
  Issuer = 'issuer',
  /** column name */
  NotionalCurrency = 'notionalCurrency',
  /** column name */
  OtherNotionalCurrency = 'otherNotionalCurrency',
  /** column name */
  RelevantTradingVenue = 'relevantTradingVenue',
  /** column name */
  ShortName = 'shortName',
  /** column name */
  TerminationDate = 'terminationDate',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type InstrumentReferenceData_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InstrumentReferenceData_Set_Input>;
  /** filter the rows which have to be updated */
  where: InstrumentReferenceData_Bool_Exp;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "mdpValidationInstruments" */
export type MdpValidationInstruments = Node & {
  __typename?: 'mdpValidationInstruments';
  amended: Scalars['Boolean']['output'];
  axeId: Scalars['String']['output'];
  buySell: Scalars['String']['output'];
  callPut: Scalars['String']['output'];
  cfi?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  deliveryType?: Maybe<Scalars['String']['output']>;
  expiryDate?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  instrumentType?: Maybe<Enum_Instrument_Type_Enum>;
  isin?: Maybe<Scalars['String']['output']>;
  issuer?: Maybe<Scalars['String']['output']>;
  notional: Scalars['bigint']['output'];
  notionalAmountInEUR: Scalars['bigint']['output'];
  notionalCurrency?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
  organizationName: Scalars['String']['output'];
  otherNotionalCurrency?: Maybe<Scalars['String']['output']>;
  relevantTradingVenue?: Maybe<Scalars['String']['output']>;
  shortName?: Maybe<Scalars['String']['output']>;
  subOrganizationId?: Maybe<Scalars['String']['output']>;
  terminated: Scalars['Boolean']['output'];
  terminationDateTime?: Maybe<Scalars['String']['output']>;
  tradeRfqDateTime: Scalars['bigint']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  userId: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

/** A Relay connection object on "mdpValidationInstruments" */
export type MdpValidationInstrumentsConnection = {
  __typename?: 'mdpValidationInstrumentsConnection';
  edges: Array<MdpValidationInstrumentsEdge>;
  pageInfo: PageInfo;
};

export type MdpValidationInstrumentsEdge = {
  __typename?: 'mdpValidationInstrumentsEdge';
  cursor: Scalars['String']['output'];
  node: MdpValidationInstruments;
};

/** Boolean expression to filter rows from the table "mdpValidationInstruments". All fields are combined with a logical 'AND'. */
export type MdpValidationInstruments_Bool_Exp = {
  _and?: InputMaybe<Array<MdpValidationInstruments_Bool_Exp>>;
  _not?: InputMaybe<MdpValidationInstruments_Bool_Exp>;
  _or?: InputMaybe<Array<MdpValidationInstruments_Bool_Exp>>;
  amended?: InputMaybe<Boolean_Comparison_Exp>;
  axeId?: InputMaybe<String_Comparison_Exp>;
  buySell?: InputMaybe<String_Comparison_Exp>;
  callPut?: InputMaybe<String_Comparison_Exp>;
  cfi?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deliveryType?: InputMaybe<String_Comparison_Exp>;
  expiryDate?: InputMaybe<String_Comparison_Exp>;
  fullName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  instrumentType?: InputMaybe<Enum_Instrument_Type_Enum_Comparison_Exp>;
  isin?: InputMaybe<String_Comparison_Exp>;
  issuer?: InputMaybe<String_Comparison_Exp>;
  notional?: InputMaybe<Bigint_Comparison_Exp>;
  notionalAmountInEUR?: InputMaybe<Bigint_Comparison_Exp>;
  notionalCurrency?: InputMaybe<String_Comparison_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  organizationName?: InputMaybe<String_Comparison_Exp>;
  otherNotionalCurrency?: InputMaybe<String_Comparison_Exp>;
  relevantTradingVenue?: InputMaybe<String_Comparison_Exp>;
  shortName?: InputMaybe<String_Comparison_Exp>;
  subOrganizationId?: InputMaybe<String_Comparison_Exp>;
  terminated?: InputMaybe<Boolean_Comparison_Exp>;
  terminationDateTime?: InputMaybe<String_Comparison_Exp>;
  tradeRfqDateTime?: InputMaybe<Bigint_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  userName?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "mdpValidationInstruments" */
export enum MdpValidationInstruments_Constraint {
  /** unique or primary key constraint on columns "id" */
  MdpValidationInstrumentsPkey = 'mdpValidationInstruments_pkey'
}

/** input type for incrementing numeric columns in table "mdpValidationInstruments" */
export type MdpValidationInstruments_Inc_Input = {
  notional?: InputMaybe<Scalars['bigint']['input']>;
  notionalAmountInEUR?: InputMaybe<Scalars['bigint']['input']>;
  tradeRfqDateTime?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "mdpValidationInstruments" */
export type MdpValidationInstruments_Insert_Input = {
  amended?: InputMaybe<Scalars['Boolean']['input']>;
  axeId?: InputMaybe<Scalars['String']['input']>;
  buySell?: InputMaybe<Scalars['String']['input']>;
  callPut?: InputMaybe<Scalars['String']['input']>;
  cfi?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deliveryType?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instrumentType?: InputMaybe<Enum_Instrument_Type_Enum>;
  isin?: InputMaybe<Scalars['String']['input']>;
  issuer?: InputMaybe<Scalars['String']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  notionalAmountInEUR?: InputMaybe<Scalars['bigint']['input']>;
  notionalCurrency?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  otherNotionalCurrency?: InputMaybe<Scalars['String']['input']>;
  relevantTradingVenue?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['String']['input']>;
  terminated?: InputMaybe<Scalars['Boolean']['input']>;
  terminationDateTime?: InputMaybe<Scalars['String']['input']>;
  tradeRfqDateTime?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "mdpValidationInstruments" */
export type MdpValidationInstruments_Mutation_Response = {
  __typename?: 'mdpValidationInstruments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<MdpValidationInstruments>;
};

/** on_conflict condition type for table "mdpValidationInstruments" */
export type MdpValidationInstruments_On_Conflict = {
  constraint: MdpValidationInstruments_Constraint;
  update_columns?: Array<MdpValidationInstruments_Update_Column>;
  where?: InputMaybe<MdpValidationInstruments_Bool_Exp>;
};

/** Ordering options when selecting data from "mdpValidationInstruments". */
export type MdpValidationInstruments_Order_By = {
  amended?: InputMaybe<Order_By>;
  axeId?: InputMaybe<Order_By>;
  buySell?: InputMaybe<Order_By>;
  callPut?: InputMaybe<Order_By>;
  cfi?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deliveryType?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  fullName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  instrumentType?: InputMaybe<Order_By>;
  isin?: InputMaybe<Order_By>;
  issuer?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  notionalAmountInEUR?: InputMaybe<Order_By>;
  notionalCurrency?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  organizationName?: InputMaybe<Order_By>;
  otherNotionalCurrency?: InputMaybe<Order_By>;
  relevantTradingVenue?: InputMaybe<Order_By>;
  shortName?: InputMaybe<Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
  terminated?: InputMaybe<Order_By>;
  terminationDateTime?: InputMaybe<Order_By>;
  tradeRfqDateTime?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  userName?: InputMaybe<Order_By>;
};

/** primary key columns input for table: mdpValidationInstruments */
export type MdpValidationInstruments_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "mdpValidationInstruments" */
export enum MdpValidationInstruments_Select_Column {
  /** column name */
  Amended = 'amended',
  /** column name */
  AxeId = 'axeId',
  /** column name */
  BuySell = 'buySell',
  /** column name */
  CallPut = 'callPut',
  /** column name */
  Cfi = 'cfi',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryType = 'deliveryType',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  InstrumentType = 'instrumentType',
  /** column name */
  Isin = 'isin',
  /** column name */
  Issuer = 'issuer',
  /** column name */
  Notional = 'notional',
  /** column name */
  NotionalAmountInEur = 'notionalAmountInEUR',
  /** column name */
  NotionalCurrency = 'notionalCurrency',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  OrganizationName = 'organizationName',
  /** column name */
  OtherNotionalCurrency = 'otherNotionalCurrency',
  /** column name */
  RelevantTradingVenue = 'relevantTradingVenue',
  /** column name */
  ShortName = 'shortName',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  Terminated = 'terminated',
  /** column name */
  TerminationDateTime = 'terminationDateTime',
  /** column name */
  TradeRfqDateTime = 'tradeRfqDateTime',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  UserName = 'userName'
}

/** input type for updating data in table "mdpValidationInstruments" */
export type MdpValidationInstruments_Set_Input = {
  amended?: InputMaybe<Scalars['Boolean']['input']>;
  axeId?: InputMaybe<Scalars['String']['input']>;
  buySell?: InputMaybe<Scalars['String']['input']>;
  callPut?: InputMaybe<Scalars['String']['input']>;
  cfi?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deliveryType?: InputMaybe<Scalars['String']['input']>;
  expiryDate?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  instrumentType?: InputMaybe<Enum_Instrument_Type_Enum>;
  isin?: InputMaybe<Scalars['String']['input']>;
  issuer?: InputMaybe<Scalars['String']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  notionalAmountInEUR?: InputMaybe<Scalars['bigint']['input']>;
  notionalCurrency?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  otherNotionalCurrency?: InputMaybe<Scalars['String']['input']>;
  relevantTradingVenue?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['String']['input']>;
  terminated?: InputMaybe<Scalars['Boolean']['input']>;
  terminationDateTime?: InputMaybe<Scalars['String']['input']>;
  tradeRfqDateTime?: InputMaybe<Scalars['bigint']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "mdpValidationInstruments" */
export enum MdpValidationInstruments_Update_Column {
  /** column name */
  Amended = 'amended',
  /** column name */
  AxeId = 'axeId',
  /** column name */
  BuySell = 'buySell',
  /** column name */
  CallPut = 'callPut',
  /** column name */
  Cfi = 'cfi',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryType = 'deliveryType',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  InstrumentType = 'instrumentType',
  /** column name */
  Isin = 'isin',
  /** column name */
  Issuer = 'issuer',
  /** column name */
  Notional = 'notional',
  /** column name */
  NotionalAmountInEur = 'notionalAmountInEUR',
  /** column name */
  NotionalCurrency = 'notionalCurrency',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  OrganizationName = 'organizationName',
  /** column name */
  OtherNotionalCurrency = 'otherNotionalCurrency',
  /** column name */
  RelevantTradingVenue = 'relevantTradingVenue',
  /** column name */
  ShortName = 'shortName',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  Terminated = 'terminated',
  /** column name */
  TerminationDateTime = 'terminationDateTime',
  /** column name */
  TradeRfqDateTime = 'tradeRfqDateTime',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId',
  /** column name */
  UserName = 'userName'
}

export type MdpValidationInstruments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<MdpValidationInstruments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<MdpValidationInstruments_Set_Input>;
  /** filter the rows which have to be updated */
  where: MdpValidationInstruments_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "axe_authors" */
  delete_axe_authors?: Maybe<Axe_Authors_Mutation_Response>;
  /** delete single row from the table: "axe_authors" */
  delete_axe_authors_by_pk?: Maybe<Axe_Authors>;
  /** delete data from the table: "axe_legs" */
  delete_axe_legs?: Maybe<Axe_Legs_Mutation_Response>;
  /** delete data from the table: "axe_legs_audit" */
  delete_axe_legs_audit?: Maybe<Axe_Legs_Audit_Mutation_Response>;
  /** delete single row from the table: "axe_legs_audit" */
  delete_axe_legs_audit_by_pk?: Maybe<Axe_Legs_Audit>;
  /** delete single row from the table: "axe_legs" */
  delete_axe_legs_by_pk?: Maybe<Axe_Legs>;
  /** delete data from the table: "axe_tier_pricing" */
  delete_axe_tier_pricing?: Maybe<Axe_Tier_Pricing_Mutation_Response>;
  /** delete single row from the table: "axe_tier_pricing" */
  delete_axe_tier_pricing_by_pk?: Maybe<Axe_Tier_Pricing>;
  /** delete data from the table: "axes" */
  delete_axes?: Maybe<Axes_Mutation_Response>;
  /** delete data from the table: "axes_audit" */
  delete_axes_audit?: Maybe<Axes_Audit_Mutation_Response>;
  /** delete single row from the table: "axes_audit" */
  delete_axes_audit_by_pk?: Maybe<Axes_Audit>;
  /** delete single row from the table: "axes" */
  delete_axes_by_pk?: Maybe<Axes>;
  /** delete data from the table: "create_users_queue" */
  delete_create_users_queue?: Maybe<Create_Users_Queue_Mutation_Response>;
  /** delete single row from the table: "create_users_queue" */
  delete_create_users_queue_by_pk?: Maybe<Create_Users_Queue>;
  /** delete data from the table: "enum_axe_buysell" */
  delete_enum_axe_buysell?: Maybe<Enum_Axe_Buysell_Mutation_Response>;
  /** delete single row from the table: "enum_axe_buysell" */
  delete_enum_axe_buysell_by_pk?: Maybe<Enum_Axe_Buysell>;
  /** delete data from the table: "enum_axe_callput" */
  delete_enum_axe_callput?: Maybe<Enum_Axe_Callput_Mutation_Response>;
  /** delete single row from the table: "enum_axe_callput" */
  delete_enum_axe_callput_by_pk?: Maybe<Enum_Axe_Callput>;
  /** delete data from the table: "enum_axe_ccypair" */
  delete_enum_axe_ccypair?: Maybe<Enum_Axe_Ccypair_Mutation_Response>;
  /** delete single row from the table: "enum_axe_ccypair" */
  delete_enum_axe_ccypair_by_pk?: Maybe<Enum_Axe_Ccypair>;
  /** delete data from the table: "enum_axe_depoccy1daycount" */
  delete_enum_axe_depoccy1daycount?: Maybe<Enum_Axe_Depoccy1daycount_Mutation_Response>;
  /** delete single row from the table: "enum_axe_depoccy1daycount" */
  delete_enum_axe_depoccy1daycount_by_pk?: Maybe<Enum_Axe_Depoccy1daycount>;
  /** delete data from the table: "enum_axe_depoccy2daycount" */
  delete_enum_axe_depoccy2daycount?: Maybe<Enum_Axe_Depoccy2daycount_Mutation_Response>;
  /** delete single row from the table: "enum_axe_depoccy2daycount" */
  delete_enum_axe_depoccy2daycount_by_pk?: Maybe<Enum_Axe_Depoccy2daycount>;
  /** delete data from the table: "enum_axe_hedgetype" */
  delete_enum_axe_hedgetype?: Maybe<Enum_Axe_Hedgetype_Mutation_Response>;
  /** delete single row from the table: "enum_axe_hedgetype" */
  delete_enum_axe_hedgetype_by_pk?: Maybe<Enum_Axe_Hedgetype>;
  /** delete data from the table: "enum_axe_premiumtype" */
  delete_enum_axe_premiumtype?: Maybe<Enum_Axe_Premiumtype_Mutation_Response>;
  /** delete single row from the table: "enum_axe_premiumtype" */
  delete_enum_axe_premiumtype_by_pk?: Maybe<Enum_Axe_Premiumtype>;
  /** delete data from the table: "enum_axe_product" */
  delete_enum_axe_product?: Maybe<Enum_Axe_Product_Mutation_Response>;
  /** delete single row from the table: "enum_axe_product" */
  delete_enum_axe_product_by_pk?: Maybe<Enum_Axe_Product>;
  /** delete data from the table: "enum_axe_state" */
  delete_enum_axe_state?: Maybe<Enum_Axe_State_Mutation_Response>;
  /** delete single row from the table: "enum_axe_state" */
  delete_enum_axe_state_by_pk?: Maybe<Enum_Axe_State>;
  /** delete data from the table: "enum_color_scheme" */
  delete_enum_color_scheme?: Maybe<Enum_Color_Scheme_Mutation_Response>;
  /** delete single row from the table: "enum_color_scheme" */
  delete_enum_color_scheme_by_pk?: Maybe<Enum_Color_Scheme>;
  /** delete data from the table: "enum_currency_notional" */
  delete_enum_currency_notional?: Maybe<Enum_Currency_Notional_Mutation_Response>;
  /** delete single row from the table: "enum_currency_notional" */
  delete_enum_currency_notional_by_pk?: Maybe<Enum_Currency_Notional>;
  /** delete data from the table: "enum_currency_premium" */
  delete_enum_currency_premium?: Maybe<Enum_Currency_Premium_Mutation_Response>;
  /** delete single row from the table: "enum_currency_premium" */
  delete_enum_currency_premium_by_pk?: Maybe<Enum_Currency_Premium>;
  /** delete data from the table: "enum_instrument_type" */
  delete_enum_instrument_type?: Maybe<Enum_Instrument_Type_Mutation_Response>;
  /** delete single row from the table: "enum_instrument_type" */
  delete_enum_instrument_type_by_pk?: Maybe<Enum_Instrument_Type>;
  /** delete data from the table: "enum_notification_channel" */
  delete_enum_notification_channel?: Maybe<Enum_Notification_Channel_Mutation_Response>;
  /** delete single row from the table: "enum_notification_channel" */
  delete_enum_notification_channel_by_pk?: Maybe<Enum_Notification_Channel>;
  /** delete data from the table: "enum_notification_type" */
  delete_enum_notification_type?: Maybe<Enum_Notification_Type_Mutation_Response>;
  /** delete single row from the table: "enum_notification_type" */
  delete_enum_notification_type_by_pk?: Maybe<Enum_Notification_Type>;
  /** delete data from the table: "enum_ptrd_callput" */
  delete_enum_ptrd_callput?: Maybe<Enum_Ptrd_Callput_Mutation_Response>;
  /** delete single row from the table: "enum_ptrd_callput" */
  delete_enum_ptrd_callput_by_pk?: Maybe<Enum_Ptrd_Callput>;
  /** delete data from the table: "enum_quote_side_type" */
  delete_enum_quote_side_type?: Maybe<Enum_Quote_Side_Type_Mutation_Response>;
  /** delete single row from the table: "enum_quote_side_type" */
  delete_enum_quote_side_type_by_pk?: Maybe<Enum_Quote_Side_Type>;
  /** delete data from the table: "enum_rfq_option_reason" */
  delete_enum_rfq_option_reason?: Maybe<Enum_Rfq_Option_Reason_Mutation_Response>;
  /** delete single row from the table: "enum_rfq_option_reason" */
  delete_enum_rfq_option_reason_by_pk?: Maybe<Enum_Rfq_Option_Reason>;
  /** delete data from the table: "enum_rfq_status" */
  delete_enum_rfq_status?: Maybe<Enum_Rfq_Status_Mutation_Response>;
  /** delete single row from the table: "enum_rfq_status" */
  delete_enum_rfq_status_by_pk?: Maybe<Enum_Rfq_Status>;
  /** delete data from the table: "enum_tenor" */
  delete_enum_tenor?: Maybe<Enum_Tenor_Mutation_Response>;
  /** delete single row from the table: "enum_tenor" */
  delete_enum_tenor_by_pk?: Maybe<Enum_Tenor>;
  /** delete data from the table: "enum_tiers" */
  delete_enum_tiers?: Maybe<Enum_Tiers_Mutation_Response>;
  /** delete single row from the table: "enum_tiers" */
  delete_enum_tiers_by_pk?: Maybe<Enum_Tiers>;
  /** delete data from the table: "enum_users_axe_suspended_state" */
  delete_enum_users_axe_suspended_state?: Maybe<Enum_Users_Axe_Suspended_State_Mutation_Response>;
  /** delete single row from the table: "enum_users_axe_suspended_state" */
  delete_enum_users_axe_suspended_state_by_pk?: Maybe<Enum_Users_Axe_Suspended_State>;
  /** delete data from the table: "expiry_cuts" */
  delete_expiry_cuts?: Maybe<Expiry_Cuts_Mutation_Response>;
  /** delete single row from the table: "expiry_cuts" */
  delete_expiry_cuts_by_pk?: Maybe<Expiry_Cuts>;
  /** delete data from the table: "fcaFeedbackMonitor" */
  delete_fcaFeedbackMonitor?: Maybe<FcaFeedbackMonitor_Mutation_Response>;
  /** delete data from the table: "filters" */
  delete_filters?: Maybe<Filters_Mutation_Response>;
  /** delete single row from the table: "filters" */
  delete_filters_by_pk?: Maybe<Filters>;
  /** delete data from the table: "funds" */
  delete_funds?: Maybe<Funds_Mutation_Response>;
  /** delete single row from the table: "funds" */
  delete_funds_by_pk?: Maybe<Funds>;
  /** delete data from the table: "give_up_requests" */
  delete_give_up_requests?: Maybe<Give_Up_Requests_Mutation_Response>;
  /** delete single row from the table: "give_up_requests" */
  delete_give_up_requests_by_pk?: Maybe<Give_Up_Requests>;
  /** delete data from the table: "give_ups" */
  delete_give_ups?: Maybe<Give_Ups_Mutation_Response>;
  /** delete single row from the table: "give_ups" */
  delete_give_ups_by_pk?: Maybe<Give_Ups>;
  /** delete data from the table: "give_ups_organizations" */
  delete_give_ups_organizations?: Maybe<Give_Ups_Organizations_Mutation_Response>;
  /** delete single row from the table: "give_ups_organizations" */
  delete_give_ups_organizations_by_pk?: Maybe<Give_Ups_Organizations>;
  /** delete data from the table: "instrumentReferenceData" */
  delete_instrumentReferenceData?: Maybe<InstrumentReferenceData_Mutation_Response>;
  /** delete single row from the table: "instrumentReferenceData" */
  delete_instrumentReferenceData_by_pk?: Maybe<InstrumentReferenceData>;
  /** delete data from the table: "mdpValidationInstruments" */
  delete_mdpValidationInstruments?: Maybe<MdpValidationInstruments_Mutation_Response>;
  /** delete single row from the table: "mdpValidationInstruments" */
  delete_mdpValidationInstruments_by_pk?: Maybe<MdpValidationInstruments>;
  /** delete data from the table: "notification_roles" */
  delete_notification_roles?: Maybe<Notification_Roles_Mutation_Response>;
  /** delete single row from the table: "notification_roles" */
  delete_notification_roles_by_pk?: Maybe<Notification_Roles>;
  /** delete data from the table: "notifications" */
  delete_notifications?: Maybe<Notifications_Mutation_Response>;
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk?: Maybe<Notifications>;
  /** delete data from the table: "organization_main_tiering" */
  delete_organization_main_tiering?: Maybe<Organization_Main_Tiering_Mutation_Response>;
  /** delete single row from the table: "organization_main_tiering" */
  delete_organization_main_tiering_by_pk?: Maybe<Organization_Main_Tiering>;
  /** delete data from the table: "organizations" */
  delete_organizations?: Maybe<Organizations_Mutation_Response>;
  /** delete single row from the table: "organizations" */
  delete_organizations_by_pk?: Maybe<Organizations>;
  /** delete data from the table: "push_subscriptions" */
  delete_push_subscriptions?: Maybe<Push_Subscriptions_Mutation_Response>;
  /** delete single row from the table: "push_subscriptions" */
  delete_push_subscriptions_by_pk?: Maybe<Push_Subscriptions>;
  /** delete data from the table: "reportSequenceIds" */
  delete_reportSequenceIds?: Maybe<ReportSequenceIds_Mutation_Response>;
  /** delete single row from the table: "reportSequenceIds" */
  delete_reportSequenceIds_by_pk?: Maybe<ReportSequenceIds>;
  /** delete data from the table: "rfq" */
  delete_rfq?: Maybe<Rfq_Mutation_Response>;
  /** delete single row from the table: "rfq" */
  delete_rfq_by_pk?: Maybe<Rfq>;
  /** delete data from the table: "rfq_legs" */
  delete_rfq_legs?: Maybe<Rfq_Legs_Mutation_Response>;
  /** delete single row from the table: "rfq_legs" */
  delete_rfq_legs_by_pk?: Maybe<Rfq_Legs>;
  /** delete data from the table: "rfq_options" */
  delete_rfq_options?: Maybe<Rfq_Options_Mutation_Response>;
  /** delete single row from the table: "rfq_options" */
  delete_rfq_options_by_pk?: Maybe<Rfq_Options>;
  /** delete data from the table: "rfq_options_legs" */
  delete_rfq_options_legs?: Maybe<Rfq_Options_Legs_Mutation_Response>;
  /** delete single row from the table: "rfq_options_legs" */
  delete_rfq_options_legs_by_pk?: Maybe<Rfq_Options_Legs>;
  /** delete data from the table: "rfq_public_queue" */
  delete_rfq_public_queue?: Maybe<Rfq_Public_Queue_Mutation_Response>;
  /** delete single row from the table: "rfq_public_queue" */
  delete_rfq_public_queue_by_pk?: Maybe<Rfq_Public_Queue>;
  /** delete data from the table: "sub_organizations" */
  delete_sub_organizations?: Maybe<Sub_Organizations_Mutation_Response>;
  /** delete single row from the table: "sub_organizations" */
  delete_sub_organizations_by_pk?: Maybe<Sub_Organizations>;
  /** delete data from the table: "trade_confirms" */
  delete_trade_confirms?: Maybe<Trade_Confirms_Mutation_Response>;
  /** delete single row from the table: "trade_confirms" */
  delete_trade_confirms_by_pk?: Maybe<Trade_Confirms>;
  /** delete data from the table: "traded_hedges" */
  delete_traded_hedges?: Maybe<Traded_Hedges_Mutation_Response>;
  /** delete data from the table: "trades" */
  delete_trades?: Maybe<Trades_Mutation_Response>;
  /** delete single row from the table: "trades" */
  delete_trades_by_pk?: Maybe<Trades>;
  /** delete data from the table: "trades_leg" */
  delete_trades_leg?: Maybe<Trades_Leg_Mutation_Response>;
  /** delete single row from the table: "trades_leg" */
  delete_trades_leg_by_pk?: Maybe<Trades_Leg>;
  /** delete data from the table: "user_main_tiering" */
  delete_user_main_tiering?: Maybe<User_Main_Tiering_Mutation_Response>;
  /** delete single row from the table: "user_main_tiering" */
  delete_user_main_tiering_by_pk?: Maybe<User_Main_Tiering>;
  /** delete data from the table: "user_notifications" */
  delete_user_notifications?: Maybe<User_Notifications_Mutation_Response>;
  /** delete single row from the table: "user_notifications" */
  delete_user_notifications_by_pk?: Maybe<User_Notifications>;
  /** delete data from the table: "user_tiers" */
  delete_user_tiers?: Maybe<User_Tiers_Mutation_Response>;
  /** delete single row from the table: "user_tiers" */
  delete_user_tiers_by_pk?: Maybe<User_Tiers>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "axe_authors" */
  insert_axe_authors?: Maybe<Axe_Authors_Mutation_Response>;
  /** insert a single row into the table: "axe_authors" */
  insert_axe_authors_one?: Maybe<Axe_Authors>;
  /** insert data into the table: "axe_legs" */
  insert_axe_legs?: Maybe<Axe_Legs_Mutation_Response>;
  /** insert data into the table: "axe_legs_audit" */
  insert_axe_legs_audit?: Maybe<Axe_Legs_Audit_Mutation_Response>;
  /** insert a single row into the table: "axe_legs_audit" */
  insert_axe_legs_audit_one?: Maybe<Axe_Legs_Audit>;
  /** insert a single row into the table: "axe_legs" */
  insert_axe_legs_one?: Maybe<Axe_Legs>;
  /** insert data into the table: "axe_tier_pricing" */
  insert_axe_tier_pricing?: Maybe<Axe_Tier_Pricing_Mutation_Response>;
  /** insert a single row into the table: "axe_tier_pricing" */
  insert_axe_tier_pricing_one?: Maybe<Axe_Tier_Pricing>;
  /** insert data into the table: "axes" */
  insert_axes?: Maybe<Axes_Mutation_Response>;
  /** insert data into the table: "axes_audit" */
  insert_axes_audit?: Maybe<Axes_Audit_Mutation_Response>;
  /** insert a single row into the table: "axes_audit" */
  insert_axes_audit_one?: Maybe<Axes_Audit>;
  /** insert a single row into the table: "axes" */
  insert_axes_one?: Maybe<Axes>;
  /** insert data into the table: "create_users_queue" */
  insert_create_users_queue?: Maybe<Create_Users_Queue_Mutation_Response>;
  /** insert a single row into the table: "create_users_queue" */
  insert_create_users_queue_one?: Maybe<Create_Users_Queue>;
  /** insert data into the table: "enum_axe_buysell" */
  insert_enum_axe_buysell?: Maybe<Enum_Axe_Buysell_Mutation_Response>;
  /** insert a single row into the table: "enum_axe_buysell" */
  insert_enum_axe_buysell_one?: Maybe<Enum_Axe_Buysell>;
  /** insert data into the table: "enum_axe_callput" */
  insert_enum_axe_callput?: Maybe<Enum_Axe_Callput_Mutation_Response>;
  /** insert a single row into the table: "enum_axe_callput" */
  insert_enum_axe_callput_one?: Maybe<Enum_Axe_Callput>;
  /** insert data into the table: "enum_axe_ccypair" */
  insert_enum_axe_ccypair?: Maybe<Enum_Axe_Ccypair_Mutation_Response>;
  /** insert a single row into the table: "enum_axe_ccypair" */
  insert_enum_axe_ccypair_one?: Maybe<Enum_Axe_Ccypair>;
  /** insert data into the table: "enum_axe_depoccy1daycount" */
  insert_enum_axe_depoccy1daycount?: Maybe<Enum_Axe_Depoccy1daycount_Mutation_Response>;
  /** insert a single row into the table: "enum_axe_depoccy1daycount" */
  insert_enum_axe_depoccy1daycount_one?: Maybe<Enum_Axe_Depoccy1daycount>;
  /** insert data into the table: "enum_axe_depoccy2daycount" */
  insert_enum_axe_depoccy2daycount?: Maybe<Enum_Axe_Depoccy2daycount_Mutation_Response>;
  /** insert a single row into the table: "enum_axe_depoccy2daycount" */
  insert_enum_axe_depoccy2daycount_one?: Maybe<Enum_Axe_Depoccy2daycount>;
  /** insert data into the table: "enum_axe_hedgetype" */
  insert_enum_axe_hedgetype?: Maybe<Enum_Axe_Hedgetype_Mutation_Response>;
  /** insert a single row into the table: "enum_axe_hedgetype" */
  insert_enum_axe_hedgetype_one?: Maybe<Enum_Axe_Hedgetype>;
  /** insert data into the table: "enum_axe_premiumtype" */
  insert_enum_axe_premiumtype?: Maybe<Enum_Axe_Premiumtype_Mutation_Response>;
  /** insert a single row into the table: "enum_axe_premiumtype" */
  insert_enum_axe_premiumtype_one?: Maybe<Enum_Axe_Premiumtype>;
  /** insert data into the table: "enum_axe_product" */
  insert_enum_axe_product?: Maybe<Enum_Axe_Product_Mutation_Response>;
  /** insert a single row into the table: "enum_axe_product" */
  insert_enum_axe_product_one?: Maybe<Enum_Axe_Product>;
  /** insert data into the table: "enum_axe_state" */
  insert_enum_axe_state?: Maybe<Enum_Axe_State_Mutation_Response>;
  /** insert a single row into the table: "enum_axe_state" */
  insert_enum_axe_state_one?: Maybe<Enum_Axe_State>;
  /** insert data into the table: "enum_color_scheme" */
  insert_enum_color_scheme?: Maybe<Enum_Color_Scheme_Mutation_Response>;
  /** insert a single row into the table: "enum_color_scheme" */
  insert_enum_color_scheme_one?: Maybe<Enum_Color_Scheme>;
  /** insert data into the table: "enum_currency_notional" */
  insert_enum_currency_notional?: Maybe<Enum_Currency_Notional_Mutation_Response>;
  /** insert a single row into the table: "enum_currency_notional" */
  insert_enum_currency_notional_one?: Maybe<Enum_Currency_Notional>;
  /** insert data into the table: "enum_currency_premium" */
  insert_enum_currency_premium?: Maybe<Enum_Currency_Premium_Mutation_Response>;
  /** insert a single row into the table: "enum_currency_premium" */
  insert_enum_currency_premium_one?: Maybe<Enum_Currency_Premium>;
  /** insert data into the table: "enum_instrument_type" */
  insert_enum_instrument_type?: Maybe<Enum_Instrument_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_instrument_type" */
  insert_enum_instrument_type_one?: Maybe<Enum_Instrument_Type>;
  /** insert data into the table: "enum_notification_channel" */
  insert_enum_notification_channel?: Maybe<Enum_Notification_Channel_Mutation_Response>;
  /** insert a single row into the table: "enum_notification_channel" */
  insert_enum_notification_channel_one?: Maybe<Enum_Notification_Channel>;
  /** insert data into the table: "enum_notification_type" */
  insert_enum_notification_type?: Maybe<Enum_Notification_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_notification_type" */
  insert_enum_notification_type_one?: Maybe<Enum_Notification_Type>;
  /** insert data into the table: "enum_ptrd_callput" */
  insert_enum_ptrd_callput?: Maybe<Enum_Ptrd_Callput_Mutation_Response>;
  /** insert a single row into the table: "enum_ptrd_callput" */
  insert_enum_ptrd_callput_one?: Maybe<Enum_Ptrd_Callput>;
  /** insert data into the table: "enum_quote_side_type" */
  insert_enum_quote_side_type?: Maybe<Enum_Quote_Side_Type_Mutation_Response>;
  /** insert a single row into the table: "enum_quote_side_type" */
  insert_enum_quote_side_type_one?: Maybe<Enum_Quote_Side_Type>;
  /** insert data into the table: "enum_rfq_option_reason" */
  insert_enum_rfq_option_reason?: Maybe<Enum_Rfq_Option_Reason_Mutation_Response>;
  /** insert a single row into the table: "enum_rfq_option_reason" */
  insert_enum_rfq_option_reason_one?: Maybe<Enum_Rfq_Option_Reason>;
  /** insert data into the table: "enum_rfq_status" */
  insert_enum_rfq_status?: Maybe<Enum_Rfq_Status_Mutation_Response>;
  /** insert a single row into the table: "enum_rfq_status" */
  insert_enum_rfq_status_one?: Maybe<Enum_Rfq_Status>;
  /** insert data into the table: "enum_tenor" */
  insert_enum_tenor?: Maybe<Enum_Tenor_Mutation_Response>;
  /** insert a single row into the table: "enum_tenor" */
  insert_enum_tenor_one?: Maybe<Enum_Tenor>;
  /** insert data into the table: "enum_tiers" */
  insert_enum_tiers?: Maybe<Enum_Tiers_Mutation_Response>;
  /** insert a single row into the table: "enum_tiers" */
  insert_enum_tiers_one?: Maybe<Enum_Tiers>;
  /** insert data into the table: "enum_users_axe_suspended_state" */
  insert_enum_users_axe_suspended_state?: Maybe<Enum_Users_Axe_Suspended_State_Mutation_Response>;
  /** insert a single row into the table: "enum_users_axe_suspended_state" */
  insert_enum_users_axe_suspended_state_one?: Maybe<Enum_Users_Axe_Suspended_State>;
  /** insert data into the table: "expiry_cuts" */
  insert_expiry_cuts?: Maybe<Expiry_Cuts_Mutation_Response>;
  /** insert a single row into the table: "expiry_cuts" */
  insert_expiry_cuts_one?: Maybe<Expiry_Cuts>;
  /** insert data into the table: "fcaFeedbackMonitor" */
  insert_fcaFeedbackMonitor?: Maybe<FcaFeedbackMonitor_Mutation_Response>;
  /** insert a single row into the table: "fcaFeedbackMonitor" */
  insert_fcaFeedbackMonitor_one?: Maybe<FcaFeedbackMonitor>;
  /** insert data into the table: "filters" */
  insert_filters?: Maybe<Filters_Mutation_Response>;
  /** insert a single row into the table: "filters" */
  insert_filters_one?: Maybe<Filters>;
  /** insert data into the table: "funds" */
  insert_funds?: Maybe<Funds_Mutation_Response>;
  /** insert a single row into the table: "funds" */
  insert_funds_one?: Maybe<Funds>;
  /** insert data into the table: "give_up_requests" */
  insert_give_up_requests?: Maybe<Give_Up_Requests_Mutation_Response>;
  /** insert a single row into the table: "give_up_requests" */
  insert_give_up_requests_one?: Maybe<Give_Up_Requests>;
  /** insert data into the table: "give_ups" */
  insert_give_ups?: Maybe<Give_Ups_Mutation_Response>;
  /** insert a single row into the table: "give_ups" */
  insert_give_ups_one?: Maybe<Give_Ups>;
  /** insert data into the table: "give_ups_organizations" */
  insert_give_ups_organizations?: Maybe<Give_Ups_Organizations_Mutation_Response>;
  /** insert a single row into the table: "give_ups_organizations" */
  insert_give_ups_organizations_one?: Maybe<Give_Ups_Organizations>;
  /** insert data into the table: "instrumentReferenceData" */
  insert_instrumentReferenceData?: Maybe<InstrumentReferenceData_Mutation_Response>;
  /** insert a single row into the table: "instrumentReferenceData" */
  insert_instrumentReferenceData_one?: Maybe<InstrumentReferenceData>;
  /** insert data into the table: "mdpValidationInstruments" */
  insert_mdpValidationInstruments?: Maybe<MdpValidationInstruments_Mutation_Response>;
  /** insert a single row into the table: "mdpValidationInstruments" */
  insert_mdpValidationInstruments_one?: Maybe<MdpValidationInstruments>;
  /** insert data into the table: "notification_roles" */
  insert_notification_roles?: Maybe<Notification_Roles_Mutation_Response>;
  /** insert a single row into the table: "notification_roles" */
  insert_notification_roles_one?: Maybe<Notification_Roles>;
  /** insert data into the table: "notifications" */
  insert_notifications?: Maybe<Notifications_Mutation_Response>;
  /** insert a single row into the table: "notifications" */
  insert_notifications_one?: Maybe<Notifications>;
  /** insert data into the table: "organization_main_tiering" */
  insert_organization_main_tiering?: Maybe<Organization_Main_Tiering_Mutation_Response>;
  /** insert a single row into the table: "organization_main_tiering" */
  insert_organization_main_tiering_one?: Maybe<Organization_Main_Tiering>;
  /** insert data into the table: "organizations" */
  insert_organizations?: Maybe<Organizations_Mutation_Response>;
  /** insert a single row into the table: "organizations" */
  insert_organizations_one?: Maybe<Organizations>;
  /** insert data into the table: "push_subscriptions" */
  insert_push_subscriptions?: Maybe<Push_Subscriptions_Mutation_Response>;
  /** insert a single row into the table: "push_subscriptions" */
  insert_push_subscriptions_one?: Maybe<Push_Subscriptions>;
  /** insert data into the table: "reportSequenceIds" */
  insert_reportSequenceIds?: Maybe<ReportSequenceIds_Mutation_Response>;
  /** insert a single row into the table: "reportSequenceIds" */
  insert_reportSequenceIds_one?: Maybe<ReportSequenceIds>;
  /** insert data into the table: "rfq" */
  insert_rfq?: Maybe<Rfq_Mutation_Response>;
  /** insert data into the table: "rfq_legs" */
  insert_rfq_legs?: Maybe<Rfq_Legs_Mutation_Response>;
  /** insert a single row into the table: "rfq_legs" */
  insert_rfq_legs_one?: Maybe<Rfq_Legs>;
  /** insert a single row into the table: "rfq" */
  insert_rfq_one?: Maybe<Rfq>;
  /** insert data into the table: "rfq_options" */
  insert_rfq_options?: Maybe<Rfq_Options_Mutation_Response>;
  /** insert data into the table: "rfq_options_legs" */
  insert_rfq_options_legs?: Maybe<Rfq_Options_Legs_Mutation_Response>;
  /** insert a single row into the table: "rfq_options_legs" */
  insert_rfq_options_legs_one?: Maybe<Rfq_Options_Legs>;
  /** insert a single row into the table: "rfq_options" */
  insert_rfq_options_one?: Maybe<Rfq_Options>;
  /** insert data into the table: "rfq_public_queue" */
  insert_rfq_public_queue?: Maybe<Rfq_Public_Queue_Mutation_Response>;
  /** insert a single row into the table: "rfq_public_queue" */
  insert_rfq_public_queue_one?: Maybe<Rfq_Public_Queue>;
  /** insert data into the table: "sub_organizations" */
  insert_sub_organizations?: Maybe<Sub_Organizations_Mutation_Response>;
  /** insert a single row into the table: "sub_organizations" */
  insert_sub_organizations_one?: Maybe<Sub_Organizations>;
  /** insert data into the table: "trade_confirms" */
  insert_trade_confirms?: Maybe<Trade_Confirms_Mutation_Response>;
  /** insert a single row into the table: "trade_confirms" */
  insert_trade_confirms_one?: Maybe<Trade_Confirms>;
  /** insert data into the table: "traded_hedges" */
  insert_traded_hedges?: Maybe<Traded_Hedges_Mutation_Response>;
  /** insert a single row into the table: "traded_hedges" */
  insert_traded_hedges_one?: Maybe<Traded_Hedges>;
  /** insert data into the table: "trades" */
  insert_trades?: Maybe<Trades_Mutation_Response>;
  /** insert data into the table: "trades_leg" */
  insert_trades_leg?: Maybe<Trades_Leg_Mutation_Response>;
  /** insert a single row into the table: "trades_leg" */
  insert_trades_leg_one?: Maybe<Trades_Leg>;
  /** insert a single row into the table: "trades" */
  insert_trades_one?: Maybe<Trades>;
  /** insert data into the table: "user_main_tiering" */
  insert_user_main_tiering?: Maybe<User_Main_Tiering_Mutation_Response>;
  /** insert a single row into the table: "user_main_tiering" */
  insert_user_main_tiering_one?: Maybe<User_Main_Tiering>;
  /** insert data into the table: "user_notifications" */
  insert_user_notifications?: Maybe<User_Notifications_Mutation_Response>;
  /** insert a single row into the table: "user_notifications" */
  insert_user_notifications_one?: Maybe<User_Notifications>;
  /** insert data into the table: "user_tiers" */
  insert_user_tiers?: Maybe<User_Tiers_Mutation_Response>;
  /** insert a single row into the table: "user_tiers" */
  insert_user_tiers_one?: Maybe<User_Tiers>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** update data of the table: "axe_authors" */
  update_axe_authors?: Maybe<Axe_Authors_Mutation_Response>;
  /** update single row of the table: "axe_authors" */
  update_axe_authors_by_pk?: Maybe<Axe_Authors>;
  /** update multiples rows of table: "axe_authors" */
  update_axe_authors_many?: Maybe<Array<Maybe<Axe_Authors_Mutation_Response>>>;
  /** update data of the table: "axe_legs" */
  update_axe_legs?: Maybe<Axe_Legs_Mutation_Response>;
  /** update data of the table: "axe_legs_audit" */
  update_axe_legs_audit?: Maybe<Axe_Legs_Audit_Mutation_Response>;
  /** update single row of the table: "axe_legs_audit" */
  update_axe_legs_audit_by_pk?: Maybe<Axe_Legs_Audit>;
  /** update multiples rows of table: "axe_legs_audit" */
  update_axe_legs_audit_many?: Maybe<Array<Maybe<Axe_Legs_Audit_Mutation_Response>>>;
  /** update single row of the table: "axe_legs" */
  update_axe_legs_by_pk?: Maybe<Axe_Legs>;
  /** update multiples rows of table: "axe_legs" */
  update_axe_legs_many?: Maybe<Array<Maybe<Axe_Legs_Mutation_Response>>>;
  /** update data of the table: "axe_tier_pricing" */
  update_axe_tier_pricing?: Maybe<Axe_Tier_Pricing_Mutation_Response>;
  /** update single row of the table: "axe_tier_pricing" */
  update_axe_tier_pricing_by_pk?: Maybe<Axe_Tier_Pricing>;
  /** update multiples rows of table: "axe_tier_pricing" */
  update_axe_tier_pricing_many?: Maybe<Array<Maybe<Axe_Tier_Pricing_Mutation_Response>>>;
  /** update data of the table: "axes" */
  update_axes?: Maybe<Axes_Mutation_Response>;
  /** update data of the table: "axes_audit" */
  update_axes_audit?: Maybe<Axes_Audit_Mutation_Response>;
  /** update single row of the table: "axes_audit" */
  update_axes_audit_by_pk?: Maybe<Axes_Audit>;
  /** update multiples rows of table: "axes_audit" */
  update_axes_audit_many?: Maybe<Array<Maybe<Axes_Audit_Mutation_Response>>>;
  /** update single row of the table: "axes" */
  update_axes_by_pk?: Maybe<Axes>;
  /** update multiples rows of table: "axes" */
  update_axes_many?: Maybe<Array<Maybe<Axes_Mutation_Response>>>;
  /** update data of the table: "create_users_queue" */
  update_create_users_queue?: Maybe<Create_Users_Queue_Mutation_Response>;
  /** update single row of the table: "create_users_queue" */
  update_create_users_queue_by_pk?: Maybe<Create_Users_Queue>;
  /** update multiples rows of table: "create_users_queue" */
  update_create_users_queue_many?: Maybe<Array<Maybe<Create_Users_Queue_Mutation_Response>>>;
  /** update data of the table: "enum_axe_buysell" */
  update_enum_axe_buysell?: Maybe<Enum_Axe_Buysell_Mutation_Response>;
  /** update single row of the table: "enum_axe_buysell" */
  update_enum_axe_buysell_by_pk?: Maybe<Enum_Axe_Buysell>;
  /** update multiples rows of table: "enum_axe_buysell" */
  update_enum_axe_buysell_many?: Maybe<Array<Maybe<Enum_Axe_Buysell_Mutation_Response>>>;
  /** update data of the table: "enum_axe_callput" */
  update_enum_axe_callput?: Maybe<Enum_Axe_Callput_Mutation_Response>;
  /** update single row of the table: "enum_axe_callput" */
  update_enum_axe_callput_by_pk?: Maybe<Enum_Axe_Callput>;
  /** update multiples rows of table: "enum_axe_callput" */
  update_enum_axe_callput_many?: Maybe<Array<Maybe<Enum_Axe_Callput_Mutation_Response>>>;
  /** update data of the table: "enum_axe_ccypair" */
  update_enum_axe_ccypair?: Maybe<Enum_Axe_Ccypair_Mutation_Response>;
  /** update single row of the table: "enum_axe_ccypair" */
  update_enum_axe_ccypair_by_pk?: Maybe<Enum_Axe_Ccypair>;
  /** update multiples rows of table: "enum_axe_ccypair" */
  update_enum_axe_ccypair_many?: Maybe<Array<Maybe<Enum_Axe_Ccypair_Mutation_Response>>>;
  /** update data of the table: "enum_axe_depoccy1daycount" */
  update_enum_axe_depoccy1daycount?: Maybe<Enum_Axe_Depoccy1daycount_Mutation_Response>;
  /** update single row of the table: "enum_axe_depoccy1daycount" */
  update_enum_axe_depoccy1daycount_by_pk?: Maybe<Enum_Axe_Depoccy1daycount>;
  /** update multiples rows of table: "enum_axe_depoccy1daycount" */
  update_enum_axe_depoccy1daycount_many?: Maybe<Array<Maybe<Enum_Axe_Depoccy1daycount_Mutation_Response>>>;
  /** update data of the table: "enum_axe_depoccy2daycount" */
  update_enum_axe_depoccy2daycount?: Maybe<Enum_Axe_Depoccy2daycount_Mutation_Response>;
  /** update single row of the table: "enum_axe_depoccy2daycount" */
  update_enum_axe_depoccy2daycount_by_pk?: Maybe<Enum_Axe_Depoccy2daycount>;
  /** update multiples rows of table: "enum_axe_depoccy2daycount" */
  update_enum_axe_depoccy2daycount_many?: Maybe<Array<Maybe<Enum_Axe_Depoccy2daycount_Mutation_Response>>>;
  /** update data of the table: "enum_axe_hedgetype" */
  update_enum_axe_hedgetype?: Maybe<Enum_Axe_Hedgetype_Mutation_Response>;
  /** update single row of the table: "enum_axe_hedgetype" */
  update_enum_axe_hedgetype_by_pk?: Maybe<Enum_Axe_Hedgetype>;
  /** update multiples rows of table: "enum_axe_hedgetype" */
  update_enum_axe_hedgetype_many?: Maybe<Array<Maybe<Enum_Axe_Hedgetype_Mutation_Response>>>;
  /** update data of the table: "enum_axe_premiumtype" */
  update_enum_axe_premiumtype?: Maybe<Enum_Axe_Premiumtype_Mutation_Response>;
  /** update single row of the table: "enum_axe_premiumtype" */
  update_enum_axe_premiumtype_by_pk?: Maybe<Enum_Axe_Premiumtype>;
  /** update multiples rows of table: "enum_axe_premiumtype" */
  update_enum_axe_premiumtype_many?: Maybe<Array<Maybe<Enum_Axe_Premiumtype_Mutation_Response>>>;
  /** update data of the table: "enum_axe_product" */
  update_enum_axe_product?: Maybe<Enum_Axe_Product_Mutation_Response>;
  /** update single row of the table: "enum_axe_product" */
  update_enum_axe_product_by_pk?: Maybe<Enum_Axe_Product>;
  /** update multiples rows of table: "enum_axe_product" */
  update_enum_axe_product_many?: Maybe<Array<Maybe<Enum_Axe_Product_Mutation_Response>>>;
  /** update data of the table: "enum_axe_state" */
  update_enum_axe_state?: Maybe<Enum_Axe_State_Mutation_Response>;
  /** update single row of the table: "enum_axe_state" */
  update_enum_axe_state_by_pk?: Maybe<Enum_Axe_State>;
  /** update multiples rows of table: "enum_axe_state" */
  update_enum_axe_state_many?: Maybe<Array<Maybe<Enum_Axe_State_Mutation_Response>>>;
  /** update data of the table: "enum_color_scheme" */
  update_enum_color_scheme?: Maybe<Enum_Color_Scheme_Mutation_Response>;
  /** update single row of the table: "enum_color_scheme" */
  update_enum_color_scheme_by_pk?: Maybe<Enum_Color_Scheme>;
  /** update multiples rows of table: "enum_color_scheme" */
  update_enum_color_scheme_many?: Maybe<Array<Maybe<Enum_Color_Scheme_Mutation_Response>>>;
  /** update data of the table: "enum_currency_notional" */
  update_enum_currency_notional?: Maybe<Enum_Currency_Notional_Mutation_Response>;
  /** update single row of the table: "enum_currency_notional" */
  update_enum_currency_notional_by_pk?: Maybe<Enum_Currency_Notional>;
  /** update multiples rows of table: "enum_currency_notional" */
  update_enum_currency_notional_many?: Maybe<Array<Maybe<Enum_Currency_Notional_Mutation_Response>>>;
  /** update data of the table: "enum_currency_premium" */
  update_enum_currency_premium?: Maybe<Enum_Currency_Premium_Mutation_Response>;
  /** update single row of the table: "enum_currency_premium" */
  update_enum_currency_premium_by_pk?: Maybe<Enum_Currency_Premium>;
  /** update multiples rows of table: "enum_currency_premium" */
  update_enum_currency_premium_many?: Maybe<Array<Maybe<Enum_Currency_Premium_Mutation_Response>>>;
  /** update data of the table: "enum_instrument_type" */
  update_enum_instrument_type?: Maybe<Enum_Instrument_Type_Mutation_Response>;
  /** update single row of the table: "enum_instrument_type" */
  update_enum_instrument_type_by_pk?: Maybe<Enum_Instrument_Type>;
  /** update multiples rows of table: "enum_instrument_type" */
  update_enum_instrument_type_many?: Maybe<Array<Maybe<Enum_Instrument_Type_Mutation_Response>>>;
  /** update data of the table: "enum_notification_channel" */
  update_enum_notification_channel?: Maybe<Enum_Notification_Channel_Mutation_Response>;
  /** update single row of the table: "enum_notification_channel" */
  update_enum_notification_channel_by_pk?: Maybe<Enum_Notification_Channel>;
  /** update multiples rows of table: "enum_notification_channel" */
  update_enum_notification_channel_many?: Maybe<Array<Maybe<Enum_Notification_Channel_Mutation_Response>>>;
  /** update data of the table: "enum_notification_type" */
  update_enum_notification_type?: Maybe<Enum_Notification_Type_Mutation_Response>;
  /** update single row of the table: "enum_notification_type" */
  update_enum_notification_type_by_pk?: Maybe<Enum_Notification_Type>;
  /** update multiples rows of table: "enum_notification_type" */
  update_enum_notification_type_many?: Maybe<Array<Maybe<Enum_Notification_Type_Mutation_Response>>>;
  /** update data of the table: "enum_ptrd_callput" */
  update_enum_ptrd_callput?: Maybe<Enum_Ptrd_Callput_Mutation_Response>;
  /** update single row of the table: "enum_ptrd_callput" */
  update_enum_ptrd_callput_by_pk?: Maybe<Enum_Ptrd_Callput>;
  /** update multiples rows of table: "enum_ptrd_callput" */
  update_enum_ptrd_callput_many?: Maybe<Array<Maybe<Enum_Ptrd_Callput_Mutation_Response>>>;
  /** update data of the table: "enum_quote_side_type" */
  update_enum_quote_side_type?: Maybe<Enum_Quote_Side_Type_Mutation_Response>;
  /** update single row of the table: "enum_quote_side_type" */
  update_enum_quote_side_type_by_pk?: Maybe<Enum_Quote_Side_Type>;
  /** update multiples rows of table: "enum_quote_side_type" */
  update_enum_quote_side_type_many?: Maybe<Array<Maybe<Enum_Quote_Side_Type_Mutation_Response>>>;
  /** update data of the table: "enum_rfq_option_reason" */
  update_enum_rfq_option_reason?: Maybe<Enum_Rfq_Option_Reason_Mutation_Response>;
  /** update single row of the table: "enum_rfq_option_reason" */
  update_enum_rfq_option_reason_by_pk?: Maybe<Enum_Rfq_Option_Reason>;
  /** update multiples rows of table: "enum_rfq_option_reason" */
  update_enum_rfq_option_reason_many?: Maybe<Array<Maybe<Enum_Rfq_Option_Reason_Mutation_Response>>>;
  /** update data of the table: "enum_rfq_status" */
  update_enum_rfq_status?: Maybe<Enum_Rfq_Status_Mutation_Response>;
  /** update single row of the table: "enum_rfq_status" */
  update_enum_rfq_status_by_pk?: Maybe<Enum_Rfq_Status>;
  /** update multiples rows of table: "enum_rfq_status" */
  update_enum_rfq_status_many?: Maybe<Array<Maybe<Enum_Rfq_Status_Mutation_Response>>>;
  /** update data of the table: "enum_tenor" */
  update_enum_tenor?: Maybe<Enum_Tenor_Mutation_Response>;
  /** update single row of the table: "enum_tenor" */
  update_enum_tenor_by_pk?: Maybe<Enum_Tenor>;
  /** update multiples rows of table: "enum_tenor" */
  update_enum_tenor_many?: Maybe<Array<Maybe<Enum_Tenor_Mutation_Response>>>;
  /** update data of the table: "enum_tiers" */
  update_enum_tiers?: Maybe<Enum_Tiers_Mutation_Response>;
  /** update single row of the table: "enum_tiers" */
  update_enum_tiers_by_pk?: Maybe<Enum_Tiers>;
  /** update multiples rows of table: "enum_tiers" */
  update_enum_tiers_many?: Maybe<Array<Maybe<Enum_Tiers_Mutation_Response>>>;
  /** update data of the table: "enum_users_axe_suspended_state" */
  update_enum_users_axe_suspended_state?: Maybe<Enum_Users_Axe_Suspended_State_Mutation_Response>;
  /** update single row of the table: "enum_users_axe_suspended_state" */
  update_enum_users_axe_suspended_state_by_pk?: Maybe<Enum_Users_Axe_Suspended_State>;
  /** update multiples rows of table: "enum_users_axe_suspended_state" */
  update_enum_users_axe_suspended_state_many?: Maybe<Array<Maybe<Enum_Users_Axe_Suspended_State_Mutation_Response>>>;
  /** update data of the table: "expiry_cuts" */
  update_expiry_cuts?: Maybe<Expiry_Cuts_Mutation_Response>;
  /** update single row of the table: "expiry_cuts" */
  update_expiry_cuts_by_pk?: Maybe<Expiry_Cuts>;
  /** update multiples rows of table: "expiry_cuts" */
  update_expiry_cuts_many?: Maybe<Array<Maybe<Expiry_Cuts_Mutation_Response>>>;
  /** update data of the table: "fcaFeedbackMonitor" */
  update_fcaFeedbackMonitor?: Maybe<FcaFeedbackMonitor_Mutation_Response>;
  /** update multiples rows of table: "fcaFeedbackMonitor" */
  update_fcaFeedbackMonitor_many?: Maybe<Array<Maybe<FcaFeedbackMonitor_Mutation_Response>>>;
  /** update data of the table: "filters" */
  update_filters?: Maybe<Filters_Mutation_Response>;
  /** update single row of the table: "filters" */
  update_filters_by_pk?: Maybe<Filters>;
  /** update multiples rows of table: "filters" */
  update_filters_many?: Maybe<Array<Maybe<Filters_Mutation_Response>>>;
  /** update data of the table: "funds" */
  update_funds?: Maybe<Funds_Mutation_Response>;
  /** update single row of the table: "funds" */
  update_funds_by_pk?: Maybe<Funds>;
  /** update multiples rows of table: "funds" */
  update_funds_many?: Maybe<Array<Maybe<Funds_Mutation_Response>>>;
  /** update data of the table: "give_up_requests" */
  update_give_up_requests?: Maybe<Give_Up_Requests_Mutation_Response>;
  /** update single row of the table: "give_up_requests" */
  update_give_up_requests_by_pk?: Maybe<Give_Up_Requests>;
  /** update multiples rows of table: "give_up_requests" */
  update_give_up_requests_many?: Maybe<Array<Maybe<Give_Up_Requests_Mutation_Response>>>;
  /** update data of the table: "give_ups" */
  update_give_ups?: Maybe<Give_Ups_Mutation_Response>;
  /** update single row of the table: "give_ups" */
  update_give_ups_by_pk?: Maybe<Give_Ups>;
  /** update multiples rows of table: "give_ups" */
  update_give_ups_many?: Maybe<Array<Maybe<Give_Ups_Mutation_Response>>>;
  /** update data of the table: "give_ups_organizations" */
  update_give_ups_organizations?: Maybe<Give_Ups_Organizations_Mutation_Response>;
  /** update single row of the table: "give_ups_organizations" */
  update_give_ups_organizations_by_pk?: Maybe<Give_Ups_Organizations>;
  /** update multiples rows of table: "give_ups_organizations" */
  update_give_ups_organizations_many?: Maybe<Array<Maybe<Give_Ups_Organizations_Mutation_Response>>>;
  /** update data of the table: "instrumentReferenceData" */
  update_instrumentReferenceData?: Maybe<InstrumentReferenceData_Mutation_Response>;
  /** update single row of the table: "instrumentReferenceData" */
  update_instrumentReferenceData_by_pk?: Maybe<InstrumentReferenceData>;
  /** update multiples rows of table: "instrumentReferenceData" */
  update_instrumentReferenceData_many?: Maybe<Array<Maybe<InstrumentReferenceData_Mutation_Response>>>;
  /** update data of the table: "mdpValidationInstruments" */
  update_mdpValidationInstruments?: Maybe<MdpValidationInstruments_Mutation_Response>;
  /** update single row of the table: "mdpValidationInstruments" */
  update_mdpValidationInstruments_by_pk?: Maybe<MdpValidationInstruments>;
  /** update multiples rows of table: "mdpValidationInstruments" */
  update_mdpValidationInstruments_many?: Maybe<Array<Maybe<MdpValidationInstruments_Mutation_Response>>>;
  /** update data of the table: "notification_roles" */
  update_notification_roles?: Maybe<Notification_Roles_Mutation_Response>;
  /** update single row of the table: "notification_roles" */
  update_notification_roles_by_pk?: Maybe<Notification_Roles>;
  /** update multiples rows of table: "notification_roles" */
  update_notification_roles_many?: Maybe<Array<Maybe<Notification_Roles_Mutation_Response>>>;
  /** update data of the table: "notifications" */
  update_notifications?: Maybe<Notifications_Mutation_Response>;
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: Maybe<Notifications>;
  /** update multiples rows of table: "notifications" */
  update_notifications_many?: Maybe<Array<Maybe<Notifications_Mutation_Response>>>;
  /** update data of the table: "organization_main_tiering" */
  update_organization_main_tiering?: Maybe<Organization_Main_Tiering_Mutation_Response>;
  /** update single row of the table: "organization_main_tiering" */
  update_organization_main_tiering_by_pk?: Maybe<Organization_Main_Tiering>;
  /** update multiples rows of table: "organization_main_tiering" */
  update_organization_main_tiering_many?: Maybe<Array<Maybe<Organization_Main_Tiering_Mutation_Response>>>;
  /** update data of the table: "organizations" */
  update_organizations?: Maybe<Organizations_Mutation_Response>;
  /** update single row of the table: "organizations" */
  update_organizations_by_pk?: Maybe<Organizations>;
  /** update multiples rows of table: "organizations" */
  update_organizations_many?: Maybe<Array<Maybe<Organizations_Mutation_Response>>>;
  /** update data of the table: "push_subscriptions" */
  update_push_subscriptions?: Maybe<Push_Subscriptions_Mutation_Response>;
  /** update single row of the table: "push_subscriptions" */
  update_push_subscriptions_by_pk?: Maybe<Push_Subscriptions>;
  /** update multiples rows of table: "push_subscriptions" */
  update_push_subscriptions_many?: Maybe<Array<Maybe<Push_Subscriptions_Mutation_Response>>>;
  /** update data of the table: "reportSequenceIds" */
  update_reportSequenceIds?: Maybe<ReportSequenceIds_Mutation_Response>;
  /** update single row of the table: "reportSequenceIds" */
  update_reportSequenceIds_by_pk?: Maybe<ReportSequenceIds>;
  /** update multiples rows of table: "reportSequenceIds" */
  update_reportSequenceIds_many?: Maybe<Array<Maybe<ReportSequenceIds_Mutation_Response>>>;
  /** update data of the table: "rfq" */
  update_rfq?: Maybe<Rfq_Mutation_Response>;
  /** update single row of the table: "rfq" */
  update_rfq_by_pk?: Maybe<Rfq>;
  /** update data of the table: "rfq_legs" */
  update_rfq_legs?: Maybe<Rfq_Legs_Mutation_Response>;
  /** update single row of the table: "rfq_legs" */
  update_rfq_legs_by_pk?: Maybe<Rfq_Legs>;
  /** update multiples rows of table: "rfq_legs" */
  update_rfq_legs_many?: Maybe<Array<Maybe<Rfq_Legs_Mutation_Response>>>;
  /** update multiples rows of table: "rfq" */
  update_rfq_many?: Maybe<Array<Maybe<Rfq_Mutation_Response>>>;
  /** update data of the table: "rfq_options" */
  update_rfq_options?: Maybe<Rfq_Options_Mutation_Response>;
  /** update single row of the table: "rfq_options" */
  update_rfq_options_by_pk?: Maybe<Rfq_Options>;
  /** update data of the table: "rfq_options_legs" */
  update_rfq_options_legs?: Maybe<Rfq_Options_Legs_Mutation_Response>;
  /** update single row of the table: "rfq_options_legs" */
  update_rfq_options_legs_by_pk?: Maybe<Rfq_Options_Legs>;
  /** update multiples rows of table: "rfq_options_legs" */
  update_rfq_options_legs_many?: Maybe<Array<Maybe<Rfq_Options_Legs_Mutation_Response>>>;
  /** update multiples rows of table: "rfq_options" */
  update_rfq_options_many?: Maybe<Array<Maybe<Rfq_Options_Mutation_Response>>>;
  /** update data of the table: "rfq_public_queue" */
  update_rfq_public_queue?: Maybe<Rfq_Public_Queue_Mutation_Response>;
  /** update single row of the table: "rfq_public_queue" */
  update_rfq_public_queue_by_pk?: Maybe<Rfq_Public_Queue>;
  /** update multiples rows of table: "rfq_public_queue" */
  update_rfq_public_queue_many?: Maybe<Array<Maybe<Rfq_Public_Queue_Mutation_Response>>>;
  /** update data of the table: "sub_organizations" */
  update_sub_organizations?: Maybe<Sub_Organizations_Mutation_Response>;
  /** update single row of the table: "sub_organizations" */
  update_sub_organizations_by_pk?: Maybe<Sub_Organizations>;
  /** update multiples rows of table: "sub_organizations" */
  update_sub_organizations_many?: Maybe<Array<Maybe<Sub_Organizations_Mutation_Response>>>;
  /** update data of the table: "trade_confirms" */
  update_trade_confirms?: Maybe<Trade_Confirms_Mutation_Response>;
  /** update single row of the table: "trade_confirms" */
  update_trade_confirms_by_pk?: Maybe<Trade_Confirms>;
  /** update multiples rows of table: "trade_confirms" */
  update_trade_confirms_many?: Maybe<Array<Maybe<Trade_Confirms_Mutation_Response>>>;
  /** update data of the table: "traded_hedges" */
  update_traded_hedges?: Maybe<Traded_Hedges_Mutation_Response>;
  /** update multiples rows of table: "traded_hedges" */
  update_traded_hedges_many?: Maybe<Array<Maybe<Traded_Hedges_Mutation_Response>>>;
  /** update data of the table: "trades" */
  update_trades?: Maybe<Trades_Mutation_Response>;
  /** update single row of the table: "trades" */
  update_trades_by_pk?: Maybe<Trades>;
  /** update data of the table: "trades_leg" */
  update_trades_leg?: Maybe<Trades_Leg_Mutation_Response>;
  /** update single row of the table: "trades_leg" */
  update_trades_leg_by_pk?: Maybe<Trades_Leg>;
  /** update multiples rows of table: "trades_leg" */
  update_trades_leg_many?: Maybe<Array<Maybe<Trades_Leg_Mutation_Response>>>;
  /** update multiples rows of table: "trades" */
  update_trades_many?: Maybe<Array<Maybe<Trades_Mutation_Response>>>;
  /** update data of the table: "user_main_tiering" */
  update_user_main_tiering?: Maybe<User_Main_Tiering_Mutation_Response>;
  /** update single row of the table: "user_main_tiering" */
  update_user_main_tiering_by_pk?: Maybe<User_Main_Tiering>;
  /** update multiples rows of table: "user_main_tiering" */
  update_user_main_tiering_many?: Maybe<Array<Maybe<User_Main_Tiering_Mutation_Response>>>;
  /** update data of the table: "user_notifications" */
  update_user_notifications?: Maybe<User_Notifications_Mutation_Response>;
  /** update single row of the table: "user_notifications" */
  update_user_notifications_by_pk?: Maybe<User_Notifications>;
  /** update multiples rows of table: "user_notifications" */
  update_user_notifications_many?: Maybe<Array<Maybe<User_Notifications_Mutation_Response>>>;
  /** update data of the table: "user_tiers" */
  update_user_tiers?: Maybe<User_Tiers_Mutation_Response>;
  /** update single row of the table: "user_tiers" */
  update_user_tiers_by_pk?: Maybe<User_Tiers>;
  /** update multiples rows of table: "user_tiers" */
  update_user_tiers_many?: Maybe<Array<Maybe<User_Tiers_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_Axe_AuthorsArgs = {
  where: Axe_Authors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Axe_Authors_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Axe_LegsArgs = {
  where: Axe_Legs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Axe_Legs_AuditArgs = {
  where: Axe_Legs_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Axe_Legs_Audit_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Axe_Legs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Axe_Tier_PricingArgs = {
  where: Axe_Tier_Pricing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Axe_Tier_Pricing_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_AxesArgs = {
  where: Axes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Axes_AuditArgs = {
  where: Axes_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Axes_Audit_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Axes_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Create_Users_QueueArgs = {
  where: Create_Users_Queue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Create_Users_Queue_By_PkArgs = {
  email: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_BuysellArgs = {
  where: Enum_Axe_Buysell_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_Buysell_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_CallputArgs = {
  where: Enum_Axe_Callput_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_Callput_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_CcypairArgs = {
  where: Enum_Axe_Ccypair_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_Ccypair_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_Depoccy1daycountArgs = {
  where: Enum_Axe_Depoccy1daycount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_Depoccy1daycount_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_Depoccy2daycountArgs = {
  where: Enum_Axe_Depoccy2daycount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_Depoccy2daycount_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_HedgetypeArgs = {
  where: Enum_Axe_Hedgetype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_Hedgetype_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_PremiumtypeArgs = {
  where: Enum_Axe_Premiumtype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_Premiumtype_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_ProductArgs = {
  where: Enum_Axe_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_Product_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_StateArgs = {
  where: Enum_Axe_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Axe_State_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Color_SchemeArgs = {
  where: Enum_Color_Scheme_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Color_Scheme_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Currency_NotionalArgs = {
  where: Enum_Currency_Notional_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Currency_Notional_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Currency_PremiumArgs = {
  where: Enum_Currency_Premium_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Currency_Premium_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Instrument_TypeArgs = {
  where: Enum_Instrument_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Instrument_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Notification_ChannelArgs = {
  where: Enum_Notification_Channel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Notification_Channel_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Notification_TypeArgs = {
  where: Enum_Notification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Notification_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Ptrd_CallputArgs = {
  where: Enum_Ptrd_Callput_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Ptrd_Callput_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Quote_Side_TypeArgs = {
  where: Enum_Quote_Side_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Quote_Side_Type_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Rfq_Option_ReasonArgs = {
  where: Enum_Rfq_Option_Reason_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Rfq_Option_Reason_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Rfq_StatusArgs = {
  where: Enum_Rfq_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Rfq_Status_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_TenorArgs = {
  where: Enum_Tenor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Tenor_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_TiersArgs = {
  where: Enum_Tiers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Tiers_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Enum_Users_Axe_Suspended_StateArgs = {
  where: Enum_Users_Axe_Suspended_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Enum_Users_Axe_Suspended_State_By_PkArgs = {
  value: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Expiry_CutsArgs = {
  where: Expiry_Cuts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Expiry_Cuts_By_PkArgs = {
  code: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FcaFeedbackMonitorArgs = {
  where: FcaFeedbackMonitor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_FiltersArgs = {
  where: Filters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Filters_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FundsArgs = {
  where: Funds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Funds_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Give_Up_RequestsArgs = {
  where: Give_Up_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Give_Up_Requests_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Give_UpsArgs = {
  where: Give_Ups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Give_Ups_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Give_Ups_OrganizationsArgs = {
  where: Give_Ups_Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Give_Ups_Organizations_By_PkArgs = {
  giveupId: Scalars['uuid']['input'];
  organizationId: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_InstrumentReferenceDataArgs = {
  where: InstrumentReferenceData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_InstrumentReferenceData_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_MdpValidationInstrumentsArgs = {
  where: MdpValidationInstruments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_MdpValidationInstruments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_RolesArgs = {
  where: Notification_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Roles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationsArgs = {
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Organization_Main_TieringArgs = {
  where: Organization_Main_Tiering_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organization_Main_Tiering_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_OrganizationsArgs = {
  where: Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Push_SubscriptionsArgs = {
  where: Push_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Push_Subscriptions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ReportSequenceIdsArgs = {
  where: ReportSequenceIds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ReportSequenceIds_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_RfqArgs = {
  where: Rfq_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rfq_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Rfq_LegsArgs = {
  where: Rfq_Legs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rfq_Legs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Rfq_OptionsArgs = {
  where: Rfq_Options_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rfq_Options_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Rfq_Options_LegsArgs = {
  where: Rfq_Options_Legs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rfq_Options_Legs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Rfq_Public_QueueArgs = {
  where: Rfq_Public_Queue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Rfq_Public_Queue_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Sub_OrganizationsArgs = {
  where: Sub_Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sub_Organizations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Trade_ConfirmsArgs = {
  where: Trade_Confirms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Trade_Confirms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Traded_HedgesArgs = {
  where: Traded_Hedges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TradesArgs = {
  where: Trades_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Trades_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Trades_LegArgs = {
  where: Trades_Leg_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Trades_Leg_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Main_TieringArgs = {
  where: User_Main_Tiering_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Main_Tiering_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_NotificationsArgs = {
  where: User_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Notifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_TiersArgs = {
  where: User_Tiers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Tiers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootInsert_Axe_AuthorsArgs = {
  objects: Array<Axe_Authors_Insert_Input>;
  on_conflict?: InputMaybe<Axe_Authors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Axe_Authors_OneArgs = {
  object: Axe_Authors_Insert_Input;
  on_conflict?: InputMaybe<Axe_Authors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Axe_LegsArgs = {
  objects: Array<Axe_Legs_Insert_Input>;
  on_conflict?: InputMaybe<Axe_Legs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Axe_Legs_AuditArgs = {
  objects: Array<Axe_Legs_Audit_Insert_Input>;
  on_conflict?: InputMaybe<Axe_Legs_Audit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Axe_Legs_Audit_OneArgs = {
  object: Axe_Legs_Audit_Insert_Input;
  on_conflict?: InputMaybe<Axe_Legs_Audit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Axe_Legs_OneArgs = {
  object: Axe_Legs_Insert_Input;
  on_conflict?: InputMaybe<Axe_Legs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Axe_Tier_PricingArgs = {
  objects: Array<Axe_Tier_Pricing_Insert_Input>;
  on_conflict?: InputMaybe<Axe_Tier_Pricing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Axe_Tier_Pricing_OneArgs = {
  object: Axe_Tier_Pricing_Insert_Input;
  on_conflict?: InputMaybe<Axe_Tier_Pricing_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AxesArgs = {
  objects: Array<Axes_Insert_Input>;
  on_conflict?: InputMaybe<Axes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Axes_AuditArgs = {
  objects: Array<Axes_Audit_Insert_Input>;
  on_conflict?: InputMaybe<Axes_Audit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Axes_Audit_OneArgs = {
  object: Axes_Audit_Insert_Input;
  on_conflict?: InputMaybe<Axes_Audit_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Axes_OneArgs = {
  object: Axes_Insert_Input;
  on_conflict?: InputMaybe<Axes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Create_Users_QueueArgs = {
  objects: Array<Create_Users_Queue_Insert_Input>;
  on_conflict?: InputMaybe<Create_Users_Queue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Create_Users_Queue_OneArgs = {
  object: Create_Users_Queue_Insert_Input;
  on_conflict?: InputMaybe<Create_Users_Queue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_BuysellArgs = {
  objects: Array<Enum_Axe_Buysell_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Axe_Buysell_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_Buysell_OneArgs = {
  object: Enum_Axe_Buysell_Insert_Input;
  on_conflict?: InputMaybe<Enum_Axe_Buysell_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_CallputArgs = {
  objects: Array<Enum_Axe_Callput_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Axe_Callput_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_Callput_OneArgs = {
  object: Enum_Axe_Callput_Insert_Input;
  on_conflict?: InputMaybe<Enum_Axe_Callput_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_CcypairArgs = {
  objects: Array<Enum_Axe_Ccypair_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Axe_Ccypair_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_Ccypair_OneArgs = {
  object: Enum_Axe_Ccypair_Insert_Input;
  on_conflict?: InputMaybe<Enum_Axe_Ccypair_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_Depoccy1daycountArgs = {
  objects: Array<Enum_Axe_Depoccy1daycount_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Axe_Depoccy1daycount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_Depoccy1daycount_OneArgs = {
  object: Enum_Axe_Depoccy1daycount_Insert_Input;
  on_conflict?: InputMaybe<Enum_Axe_Depoccy1daycount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_Depoccy2daycountArgs = {
  objects: Array<Enum_Axe_Depoccy2daycount_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Axe_Depoccy2daycount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_Depoccy2daycount_OneArgs = {
  object: Enum_Axe_Depoccy2daycount_Insert_Input;
  on_conflict?: InputMaybe<Enum_Axe_Depoccy2daycount_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_HedgetypeArgs = {
  objects: Array<Enum_Axe_Hedgetype_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Axe_Hedgetype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_Hedgetype_OneArgs = {
  object: Enum_Axe_Hedgetype_Insert_Input;
  on_conflict?: InputMaybe<Enum_Axe_Hedgetype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_PremiumtypeArgs = {
  objects: Array<Enum_Axe_Premiumtype_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Axe_Premiumtype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_Premiumtype_OneArgs = {
  object: Enum_Axe_Premiumtype_Insert_Input;
  on_conflict?: InputMaybe<Enum_Axe_Premiumtype_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_ProductArgs = {
  objects: Array<Enum_Axe_Product_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Axe_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_Product_OneArgs = {
  object: Enum_Axe_Product_Insert_Input;
  on_conflict?: InputMaybe<Enum_Axe_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_StateArgs = {
  objects: Array<Enum_Axe_State_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Axe_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Axe_State_OneArgs = {
  object: Enum_Axe_State_Insert_Input;
  on_conflict?: InputMaybe<Enum_Axe_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Color_SchemeArgs = {
  objects: Array<Enum_Color_Scheme_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Color_Scheme_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Color_Scheme_OneArgs = {
  object: Enum_Color_Scheme_Insert_Input;
  on_conflict?: InputMaybe<Enum_Color_Scheme_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Currency_NotionalArgs = {
  objects: Array<Enum_Currency_Notional_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Currency_Notional_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Currency_Notional_OneArgs = {
  object: Enum_Currency_Notional_Insert_Input;
  on_conflict?: InputMaybe<Enum_Currency_Notional_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Currency_PremiumArgs = {
  objects: Array<Enum_Currency_Premium_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Currency_Premium_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Currency_Premium_OneArgs = {
  object: Enum_Currency_Premium_Insert_Input;
  on_conflict?: InputMaybe<Enum_Currency_Premium_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Instrument_TypeArgs = {
  objects: Array<Enum_Instrument_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Instrument_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Instrument_Type_OneArgs = {
  object: Enum_Instrument_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Instrument_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Notification_ChannelArgs = {
  objects: Array<Enum_Notification_Channel_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Notification_Channel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Notification_Channel_OneArgs = {
  object: Enum_Notification_Channel_Insert_Input;
  on_conflict?: InputMaybe<Enum_Notification_Channel_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Notification_TypeArgs = {
  objects: Array<Enum_Notification_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Notification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Notification_Type_OneArgs = {
  object: Enum_Notification_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Notification_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Ptrd_CallputArgs = {
  objects: Array<Enum_Ptrd_Callput_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Ptrd_Callput_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Ptrd_Callput_OneArgs = {
  object: Enum_Ptrd_Callput_Insert_Input;
  on_conflict?: InputMaybe<Enum_Ptrd_Callput_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Quote_Side_TypeArgs = {
  objects: Array<Enum_Quote_Side_Type_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Quote_Side_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Quote_Side_Type_OneArgs = {
  object: Enum_Quote_Side_Type_Insert_Input;
  on_conflict?: InputMaybe<Enum_Quote_Side_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Rfq_Option_ReasonArgs = {
  objects: Array<Enum_Rfq_Option_Reason_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Rfq_Option_Reason_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Rfq_Option_Reason_OneArgs = {
  object: Enum_Rfq_Option_Reason_Insert_Input;
  on_conflict?: InputMaybe<Enum_Rfq_Option_Reason_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Rfq_StatusArgs = {
  objects: Array<Enum_Rfq_Status_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Rfq_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Rfq_Status_OneArgs = {
  object: Enum_Rfq_Status_Insert_Input;
  on_conflict?: InputMaybe<Enum_Rfq_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_TenorArgs = {
  objects: Array<Enum_Tenor_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Tenor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Tenor_OneArgs = {
  object: Enum_Tenor_Insert_Input;
  on_conflict?: InputMaybe<Enum_Tenor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_TiersArgs = {
  objects: Array<Enum_Tiers_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Tiers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Tiers_OneArgs = {
  object: Enum_Tiers_Insert_Input;
  on_conflict?: InputMaybe<Enum_Tiers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Users_Axe_Suspended_StateArgs = {
  objects: Array<Enum_Users_Axe_Suspended_State_Insert_Input>;
  on_conflict?: InputMaybe<Enum_Users_Axe_Suspended_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Enum_Users_Axe_Suspended_State_OneArgs = {
  object: Enum_Users_Axe_Suspended_State_Insert_Input;
  on_conflict?: InputMaybe<Enum_Users_Axe_Suspended_State_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Expiry_CutsArgs = {
  objects: Array<Expiry_Cuts_Insert_Input>;
  on_conflict?: InputMaybe<Expiry_Cuts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Expiry_Cuts_OneArgs = {
  object: Expiry_Cuts_Insert_Input;
  on_conflict?: InputMaybe<Expiry_Cuts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FcaFeedbackMonitorArgs = {
  objects: Array<FcaFeedbackMonitor_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_FcaFeedbackMonitor_OneArgs = {
  object: FcaFeedbackMonitor_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_FiltersArgs = {
  objects: Array<Filters_Insert_Input>;
  on_conflict?: InputMaybe<Filters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Filters_OneArgs = {
  object: Filters_Insert_Input;
  on_conflict?: InputMaybe<Filters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FundsArgs = {
  objects: Array<Funds_Insert_Input>;
  on_conflict?: InputMaybe<Funds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Funds_OneArgs = {
  object: Funds_Insert_Input;
  on_conflict?: InputMaybe<Funds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Give_Up_RequestsArgs = {
  objects: Array<Give_Up_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Give_Up_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Give_Up_Requests_OneArgs = {
  object: Give_Up_Requests_Insert_Input;
  on_conflict?: InputMaybe<Give_Up_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Give_UpsArgs = {
  objects: Array<Give_Ups_Insert_Input>;
  on_conflict?: InputMaybe<Give_Ups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Give_Ups_OneArgs = {
  object: Give_Ups_Insert_Input;
  on_conflict?: InputMaybe<Give_Ups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Give_Ups_OrganizationsArgs = {
  objects: Array<Give_Ups_Organizations_Insert_Input>;
  on_conflict?: InputMaybe<Give_Ups_Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Give_Ups_Organizations_OneArgs = {
  object: Give_Ups_Organizations_Insert_Input;
  on_conflict?: InputMaybe<Give_Ups_Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InstrumentReferenceDataArgs = {
  objects: Array<InstrumentReferenceData_Insert_Input>;
  on_conflict?: InputMaybe<InstrumentReferenceData_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_InstrumentReferenceData_OneArgs = {
  object: InstrumentReferenceData_Insert_Input;
  on_conflict?: InputMaybe<InstrumentReferenceData_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MdpValidationInstrumentsArgs = {
  objects: Array<MdpValidationInstruments_Insert_Input>;
  on_conflict?: InputMaybe<MdpValidationInstruments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MdpValidationInstruments_OneArgs = {
  object: MdpValidationInstruments_Insert_Input;
  on_conflict?: InputMaybe<MdpValidationInstruments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_RolesArgs = {
  objects: Array<Notification_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Roles_OneArgs = {
  object: Notification_Roles_Insert_Input;
  on_conflict?: InputMaybe<Notification_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationsArgs = {
  objects: Array<Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notifications_OneArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organization_Main_TieringArgs = {
  objects: Array<Organization_Main_Tiering_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Main_Tiering_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organization_Main_Tiering_OneArgs = {
  object: Organization_Main_Tiering_Insert_Input;
  on_conflict?: InputMaybe<Organization_Main_Tiering_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrganizationsArgs = {
  objects: Array<Organizations_Insert_Input>;
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_OneArgs = {
  object: Organizations_Insert_Input;
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Push_SubscriptionsArgs = {
  objects: Array<Push_Subscriptions_Insert_Input>;
  on_conflict?: InputMaybe<Push_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Push_Subscriptions_OneArgs = {
  object: Push_Subscriptions_Insert_Input;
  on_conflict?: InputMaybe<Push_Subscriptions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReportSequenceIdsArgs = {
  objects: Array<ReportSequenceIds_Insert_Input>;
  on_conflict?: InputMaybe<ReportSequenceIds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReportSequenceIds_OneArgs = {
  object: ReportSequenceIds_Insert_Input;
  on_conflict?: InputMaybe<ReportSequenceIds_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RfqArgs = {
  objects: Array<Rfq_Insert_Input>;
  on_conflict?: InputMaybe<Rfq_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rfq_LegsArgs = {
  objects: Array<Rfq_Legs_Insert_Input>;
  on_conflict?: InputMaybe<Rfq_Legs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rfq_Legs_OneArgs = {
  object: Rfq_Legs_Insert_Input;
  on_conflict?: InputMaybe<Rfq_Legs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rfq_OneArgs = {
  object: Rfq_Insert_Input;
  on_conflict?: InputMaybe<Rfq_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rfq_OptionsArgs = {
  objects: Array<Rfq_Options_Insert_Input>;
  on_conflict?: InputMaybe<Rfq_Options_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rfq_Options_LegsArgs = {
  objects: Array<Rfq_Options_Legs_Insert_Input>;
  on_conflict?: InputMaybe<Rfq_Options_Legs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rfq_Options_Legs_OneArgs = {
  object: Rfq_Options_Legs_Insert_Input;
  on_conflict?: InputMaybe<Rfq_Options_Legs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rfq_Options_OneArgs = {
  object: Rfq_Options_Insert_Input;
  on_conflict?: InputMaybe<Rfq_Options_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rfq_Public_QueueArgs = {
  objects: Array<Rfq_Public_Queue_Insert_Input>;
  on_conflict?: InputMaybe<Rfq_Public_Queue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Rfq_Public_Queue_OneArgs = {
  object: Rfq_Public_Queue_Insert_Input;
  on_conflict?: InputMaybe<Rfq_Public_Queue_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sub_OrganizationsArgs = {
  objects: Array<Sub_Organizations_Insert_Input>;
  on_conflict?: InputMaybe<Sub_Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sub_Organizations_OneArgs = {
  object: Sub_Organizations_Insert_Input;
  on_conflict?: InputMaybe<Sub_Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trade_ConfirmsArgs = {
  objects: Array<Trade_Confirms_Insert_Input>;
  on_conflict?: InputMaybe<Trade_Confirms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trade_Confirms_OneArgs = {
  object: Trade_Confirms_Insert_Input;
  on_conflict?: InputMaybe<Trade_Confirms_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Traded_HedgesArgs = {
  objects: Array<Traded_Hedges_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Traded_Hedges_OneArgs = {
  object: Traded_Hedges_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_TradesArgs = {
  objects: Array<Trades_Insert_Input>;
  on_conflict?: InputMaybe<Trades_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trades_LegArgs = {
  objects: Array<Trades_Leg_Insert_Input>;
  on_conflict?: InputMaybe<Trades_Leg_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trades_Leg_OneArgs = {
  object: Trades_Leg_Insert_Input;
  on_conflict?: InputMaybe<Trades_Leg_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Trades_OneArgs = {
  object: Trades_Insert_Input;
  on_conflict?: InputMaybe<Trades_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Main_TieringArgs = {
  objects: Array<User_Main_Tiering_Insert_Input>;
  on_conflict?: InputMaybe<User_Main_Tiering_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Main_Tiering_OneArgs = {
  object: User_Main_Tiering_Insert_Input;
  on_conflict?: InputMaybe<User_Main_Tiering_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_NotificationsArgs = {
  objects: Array<User_Notifications_Insert_Input>;
  on_conflict?: InputMaybe<User_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Notifications_OneArgs = {
  object: User_Notifications_Insert_Input;
  on_conflict?: InputMaybe<User_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_TiersArgs = {
  objects: Array<User_Tiers_Insert_Input>;
  on_conflict?: InputMaybe<User_Tiers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Tiers_OneArgs = {
  object: User_Tiers_Insert_Input;
  on_conflict?: InputMaybe<User_Tiers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Axe_AuthorsArgs = {
  _set?: InputMaybe<Axe_Authors_Set_Input>;
  where: Axe_Authors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Axe_Authors_By_PkArgs = {
  _set?: InputMaybe<Axe_Authors_Set_Input>;
  pk_columns: Axe_Authors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Axe_Authors_ManyArgs = {
  updates: Array<Axe_Authors_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Axe_LegsArgs = {
  _inc?: InputMaybe<Axe_Legs_Inc_Input>;
  _set?: InputMaybe<Axe_Legs_Set_Input>;
  where: Axe_Legs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Axe_Legs_AuditArgs = {
  _inc?: InputMaybe<Axe_Legs_Audit_Inc_Input>;
  _set?: InputMaybe<Axe_Legs_Audit_Set_Input>;
  where: Axe_Legs_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Axe_Legs_Audit_By_PkArgs = {
  _inc?: InputMaybe<Axe_Legs_Audit_Inc_Input>;
  _set?: InputMaybe<Axe_Legs_Audit_Set_Input>;
  pk_columns: Axe_Legs_Audit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Axe_Legs_Audit_ManyArgs = {
  updates: Array<Axe_Legs_Audit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Axe_Legs_By_PkArgs = {
  _inc?: InputMaybe<Axe_Legs_Inc_Input>;
  _set?: InputMaybe<Axe_Legs_Set_Input>;
  pk_columns: Axe_Legs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Axe_Legs_ManyArgs = {
  updates: Array<Axe_Legs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Axe_Tier_PricingArgs = {
  _inc?: InputMaybe<Axe_Tier_Pricing_Inc_Input>;
  _set?: InputMaybe<Axe_Tier_Pricing_Set_Input>;
  where: Axe_Tier_Pricing_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Axe_Tier_Pricing_By_PkArgs = {
  _inc?: InputMaybe<Axe_Tier_Pricing_Inc_Input>;
  _set?: InputMaybe<Axe_Tier_Pricing_Set_Input>;
  pk_columns: Axe_Tier_Pricing_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Axe_Tier_Pricing_ManyArgs = {
  updates: Array<Axe_Tier_Pricing_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AxesArgs = {
  _inc?: InputMaybe<Axes_Inc_Input>;
  _set?: InputMaybe<Axes_Set_Input>;
  where: Axes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Axes_AuditArgs = {
  _inc?: InputMaybe<Axes_Audit_Inc_Input>;
  _set?: InputMaybe<Axes_Audit_Set_Input>;
  where: Axes_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Axes_Audit_By_PkArgs = {
  _inc?: InputMaybe<Axes_Audit_Inc_Input>;
  _set?: InputMaybe<Axes_Audit_Set_Input>;
  pk_columns: Axes_Audit_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Axes_Audit_ManyArgs = {
  updates: Array<Axes_Audit_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Axes_By_PkArgs = {
  _inc?: InputMaybe<Axes_Inc_Input>;
  _set?: InputMaybe<Axes_Set_Input>;
  pk_columns: Axes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Axes_ManyArgs = {
  updates: Array<Axes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Create_Users_QueueArgs = {
  _set?: InputMaybe<Create_Users_Queue_Set_Input>;
  where: Create_Users_Queue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Create_Users_Queue_By_PkArgs = {
  _set?: InputMaybe<Create_Users_Queue_Set_Input>;
  pk_columns: Create_Users_Queue_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Create_Users_Queue_ManyArgs = {
  updates: Array<Create_Users_Queue_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_BuysellArgs = {
  _set?: InputMaybe<Enum_Axe_Buysell_Set_Input>;
  where: Enum_Axe_Buysell_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Buysell_By_PkArgs = {
  _set?: InputMaybe<Enum_Axe_Buysell_Set_Input>;
  pk_columns: Enum_Axe_Buysell_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Buysell_ManyArgs = {
  updates: Array<Enum_Axe_Buysell_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_CallputArgs = {
  _set?: InputMaybe<Enum_Axe_Callput_Set_Input>;
  where: Enum_Axe_Callput_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Callput_By_PkArgs = {
  _set?: InputMaybe<Enum_Axe_Callput_Set_Input>;
  pk_columns: Enum_Axe_Callput_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Callput_ManyArgs = {
  updates: Array<Enum_Axe_Callput_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_CcypairArgs = {
  _set?: InputMaybe<Enum_Axe_Ccypair_Set_Input>;
  where: Enum_Axe_Ccypair_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Ccypair_By_PkArgs = {
  _set?: InputMaybe<Enum_Axe_Ccypair_Set_Input>;
  pk_columns: Enum_Axe_Ccypair_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Ccypair_ManyArgs = {
  updates: Array<Enum_Axe_Ccypair_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Depoccy1daycountArgs = {
  _set?: InputMaybe<Enum_Axe_Depoccy1daycount_Set_Input>;
  where: Enum_Axe_Depoccy1daycount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Depoccy1daycount_By_PkArgs = {
  _set?: InputMaybe<Enum_Axe_Depoccy1daycount_Set_Input>;
  pk_columns: Enum_Axe_Depoccy1daycount_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Depoccy1daycount_ManyArgs = {
  updates: Array<Enum_Axe_Depoccy1daycount_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Depoccy2daycountArgs = {
  _set?: InputMaybe<Enum_Axe_Depoccy2daycount_Set_Input>;
  where: Enum_Axe_Depoccy2daycount_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Depoccy2daycount_By_PkArgs = {
  _set?: InputMaybe<Enum_Axe_Depoccy2daycount_Set_Input>;
  pk_columns: Enum_Axe_Depoccy2daycount_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Depoccy2daycount_ManyArgs = {
  updates: Array<Enum_Axe_Depoccy2daycount_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_HedgetypeArgs = {
  _set?: InputMaybe<Enum_Axe_Hedgetype_Set_Input>;
  where: Enum_Axe_Hedgetype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Hedgetype_By_PkArgs = {
  _set?: InputMaybe<Enum_Axe_Hedgetype_Set_Input>;
  pk_columns: Enum_Axe_Hedgetype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Hedgetype_ManyArgs = {
  updates: Array<Enum_Axe_Hedgetype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_PremiumtypeArgs = {
  _set?: InputMaybe<Enum_Axe_Premiumtype_Set_Input>;
  where: Enum_Axe_Premiumtype_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Premiumtype_By_PkArgs = {
  _set?: InputMaybe<Enum_Axe_Premiumtype_Set_Input>;
  pk_columns: Enum_Axe_Premiumtype_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Premiumtype_ManyArgs = {
  updates: Array<Enum_Axe_Premiumtype_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_ProductArgs = {
  _set?: InputMaybe<Enum_Axe_Product_Set_Input>;
  where: Enum_Axe_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Product_By_PkArgs = {
  _set?: InputMaybe<Enum_Axe_Product_Set_Input>;
  pk_columns: Enum_Axe_Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_Product_ManyArgs = {
  updates: Array<Enum_Axe_Product_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_StateArgs = {
  _set?: InputMaybe<Enum_Axe_State_Set_Input>;
  where: Enum_Axe_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_State_By_PkArgs = {
  _set?: InputMaybe<Enum_Axe_State_Set_Input>;
  pk_columns: Enum_Axe_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Axe_State_ManyArgs = {
  updates: Array<Enum_Axe_State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Color_SchemeArgs = {
  _set?: InputMaybe<Enum_Color_Scheme_Set_Input>;
  where: Enum_Color_Scheme_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Color_Scheme_By_PkArgs = {
  _set?: InputMaybe<Enum_Color_Scheme_Set_Input>;
  pk_columns: Enum_Color_Scheme_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Color_Scheme_ManyArgs = {
  updates: Array<Enum_Color_Scheme_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Currency_NotionalArgs = {
  _set?: InputMaybe<Enum_Currency_Notional_Set_Input>;
  where: Enum_Currency_Notional_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Currency_Notional_By_PkArgs = {
  _set?: InputMaybe<Enum_Currency_Notional_Set_Input>;
  pk_columns: Enum_Currency_Notional_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Currency_Notional_ManyArgs = {
  updates: Array<Enum_Currency_Notional_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Currency_PremiumArgs = {
  _set?: InputMaybe<Enum_Currency_Premium_Set_Input>;
  where: Enum_Currency_Premium_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Currency_Premium_By_PkArgs = {
  _set?: InputMaybe<Enum_Currency_Premium_Set_Input>;
  pk_columns: Enum_Currency_Premium_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Currency_Premium_ManyArgs = {
  updates: Array<Enum_Currency_Premium_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Instrument_TypeArgs = {
  _set?: InputMaybe<Enum_Instrument_Type_Set_Input>;
  where: Enum_Instrument_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Instrument_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Instrument_Type_Set_Input>;
  pk_columns: Enum_Instrument_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Instrument_Type_ManyArgs = {
  updates: Array<Enum_Instrument_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Notification_ChannelArgs = {
  _set?: InputMaybe<Enum_Notification_Channel_Set_Input>;
  where: Enum_Notification_Channel_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Notification_Channel_By_PkArgs = {
  _set?: InputMaybe<Enum_Notification_Channel_Set_Input>;
  pk_columns: Enum_Notification_Channel_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Notification_Channel_ManyArgs = {
  updates: Array<Enum_Notification_Channel_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Notification_TypeArgs = {
  _set?: InputMaybe<Enum_Notification_Type_Set_Input>;
  where: Enum_Notification_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Notification_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Notification_Type_Set_Input>;
  pk_columns: Enum_Notification_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Notification_Type_ManyArgs = {
  updates: Array<Enum_Notification_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Ptrd_CallputArgs = {
  _set?: InputMaybe<Enum_Ptrd_Callput_Set_Input>;
  where: Enum_Ptrd_Callput_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Ptrd_Callput_By_PkArgs = {
  _set?: InputMaybe<Enum_Ptrd_Callput_Set_Input>;
  pk_columns: Enum_Ptrd_Callput_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Ptrd_Callput_ManyArgs = {
  updates: Array<Enum_Ptrd_Callput_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Quote_Side_TypeArgs = {
  _set?: InputMaybe<Enum_Quote_Side_Type_Set_Input>;
  where: Enum_Quote_Side_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Quote_Side_Type_By_PkArgs = {
  _set?: InputMaybe<Enum_Quote_Side_Type_Set_Input>;
  pk_columns: Enum_Quote_Side_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Quote_Side_Type_ManyArgs = {
  updates: Array<Enum_Quote_Side_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Rfq_Option_ReasonArgs = {
  _set?: InputMaybe<Enum_Rfq_Option_Reason_Set_Input>;
  where: Enum_Rfq_Option_Reason_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Rfq_Option_Reason_By_PkArgs = {
  _set?: InputMaybe<Enum_Rfq_Option_Reason_Set_Input>;
  pk_columns: Enum_Rfq_Option_Reason_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Rfq_Option_Reason_ManyArgs = {
  updates: Array<Enum_Rfq_Option_Reason_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Rfq_StatusArgs = {
  _set?: InputMaybe<Enum_Rfq_Status_Set_Input>;
  where: Enum_Rfq_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Rfq_Status_By_PkArgs = {
  _set?: InputMaybe<Enum_Rfq_Status_Set_Input>;
  pk_columns: Enum_Rfq_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Rfq_Status_ManyArgs = {
  updates: Array<Enum_Rfq_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_TenorArgs = {
  _set?: InputMaybe<Enum_Tenor_Set_Input>;
  where: Enum_Tenor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Tenor_By_PkArgs = {
  _set?: InputMaybe<Enum_Tenor_Set_Input>;
  pk_columns: Enum_Tenor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Tenor_ManyArgs = {
  updates: Array<Enum_Tenor_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_TiersArgs = {
  _set?: InputMaybe<Enum_Tiers_Set_Input>;
  where: Enum_Tiers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Tiers_By_PkArgs = {
  _set?: InputMaybe<Enum_Tiers_Set_Input>;
  pk_columns: Enum_Tiers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Tiers_ManyArgs = {
  updates: Array<Enum_Tiers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Users_Axe_Suspended_StateArgs = {
  _set?: InputMaybe<Enum_Users_Axe_Suspended_State_Set_Input>;
  where: Enum_Users_Axe_Suspended_State_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Users_Axe_Suspended_State_By_PkArgs = {
  _set?: InputMaybe<Enum_Users_Axe_Suspended_State_Set_Input>;
  pk_columns: Enum_Users_Axe_Suspended_State_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Enum_Users_Axe_Suspended_State_ManyArgs = {
  updates: Array<Enum_Users_Axe_Suspended_State_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Expiry_CutsArgs = {
  _set?: InputMaybe<Expiry_Cuts_Set_Input>;
  where: Expiry_Cuts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Expiry_Cuts_By_PkArgs = {
  _set?: InputMaybe<Expiry_Cuts_Set_Input>;
  pk_columns: Expiry_Cuts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Expiry_Cuts_ManyArgs = {
  updates: Array<Expiry_Cuts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FcaFeedbackMonitorArgs = {
  _set?: InputMaybe<FcaFeedbackMonitor_Set_Input>;
  where: FcaFeedbackMonitor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_FcaFeedbackMonitor_ManyArgs = {
  updates: Array<FcaFeedbackMonitor_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FiltersArgs = {
  _append?: InputMaybe<Filters_Append_Input>;
  _delete_at_path?: InputMaybe<Filters_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Filters_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Filters_Delete_Key_Input>;
  _prepend?: InputMaybe<Filters_Prepend_Input>;
  _set?: InputMaybe<Filters_Set_Input>;
  where: Filters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Filters_By_PkArgs = {
  _append?: InputMaybe<Filters_Append_Input>;
  _delete_at_path?: InputMaybe<Filters_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Filters_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Filters_Delete_Key_Input>;
  _prepend?: InputMaybe<Filters_Prepend_Input>;
  _set?: InputMaybe<Filters_Set_Input>;
  pk_columns: Filters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Filters_ManyArgs = {
  updates: Array<Filters_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FundsArgs = {
  _set?: InputMaybe<Funds_Set_Input>;
  where: Funds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Funds_By_PkArgs = {
  _set?: InputMaybe<Funds_Set_Input>;
  pk_columns: Funds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Funds_ManyArgs = {
  updates: Array<Funds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Give_Up_RequestsArgs = {
  _set?: InputMaybe<Give_Up_Requests_Set_Input>;
  where: Give_Up_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Give_Up_Requests_By_PkArgs = {
  _set?: InputMaybe<Give_Up_Requests_Set_Input>;
  pk_columns: Give_Up_Requests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Give_Up_Requests_ManyArgs = {
  updates: Array<Give_Up_Requests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Give_UpsArgs = {
  _set?: InputMaybe<Give_Ups_Set_Input>;
  where: Give_Ups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Give_Ups_By_PkArgs = {
  _set?: InputMaybe<Give_Ups_Set_Input>;
  pk_columns: Give_Ups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Give_Ups_ManyArgs = {
  updates: Array<Give_Ups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Give_Ups_OrganizationsArgs = {
  _set?: InputMaybe<Give_Ups_Organizations_Set_Input>;
  where: Give_Ups_Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Give_Ups_Organizations_By_PkArgs = {
  _set?: InputMaybe<Give_Ups_Organizations_Set_Input>;
  pk_columns: Give_Ups_Organizations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Give_Ups_Organizations_ManyArgs = {
  updates: Array<Give_Ups_Organizations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_InstrumentReferenceDataArgs = {
  _set?: InputMaybe<InstrumentReferenceData_Set_Input>;
  where: InstrumentReferenceData_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_InstrumentReferenceData_By_PkArgs = {
  _set?: InputMaybe<InstrumentReferenceData_Set_Input>;
  pk_columns: InstrumentReferenceData_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_InstrumentReferenceData_ManyArgs = {
  updates: Array<InstrumentReferenceData_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MdpValidationInstrumentsArgs = {
  _inc?: InputMaybe<MdpValidationInstruments_Inc_Input>;
  _set?: InputMaybe<MdpValidationInstruments_Set_Input>;
  where: MdpValidationInstruments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_MdpValidationInstruments_By_PkArgs = {
  _inc?: InputMaybe<MdpValidationInstruments_Inc_Input>;
  _set?: InputMaybe<MdpValidationInstruments_Set_Input>;
  pk_columns: MdpValidationInstruments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MdpValidationInstruments_ManyArgs = {
  updates: Array<MdpValidationInstruments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_RolesArgs = {
  _set?: InputMaybe<Notification_Roles_Set_Input>;
  where: Notification_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Roles_By_PkArgs = {
  _set?: InputMaybe<Notification_Roles_Set_Input>;
  pk_columns: Notification_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Roles_ManyArgs = {
  updates: Array<Notification_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationsArgs = {
  _set?: InputMaybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_By_PkArgs = {
  _set?: InputMaybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_ManyArgs = {
  updates: Array<Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_Main_TieringArgs = {
  _set?: InputMaybe<Organization_Main_Tiering_Set_Input>;
  where: Organization_Main_Tiering_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_Main_Tiering_By_PkArgs = {
  _set?: InputMaybe<Organization_Main_Tiering_Set_Input>;
  pk_columns: Organization_Main_Tiering_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_Main_Tiering_ManyArgs = {
  updates: Array<Organization_Main_Tiering_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrganizationsArgs = {
  _set?: InputMaybe<Organizations_Set_Input>;
  where: Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_By_PkArgs = {
  _set?: InputMaybe<Organizations_Set_Input>;
  pk_columns: Organizations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_ManyArgs = {
  updates: Array<Organizations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Push_SubscriptionsArgs = {
  _append?: InputMaybe<Push_Subscriptions_Append_Input>;
  _delete_at_path?: InputMaybe<Push_Subscriptions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Push_Subscriptions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Push_Subscriptions_Delete_Key_Input>;
  _prepend?: InputMaybe<Push_Subscriptions_Prepend_Input>;
  _set?: InputMaybe<Push_Subscriptions_Set_Input>;
  where: Push_Subscriptions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Push_Subscriptions_By_PkArgs = {
  _append?: InputMaybe<Push_Subscriptions_Append_Input>;
  _delete_at_path?: InputMaybe<Push_Subscriptions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Push_Subscriptions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Push_Subscriptions_Delete_Key_Input>;
  _prepend?: InputMaybe<Push_Subscriptions_Prepend_Input>;
  _set?: InputMaybe<Push_Subscriptions_Set_Input>;
  pk_columns: Push_Subscriptions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Push_Subscriptions_ManyArgs = {
  updates: Array<Push_Subscriptions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReportSequenceIdsArgs = {
  _inc?: InputMaybe<ReportSequenceIds_Inc_Input>;
  _set?: InputMaybe<ReportSequenceIds_Set_Input>;
  where: ReportSequenceIds_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ReportSequenceIds_By_PkArgs = {
  _inc?: InputMaybe<ReportSequenceIds_Inc_Input>;
  _set?: InputMaybe<ReportSequenceIds_Set_Input>;
  pk_columns: ReportSequenceIds_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ReportSequenceIds_ManyArgs = {
  updates: Array<ReportSequenceIds_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RfqArgs = {
  _inc?: InputMaybe<Rfq_Inc_Input>;
  _set?: InputMaybe<Rfq_Set_Input>;
  where: Rfq_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rfq_By_PkArgs = {
  _inc?: InputMaybe<Rfq_Inc_Input>;
  _set?: InputMaybe<Rfq_Set_Input>;
  pk_columns: Rfq_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rfq_LegsArgs = {
  _inc?: InputMaybe<Rfq_Legs_Inc_Input>;
  _set?: InputMaybe<Rfq_Legs_Set_Input>;
  where: Rfq_Legs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rfq_Legs_By_PkArgs = {
  _inc?: InputMaybe<Rfq_Legs_Inc_Input>;
  _set?: InputMaybe<Rfq_Legs_Set_Input>;
  pk_columns: Rfq_Legs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rfq_Legs_ManyArgs = {
  updates: Array<Rfq_Legs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rfq_ManyArgs = {
  updates: Array<Rfq_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rfq_OptionsArgs = {
  _set?: InputMaybe<Rfq_Options_Set_Input>;
  where: Rfq_Options_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rfq_Options_By_PkArgs = {
  _set?: InputMaybe<Rfq_Options_Set_Input>;
  pk_columns: Rfq_Options_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rfq_Options_LegsArgs = {
  _inc?: InputMaybe<Rfq_Options_Legs_Inc_Input>;
  _set?: InputMaybe<Rfq_Options_Legs_Set_Input>;
  where: Rfq_Options_Legs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rfq_Options_Legs_By_PkArgs = {
  _inc?: InputMaybe<Rfq_Options_Legs_Inc_Input>;
  _set?: InputMaybe<Rfq_Options_Legs_Set_Input>;
  pk_columns: Rfq_Options_Legs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rfq_Options_Legs_ManyArgs = {
  updates: Array<Rfq_Options_Legs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rfq_Options_ManyArgs = {
  updates: Array<Rfq_Options_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Rfq_Public_QueueArgs = {
  _set?: InputMaybe<Rfq_Public_Queue_Set_Input>;
  where: Rfq_Public_Queue_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rfq_Public_Queue_By_PkArgs = {
  _set?: InputMaybe<Rfq_Public_Queue_Set_Input>;
  pk_columns: Rfq_Public_Queue_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Rfq_Public_Queue_ManyArgs = {
  updates: Array<Rfq_Public_Queue_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Sub_OrganizationsArgs = {
  _set?: InputMaybe<Sub_Organizations_Set_Input>;
  where: Sub_Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sub_Organizations_By_PkArgs = {
  _set?: InputMaybe<Sub_Organizations_Set_Input>;
  pk_columns: Sub_Organizations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sub_Organizations_ManyArgs = {
  updates: Array<Sub_Organizations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_ConfirmsArgs = {
  _set?: InputMaybe<Trade_Confirms_Set_Input>;
  where: Trade_Confirms_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_Confirms_By_PkArgs = {
  _set?: InputMaybe<Trade_Confirms_Set_Input>;
  pk_columns: Trade_Confirms_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Trade_Confirms_ManyArgs = {
  updates: Array<Trade_Confirms_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Traded_HedgesArgs = {
  _inc?: InputMaybe<Traded_Hedges_Inc_Input>;
  _set?: InputMaybe<Traded_Hedges_Set_Input>;
  where: Traded_Hedges_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Traded_Hedges_ManyArgs = {
  updates: Array<Traded_Hedges_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TradesArgs = {
  _inc?: InputMaybe<Trades_Inc_Input>;
  _set?: InputMaybe<Trades_Set_Input>;
  where: Trades_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Trades_By_PkArgs = {
  _inc?: InputMaybe<Trades_Inc_Input>;
  _set?: InputMaybe<Trades_Set_Input>;
  pk_columns: Trades_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Trades_LegArgs = {
  _inc?: InputMaybe<Trades_Leg_Inc_Input>;
  _set?: InputMaybe<Trades_Leg_Set_Input>;
  where: Trades_Leg_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Trades_Leg_By_PkArgs = {
  _inc?: InputMaybe<Trades_Leg_Inc_Input>;
  _set?: InputMaybe<Trades_Leg_Set_Input>;
  pk_columns: Trades_Leg_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Trades_Leg_ManyArgs = {
  updates: Array<Trades_Leg_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Trades_ManyArgs = {
  updates: Array<Trades_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Main_TieringArgs = {
  _set?: InputMaybe<User_Main_Tiering_Set_Input>;
  where: User_Main_Tiering_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Main_Tiering_By_PkArgs = {
  _set?: InputMaybe<User_Main_Tiering_Set_Input>;
  pk_columns: User_Main_Tiering_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Main_Tiering_ManyArgs = {
  updates: Array<User_Main_Tiering_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_NotificationsArgs = {
  _set?: InputMaybe<User_Notifications_Set_Input>;
  where: User_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Notifications_By_PkArgs = {
  _set?: InputMaybe<User_Notifications_Set_Input>;
  pk_columns: User_Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Notifications_ManyArgs = {
  updates: Array<User_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_TiersArgs = {
  _set?: InputMaybe<User_Tiers_Set_Input>;
  where: User_Tiers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Tiers_By_PkArgs = {
  _set?: InputMaybe<User_Tiers_Set_Input>;
  pk_columns: User_Tiers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Tiers_ManyArgs = {
  updates: Array<User_Tiers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** columns and relationships of "notification_roles" */
export type Notification_Roles = Node & {
  __typename?: 'notification_roles';
  id: Scalars['ID']['output'];
  notificationId: Scalars['uuid']['output'];
  /** An object relationship */
  notification_role?: Maybe<Notifications>;
  role: Scalars['String']['output'];
};

/** A Relay connection object on "notification_roles" */
export type Notification_RolesConnection = {
  __typename?: 'notification_rolesConnection';
  edges: Array<Notification_RolesEdge>;
  pageInfo: PageInfo;
};

export type Notification_RolesEdge = {
  __typename?: 'notification_rolesEdge';
  cursor: Scalars['String']['output'];
  node: Notification_Roles;
};

/** aggregated selection of "notification_roles" */
export type Notification_Roles_Aggregate = {
  __typename?: 'notification_roles_aggregate';
  aggregate?: Maybe<Notification_Roles_Aggregate_Fields>;
  nodes: Array<Notification_Roles>;
};

export type Notification_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Notification_Roles_Aggregate_Bool_Exp_Count>;
};

export type Notification_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Notification_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Notification_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "notification_roles" */
export type Notification_Roles_Aggregate_Fields = {
  __typename?: 'notification_roles_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notification_Roles_Max_Fields>;
  min?: Maybe<Notification_Roles_Min_Fields>;
};


/** aggregate fields of "notification_roles" */
export type Notification_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "notification_roles" */
export type Notification_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notification_Roles_Max_Order_By>;
  min?: InputMaybe<Notification_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notification_roles" */
export type Notification_Roles_Arr_Rel_Insert_Input = {
  data: Array<Notification_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notification_roles". All fields are combined with a logical 'AND'. */
export type Notification_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Roles_Bool_Exp>>;
  _not?: InputMaybe<Notification_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Roles_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notificationId?: InputMaybe<Uuid_Comparison_Exp>;
  notification_role?: InputMaybe<Notifications_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_roles" */
export enum Notification_Roles_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationRolesPkey = 'notification_roles_pkey'
}

/** input type for inserting data into table "notification_roles" */
export type Notification_Roles_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  notificationId?: InputMaybe<Scalars['uuid']['input']>;
  notification_role?: InputMaybe<Notifications_Obj_Rel_Insert_Input>;
  role?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Notification_Roles_Max_Fields = {
  __typename?: 'notification_roles_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  notificationId?: Maybe<Scalars['uuid']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "notification_roles" */
export type Notification_Roles_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  notificationId?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notification_Roles_Min_Fields = {
  __typename?: 'notification_roles_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  notificationId?: Maybe<Scalars['uuid']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "notification_roles" */
export type Notification_Roles_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  notificationId?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notification_roles" */
export type Notification_Roles_Mutation_Response = {
  __typename?: 'notification_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Roles>;
};

/** on_conflict condition type for table "notification_roles" */
export type Notification_Roles_On_Conflict = {
  constraint: Notification_Roles_Constraint;
  update_columns?: Array<Notification_Roles_Update_Column>;
  where?: InputMaybe<Notification_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_roles". */
export type Notification_Roles_Order_By = {
  id?: InputMaybe<Order_By>;
  notificationId?: InputMaybe<Order_By>;
  notification_role?: InputMaybe<Notifications_Order_By>;
  role?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_roles */
export type Notification_Roles_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "notification_roles" */
export enum Notification_Roles_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notificationId',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "notification_roles" */
export type Notification_Roles_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  notificationId?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "notification_roles" */
export enum Notification_Roles_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notificationId',
  /** column name */
  Role = 'role'
}

export type Notification_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Roles_Bool_Exp;
};

/** columns and relationships of "notifications" */
export type Notifications = Node & {
  __typename?: 'notifications';
  authorId?: Maybe<Scalars['String']['output']>;
  axeId?: Maybe<Scalars['String']['output']>;
  channels?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['ID']['output'];
  important: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  notification_roles: Array<Notification_Roles>;
  /** An aggregate relationship */
  notification_roles_aggregate: Notification_Roles_Aggregate;
  /** An array relationship connection */
  notification_roles_connection: Notification_RolesConnection;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organizationId?: Maybe<Scalars['String']['output']>;
  rfqId?: Maybe<Scalars['String']['output']>;
  subOrganizationId?: Maybe<Scalars['uuid']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Enum_Notification_Type_Enum>;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  userId?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  user_notifications: Array<User_Notifications>;
  /** An aggregate relationship */
  user_notifications_aggregate: User_Notifications_Aggregate;
  /** An array relationship connection */
  user_notifications_connection: User_NotificationsConnection;
};


/** columns and relationships of "notifications" */
export type NotificationsNotification_RolesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Roles_Order_By>>;
  where?: InputMaybe<Notification_Roles_Bool_Exp>;
};


/** columns and relationships of "notifications" */
export type NotificationsNotification_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Roles_Order_By>>;
  where?: InputMaybe<Notification_Roles_Bool_Exp>;
};


/** columns and relationships of "notifications" */
export type NotificationsNotification_Roles_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Notification_Roles_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Roles_Order_By>>;
  where?: InputMaybe<Notification_Roles_Bool_Exp>;
};


/** columns and relationships of "notifications" */
export type NotificationsUser_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};


/** columns and relationships of "notifications" */
export type NotificationsUser_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};


/** columns and relationships of "notifications" */
export type NotificationsUser_Notifications_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

/** A Relay connection object on "notifications" */
export type NotificationsConnection = {
  __typename?: 'notificationsConnection';
  edges: Array<NotificationsEdge>;
  pageInfo: PageInfo;
};

export type NotificationsEdge = {
  __typename?: 'notificationsEdge';
  cursor: Scalars['String']['output'];
  node: Notifications;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Bool_Exp>>;
  authorId?: InputMaybe<String_Comparison_Exp>;
  axeId?: InputMaybe<String_Comparison_Exp>;
  channels?: InputMaybe<String_Array_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  important?: InputMaybe<Boolean_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  notification_roles?: InputMaybe<Notification_Roles_Bool_Exp>;
  notification_roles_aggregate?: InputMaybe<Notification_Roles_Aggregate_Bool_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  rfqId?: InputMaybe<String_Comparison_Exp>;
  subOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Enum_Notification_Type_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  user_notifications?: InputMaybe<User_Notifications_Bool_Exp>;
  user_notifications_aggregate?: InputMaybe<User_Notifications_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationsPkey = 'notifications_pkey'
}

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  authorId?: InputMaybe<Scalars['String']['input']>;
  axeId?: InputMaybe<Scalars['String']['input']>;
  channels?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  important?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  notification_roles?: InputMaybe<Notification_Roles_Arr_Rel_Insert_Input>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  rfqId?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Enum_Notification_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
  user_notifications?: InputMaybe<User_Notifications_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  __typename?: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>;
};

/** input type for inserting object relation for remote table "notifications" */
export type Notifications_Obj_Rel_Insert_Input = {
  data: Notifications_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** on_conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint;
  update_columns?: Array<Notifications_Update_Column>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  authorId?: InputMaybe<Order_By>;
  axeId?: InputMaybe<Order_By>;
  channels?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  important?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  notification_roles_aggregate?: InputMaybe<Notification_Roles_Aggregate_Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organizationId?: InputMaybe<Order_By>;
  rfqId?: InputMaybe<Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
  user_notifications_aggregate?: InputMaybe<User_Notifications_Aggregate_Order_By>;
};

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  AxeId = 'axeId',
  /** column name */
  Channels = 'channels',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Important = 'important',
  /** column name */
  Link = 'link',
  /** column name */
  Message = 'message',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  RfqId = 'rfqId',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  authorId?: InputMaybe<Scalars['String']['input']>;
  axeId?: InputMaybe<Scalars['String']['input']>;
  channels?: InputMaybe<Array<Scalars['String']['input']>>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  important?: InputMaybe<Scalars['Boolean']['input']>;
  link?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  rfqId?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Enum_Notification_Type_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "notifications" */
export enum Notifications_Update_Column {
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  AxeId = 'axeId',
  /** column name */
  Channels = 'channels',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Important = 'important',
  /** column name */
  Link = 'link',
  /** column name */
  Message = 'message',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  RfqId = 'rfqId',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Notifications_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notifications_Bool_Exp;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "organization_main_tiering" */
export type Organization_Main_Tiering = Node & {
  __typename?: 'organization_main_tiering';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['ID']['output'];
  isRemoved?: Maybe<Scalars['Boolean']['output']>;
  organizationId: Scalars['String']['output'];
  /** An object relationship */
  organization_main_tiering_organizationId?: Maybe<Organizations>;
  /** An object relationship */
  organization_main_tiering_user?: Maybe<Sub_Organizations>;
  ownerOrganization: Scalars['String']['output'];
  subOrganizationId?: Maybe<Scalars['uuid']['output']>;
  tier: Enum_Tiers_Enum;
  updatedAt: Scalars['timestamptz']['output'];
};

/** A Relay connection object on "organization_main_tiering" */
export type Organization_Main_TieringConnection = {
  __typename?: 'organization_main_tieringConnection';
  edges: Array<Organization_Main_TieringEdge>;
  pageInfo: PageInfo;
};

export type Organization_Main_TieringEdge = {
  __typename?: 'organization_main_tieringEdge';
  cursor: Scalars['String']['output'];
  node: Organization_Main_Tiering;
};

/** Boolean expression to filter rows from the table "organization_main_tiering". All fields are combined with a logical 'AND'. */
export type Organization_Main_Tiering_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Main_Tiering_Bool_Exp>>;
  _not?: InputMaybe<Organization_Main_Tiering_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Main_Tiering_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isRemoved?: InputMaybe<Boolean_Comparison_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  organization_main_tiering_organizationId?: InputMaybe<Organizations_Bool_Exp>;
  organization_main_tiering_user?: InputMaybe<Sub_Organizations_Bool_Exp>;
  ownerOrganization?: InputMaybe<String_Comparison_Exp>;
  subOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
  tier?: InputMaybe<Enum_Tiers_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_main_tiering" */
export enum Organization_Main_Tiering_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationMainTieringPkey = 'organization_main_tiering_pkey'
}

/** input type for inserting data into table "organization_main_tiering" */
export type Organization_Main_Tiering_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isRemoved?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  organization_main_tiering_organizationId?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organization_main_tiering_user?: InputMaybe<Sub_Organizations_Obj_Rel_Insert_Input>;
  ownerOrganization?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  tier?: InputMaybe<Enum_Tiers_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** response of any mutation on the table "organization_main_tiering" */
export type Organization_Main_Tiering_Mutation_Response = {
  __typename?: 'organization_main_tiering_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Main_Tiering>;
};

/** on_conflict condition type for table "organization_main_tiering" */
export type Organization_Main_Tiering_On_Conflict = {
  constraint: Organization_Main_Tiering_Constraint;
  update_columns?: Array<Organization_Main_Tiering_Update_Column>;
  where?: InputMaybe<Organization_Main_Tiering_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_main_tiering". */
export type Organization_Main_Tiering_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isRemoved?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  organization_main_tiering_organizationId?: InputMaybe<Organizations_Order_By>;
  organization_main_tiering_user?: InputMaybe<Sub_Organizations_Order_By>;
  ownerOrganization?: InputMaybe<Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
  tier?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_main_tiering */
export type Organization_Main_Tiering_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "organization_main_tiering" */
export enum Organization_Main_Tiering_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsRemoved = 'isRemoved',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  OwnerOrganization = 'ownerOrganization',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  Tier = 'tier',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "organization_main_tiering" */
export type Organization_Main_Tiering_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isRemoved?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  ownerOrganization?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  tier?: InputMaybe<Enum_Tiers_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "organization_main_tiering" */
export enum Organization_Main_Tiering_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsRemoved = 'isRemoved',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  OwnerOrganization = 'ownerOrganization',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  Tier = 'tier',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Organization_Main_Tiering_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Main_Tiering_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Main_Tiering_Bool_Exp;
};

/** columns and relationships of "organizations" */
export type Organizations = Node & {
  __typename?: 'organizations';
  createdAt: Scalars['timestamptz']['output'];
  displayName: Scalars['String']['output'];
  fixConnectionString?: Maybe<Scalars['String']['output']>;
  fixConnectionUp?: Maybe<Scalars['Boolean']['output']>;
  fixEnabled: Scalars['Boolean']['output'];
  /** An array relationship */
  giveups: Array<Give_Ups_Organizations>;
  /** An aggregate relationship */
  giveups_aggregate: Give_Ups_Organizations_Aggregate;
  /** An array relationship connection */
  giveups_connection: Give_Ups_OrganizationsConnection;
  id: Scalars['ID']['output'];
  lei?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** An array relationship */
  subOrganizations: Array<Sub_Organizations>;
  /** An aggregate relationship */
  subOrganizations_aggregate: Sub_Organizations_Aggregate;
  /** An array relationship connection */
  subOrganizations_connection: Sub_OrganizationsConnection;
  updatedAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregate relationship */
  users_aggregate: Users_Aggregate;
  /** An array relationship connection */
  users_connection: UsersConnection;
};


/** columns and relationships of "organizations" */
export type OrganizationsGiveupsArgs = {
  distinct_on?: InputMaybe<Array<Give_Ups_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Give_Ups_Organizations_Order_By>>;
  where?: InputMaybe<Give_Ups_Organizations_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsGiveups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Give_Ups_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Give_Ups_Organizations_Order_By>>;
  where?: InputMaybe<Give_Ups_Organizations_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsGiveups_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Give_Ups_Organizations_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Give_Ups_Organizations_Order_By>>;
  where?: InputMaybe<Give_Ups_Organizations_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsSubOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Sub_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sub_Organizations_Order_By>>;
  where?: InputMaybe<Sub_Organizations_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsSubOrganizations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sub_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sub_Organizations_Order_By>>;
  where?: InputMaybe<Sub_Organizations_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsSubOrganizations_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Sub_Organizations_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sub_Organizations_Order_By>>;
  where?: InputMaybe<Sub_Organizations_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsUsers_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** A Relay connection object on "organizations" */
export type OrganizationsConnection = {
  __typename?: 'organizationsConnection';
  edges: Array<OrganizationsEdge>;
  pageInfo: PageInfo;
};

export type OrganizationsEdge = {
  __typename?: 'organizationsEdge';
  cursor: Scalars['String']['output'];
  node: Organizations;
};

/** aggregated selection of "organizations" */
export type Organizations_Aggregate = {
  __typename?: 'organizations_aggregate';
  aggregate?: Maybe<Organizations_Aggregate_Fields>;
  nodes: Array<Organizations>;
};

export type Organizations_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Organizations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Organizations_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Organizations_Aggregate_Bool_Exp_Count>;
};

export type Organizations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organizations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Organizations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organizations" */
export type Organizations_Aggregate_Fields = {
  __typename?: 'organizations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Organizations_Max_Fields>;
  min?: Maybe<Organizations_Min_Fields>;
};


/** aggregate fields of "organizations" */
export type Organizations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "organizations" */
export type Organizations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organizations_Max_Order_By>;
  min?: InputMaybe<Organizations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organizations" */
export type Organizations_Arr_Rel_Insert_Input = {
  data: Array<Organizations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type Organizations_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_Bool_Exp>>;
  _not?: InputMaybe<Organizations_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  fixConnectionString?: InputMaybe<String_Comparison_Exp>;
  fixConnectionUp?: InputMaybe<Boolean_Comparison_Exp>;
  fixEnabled?: InputMaybe<Boolean_Comparison_Exp>;
  giveups?: InputMaybe<Give_Ups_Organizations_Bool_Exp>;
  giveups_aggregate?: InputMaybe<Give_Ups_Organizations_Aggregate_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  lei?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  subOrganizations?: InputMaybe<Sub_Organizations_Bool_Exp>;
  subOrganizations_aggregate?: InputMaybe<Sub_Organizations_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
  users_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "organizations" */
export enum Organizations_Constraint {
  /** unique or primary key constraint on columns "name" */
  OrganizationsNameKey = 'organizations_name_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationsPkey = 'organizations_pkey'
}

/** input type for inserting data into table "organizations" */
export type Organizations_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  fixConnectionString?: InputMaybe<Scalars['String']['input']>;
  fixConnectionUp?: InputMaybe<Scalars['Boolean']['input']>;
  fixEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  giveups?: InputMaybe<Give_Ups_Organizations_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['String']['input']>;
  lei?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  subOrganizations?: InputMaybe<Sub_Organizations_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  users?: InputMaybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organizations_Max_Fields = {
  __typename?: 'organizations_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  fixConnectionString?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lei?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "organizations" */
export type Organizations_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  fixConnectionString?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lei?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Organizations_Min_Fields = {
  __typename?: 'organizations_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  fixConnectionString?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lei?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "organizations" */
export type Organizations_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  fixConnectionString?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lei?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organizations" */
export type Organizations_Mutation_Response = {
  __typename?: 'organizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organizations>;
};

/** input type for inserting object relation for remote table "organizations" */
export type Organizations_Obj_Rel_Insert_Input = {
  data: Organizations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organizations_On_Conflict>;
};

/** on_conflict condition type for table "organizations" */
export type Organizations_On_Conflict = {
  constraint: Organizations_Constraint;
  update_columns?: Array<Organizations_Update_Column>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

/** Ordering options when selecting data from "organizations". */
export type Organizations_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  fixConnectionString?: InputMaybe<Order_By>;
  fixConnectionUp?: InputMaybe<Order_By>;
  fixEnabled?: InputMaybe<Order_By>;
  giveups_aggregate?: InputMaybe<Give_Ups_Organizations_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  lei?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  subOrganizations_aggregate?: InputMaybe<Sub_Organizations_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  users_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: organizations */
export type Organizations_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "organizations" */
export enum Organizations_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  FixConnectionString = 'fixConnectionString',
  /** column name */
  FixConnectionUp = 'fixConnectionUp',
  /** column name */
  FixEnabled = 'fixEnabled',
  /** column name */
  Id = 'id',
  /** column name */
  Lei = 'lei',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "organizations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FixConnectionUp = 'fixConnectionUp',
  /** column name */
  FixEnabled = 'fixEnabled'
}

/** select "organizations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organizations" */
export enum Organizations_Select_Column_Organizations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FixConnectionUp = 'fixConnectionUp',
  /** column name */
  FixEnabled = 'fixEnabled'
}

/** input type for updating data in table "organizations" */
export type Organizations_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  fixConnectionString?: InputMaybe<Scalars['String']['input']>;
  fixConnectionUp?: InputMaybe<Scalars['Boolean']['input']>;
  fixEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  lei?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "organizations" */
export enum Organizations_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  FixConnectionString = 'fixConnectionString',
  /** column name */
  FixConnectionUp = 'fixConnectionUp',
  /** column name */
  FixEnabled = 'fixEnabled',
  /** column name */
  Id = 'id',
  /** column name */
  Lei = 'lei',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Organizations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organizations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organizations_Bool_Exp;
};

/** columns and relationships of "push_subscriptions" */
export type Push_Subscriptions = Node & {
  __typename?: 'push_subscriptions';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['ID']['output'];
  subscription: Scalars['jsonb']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};


/** columns and relationships of "push_subscriptions" */
export type Push_SubscriptionsSubscriptionArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** A Relay connection object on "push_subscriptions" */
export type Push_SubscriptionsConnection = {
  __typename?: 'push_subscriptionsConnection';
  edges: Array<Push_SubscriptionsEdge>;
  pageInfo: PageInfo;
};

export type Push_SubscriptionsEdge = {
  __typename?: 'push_subscriptionsEdge';
  cursor: Scalars['String']['output'];
  node: Push_Subscriptions;
};

/** aggregated selection of "push_subscriptions" */
export type Push_Subscriptions_Aggregate = {
  __typename?: 'push_subscriptions_aggregate';
  aggregate?: Maybe<Push_Subscriptions_Aggregate_Fields>;
  nodes: Array<Push_Subscriptions>;
};

export type Push_Subscriptions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Push_Subscriptions_Aggregate_Bool_Exp_Count>;
};

export type Push_Subscriptions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Push_Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Push_Subscriptions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "push_subscriptions" */
export type Push_Subscriptions_Aggregate_Fields = {
  __typename?: 'push_subscriptions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Push_Subscriptions_Max_Fields>;
  min?: Maybe<Push_Subscriptions_Min_Fields>;
};


/** aggregate fields of "push_subscriptions" */
export type Push_Subscriptions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Push_Subscriptions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "push_subscriptions" */
export type Push_Subscriptions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Push_Subscriptions_Max_Order_By>;
  min?: InputMaybe<Push_Subscriptions_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Push_Subscriptions_Append_Input = {
  subscription?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "push_subscriptions" */
export type Push_Subscriptions_Arr_Rel_Insert_Input = {
  data: Array<Push_Subscriptions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Push_Subscriptions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "push_subscriptions". All fields are combined with a logical 'AND'. */
export type Push_Subscriptions_Bool_Exp = {
  _and?: InputMaybe<Array<Push_Subscriptions_Bool_Exp>>;
  _not?: InputMaybe<Push_Subscriptions_Bool_Exp>;
  _or?: InputMaybe<Array<Push_Subscriptions_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  subscription?: InputMaybe<Jsonb_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "push_subscriptions" */
export enum Push_Subscriptions_Constraint {
  /** unique or primary key constraint on columns "id" */
  PushSubscriptionsPkey = 'push_subscriptions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Push_Subscriptions_Delete_At_Path_Input = {
  subscription?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Push_Subscriptions_Delete_Elem_Input = {
  subscription?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Push_Subscriptions_Delete_Key_Input = {
  subscription?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "push_subscriptions" */
export type Push_Subscriptions_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  subscription?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Push_Subscriptions_Max_Fields = {
  __typename?: 'push_subscriptions_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "push_subscriptions" */
export type Push_Subscriptions_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Push_Subscriptions_Min_Fields = {
  __typename?: 'push_subscriptions_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "push_subscriptions" */
export type Push_Subscriptions_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "push_subscriptions" */
export type Push_Subscriptions_Mutation_Response = {
  __typename?: 'push_subscriptions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Push_Subscriptions>;
};

/** on_conflict condition type for table "push_subscriptions" */
export type Push_Subscriptions_On_Conflict = {
  constraint: Push_Subscriptions_Constraint;
  update_columns?: Array<Push_Subscriptions_Update_Column>;
  where?: InputMaybe<Push_Subscriptions_Bool_Exp>;
};

/** Ordering options when selecting data from "push_subscriptions". */
export type Push_Subscriptions_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subscription?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: push_subscriptions */
export type Push_Subscriptions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Push_Subscriptions_Prepend_Input = {
  subscription?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "push_subscriptions" */
export enum Push_Subscriptions_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Subscription = 'subscription',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "push_subscriptions" */
export type Push_Subscriptions_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  subscription?: InputMaybe<Scalars['jsonb']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "push_subscriptions" */
export enum Push_Subscriptions_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Subscription = 'subscription',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Push_Subscriptions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Push_Subscriptions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Push_Subscriptions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Push_Subscriptions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Push_Subscriptions_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Push_Subscriptions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Push_Subscriptions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Push_Subscriptions_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship connection */
  axe_authors_connection: Axe_AuthorsConnection;
  /** fetch data from the table: "axe_legs_audit" */
  axe_legs_audit_connection: Axe_Legs_AuditConnection;
  /** An array relationship connection */
  axe_legs_connection: Axe_LegsConnection;
  /** fetch data from the table: "axe_tier_pricing" */
  axe_tier_pricing_connection: Axe_Tier_PricingConnection;
  /** fetch data from the table: "axes_audit" */
  axes_audit_connection: Axes_AuditConnection;
  /** fetch data from the table: "axes" */
  axes_connection: AxesConnection;
  /** fetch data from the table: "create_users_queue" */
  create_users_queue_connection: Create_Users_QueueConnection;
  /** fetch data from the table: "enum_axe_buysell" */
  enum_axe_buysell_connection: Enum_Axe_BuysellConnection;
  /** fetch data from the table: "enum_axe_callput" */
  enum_axe_callput_connection: Enum_Axe_CallputConnection;
  /** fetch data from the table: "enum_axe_ccypair" */
  enum_axe_ccypair_connection: Enum_Axe_CcypairConnection;
  /** fetch data from the table: "enum_axe_depoccy1daycount" */
  enum_axe_depoccy1daycount_connection: Enum_Axe_Depoccy1daycountConnection;
  /** fetch data from the table: "enum_axe_depoccy2daycount" */
  enum_axe_depoccy2daycount_connection: Enum_Axe_Depoccy2daycountConnection;
  /** fetch data from the table: "enum_axe_hedgetype" */
  enum_axe_hedgetype_connection: Enum_Axe_HedgetypeConnection;
  /** fetch data from the table: "enum_axe_premiumtype" */
  enum_axe_premiumtype_connection: Enum_Axe_PremiumtypeConnection;
  /** fetch data from the table: "enum_axe_product" */
  enum_axe_product_connection: Enum_Axe_ProductConnection;
  /** fetch data from the table: "enum_axe_state" */
  enum_axe_state_connection: Enum_Axe_StateConnection;
  /** fetch data from the table: "enum_color_scheme" */
  enum_color_scheme_connection: Enum_Color_SchemeConnection;
  /** fetch data from the table: "enum_currency_notional" */
  enum_currency_notional_connection: Enum_Currency_NotionalConnection;
  /** fetch data from the table: "enum_currency_premium" */
  enum_currency_premium_connection: Enum_Currency_PremiumConnection;
  /** fetch data from the table: "enum_instrument_type" */
  enum_instrument_type_connection: Enum_Instrument_TypeConnection;
  /** fetch data from the table: "enum_notification_channel" */
  enum_notification_channel_connection: Enum_Notification_ChannelConnection;
  /** fetch data from the table: "enum_notification_type" */
  enum_notification_type_connection: Enum_Notification_TypeConnection;
  /** fetch data from the table: "enum_ptrd_callput" */
  enum_ptrd_callput_connection: Enum_Ptrd_CallputConnection;
  /** fetch data from the table: "enum_quote_side_type" */
  enum_quote_side_type_connection: Enum_Quote_Side_TypeConnection;
  /** fetch data from the table: "enum_rfq_option_reason" */
  enum_rfq_option_reason_connection: Enum_Rfq_Option_ReasonConnection;
  /** fetch data from the table: "enum_rfq_status" */
  enum_rfq_status_connection: Enum_Rfq_StatusConnection;
  /** fetch data from the table: "enum_tenor" */
  enum_tenor_connection: Enum_TenorConnection;
  /** fetch data from the table: "enum_tiers" */
  enum_tiers_connection: Enum_TiersConnection;
  /** fetch data from the table: "enum_users_axe_suspended_state" */
  enum_users_axe_suspended_state_connection: Enum_Users_Axe_Suspended_StateConnection;
  /** fetch data from the table: "expiry_cuts" */
  expiry_cuts_connection: Expiry_CutsConnection;
  /** fetch data from the table: "filters" */
  filters_connection: FiltersConnection;
  /** fetch data from the table: "funds" */
  funds_connection: FundsConnection;
  /** fetch data from the table: "give_up_requests" */
  give_up_requests_connection: Give_Up_RequestsConnection;
  /** fetch data from the table: "give_ups" */
  give_ups_connection: Give_UpsConnection;
  /** fetch data from the table: "give_ups_organizations" */
  give_ups_organizations_connection: Give_Ups_OrganizationsConnection;
  /** fetch data from the table: "instrumentReferenceData" */
  instrumentReferenceData_connection: InstrumentReferenceDataConnection;
  /** fetch data from the table: "mdpValidationInstruments" */
  mdpValidationInstruments_connection: MdpValidationInstrumentsConnection;
  node?: Maybe<Node>;
  /** An array relationship connection */
  notification_roles_connection: Notification_RolesConnection;
  /** fetch data from the table: "notifications" */
  notifications_connection: NotificationsConnection;
  /** fetch data from the table: "organization_main_tiering" */
  organization_main_tiering_connection: Organization_Main_TieringConnection;
  /** fetch data from the table: "organizations" */
  organizations_connection: OrganizationsConnection;
  /** fetch data from the table: "push_subscriptions" */
  push_subscriptions_connection: Push_SubscriptionsConnection;
  /** fetch data from the table: "reportSequenceIds" */
  reportSequenceIds_connection: ReportSequenceIdsConnection;
  /** fetch data from the table: "rfq" */
  rfq_connection: RfqConnection;
  /** An array relationship connection */
  rfq_legs_connection: Rfq_LegsConnection;
  /** An array relationship connection */
  rfq_options_connection: Rfq_OptionsConnection;
  /** An array relationship connection */
  rfq_options_legs_connection: Rfq_Options_LegsConnection;
  /** fetch data from the table: "rfq_public_queue" */
  rfq_public_queue_connection: Rfq_Public_QueueConnection;
  /** fetch data from the table: "sub_organizations" */
  sub_organizations_connection: Sub_OrganizationsConnection;
  /** fetch data from the table: "trade_confirms" */
  trade_confirms_connection: Trade_ConfirmsConnection;
  /** fetch data from the table: "trades" */
  trades_connection: TradesConnection;
  /** fetch data from the table: "trades_leg" */
  trades_leg_connection: Trades_LegConnection;
  /** fetch data from the table: "user_main_tiering" */
  user_main_tiering_connection: User_Main_TieringConnection;
  /** An array relationship connection */
  user_notifications_connection: User_NotificationsConnection;
  /** fetch data from the table: "user_tiers" */
  user_tiers_connection: User_TiersConnection;
  /** An array relationship connection */
  users_connection: UsersConnection;
};


export type Query_RootAxe_Authors_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axe_Authors_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Authors_Order_By>>;
  where?: InputMaybe<Axe_Authors_Bool_Exp>;
};


export type Query_RootAxe_Legs_Audit_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axe_Legs_Audit_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Legs_Audit_Order_By>>;
  where?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
};


export type Query_RootAxe_Legs_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axe_Legs_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Legs_Order_By>>;
  where?: InputMaybe<Axe_Legs_Bool_Exp>;
};


export type Query_RootAxe_Tier_Pricing_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axe_Tier_Pricing_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Tier_Pricing_Order_By>>;
  where?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
};


export type Query_RootAxes_Audit_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axes_Audit_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Audit_Order_By>>;
  where?: InputMaybe<Axes_Audit_Bool_Exp>;
};


export type Query_RootAxes_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};


export type Query_RootCreate_Users_Queue_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Create_Users_Queue_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Create_Users_Queue_Order_By>>;
  where?: InputMaybe<Create_Users_Queue_Bool_Exp>;
};


export type Query_RootEnum_Axe_Buysell_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Buysell_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Buysell_Order_By>>;
  where?: InputMaybe<Enum_Axe_Buysell_Bool_Exp>;
};


export type Query_RootEnum_Axe_Callput_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Callput_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Callput_Order_By>>;
  where?: InputMaybe<Enum_Axe_Callput_Bool_Exp>;
};


export type Query_RootEnum_Axe_Ccypair_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Ccypair_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Ccypair_Order_By>>;
  where?: InputMaybe<Enum_Axe_Ccypair_Bool_Exp>;
};


export type Query_RootEnum_Axe_Depoccy1daycount_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Depoccy1daycount_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Depoccy1daycount_Order_By>>;
  where?: InputMaybe<Enum_Axe_Depoccy1daycount_Bool_Exp>;
};


export type Query_RootEnum_Axe_Depoccy2daycount_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Depoccy2daycount_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Depoccy2daycount_Order_By>>;
  where?: InputMaybe<Enum_Axe_Depoccy2daycount_Bool_Exp>;
};


export type Query_RootEnum_Axe_Hedgetype_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Hedgetype_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Hedgetype_Order_By>>;
  where?: InputMaybe<Enum_Axe_Hedgetype_Bool_Exp>;
};


export type Query_RootEnum_Axe_Premiumtype_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Premiumtype_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Premiumtype_Order_By>>;
  where?: InputMaybe<Enum_Axe_Premiumtype_Bool_Exp>;
};


export type Query_RootEnum_Axe_Product_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Product_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Product_Order_By>>;
  where?: InputMaybe<Enum_Axe_Product_Bool_Exp>;
};


export type Query_RootEnum_Axe_State_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_State_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_State_Order_By>>;
  where?: InputMaybe<Enum_Axe_State_Bool_Exp>;
};


export type Query_RootEnum_Color_Scheme_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Color_Scheme_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Color_Scheme_Order_By>>;
  where?: InputMaybe<Enum_Color_Scheme_Bool_Exp>;
};


export type Query_RootEnum_Currency_Notional_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Currency_Notional_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Currency_Notional_Order_By>>;
  where?: InputMaybe<Enum_Currency_Notional_Bool_Exp>;
};


export type Query_RootEnum_Currency_Premium_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Currency_Premium_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Currency_Premium_Order_By>>;
  where?: InputMaybe<Enum_Currency_Premium_Bool_Exp>;
};


export type Query_RootEnum_Instrument_Type_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Instrument_Type_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Instrument_Type_Order_By>>;
  where?: InputMaybe<Enum_Instrument_Type_Bool_Exp>;
};


export type Query_RootEnum_Notification_Channel_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Notification_Channel_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Notification_Channel_Order_By>>;
  where?: InputMaybe<Enum_Notification_Channel_Bool_Exp>;
};


export type Query_RootEnum_Notification_Type_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Notification_Type_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Notification_Type_Order_By>>;
  where?: InputMaybe<Enum_Notification_Type_Bool_Exp>;
};


export type Query_RootEnum_Ptrd_Callput_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Ptrd_Callput_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Ptrd_Callput_Order_By>>;
  where?: InputMaybe<Enum_Ptrd_Callput_Bool_Exp>;
};


export type Query_RootEnum_Quote_Side_Type_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Quote_Side_Type_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Quote_Side_Type_Order_By>>;
  where?: InputMaybe<Enum_Quote_Side_Type_Bool_Exp>;
};


export type Query_RootEnum_Rfq_Option_Reason_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Rfq_Option_Reason_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Rfq_Option_Reason_Order_By>>;
  where?: InputMaybe<Enum_Rfq_Option_Reason_Bool_Exp>;
};


export type Query_RootEnum_Rfq_Status_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Rfq_Status_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Rfq_Status_Order_By>>;
  where?: InputMaybe<Enum_Rfq_Status_Bool_Exp>;
};


export type Query_RootEnum_Tenor_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Tenor_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Tenor_Order_By>>;
  where?: InputMaybe<Enum_Tenor_Bool_Exp>;
};


export type Query_RootEnum_Tiers_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Tiers_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Tiers_Order_By>>;
  where?: InputMaybe<Enum_Tiers_Bool_Exp>;
};


export type Query_RootEnum_Users_Axe_Suspended_State_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Users_Axe_Suspended_State_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Users_Axe_Suspended_State_Order_By>>;
  where?: InputMaybe<Enum_Users_Axe_Suspended_State_Bool_Exp>;
};


export type Query_RootExpiry_Cuts_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Expiry_Cuts_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Expiry_Cuts_Order_By>>;
  where?: InputMaybe<Expiry_Cuts_Bool_Exp>;
};


export type Query_RootFilters_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Filters_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Filters_Order_By>>;
  where?: InputMaybe<Filters_Bool_Exp>;
};


export type Query_RootFunds_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Funds_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Funds_Order_By>>;
  where?: InputMaybe<Funds_Bool_Exp>;
};


export type Query_RootGive_Up_Requests_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Give_Up_Requests_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Give_Up_Requests_Order_By>>;
  where?: InputMaybe<Give_Up_Requests_Bool_Exp>;
};


export type Query_RootGive_Ups_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Give_Ups_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Give_Ups_Order_By>>;
  where?: InputMaybe<Give_Ups_Bool_Exp>;
};


export type Query_RootGive_Ups_Organizations_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Give_Ups_Organizations_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Give_Ups_Organizations_Order_By>>;
  where?: InputMaybe<Give_Ups_Organizations_Bool_Exp>;
};


export type Query_RootInstrumentReferenceData_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<InstrumentReferenceData_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InstrumentReferenceData_Order_By>>;
  where?: InputMaybe<InstrumentReferenceData_Bool_Exp>;
};


export type Query_RootMdpValidationInstruments_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<MdpValidationInstruments_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MdpValidationInstruments_Order_By>>;
  where?: InputMaybe<MdpValidationInstruments_Bool_Exp>;
};


export type Query_RootNodeArgs = {
  id: Scalars['ID']['input'];
};


export type Query_RootNotification_Roles_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Notification_Roles_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Roles_Order_By>>;
  where?: InputMaybe<Notification_Roles_Bool_Exp>;
};


export type Query_RootNotifications_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootOrganization_Main_Tiering_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Organization_Main_Tiering_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Main_Tiering_Order_By>>;
  where?: InputMaybe<Organization_Main_Tiering_Bool_Exp>;
};


export type Query_RootOrganizations_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Query_RootPush_Subscriptions_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Push_Subscriptions_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Push_Subscriptions_Order_By>>;
  where?: InputMaybe<Push_Subscriptions_Bool_Exp>;
};


export type Query_RootReportSequenceIds_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<ReportSequenceIds_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportSequenceIds_Order_By>>;
  where?: InputMaybe<ReportSequenceIds_Bool_Exp>;
};


export type Query_RootRfq_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Rfq_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Order_By>>;
  where?: InputMaybe<Rfq_Bool_Exp>;
};


export type Query_RootRfq_Legs_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Rfq_Legs_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Legs_Order_By>>;
  where?: InputMaybe<Rfq_Legs_Bool_Exp>;
};


export type Query_RootRfq_Options_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Rfq_Options_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Options_Order_By>>;
  where?: InputMaybe<Rfq_Options_Bool_Exp>;
};


export type Query_RootRfq_Options_Legs_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Rfq_Options_Legs_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Options_Legs_Order_By>>;
  where?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
};


export type Query_RootRfq_Public_Queue_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Rfq_Public_Queue_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Public_Queue_Order_By>>;
  where?: InputMaybe<Rfq_Public_Queue_Bool_Exp>;
};


export type Query_RootSub_Organizations_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Sub_Organizations_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sub_Organizations_Order_By>>;
  where?: InputMaybe<Sub_Organizations_Bool_Exp>;
};


export type Query_RootTrade_Confirms_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Trade_Confirms_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trade_Confirms_Order_By>>;
  where?: InputMaybe<Trade_Confirms_Bool_Exp>;
};


export type Query_RootTrades_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Trades_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trades_Order_By>>;
  where?: InputMaybe<Trades_Bool_Exp>;
};


export type Query_RootTrades_Leg_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Trades_Leg_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trades_Leg_Order_By>>;
  where?: InputMaybe<Trades_Leg_Bool_Exp>;
};


export type Query_RootUser_Main_Tiering_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<User_Main_Tiering_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Main_Tiering_Order_By>>;
  where?: InputMaybe<User_Main_Tiering_Bool_Exp>;
};


export type Query_RootUser_Notifications_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};


export type Query_RootUser_Tiers_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<User_Tiers_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Tiers_Order_By>>;
  where?: InputMaybe<User_Tiers_Bool_Exp>;
};


export type Query_RootUsers_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** columns and relationships of "reportSequenceIds" */
export type ReportSequenceIds = Node & {
  __typename?: 'reportSequenceIds';
  DATINS?: Maybe<Scalars['Int']['output']>;
  DATNQU?: Maybe<Scalars['Int']['output']>;
  DATNTR?: Maybe<Scalars['Int']['output']>;
  DATNWD?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
};

/** A Relay connection object on "reportSequenceIds" */
export type ReportSequenceIdsConnection = {
  __typename?: 'reportSequenceIdsConnection';
  edges: Array<ReportSequenceIdsEdge>;
  pageInfo: PageInfo;
};

export type ReportSequenceIdsEdge = {
  __typename?: 'reportSequenceIdsEdge';
  cursor: Scalars['String']['output'];
  node: ReportSequenceIds;
};

/** Boolean expression to filter rows from the table "reportSequenceIds". All fields are combined with a logical 'AND'. */
export type ReportSequenceIds_Bool_Exp = {
  DATINS?: InputMaybe<Int_Comparison_Exp>;
  DATNQU?: InputMaybe<Int_Comparison_Exp>;
  DATNTR?: InputMaybe<Int_Comparison_Exp>;
  DATNWD?: InputMaybe<Int_Comparison_Exp>;
  _and?: InputMaybe<Array<ReportSequenceIds_Bool_Exp>>;
  _not?: InputMaybe<ReportSequenceIds_Bool_Exp>;
  _or?: InputMaybe<Array<ReportSequenceIds_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "reportSequenceIds" */
export enum ReportSequenceIds_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReportSequenceIdsPkey = 'reportSequenceIds_pkey'
}

/** input type for incrementing numeric columns in table "reportSequenceIds" */
export type ReportSequenceIds_Inc_Input = {
  DATINS?: InputMaybe<Scalars['Int']['input']>;
  DATNQU?: InputMaybe<Scalars['Int']['input']>;
  DATNTR?: InputMaybe<Scalars['Int']['input']>;
  DATNWD?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "reportSequenceIds" */
export type ReportSequenceIds_Insert_Input = {
  DATINS?: InputMaybe<Scalars['Int']['input']>;
  DATNQU?: InputMaybe<Scalars['Int']['input']>;
  DATNTR?: InputMaybe<Scalars['Int']['input']>;
  DATNWD?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** response of any mutation on the table "reportSequenceIds" */
export type ReportSequenceIds_Mutation_Response = {
  __typename?: 'reportSequenceIds_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ReportSequenceIds>;
};

/** on_conflict condition type for table "reportSequenceIds" */
export type ReportSequenceIds_On_Conflict = {
  constraint: ReportSequenceIds_Constraint;
  update_columns?: Array<ReportSequenceIds_Update_Column>;
  where?: InputMaybe<ReportSequenceIds_Bool_Exp>;
};

/** Ordering options when selecting data from "reportSequenceIds". */
export type ReportSequenceIds_Order_By = {
  DATINS?: InputMaybe<Order_By>;
  DATNQU?: InputMaybe<Order_By>;
  DATNTR?: InputMaybe<Order_By>;
  DATNWD?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reportSequenceIds */
export type ReportSequenceIds_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "reportSequenceIds" */
export enum ReportSequenceIds_Select_Column {
  /** column name */
  Datins = 'DATINS',
  /** column name */
  Datnqu = 'DATNQU',
  /** column name */
  Datntr = 'DATNTR',
  /** column name */
  Datnwd = 'DATNWD',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "reportSequenceIds" */
export type ReportSequenceIds_Set_Input = {
  DATINS?: InputMaybe<Scalars['Int']['input']>;
  DATNQU?: InputMaybe<Scalars['Int']['input']>;
  DATNTR?: InputMaybe<Scalars['Int']['input']>;
  DATNWD?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "reportSequenceIds" */
export enum ReportSequenceIds_Update_Column {
  /** column name */
  Datins = 'DATINS',
  /** column name */
  Datnqu = 'DATNQU',
  /** column name */
  Datntr = 'DATNTR',
  /** column name */
  Datnwd = 'DATNWD',
  /** column name */
  Id = 'id'
}

export type ReportSequenceIds_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ReportSequenceIds_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ReportSequenceIds_Set_Input>;
  /** filter the rows which have to be updated */
  where: ReportSequenceIds_Bool_Exp;
};

/** columns and relationships of "rfq" */
export type Rfq = Node & {
  __typename?: 'rfq';
  /** An array relationship */
  axe: Array<Axes>;
  /** An array relationship */
  axeCustomer: Array<Users>;
  axeCustomerUserId: Scalars['String']['output'];
  /** An aggregate relationship */
  axeCustomer_aggregate: Users_Aggregate;
  /** An array relationship connection */
  axeCustomer_connection: UsersConnection;
  axeId: Scalars['uuid']['output'];
  /** An array relationship */
  axeOwner: Array<Users>;
  axeOwnerUserId: Scalars['String']['output'];
  /** An aggregate relationship */
  axeOwner_aggregate: Users_Aggregate;
  /** An array relationship connection */
  axeOwner_connection: UsersConnection;
  /** An aggregate relationship */
  axe_aggregate: Axes_Aggregate;
  /** An array relationship connection */
  axe_connection: AxesConnection;
  channel?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  /** An array relationship */
  customerOrganization: Array<Organizations>;
  customerOrganizationId?: Maybe<Scalars['String']['output']>;
  /** An aggregate relationship */
  customerOrganization_aggregate: Organizations_Aggregate;
  /** An array relationship connection */
  customerOrganization_connection: OrganizationsConnection;
  customerSubOrganizationId?: Maybe<Scalars['uuid']['output']>;
  deltaHedge?: Maybe<Scalars['bigint']['output']>;
  expireAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  fund?: Maybe<Funds>;
  fundId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  give_up?: Maybe<Give_Ups>;
  giveupId?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['ID']['output'];
  notional?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  ownerOrganization: Array<Organizations>;
  ownerOrganizationId?: Maybe<Scalars['String']['output']>;
  /** An aggregate relationship */
  ownerOrganization_aggregate: Organizations_Aggregate;
  /** An array relationship connection */
  ownerOrganization_connection: OrganizationsConnection;
  /** An array relationship */
  ownerSubOrganization: Array<Sub_Organizations>;
  ownerSubOrganizationId?: Maybe<Scalars['uuid']['output']>;
  /** An aggregate relationship */
  ownerSubOrganization_aggregate: Sub_Organizations_Aggregate;
  /** An array relationship connection */
  ownerSubOrganization_connection: Sub_OrganizationsConnection;
  premiumType?: Maybe<Enum_Axe_Premiumtype_Enum>;
  /** An object relationship */
  rfq_axe_tiers?: Maybe<User_Tiers>;
  /** An array relationship */
  rfq_legs: Array<Rfq_Legs>;
  /** An aggregate relationship */
  rfq_legs_aggregate: Rfq_Legs_Aggregate;
  /** An array relationship connection */
  rfq_legs_connection: Rfq_LegsConnection;
  /** An array relationship */
  rfq_options: Array<Rfq_Options>;
  /** An aggregate relationship */
  rfq_options_aggregate: Rfq_Options_Aggregate;
  /** An array relationship connection */
  rfq_options_connection: Rfq_OptionsConnection;
  side?: Maybe<Enum_Quote_Side_Type_Enum>;
  status?: Maybe<Enum_Rfq_Status_Enum>;
  /** An object relationship */
  trades?: Maybe<Trades>;
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "rfq" */
export type RfqAxeArgs = {
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqAxeCustomerArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqAxeCustomer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqAxeCustomer_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqAxeOwnerArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqAxeOwner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqAxeOwner_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqAxe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqAxe_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqCustomerOrganizationArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqCustomerOrganization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqCustomerOrganization_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqOwnerOrganizationArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqOwnerOrganization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqOwnerOrganization_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqOwnerSubOrganizationArgs = {
  distinct_on?: InputMaybe<Array<Sub_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sub_Organizations_Order_By>>;
  where?: InputMaybe<Sub_Organizations_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqOwnerSubOrganization_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Sub_Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sub_Organizations_Order_By>>;
  where?: InputMaybe<Sub_Organizations_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqOwnerSubOrganization_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Sub_Organizations_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sub_Organizations_Order_By>>;
  where?: InputMaybe<Sub_Organizations_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqRfq_LegsArgs = {
  distinct_on?: InputMaybe<Array<Rfq_Legs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Legs_Order_By>>;
  where?: InputMaybe<Rfq_Legs_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqRfq_Legs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rfq_Legs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Legs_Order_By>>;
  where?: InputMaybe<Rfq_Legs_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqRfq_Legs_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Rfq_Legs_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Legs_Order_By>>;
  where?: InputMaybe<Rfq_Legs_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqRfq_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Rfq_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Options_Order_By>>;
  where?: InputMaybe<Rfq_Options_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqRfq_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rfq_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Options_Order_By>>;
  where?: InputMaybe<Rfq_Options_Bool_Exp>;
};


/** columns and relationships of "rfq" */
export type RfqRfq_Options_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Rfq_Options_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Options_Order_By>>;
  where?: InputMaybe<Rfq_Options_Bool_Exp>;
};

/** A Relay connection object on "rfq" */
export type RfqConnection = {
  __typename?: 'rfqConnection';
  edges: Array<RfqEdge>;
  pageInfo: PageInfo;
};

export type RfqEdge = {
  __typename?: 'rfqEdge';
  cursor: Scalars['String']['output'];
  node: Rfq;
};

/** Boolean expression to filter rows from the table "rfq". All fields are combined with a logical 'AND'. */
export type Rfq_Bool_Exp = {
  _and?: InputMaybe<Array<Rfq_Bool_Exp>>;
  _not?: InputMaybe<Rfq_Bool_Exp>;
  _or?: InputMaybe<Array<Rfq_Bool_Exp>>;
  axe?: InputMaybe<Axes_Bool_Exp>;
  axeCustomer?: InputMaybe<Users_Bool_Exp>;
  axeCustomerUserId?: InputMaybe<String_Comparison_Exp>;
  axeCustomer_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  axeId?: InputMaybe<Uuid_Comparison_Exp>;
  axeOwner?: InputMaybe<Users_Bool_Exp>;
  axeOwnerUserId?: InputMaybe<String_Comparison_Exp>;
  axeOwner_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  axe_aggregate?: InputMaybe<Axes_Aggregate_Bool_Exp>;
  channel?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerOrganization?: InputMaybe<Organizations_Bool_Exp>;
  customerOrganizationId?: InputMaybe<String_Comparison_Exp>;
  customerOrganization_aggregate?: InputMaybe<Organizations_Aggregate_Bool_Exp>;
  customerSubOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
  deltaHedge?: InputMaybe<Bigint_Comparison_Exp>;
  expireAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fund?: InputMaybe<Funds_Bool_Exp>;
  fundId?: InputMaybe<Uuid_Comparison_Exp>;
  give_up?: InputMaybe<Give_Ups_Bool_Exp>;
  giveupId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notional?: InputMaybe<Bigint_Comparison_Exp>;
  ownerOrganization?: InputMaybe<Organizations_Bool_Exp>;
  ownerOrganizationId?: InputMaybe<String_Comparison_Exp>;
  ownerOrganization_aggregate?: InputMaybe<Organizations_Aggregate_Bool_Exp>;
  ownerSubOrganization?: InputMaybe<Sub_Organizations_Bool_Exp>;
  ownerSubOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
  ownerSubOrganization_aggregate?: InputMaybe<Sub_Organizations_Aggregate_Bool_Exp>;
  premiumType?: InputMaybe<Enum_Axe_Premiumtype_Enum_Comparison_Exp>;
  rfq_axe_tiers?: InputMaybe<User_Tiers_Bool_Exp>;
  rfq_legs?: InputMaybe<Rfq_Legs_Bool_Exp>;
  rfq_legs_aggregate?: InputMaybe<Rfq_Legs_Aggregate_Bool_Exp>;
  rfq_options?: InputMaybe<Rfq_Options_Bool_Exp>;
  rfq_options_aggregate?: InputMaybe<Rfq_Options_Aggregate_Bool_Exp>;
  side?: InputMaybe<Enum_Quote_Side_Type_Enum_Comparison_Exp>;
  status?: InputMaybe<Enum_Rfq_Status_Enum_Comparison_Exp>;
  trades?: InputMaybe<Trades_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rfq" */
export enum Rfq_Constraint {
  /** unique or primary key constraint on columns "id" */
  RfqPkey = 'rfq_pkey'
}

/** input type for incrementing numeric columns in table "rfq" */
export type Rfq_Inc_Input = {
  deltaHedge?: InputMaybe<Scalars['bigint']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "rfq" */
export type Rfq_Insert_Input = {
  axe?: InputMaybe<Axes_Arr_Rel_Insert_Input>;
  axeCustomer?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  axeCustomerUserId?: InputMaybe<Scalars['String']['input']>;
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  axeOwner?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  axeOwnerUserId?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  customerOrganization?: InputMaybe<Organizations_Arr_Rel_Insert_Input>;
  customerOrganizationId?: InputMaybe<Scalars['String']['input']>;
  customerSubOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  deltaHedge?: InputMaybe<Scalars['bigint']['input']>;
  expireAt?: InputMaybe<Scalars['timestamptz']['input']>;
  fund?: InputMaybe<Funds_Obj_Rel_Insert_Input>;
  fundId?: InputMaybe<Scalars['uuid']['input']>;
  give_up?: InputMaybe<Give_Ups_Obj_Rel_Insert_Input>;
  giveupId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  ownerOrganization?: InputMaybe<Organizations_Arr_Rel_Insert_Input>;
  ownerOrganizationId?: InputMaybe<Scalars['String']['input']>;
  ownerSubOrganization?: InputMaybe<Sub_Organizations_Arr_Rel_Insert_Input>;
  ownerSubOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  premiumType?: InputMaybe<Enum_Axe_Premiumtype_Enum>;
  rfq_axe_tiers?: InputMaybe<User_Tiers_Obj_Rel_Insert_Input>;
  rfq_legs?: InputMaybe<Rfq_Legs_Arr_Rel_Insert_Input>;
  rfq_options?: InputMaybe<Rfq_Options_Arr_Rel_Insert_Input>;
  side?: InputMaybe<Enum_Quote_Side_Type_Enum>;
  status?: InputMaybe<Enum_Rfq_Status_Enum>;
  trades?: InputMaybe<Trades_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "rfq_legs" */
export type Rfq_Legs = Node & {
  __typename?: 'rfq_legs';
  buySell?: Maybe<Enum_Axe_Buysell_Enum>;
  callPut?: Maybe<Enum_Axe_Callput_Enum>;
  ccyPair: Enum_Axe_Ccypair_Enum;
  createdAt: Scalars['timestamptz']['output'];
  cut?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['date']['output']>;
  /** An object relationship */
  expiryCut?: Maybe<Expiry_Cuts>;
  expiryDate?: Maybe<Scalars['date']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  hedgeType?: Maybe<Enum_Axe_Hedgetype_Enum>;
  id: Scalars['ID']['output'];
  minimumNotionalAmount?: Maybe<Scalars['bigint']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  notionalCurrency?: Maybe<Enum_Currency_Notional_Enum>;
  orderIndex?: Maybe<Scalars['Int']['output']>;
  premium: Scalars['Int']['output'];
  premiumCurrency?: Maybe<Enum_Currency_Premium_Enum>;
  premiumDate?: Maybe<Scalars['date']['output']>;
  premiumType?: Maybe<Enum_Axe_Premiumtype_Enum>;
  product?: Maybe<Enum_Axe_Product_Enum>;
  /** An object relationship */
  rfq: Rfq;
  rfqId: Scalars['uuid']['output'];
  /** An array relationship */
  rfq_options_legs: Array<Rfq_Options_Legs>;
  /** An aggregate relationship */
  rfq_options_legs_aggregate: Rfq_Options_Legs_Aggregate;
  /** An array relationship connection */
  rfq_options_legs_connection: Rfq_Options_LegsConnection;
  spot?: Maybe<Scalars['float8']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  swaps?: Maybe<Scalars['float8']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "rfq_legs" */
export type Rfq_LegsRfq_Options_LegsArgs = {
  distinct_on?: InputMaybe<Array<Rfq_Options_Legs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Options_Legs_Order_By>>;
  where?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
};


/** columns and relationships of "rfq_legs" */
export type Rfq_LegsRfq_Options_Legs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rfq_Options_Legs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Options_Legs_Order_By>>;
  where?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
};


/** columns and relationships of "rfq_legs" */
export type Rfq_LegsRfq_Options_Legs_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Rfq_Options_Legs_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Options_Legs_Order_By>>;
  where?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
};

/** A Relay connection object on "rfq_legs" */
export type Rfq_LegsConnection = {
  __typename?: 'rfq_legsConnection';
  edges: Array<Rfq_LegsEdge>;
  pageInfo: PageInfo;
};

export type Rfq_LegsEdge = {
  __typename?: 'rfq_legsEdge';
  cursor: Scalars['String']['output'];
  node: Rfq_Legs;
};

/** aggregated selection of "rfq_legs" */
export type Rfq_Legs_Aggregate = {
  __typename?: 'rfq_legs_aggregate';
  aggregate?: Maybe<Rfq_Legs_Aggregate_Fields>;
  nodes: Array<Rfq_Legs>;
};

export type Rfq_Legs_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Rfq_Legs_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Rfq_Legs_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Rfq_Legs_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Rfq_Legs_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Rfq_Legs_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Rfq_Legs_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Rfq_Legs_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Rfq_Legs_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Rfq_Legs_Aggregate_Bool_Exp_Var_Samp>;
};

export type Rfq_Legs_Aggregate_Bool_Exp_Avg = {
  arguments: Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rfq_Legs_Aggregate_Bool_Exp_Corr = {
  arguments: Rfq_Legs_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rfq_Legs_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Rfq_Legs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rfq_Legs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Legs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Rfq_Legs_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Rfq_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rfq_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Rfq_Legs_Aggregate_Bool_Exp_Max = {
  arguments: Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rfq_Legs_Aggregate_Bool_Exp_Min = {
  arguments: Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rfq_Legs_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rfq_Legs_Aggregate_Bool_Exp_Sum = {
  arguments: Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rfq_Legs_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "rfq_legs" */
export type Rfq_Legs_Aggregate_Fields = {
  __typename?: 'rfq_legs_aggregate_fields';
  avg?: Maybe<Rfq_Legs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Rfq_Legs_Max_Fields>;
  min?: Maybe<Rfq_Legs_Min_Fields>;
  stddev?: Maybe<Rfq_Legs_Stddev_Fields>;
  stddev_pop?: Maybe<Rfq_Legs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rfq_Legs_Stddev_Samp_Fields>;
  sum?: Maybe<Rfq_Legs_Sum_Fields>;
  var_pop?: Maybe<Rfq_Legs_Var_Pop_Fields>;
  var_samp?: Maybe<Rfq_Legs_Var_Samp_Fields>;
  variance?: Maybe<Rfq_Legs_Variance_Fields>;
};


/** aggregate fields of "rfq_legs" */
export type Rfq_Legs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rfq_Legs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rfq_legs" */
export type Rfq_Legs_Aggregate_Order_By = {
  avg?: InputMaybe<Rfq_Legs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rfq_Legs_Max_Order_By>;
  min?: InputMaybe<Rfq_Legs_Min_Order_By>;
  stddev?: InputMaybe<Rfq_Legs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rfq_Legs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rfq_Legs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rfq_Legs_Sum_Order_By>;
  var_pop?: InputMaybe<Rfq_Legs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rfq_Legs_Var_Samp_Order_By>;
  variance?: InputMaybe<Rfq_Legs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rfq_legs" */
export type Rfq_Legs_Arr_Rel_Insert_Input = {
  data: Array<Rfq_Legs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rfq_Legs_On_Conflict>;
};

/** aggregate avg on columns */
export type Rfq_Legs_Avg_Fields = {
  __typename?: 'rfq_legs_avg_fields';
  forward?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rfq_legs" */
export type Rfq_Legs_Avg_Order_By = {
  forward?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rfq_legs". All fields are combined with a logical 'AND'. */
export type Rfq_Legs_Bool_Exp = {
  _and?: InputMaybe<Array<Rfq_Legs_Bool_Exp>>;
  _not?: InputMaybe<Rfq_Legs_Bool_Exp>;
  _or?: InputMaybe<Array<Rfq_Legs_Bool_Exp>>;
  buySell?: InputMaybe<Enum_Axe_Buysell_Enum_Comparison_Exp>;
  callPut?: InputMaybe<Enum_Axe_Callput_Enum_Comparison_Exp>;
  ccyPair?: InputMaybe<Enum_Axe_Ccypair_Enum_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  cut?: InputMaybe<String_Comparison_Exp>;
  deliveryDate?: InputMaybe<Date_Comparison_Exp>;
  expiryCut?: InputMaybe<Expiry_Cuts_Bool_Exp>;
  expiryDate?: InputMaybe<Date_Comparison_Exp>;
  forward?: InputMaybe<Float8_Comparison_Exp>;
  hedgeType?: InputMaybe<Enum_Axe_Hedgetype_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  minimumNotionalAmount?: InputMaybe<Bigint_Comparison_Exp>;
  notional?: InputMaybe<Bigint_Comparison_Exp>;
  notionalCurrency?: InputMaybe<Enum_Currency_Notional_Enum_Comparison_Exp>;
  orderIndex?: InputMaybe<Int_Comparison_Exp>;
  premium?: InputMaybe<Int_Comparison_Exp>;
  premiumCurrency?: InputMaybe<Enum_Currency_Premium_Enum_Comparison_Exp>;
  premiumDate?: InputMaybe<Date_Comparison_Exp>;
  premiumType?: InputMaybe<Enum_Axe_Premiumtype_Enum_Comparison_Exp>;
  product?: InputMaybe<Enum_Axe_Product_Enum_Comparison_Exp>;
  rfq?: InputMaybe<Rfq_Bool_Exp>;
  rfqId?: InputMaybe<Uuid_Comparison_Exp>;
  rfq_options_legs?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
  rfq_options_legs_aggregate?: InputMaybe<Rfq_Options_Legs_Aggregate_Bool_Exp>;
  spot?: InputMaybe<Float8_Comparison_Exp>;
  strike?: InputMaybe<Float8_Comparison_Exp>;
  swaps?: InputMaybe<Float8_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rfq_legs" */
export enum Rfq_Legs_Constraint {
  /** unique or primary key constraint on columns "id" */
  RfqLegsPkey = 'rfq_legs_pkey',
  /** unique or primary key constraint on columns "orderIndex", "rfqId" */
  UniqueOrderPerRfq = 'unique_order_per_rfq'
}

/** input type for incrementing numeric columns in table "rfq_legs" */
export type Rfq_Legs_Inc_Input = {
  forward?: InputMaybe<Scalars['float8']['input']>;
  minimumNotionalAmount?: InputMaybe<Scalars['bigint']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  orderIndex?: InputMaybe<Scalars['Int']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  spot?: InputMaybe<Scalars['float8']['input']>;
  strike?: InputMaybe<Scalars['float8']['input']>;
  swaps?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rfq_legs" */
export type Rfq_Legs_Insert_Input = {
  buySell?: InputMaybe<Enum_Axe_Buysell_Enum>;
  callPut?: InputMaybe<Enum_Axe_Callput_Enum>;
  ccyPair?: InputMaybe<Enum_Axe_Ccypair_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  cut?: InputMaybe<Scalars['String']['input']>;
  deliveryDate?: InputMaybe<Scalars['date']['input']>;
  expiryCut?: InputMaybe<Expiry_Cuts_Obj_Rel_Insert_Input>;
  expiryDate?: InputMaybe<Scalars['date']['input']>;
  forward?: InputMaybe<Scalars['float8']['input']>;
  hedgeType?: InputMaybe<Enum_Axe_Hedgetype_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minimumNotionalAmount?: InputMaybe<Scalars['bigint']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  notionalCurrency?: InputMaybe<Enum_Currency_Notional_Enum>;
  orderIndex?: InputMaybe<Scalars['Int']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  premiumCurrency?: InputMaybe<Enum_Currency_Premium_Enum>;
  premiumDate?: InputMaybe<Scalars['date']['input']>;
  premiumType?: InputMaybe<Enum_Axe_Premiumtype_Enum>;
  product?: InputMaybe<Enum_Axe_Product_Enum>;
  rfq?: InputMaybe<Rfq_Obj_Rel_Insert_Input>;
  rfqId?: InputMaybe<Scalars['uuid']['input']>;
  rfq_options_legs?: InputMaybe<Rfq_Options_Legs_Arr_Rel_Insert_Input>;
  spot?: InputMaybe<Scalars['float8']['input']>;
  strike?: InputMaybe<Scalars['float8']['input']>;
  swaps?: InputMaybe<Scalars['float8']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Rfq_Legs_Max_Fields = {
  __typename?: 'rfq_legs_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  cut?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['date']['output']>;
  expiryDate?: Maybe<Scalars['date']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['bigint']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  orderIndex?: Maybe<Scalars['Int']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  premiumDate?: Maybe<Scalars['date']['output']>;
  rfqId?: Maybe<Scalars['uuid']['output']>;
  spot?: Maybe<Scalars['float8']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  swaps?: Maybe<Scalars['float8']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "rfq_legs" */
export type Rfq_Legs_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  cut?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  premiumDate?: InputMaybe<Order_By>;
  rfqId?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rfq_Legs_Min_Fields = {
  __typename?: 'rfq_legs_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  cut?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['date']['output']>;
  expiryDate?: Maybe<Scalars['date']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['bigint']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  orderIndex?: Maybe<Scalars['Int']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  premiumDate?: Maybe<Scalars['date']['output']>;
  rfqId?: Maybe<Scalars['uuid']['output']>;
  spot?: Maybe<Scalars['float8']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  swaps?: Maybe<Scalars['float8']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "rfq_legs" */
export type Rfq_Legs_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  cut?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  premiumDate?: InputMaybe<Order_By>;
  rfqId?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rfq_legs" */
export type Rfq_Legs_Mutation_Response = {
  __typename?: 'rfq_legs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rfq_Legs>;
};

/** input type for inserting object relation for remote table "rfq_legs" */
export type Rfq_Legs_Obj_Rel_Insert_Input = {
  data: Rfq_Legs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Rfq_Legs_On_Conflict>;
};

/** on_conflict condition type for table "rfq_legs" */
export type Rfq_Legs_On_Conflict = {
  constraint: Rfq_Legs_Constraint;
  update_columns?: Array<Rfq_Legs_Update_Column>;
  where?: InputMaybe<Rfq_Legs_Bool_Exp>;
};

/** Ordering options when selecting data from "rfq_legs". */
export type Rfq_Legs_Order_By = {
  buySell?: InputMaybe<Order_By>;
  callPut?: InputMaybe<Order_By>;
  ccyPair?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cut?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  expiryCut?: InputMaybe<Expiry_Cuts_Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  hedgeType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  notionalCurrency?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  premiumCurrency?: InputMaybe<Order_By>;
  premiumDate?: InputMaybe<Order_By>;
  premiumType?: InputMaybe<Order_By>;
  product?: InputMaybe<Order_By>;
  rfq?: InputMaybe<Rfq_Order_By>;
  rfqId?: InputMaybe<Order_By>;
  rfq_options_legs_aggregate?: InputMaybe<Rfq_Options_Legs_Aggregate_Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rfq_legs */
export type Rfq_Legs_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "rfq_legs" */
export enum Rfq_Legs_Select_Column {
  /** column name */
  BuySell = 'buySell',
  /** column name */
  CallPut = 'callPut',
  /** column name */
  CcyPair = 'ccyPair',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Cut = 'cut',
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Forward = 'forward',
  /** column name */
  HedgeType = 'hedgeType',
  /** column name */
  Id = 'id',
  /** column name */
  MinimumNotionalAmount = 'minimumNotionalAmount',
  /** column name */
  Notional = 'notional',
  /** column name */
  NotionalCurrency = 'notionalCurrency',
  /** column name */
  OrderIndex = 'orderIndex',
  /** column name */
  Premium = 'premium',
  /** column name */
  PremiumCurrency = 'premiumCurrency',
  /** column name */
  PremiumDate = 'premiumDate',
  /** column name */
  PremiumType = 'premiumType',
  /** column name */
  Product = 'product',
  /** column name */
  RfqId = 'rfqId',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "rfq_legs_aggregate_bool_exp_avg_arguments_columns" columns of table "rfq_legs" */
export enum Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps'
}

/** select "rfq_legs_aggregate_bool_exp_corr_arguments_columns" columns of table "rfq_legs" */
export enum Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps'
}

/** select "rfq_legs_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "rfq_legs" */
export enum Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps'
}

/** select "rfq_legs_aggregate_bool_exp_max_arguments_columns" columns of table "rfq_legs" */
export enum Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps'
}

/** select "rfq_legs_aggregate_bool_exp_min_arguments_columns" columns of table "rfq_legs" */
export enum Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps'
}

/** select "rfq_legs_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "rfq_legs" */
export enum Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps'
}

/** select "rfq_legs_aggregate_bool_exp_sum_arguments_columns" columns of table "rfq_legs" */
export enum Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps'
}

/** select "rfq_legs_aggregate_bool_exp_var_samp_arguments_columns" columns of table "rfq_legs" */
export enum Rfq_Legs_Select_Column_Rfq_Legs_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps'
}

/** input type for updating data in table "rfq_legs" */
export type Rfq_Legs_Set_Input = {
  buySell?: InputMaybe<Enum_Axe_Buysell_Enum>;
  callPut?: InputMaybe<Enum_Axe_Callput_Enum>;
  ccyPair?: InputMaybe<Enum_Axe_Ccypair_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  cut?: InputMaybe<Scalars['String']['input']>;
  deliveryDate?: InputMaybe<Scalars['date']['input']>;
  expiryDate?: InputMaybe<Scalars['date']['input']>;
  forward?: InputMaybe<Scalars['float8']['input']>;
  hedgeType?: InputMaybe<Enum_Axe_Hedgetype_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  minimumNotionalAmount?: InputMaybe<Scalars['bigint']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  notionalCurrency?: InputMaybe<Enum_Currency_Notional_Enum>;
  orderIndex?: InputMaybe<Scalars['Int']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  premiumCurrency?: InputMaybe<Enum_Currency_Premium_Enum>;
  premiumDate?: InputMaybe<Scalars['date']['input']>;
  premiumType?: InputMaybe<Enum_Axe_Premiumtype_Enum>;
  product?: InputMaybe<Enum_Axe_Product_Enum>;
  rfqId?: InputMaybe<Scalars['uuid']['input']>;
  spot?: InputMaybe<Scalars['float8']['input']>;
  strike?: InputMaybe<Scalars['float8']['input']>;
  swaps?: InputMaybe<Scalars['float8']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Rfq_Legs_Stddev_Fields = {
  __typename?: 'rfq_legs_stddev_fields';
  forward?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rfq_legs" */
export type Rfq_Legs_Stddev_Order_By = {
  forward?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rfq_Legs_Stddev_Pop_Fields = {
  __typename?: 'rfq_legs_stddev_pop_fields';
  forward?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rfq_legs" */
export type Rfq_Legs_Stddev_Pop_Order_By = {
  forward?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rfq_Legs_Stddev_Samp_Fields = {
  __typename?: 'rfq_legs_stddev_samp_fields';
  forward?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rfq_legs" */
export type Rfq_Legs_Stddev_Samp_Order_By = {
  forward?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rfq_Legs_Sum_Fields = {
  __typename?: 'rfq_legs_sum_fields';
  forward?: Maybe<Scalars['float8']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['bigint']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  orderIndex?: Maybe<Scalars['Int']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  spot?: Maybe<Scalars['float8']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  swaps?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rfq_legs" */
export type Rfq_Legs_Sum_Order_By = {
  forward?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
};

/** update columns of table "rfq_legs" */
export enum Rfq_Legs_Update_Column {
  /** column name */
  BuySell = 'buySell',
  /** column name */
  CallPut = 'callPut',
  /** column name */
  CcyPair = 'ccyPair',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Cut = 'cut',
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Forward = 'forward',
  /** column name */
  HedgeType = 'hedgeType',
  /** column name */
  Id = 'id',
  /** column name */
  MinimumNotionalAmount = 'minimumNotionalAmount',
  /** column name */
  Notional = 'notional',
  /** column name */
  NotionalCurrency = 'notionalCurrency',
  /** column name */
  OrderIndex = 'orderIndex',
  /** column name */
  Premium = 'premium',
  /** column name */
  PremiumCurrency = 'premiumCurrency',
  /** column name */
  PremiumDate = 'premiumDate',
  /** column name */
  PremiumType = 'premiumType',
  /** column name */
  Product = 'product',
  /** column name */
  RfqId = 'rfqId',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike',
  /** column name */
  Swaps = 'swaps',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Rfq_Legs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rfq_Legs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rfq_Legs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rfq_Legs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rfq_Legs_Var_Pop_Fields = {
  __typename?: 'rfq_legs_var_pop_fields';
  forward?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rfq_legs" */
export type Rfq_Legs_Var_Pop_Order_By = {
  forward?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rfq_Legs_Var_Samp_Fields = {
  __typename?: 'rfq_legs_var_samp_fields';
  forward?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rfq_legs" */
export type Rfq_Legs_Var_Samp_Order_By = {
  forward?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rfq_Legs_Variance_Fields = {
  __typename?: 'rfq_legs_variance_fields';
  forward?: Maybe<Scalars['Float']['output']>;
  minimumNotionalAmount?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
  swaps?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rfq_legs" */
export type Rfq_Legs_Variance_Order_By = {
  forward?: InputMaybe<Order_By>;
  minimumNotionalAmount?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  swaps?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rfq" */
export type Rfq_Mutation_Response = {
  __typename?: 'rfq_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rfq>;
};

/** input type for inserting object relation for remote table "rfq" */
export type Rfq_Obj_Rel_Insert_Input = {
  data: Rfq_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Rfq_On_Conflict>;
};

/** on_conflict condition type for table "rfq" */
export type Rfq_On_Conflict = {
  constraint: Rfq_Constraint;
  update_columns?: Array<Rfq_Update_Column>;
  where?: InputMaybe<Rfq_Bool_Exp>;
};

/** columns and relationships of "rfq_options" */
export type Rfq_Options = Node & {
  __typename?: 'rfq_options';
  createdAt: Scalars['timestamptz']['output'];
  fixQuoteId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  reason?: Maybe<Enum_Rfq_Option_Reason_Enum>;
  /** An object relationship */
  rfq: Rfq;
  rfqId: Scalars['uuid']['output'];
  /** An array relationship */
  rfq_options_legs: Array<Rfq_Options_Legs>;
  /** An aggregate relationship */
  rfq_options_legs_aggregate: Rfq_Options_Legs_Aggregate;
  /** An array relationship connection */
  rfq_options_legs_connection: Rfq_Options_LegsConnection;
  side?: Maybe<Enum_Quote_Side_Type_Enum>;
  status?: Maybe<Enum_Rfq_Status_Enum>;
  updatedAt: Scalars['timestamptz']['output'];
  userId: Scalars['String']['output'];
};


/** columns and relationships of "rfq_options" */
export type Rfq_OptionsRfq_Options_LegsArgs = {
  distinct_on?: InputMaybe<Array<Rfq_Options_Legs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Options_Legs_Order_By>>;
  where?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
};


/** columns and relationships of "rfq_options" */
export type Rfq_OptionsRfq_Options_Legs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Rfq_Options_Legs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Options_Legs_Order_By>>;
  where?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
};


/** columns and relationships of "rfq_options" */
export type Rfq_OptionsRfq_Options_Legs_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Rfq_Options_Legs_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Options_Legs_Order_By>>;
  where?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
};

/** A Relay connection object on "rfq_options" */
export type Rfq_OptionsConnection = {
  __typename?: 'rfq_optionsConnection';
  edges: Array<Rfq_OptionsEdge>;
  pageInfo: PageInfo;
};

export type Rfq_OptionsEdge = {
  __typename?: 'rfq_optionsEdge';
  cursor: Scalars['String']['output'];
  node: Rfq_Options;
};

/** aggregated selection of "rfq_options" */
export type Rfq_Options_Aggregate = {
  __typename?: 'rfq_options_aggregate';
  aggregate?: Maybe<Rfq_Options_Aggregate_Fields>;
  nodes: Array<Rfq_Options>;
};

export type Rfq_Options_Aggregate_Bool_Exp = {
  count?: InputMaybe<Rfq_Options_Aggregate_Bool_Exp_Count>;
};

export type Rfq_Options_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rfq_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Options_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "rfq_options" */
export type Rfq_Options_Aggregate_Fields = {
  __typename?: 'rfq_options_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Rfq_Options_Max_Fields>;
  min?: Maybe<Rfq_Options_Min_Fields>;
};


/** aggregate fields of "rfq_options" */
export type Rfq_Options_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rfq_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rfq_options" */
export type Rfq_Options_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rfq_Options_Max_Order_By>;
  min?: InputMaybe<Rfq_Options_Min_Order_By>;
};

/** input type for inserting array relation for remote table "rfq_options" */
export type Rfq_Options_Arr_Rel_Insert_Input = {
  data: Array<Rfq_Options_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rfq_Options_On_Conflict>;
};

/** Boolean expression to filter rows from the table "rfq_options". All fields are combined with a logical 'AND'. */
export type Rfq_Options_Bool_Exp = {
  _and?: InputMaybe<Array<Rfq_Options_Bool_Exp>>;
  _not?: InputMaybe<Rfq_Options_Bool_Exp>;
  _or?: InputMaybe<Array<Rfq_Options_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  fixQuoteId?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  reason?: InputMaybe<Enum_Rfq_Option_Reason_Enum_Comparison_Exp>;
  rfq?: InputMaybe<Rfq_Bool_Exp>;
  rfqId?: InputMaybe<Uuid_Comparison_Exp>;
  rfq_options_legs?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
  rfq_options_legs_aggregate?: InputMaybe<Rfq_Options_Legs_Aggregate_Bool_Exp>;
  side?: InputMaybe<Enum_Quote_Side_Type_Enum_Comparison_Exp>;
  status?: InputMaybe<Enum_Rfq_Status_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "rfq_options" */
export enum Rfq_Options_Constraint {
  /** unique or primary key constraint on columns "id" */
  RfqOptionsPkey = 'rfq_options_pkey'
}

/** input type for inserting data into table "rfq_options" */
export type Rfq_Options_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  fixQuoteId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  reason?: InputMaybe<Enum_Rfq_Option_Reason_Enum>;
  rfq?: InputMaybe<Rfq_Obj_Rel_Insert_Input>;
  rfqId?: InputMaybe<Scalars['uuid']['input']>;
  rfq_options_legs?: InputMaybe<Rfq_Options_Legs_Arr_Rel_Insert_Input>;
  side?: InputMaybe<Enum_Quote_Side_Type_Enum>;
  status?: InputMaybe<Enum_Rfq_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "rfq_options_legs" */
export type Rfq_Options_Legs = Node & {
  __typename?: 'rfq_options_legs';
  bsDelta?: Maybe<Scalars['bigint']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  delta?: Maybe<Scalars['bigint']['output']>;
  id: Scalars['ID']['output'];
  premium?: Maybe<Scalars['Int']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  rfqLegId: Scalars['uuid']['output'];
  rfqOptionId: Scalars['uuid']['output'];
  /** An object relationship */
  rfq_leg: Rfq_Legs;
  /** An object relationship */
  rfq_option: Rfq_Options;
  updatedAt: Scalars['timestamptz']['output'];
};

/** A Relay connection object on "rfq_options_legs" */
export type Rfq_Options_LegsConnection = {
  __typename?: 'rfq_options_legsConnection';
  edges: Array<Rfq_Options_LegsEdge>;
  pageInfo: PageInfo;
};

export type Rfq_Options_LegsEdge = {
  __typename?: 'rfq_options_legsEdge';
  cursor: Scalars['String']['output'];
  node: Rfq_Options_Legs;
};

/** aggregated selection of "rfq_options_legs" */
export type Rfq_Options_Legs_Aggregate = {
  __typename?: 'rfq_options_legs_aggregate';
  aggregate?: Maybe<Rfq_Options_Legs_Aggregate_Fields>;
  nodes: Array<Rfq_Options_Legs>;
};

export type Rfq_Options_Legs_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Rfq_Options_Legs_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Rfq_Options_Legs_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Rfq_Options_Legs_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Rfq_Options_Legs_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Rfq_Options_Legs_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Rfq_Options_Legs_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Rfq_Options_Legs_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Rfq_Options_Legs_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Rfq_Options_Legs_Aggregate_Bool_Exp_Var_Samp>;
};

export type Rfq_Options_Legs_Aggregate_Bool_Exp_Avg = {
  arguments: Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rfq_Options_Legs_Aggregate_Bool_Exp_Corr = {
  arguments: Rfq_Options_Legs_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rfq_Options_Legs_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Rfq_Options_Legs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Rfq_Options_Legs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Rfq_Options_Legs_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Rfq_Options_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rfq_Options_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Rfq_Options_Legs_Aggregate_Bool_Exp_Max = {
  arguments: Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rfq_Options_Legs_Aggregate_Bool_Exp_Min = {
  arguments: Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rfq_Options_Legs_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rfq_Options_Legs_Aggregate_Bool_Exp_Sum = {
  arguments: Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Rfq_Options_Legs_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "rfq_options_legs" */
export type Rfq_Options_Legs_Aggregate_Fields = {
  __typename?: 'rfq_options_legs_aggregate_fields';
  avg?: Maybe<Rfq_Options_Legs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Rfq_Options_Legs_Max_Fields>;
  min?: Maybe<Rfq_Options_Legs_Min_Fields>;
  stddev?: Maybe<Rfq_Options_Legs_Stddev_Fields>;
  stddev_pop?: Maybe<Rfq_Options_Legs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Rfq_Options_Legs_Stddev_Samp_Fields>;
  sum?: Maybe<Rfq_Options_Legs_Sum_Fields>;
  var_pop?: Maybe<Rfq_Options_Legs_Var_Pop_Fields>;
  var_samp?: Maybe<Rfq_Options_Legs_Var_Samp_Fields>;
  variance?: Maybe<Rfq_Options_Legs_Variance_Fields>;
};


/** aggregate fields of "rfq_options_legs" */
export type Rfq_Options_Legs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Rfq_Options_Legs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "rfq_options_legs" */
export type Rfq_Options_Legs_Aggregate_Order_By = {
  avg?: InputMaybe<Rfq_Options_Legs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Rfq_Options_Legs_Max_Order_By>;
  min?: InputMaybe<Rfq_Options_Legs_Min_Order_By>;
  stddev?: InputMaybe<Rfq_Options_Legs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Rfq_Options_Legs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Rfq_Options_Legs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Rfq_Options_Legs_Sum_Order_By>;
  var_pop?: InputMaybe<Rfq_Options_Legs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Rfq_Options_Legs_Var_Samp_Order_By>;
  variance?: InputMaybe<Rfq_Options_Legs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "rfq_options_legs" */
export type Rfq_Options_Legs_Arr_Rel_Insert_Input = {
  data: Array<Rfq_Options_Legs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Rfq_Options_Legs_On_Conflict>;
};

/** aggregate avg on columns */
export type Rfq_Options_Legs_Avg_Fields = {
  __typename?: 'rfq_options_legs_avg_fields';
  bsDelta?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "rfq_options_legs" */
export type Rfq_Options_Legs_Avg_Order_By = {
  bsDelta?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "rfq_options_legs". All fields are combined with a logical 'AND'. */
export type Rfq_Options_Legs_Bool_Exp = {
  _and?: InputMaybe<Array<Rfq_Options_Legs_Bool_Exp>>;
  _not?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
  _or?: InputMaybe<Array<Rfq_Options_Legs_Bool_Exp>>;
  bsDelta?: InputMaybe<Bigint_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  delta?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  premium?: InputMaybe<Int_Comparison_Exp>;
  pricingVolatility?: InputMaybe<Float8_Comparison_Exp>;
  rfqLegId?: InputMaybe<Uuid_Comparison_Exp>;
  rfqOptionId?: InputMaybe<Uuid_Comparison_Exp>;
  rfq_leg?: InputMaybe<Rfq_Legs_Bool_Exp>;
  rfq_option?: InputMaybe<Rfq_Options_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rfq_options_legs" */
export enum Rfq_Options_Legs_Constraint {
  /** unique or primary key constraint on columns "id" */
  RfqOptionsLegsPkey = 'rfq_options_legs_pkey'
}

/** input type for incrementing numeric columns in table "rfq_options_legs" */
export type Rfq_Options_Legs_Inc_Input = {
  bsDelta?: InputMaybe<Scalars['bigint']['input']>;
  delta?: InputMaybe<Scalars['bigint']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "rfq_options_legs" */
export type Rfq_Options_Legs_Insert_Input = {
  bsDelta?: InputMaybe<Scalars['bigint']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  delta?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
  rfqLegId?: InputMaybe<Scalars['uuid']['input']>;
  rfqOptionId?: InputMaybe<Scalars['uuid']['input']>;
  rfq_leg?: InputMaybe<Rfq_Legs_Obj_Rel_Insert_Input>;
  rfq_option?: InputMaybe<Rfq_Options_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Rfq_Options_Legs_Max_Fields = {
  __typename?: 'rfq_options_legs_max_fields';
  bsDelta?: Maybe<Scalars['bigint']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  delta?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  rfqLegId?: Maybe<Scalars['uuid']['output']>;
  rfqOptionId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "rfq_options_legs" */
export type Rfq_Options_Legs_Max_Order_By = {
  bsDelta?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  rfqLegId?: InputMaybe<Order_By>;
  rfqOptionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rfq_Options_Legs_Min_Fields = {
  __typename?: 'rfq_options_legs_min_fields';
  bsDelta?: Maybe<Scalars['bigint']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  delta?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  rfqLegId?: Maybe<Scalars['uuid']['output']>;
  rfqOptionId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "rfq_options_legs" */
export type Rfq_Options_Legs_Min_Order_By = {
  bsDelta?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  rfqLegId?: InputMaybe<Order_By>;
  rfqOptionId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rfq_options_legs" */
export type Rfq_Options_Legs_Mutation_Response = {
  __typename?: 'rfq_options_legs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rfq_Options_Legs>;
};

/** on_conflict condition type for table "rfq_options_legs" */
export type Rfq_Options_Legs_On_Conflict = {
  constraint: Rfq_Options_Legs_Constraint;
  update_columns?: Array<Rfq_Options_Legs_Update_Column>;
  where?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
};

/** Ordering options when selecting data from "rfq_options_legs". */
export type Rfq_Options_Legs_Order_By = {
  bsDelta?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  rfqLegId?: InputMaybe<Order_By>;
  rfqOptionId?: InputMaybe<Order_By>;
  rfq_leg?: InputMaybe<Rfq_Legs_Order_By>;
  rfq_option?: InputMaybe<Rfq_Options_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rfq_options_legs */
export type Rfq_Options_Legs_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "rfq_options_legs" */
export enum Rfq_Options_Legs_Select_Column {
  /** column name */
  BsDelta = 'bsDelta',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Delta = 'delta',
  /** column name */
  Id = 'id',
  /** column name */
  Premium = 'premium',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  RfqLegId = 'rfqLegId',
  /** column name */
  RfqOptionId = 'rfqOptionId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "rfq_options_legs_aggregate_bool_exp_avg_arguments_columns" columns of table "rfq_options_legs" */
export enum Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** select "rfq_options_legs_aggregate_bool_exp_corr_arguments_columns" columns of table "rfq_options_legs" */
export enum Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** select "rfq_options_legs_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "rfq_options_legs" */
export enum Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** select "rfq_options_legs_aggregate_bool_exp_max_arguments_columns" columns of table "rfq_options_legs" */
export enum Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** select "rfq_options_legs_aggregate_bool_exp_min_arguments_columns" columns of table "rfq_options_legs" */
export enum Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** select "rfq_options_legs_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "rfq_options_legs" */
export enum Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** select "rfq_options_legs_aggregate_bool_exp_sum_arguments_columns" columns of table "rfq_options_legs" */
export enum Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** select "rfq_options_legs_aggregate_bool_exp_var_samp_arguments_columns" columns of table "rfq_options_legs" */
export enum Rfq_Options_Legs_Select_Column_Rfq_Options_Legs_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  PricingVolatility = 'pricingVolatility'
}

/** input type for updating data in table "rfq_options_legs" */
export type Rfq_Options_Legs_Set_Input = {
  bsDelta?: InputMaybe<Scalars['bigint']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  delta?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
  rfqLegId?: InputMaybe<Scalars['uuid']['input']>;
  rfqOptionId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Rfq_Options_Legs_Stddev_Fields = {
  __typename?: 'rfq_options_legs_stddev_fields';
  bsDelta?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "rfq_options_legs" */
export type Rfq_Options_Legs_Stddev_Order_By = {
  bsDelta?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Rfq_Options_Legs_Stddev_Pop_Fields = {
  __typename?: 'rfq_options_legs_stddev_pop_fields';
  bsDelta?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "rfq_options_legs" */
export type Rfq_Options_Legs_Stddev_Pop_Order_By = {
  bsDelta?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Rfq_Options_Legs_Stddev_Samp_Fields = {
  __typename?: 'rfq_options_legs_stddev_samp_fields';
  bsDelta?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "rfq_options_legs" */
export type Rfq_Options_Legs_Stddev_Samp_Order_By = {
  bsDelta?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Rfq_Options_Legs_Sum_Fields = {
  __typename?: 'rfq_options_legs_sum_fields';
  bsDelta?: Maybe<Scalars['bigint']['output']>;
  delta?: Maybe<Scalars['bigint']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "rfq_options_legs" */
export type Rfq_Options_Legs_Sum_Order_By = {
  bsDelta?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
};

/** update columns of table "rfq_options_legs" */
export enum Rfq_Options_Legs_Update_Column {
  /** column name */
  BsDelta = 'bsDelta',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Delta = 'delta',
  /** column name */
  Id = 'id',
  /** column name */
  Premium = 'premium',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  RfqLegId = 'rfqLegId',
  /** column name */
  RfqOptionId = 'rfqOptionId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Rfq_Options_Legs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rfq_Options_Legs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rfq_Options_Legs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rfq_Options_Legs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Rfq_Options_Legs_Var_Pop_Fields = {
  __typename?: 'rfq_options_legs_var_pop_fields';
  bsDelta?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "rfq_options_legs" */
export type Rfq_Options_Legs_Var_Pop_Order_By = {
  bsDelta?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Rfq_Options_Legs_Var_Samp_Fields = {
  __typename?: 'rfq_options_legs_var_samp_fields';
  bsDelta?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "rfq_options_legs" */
export type Rfq_Options_Legs_Var_Samp_Order_By = {
  bsDelta?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Rfq_Options_Legs_Variance_Fields = {
  __typename?: 'rfq_options_legs_variance_fields';
  bsDelta?: Maybe<Scalars['Float']['output']>;
  delta?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "rfq_options_legs" */
export type Rfq_Options_Legs_Variance_Order_By = {
  bsDelta?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Rfq_Options_Max_Fields = {
  __typename?: 'rfq_options_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  fixQuoteId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  rfqId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "rfq_options" */
export type Rfq_Options_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  fixQuoteId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rfqId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Rfq_Options_Min_Fields = {
  __typename?: 'rfq_options_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  fixQuoteId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  rfqId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "rfq_options" */
export type Rfq_Options_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  fixQuoteId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rfqId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "rfq_options" */
export type Rfq_Options_Mutation_Response = {
  __typename?: 'rfq_options_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rfq_Options>;
};

/** input type for inserting object relation for remote table "rfq_options" */
export type Rfq_Options_Obj_Rel_Insert_Input = {
  data: Rfq_Options_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Rfq_Options_On_Conflict>;
};

/** on_conflict condition type for table "rfq_options" */
export type Rfq_Options_On_Conflict = {
  constraint: Rfq_Options_Constraint;
  update_columns?: Array<Rfq_Options_Update_Column>;
  where?: InputMaybe<Rfq_Options_Bool_Exp>;
};

/** Ordering options when selecting data from "rfq_options". */
export type Rfq_Options_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  fixQuoteId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  rfq?: InputMaybe<Rfq_Order_By>;
  rfqId?: InputMaybe<Order_By>;
  rfq_options_legs_aggregate?: InputMaybe<Rfq_Options_Legs_Aggregate_Order_By>;
  side?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rfq_options */
export type Rfq_Options_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "rfq_options" */
export enum Rfq_Options_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FixQuoteId = 'fixQuoteId',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  RfqId = 'rfqId',
  /** column name */
  Side = 'side',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "rfq_options" */
export type Rfq_Options_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  fixQuoteId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  reason?: InputMaybe<Enum_Rfq_Option_Reason_Enum>;
  rfqId?: InputMaybe<Scalars['uuid']['input']>;
  side?: InputMaybe<Enum_Quote_Side_Type_Enum>;
  status?: InputMaybe<Enum_Rfq_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "rfq_options" */
export enum Rfq_Options_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FixQuoteId = 'fixQuoteId',
  /** column name */
  Id = 'id',
  /** column name */
  Reason = 'reason',
  /** column name */
  RfqId = 'rfqId',
  /** column name */
  Side = 'side',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type Rfq_Options_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rfq_Options_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rfq_Options_Bool_Exp;
};

/** Ordering options when selecting data from "rfq". */
export type Rfq_Order_By = {
  axeCustomerUserId?: InputMaybe<Order_By>;
  axeCustomer_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  axeId?: InputMaybe<Order_By>;
  axeOwnerUserId?: InputMaybe<Order_By>;
  axeOwner_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  axe_aggregate?: InputMaybe<Axes_Aggregate_Order_By>;
  channel?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerOrganizationId?: InputMaybe<Order_By>;
  customerOrganization_aggregate?: InputMaybe<Organizations_Aggregate_Order_By>;
  customerSubOrganizationId?: InputMaybe<Order_By>;
  deltaHedge?: InputMaybe<Order_By>;
  expireAt?: InputMaybe<Order_By>;
  fund?: InputMaybe<Funds_Order_By>;
  fundId?: InputMaybe<Order_By>;
  give_up?: InputMaybe<Give_Ups_Order_By>;
  giveupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  ownerOrganizationId?: InputMaybe<Order_By>;
  ownerOrganization_aggregate?: InputMaybe<Organizations_Aggregate_Order_By>;
  ownerSubOrganizationId?: InputMaybe<Order_By>;
  ownerSubOrganization_aggregate?: InputMaybe<Sub_Organizations_Aggregate_Order_By>;
  premiumType?: InputMaybe<Order_By>;
  rfq_axe_tiers?: InputMaybe<User_Tiers_Order_By>;
  rfq_legs_aggregate?: InputMaybe<Rfq_Legs_Aggregate_Order_By>;
  rfq_options_aggregate?: InputMaybe<Rfq_Options_Aggregate_Order_By>;
  side?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  trades?: InputMaybe<Trades_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rfq */
export type Rfq_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** columns and relationships of "rfq_public_queue" */
export type Rfq_Public_Queue = Node & {
  __typename?: 'rfq_public_queue';
  axeId: Scalars['uuid']['output'];
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['ID']['output'];
  rfqId: Scalars['uuid']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};

/** A Relay connection object on "rfq_public_queue" */
export type Rfq_Public_QueueConnection = {
  __typename?: 'rfq_public_queueConnection';
  edges: Array<Rfq_Public_QueueEdge>;
  pageInfo: PageInfo;
};

export type Rfq_Public_QueueEdge = {
  __typename?: 'rfq_public_queueEdge';
  cursor: Scalars['String']['output'];
  node: Rfq_Public_Queue;
};

/** Boolean expression to filter rows from the table "rfq_public_queue". All fields are combined with a logical 'AND'. */
export type Rfq_Public_Queue_Bool_Exp = {
  _and?: InputMaybe<Array<Rfq_Public_Queue_Bool_Exp>>;
  _not?: InputMaybe<Rfq_Public_Queue_Bool_Exp>;
  _or?: InputMaybe<Array<Rfq_Public_Queue_Bool_Exp>>;
  axeId?: InputMaybe<Uuid_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  rfqId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "rfq_public_queue" */
export enum Rfq_Public_Queue_Constraint {
  /** unique or primary key constraint on columns "id" */
  RfqPublicQueuePkey = 'rfq_public_queue_pkey'
}

/** input type for inserting data into table "rfq_public_queue" */
export type Rfq_Public_Queue_Insert_Input = {
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  rfqId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** response of any mutation on the table "rfq_public_queue" */
export type Rfq_Public_Queue_Mutation_Response = {
  __typename?: 'rfq_public_queue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Rfq_Public_Queue>;
};

/** on_conflict condition type for table "rfq_public_queue" */
export type Rfq_Public_Queue_On_Conflict = {
  constraint: Rfq_Public_Queue_Constraint;
  update_columns?: Array<Rfq_Public_Queue_Update_Column>;
  where?: InputMaybe<Rfq_Public_Queue_Bool_Exp>;
};

/** Ordering options when selecting data from "rfq_public_queue". */
export type Rfq_Public_Queue_Order_By = {
  axeId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rfqId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: rfq_public_queue */
export type Rfq_Public_Queue_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "rfq_public_queue" */
export enum Rfq_Public_Queue_Select_Column {
  /** column name */
  AxeId = 'axeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  RfqId = 'rfqId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "rfq_public_queue" */
export type Rfq_Public_Queue_Set_Input = {
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  rfqId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "rfq_public_queue" */
export enum Rfq_Public_Queue_Update_Column {
  /** column name */
  AxeId = 'axeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  RfqId = 'rfqId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Rfq_Public_Queue_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rfq_Public_Queue_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rfq_Public_Queue_Bool_Exp;
};

/** select columns of table "rfq" */
export enum Rfq_Select_Column {
  /** column name */
  AxeCustomerUserId = 'axeCustomerUserId',
  /** column name */
  AxeId = 'axeId',
  /** column name */
  AxeOwnerUserId = 'axeOwnerUserId',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerOrganizationId = 'customerOrganizationId',
  /** column name */
  CustomerSubOrganizationId = 'customerSubOrganizationId',
  /** column name */
  DeltaHedge = 'deltaHedge',
  /** column name */
  ExpireAt = 'expireAt',
  /** column name */
  FundId = 'fundId',
  /** column name */
  GiveupId = 'giveupId',
  /** column name */
  Id = 'id',
  /** column name */
  Notional = 'notional',
  /** column name */
  OwnerOrganizationId = 'ownerOrganizationId',
  /** column name */
  OwnerSubOrganizationId = 'ownerSubOrganizationId',
  /** column name */
  PremiumType = 'premiumType',
  /** column name */
  Side = 'side',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "rfq" */
export type Rfq_Set_Input = {
  axeCustomerUserId?: InputMaybe<Scalars['String']['input']>;
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  axeOwnerUserId?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  customerOrganizationId?: InputMaybe<Scalars['String']['input']>;
  customerSubOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  deltaHedge?: InputMaybe<Scalars['bigint']['input']>;
  expireAt?: InputMaybe<Scalars['timestamptz']['input']>;
  fundId?: InputMaybe<Scalars['uuid']['input']>;
  giveupId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  ownerOrganizationId?: InputMaybe<Scalars['String']['input']>;
  ownerSubOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  premiumType?: InputMaybe<Enum_Axe_Premiumtype_Enum>;
  side?: InputMaybe<Enum_Quote_Side_Type_Enum>;
  status?: InputMaybe<Enum_Rfq_Status_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "rfq" */
export enum Rfq_Update_Column {
  /** column name */
  AxeCustomerUserId = 'axeCustomerUserId',
  /** column name */
  AxeId = 'axeId',
  /** column name */
  AxeOwnerUserId = 'axeOwnerUserId',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerOrganizationId = 'customerOrganizationId',
  /** column name */
  CustomerSubOrganizationId = 'customerSubOrganizationId',
  /** column name */
  DeltaHedge = 'deltaHedge',
  /** column name */
  ExpireAt = 'expireAt',
  /** column name */
  FundId = 'fundId',
  /** column name */
  GiveupId = 'giveupId',
  /** column name */
  Id = 'id',
  /** column name */
  Notional = 'notional',
  /** column name */
  OwnerOrganizationId = 'ownerOrganizationId',
  /** column name */
  OwnerSubOrganizationId = 'ownerSubOrganizationId',
  /** column name */
  PremiumType = 'premiumType',
  /** column name */
  Side = 'side',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Rfq_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Rfq_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Rfq_Set_Input>;
  /** filter the rows which have to be updated */
  where: Rfq_Bool_Exp;
};

/** columns and relationships of "sub_organizations" */
export type Sub_Organizations = Node & {
  __typename?: 'sub_organizations';
  createdAt: Scalars['timestamptz']['output'];
  displayName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  /** An object relationship */
  parent_organization?: Maybe<Organizations>;
  updatedAt: Scalars['timestamptz']['output'];
};

/** A Relay connection object on "sub_organizations" */
export type Sub_OrganizationsConnection = {
  __typename?: 'sub_organizationsConnection';
  edges: Array<Sub_OrganizationsEdge>;
  pageInfo: PageInfo;
};

export type Sub_OrganizationsEdge = {
  __typename?: 'sub_organizationsEdge';
  cursor: Scalars['String']['output'];
  node: Sub_Organizations;
};

/** aggregated selection of "sub_organizations" */
export type Sub_Organizations_Aggregate = {
  __typename?: 'sub_organizations_aggregate';
  aggregate?: Maybe<Sub_Organizations_Aggregate_Fields>;
  nodes: Array<Sub_Organizations>;
};

export type Sub_Organizations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Sub_Organizations_Aggregate_Bool_Exp_Count>;
};

export type Sub_Organizations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Sub_Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Sub_Organizations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "sub_organizations" */
export type Sub_Organizations_Aggregate_Fields = {
  __typename?: 'sub_organizations_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Sub_Organizations_Max_Fields>;
  min?: Maybe<Sub_Organizations_Min_Fields>;
};


/** aggregate fields of "sub_organizations" */
export type Sub_Organizations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Sub_Organizations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "sub_organizations" */
export type Sub_Organizations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sub_Organizations_Max_Order_By>;
  min?: InputMaybe<Sub_Organizations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "sub_organizations" */
export type Sub_Organizations_Arr_Rel_Insert_Input = {
  data: Array<Sub_Organizations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Sub_Organizations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "sub_organizations". All fields are combined with a logical 'AND'. */
export type Sub_Organizations_Bool_Exp = {
  _and?: InputMaybe<Array<Sub_Organizations_Bool_Exp>>;
  _not?: InputMaybe<Sub_Organizations_Bool_Exp>;
  _or?: InputMaybe<Array<Sub_Organizations_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  parent_organization?: InputMaybe<Organizations_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sub_organizations" */
export enum Sub_Organizations_Constraint {
  /** unique or primary key constraint on columns "name" */
  SubOrganizationsNameKey = 'sub_organizations_name_key',
  /** unique or primary key constraint on columns "id" */
  SubOrganizationsPkey = 'sub_organizations_pkey'
}

/** input type for inserting data into table "sub_organizations" */
export type Sub_Organizations_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  parent_organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Sub_Organizations_Max_Fields = {
  __typename?: 'sub_organizations_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "sub_organizations" */
export type Sub_Organizations_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Sub_Organizations_Min_Fields = {
  __typename?: 'sub_organizations_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "sub_organizations" */
export type Sub_Organizations_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "sub_organizations" */
export type Sub_Organizations_Mutation_Response = {
  __typename?: 'sub_organizations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Sub_Organizations>;
};

/** input type for inserting object relation for remote table "sub_organizations" */
export type Sub_Organizations_Obj_Rel_Insert_Input = {
  data: Sub_Organizations_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Sub_Organizations_On_Conflict>;
};

/** on_conflict condition type for table "sub_organizations" */
export type Sub_Organizations_On_Conflict = {
  constraint: Sub_Organizations_Constraint;
  update_columns?: Array<Sub_Organizations_Update_Column>;
  where?: InputMaybe<Sub_Organizations_Bool_Exp>;
};

/** Ordering options when selecting data from "sub_organizations". */
export type Sub_Organizations_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  parent_organization?: InputMaybe<Organizations_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sub_organizations */
export type Sub_Organizations_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "sub_organizations" */
export enum Sub_Organizations_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "sub_organizations" */
export type Sub_Organizations_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "sub_organizations" */
export enum Sub_Organizations_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Sub_Organizations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Sub_Organizations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Sub_Organizations_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship connection */
  axe_authors_connection: Axe_AuthorsConnection;
  /** fetch data from the table: "axe_legs_audit" */
  axe_legs_audit_connection: Axe_Legs_AuditConnection;
  /** An array relationship connection */
  axe_legs_connection: Axe_LegsConnection;
  /** fetch data from the table: "axe_tier_pricing" */
  axe_tier_pricing_connection: Axe_Tier_PricingConnection;
  /** fetch data from the table: "axes_audit" */
  axes_audit_connection: Axes_AuditConnection;
  /** fetch data from the table: "axes" */
  axes_connection: AxesConnection;
  /** fetch data from the table: "create_users_queue" */
  create_users_queue_connection: Create_Users_QueueConnection;
  /** fetch data from the table: "enum_axe_buysell" */
  enum_axe_buysell_connection: Enum_Axe_BuysellConnection;
  /** fetch data from the table: "enum_axe_callput" */
  enum_axe_callput_connection: Enum_Axe_CallputConnection;
  /** fetch data from the table: "enum_axe_ccypair" */
  enum_axe_ccypair_connection: Enum_Axe_CcypairConnection;
  /** fetch data from the table: "enum_axe_depoccy1daycount" */
  enum_axe_depoccy1daycount_connection: Enum_Axe_Depoccy1daycountConnection;
  /** fetch data from the table: "enum_axe_depoccy2daycount" */
  enum_axe_depoccy2daycount_connection: Enum_Axe_Depoccy2daycountConnection;
  /** fetch data from the table: "enum_axe_hedgetype" */
  enum_axe_hedgetype_connection: Enum_Axe_HedgetypeConnection;
  /** fetch data from the table: "enum_axe_premiumtype" */
  enum_axe_premiumtype_connection: Enum_Axe_PremiumtypeConnection;
  /** fetch data from the table: "enum_axe_product" */
  enum_axe_product_connection: Enum_Axe_ProductConnection;
  /** fetch data from the table: "enum_axe_state" */
  enum_axe_state_connection: Enum_Axe_StateConnection;
  /** fetch data from the table: "enum_color_scheme" */
  enum_color_scheme_connection: Enum_Color_SchemeConnection;
  /** fetch data from the table: "enum_currency_notional" */
  enum_currency_notional_connection: Enum_Currency_NotionalConnection;
  /** fetch data from the table: "enum_currency_premium" */
  enum_currency_premium_connection: Enum_Currency_PremiumConnection;
  /** fetch data from the table: "enum_instrument_type" */
  enum_instrument_type_connection: Enum_Instrument_TypeConnection;
  /** fetch data from the table: "enum_notification_channel" */
  enum_notification_channel_connection: Enum_Notification_ChannelConnection;
  /** fetch data from the table: "enum_notification_type" */
  enum_notification_type_connection: Enum_Notification_TypeConnection;
  /** fetch data from the table: "enum_ptrd_callput" */
  enum_ptrd_callput_connection: Enum_Ptrd_CallputConnection;
  /** fetch data from the table: "enum_quote_side_type" */
  enum_quote_side_type_connection: Enum_Quote_Side_TypeConnection;
  /** fetch data from the table: "enum_rfq_option_reason" */
  enum_rfq_option_reason_connection: Enum_Rfq_Option_ReasonConnection;
  /** fetch data from the table: "enum_rfq_status" */
  enum_rfq_status_connection: Enum_Rfq_StatusConnection;
  /** fetch data from the table: "enum_tenor" */
  enum_tenor_connection: Enum_TenorConnection;
  /** fetch data from the table: "enum_tiers" */
  enum_tiers_connection: Enum_TiersConnection;
  /** fetch data from the table: "enum_users_axe_suspended_state" */
  enum_users_axe_suspended_state_connection: Enum_Users_Axe_Suspended_StateConnection;
  /** fetch data from the table: "expiry_cuts" */
  expiry_cuts_connection: Expiry_CutsConnection;
  /** fetch data from the table: "filters" */
  filters_connection: FiltersConnection;
  /** fetch data from the table: "funds" */
  funds_connection: FundsConnection;
  /** fetch data from the table: "give_up_requests" */
  give_up_requests_connection: Give_Up_RequestsConnection;
  /** fetch data from the table: "give_ups" */
  give_ups_connection: Give_UpsConnection;
  /** fetch data from the table: "give_ups_organizations" */
  give_ups_organizations_connection: Give_Ups_OrganizationsConnection;
  /** fetch data from the table: "instrumentReferenceData" */
  instrumentReferenceData_connection: InstrumentReferenceDataConnection;
  /** fetch data from the table: "mdpValidationInstruments" */
  mdpValidationInstruments_connection: MdpValidationInstrumentsConnection;
  node?: Maybe<Node>;
  /** An array relationship connection */
  notification_roles_connection: Notification_RolesConnection;
  /** fetch data from the table: "notifications" */
  notifications_connection: NotificationsConnection;
  /** fetch data from the table: "organization_main_tiering" */
  organization_main_tiering_connection: Organization_Main_TieringConnection;
  /** fetch data from the table: "organizations" */
  organizations_connection: OrganizationsConnection;
  /** fetch data from the table: "push_subscriptions" */
  push_subscriptions_connection: Push_SubscriptionsConnection;
  /** fetch data from the table: "reportSequenceIds" */
  reportSequenceIds_connection: ReportSequenceIdsConnection;
  /** fetch data from the table: "rfq" */
  rfq_connection: RfqConnection;
  /** An array relationship connection */
  rfq_legs_connection: Rfq_LegsConnection;
  /** An array relationship connection */
  rfq_options_connection: Rfq_OptionsConnection;
  /** An array relationship connection */
  rfq_options_legs_connection: Rfq_Options_LegsConnection;
  /** fetch data from the table: "rfq_public_queue" */
  rfq_public_queue_connection: Rfq_Public_QueueConnection;
  /** fetch data from the table: "sub_organizations" */
  sub_organizations_connection: Sub_OrganizationsConnection;
  /** fetch data from the table: "trade_confirms" */
  trade_confirms_connection: Trade_ConfirmsConnection;
  /** fetch data from the table: "trades" */
  trades_connection: TradesConnection;
  /** fetch data from the table: "trades_leg" */
  trades_leg_connection: Trades_LegConnection;
  /** fetch data from the table: "user_main_tiering" */
  user_main_tiering_connection: User_Main_TieringConnection;
  /** An array relationship connection */
  user_notifications_connection: User_NotificationsConnection;
  /** fetch data from the table: "user_tiers" */
  user_tiers_connection: User_TiersConnection;
  /** An array relationship connection */
  users_connection: UsersConnection;
};


export type Subscription_RootAxe_Authors_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axe_Authors_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Authors_Order_By>>;
  where?: InputMaybe<Axe_Authors_Bool_Exp>;
};


export type Subscription_RootAxe_Legs_Audit_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axe_Legs_Audit_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Legs_Audit_Order_By>>;
  where?: InputMaybe<Axe_Legs_Audit_Bool_Exp>;
};


export type Subscription_RootAxe_Legs_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axe_Legs_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Legs_Order_By>>;
  where?: InputMaybe<Axe_Legs_Bool_Exp>;
};


export type Subscription_RootAxe_Tier_Pricing_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axe_Tier_Pricing_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axe_Tier_Pricing_Order_By>>;
  where?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
};


export type Subscription_RootAxes_Audit_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axes_Audit_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Audit_Order_By>>;
  where?: InputMaybe<Axes_Audit_Bool_Exp>;
};


export type Subscription_RootAxes_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};


export type Subscription_RootCreate_Users_Queue_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Create_Users_Queue_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Create_Users_Queue_Order_By>>;
  where?: InputMaybe<Create_Users_Queue_Bool_Exp>;
};


export type Subscription_RootEnum_Axe_Buysell_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Buysell_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Buysell_Order_By>>;
  where?: InputMaybe<Enum_Axe_Buysell_Bool_Exp>;
};


export type Subscription_RootEnum_Axe_Callput_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Callput_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Callput_Order_By>>;
  where?: InputMaybe<Enum_Axe_Callput_Bool_Exp>;
};


export type Subscription_RootEnum_Axe_Ccypair_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Ccypair_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Ccypair_Order_By>>;
  where?: InputMaybe<Enum_Axe_Ccypair_Bool_Exp>;
};


export type Subscription_RootEnum_Axe_Depoccy1daycount_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Depoccy1daycount_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Depoccy1daycount_Order_By>>;
  where?: InputMaybe<Enum_Axe_Depoccy1daycount_Bool_Exp>;
};


export type Subscription_RootEnum_Axe_Depoccy2daycount_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Depoccy2daycount_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Depoccy2daycount_Order_By>>;
  where?: InputMaybe<Enum_Axe_Depoccy2daycount_Bool_Exp>;
};


export type Subscription_RootEnum_Axe_Hedgetype_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Hedgetype_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Hedgetype_Order_By>>;
  where?: InputMaybe<Enum_Axe_Hedgetype_Bool_Exp>;
};


export type Subscription_RootEnum_Axe_Premiumtype_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Premiumtype_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Premiumtype_Order_By>>;
  where?: InputMaybe<Enum_Axe_Premiumtype_Bool_Exp>;
};


export type Subscription_RootEnum_Axe_Product_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_Product_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_Product_Order_By>>;
  where?: InputMaybe<Enum_Axe_Product_Bool_Exp>;
};


export type Subscription_RootEnum_Axe_State_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Axe_State_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Axe_State_Order_By>>;
  where?: InputMaybe<Enum_Axe_State_Bool_Exp>;
};


export type Subscription_RootEnum_Color_Scheme_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Color_Scheme_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Color_Scheme_Order_By>>;
  where?: InputMaybe<Enum_Color_Scheme_Bool_Exp>;
};


export type Subscription_RootEnum_Currency_Notional_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Currency_Notional_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Currency_Notional_Order_By>>;
  where?: InputMaybe<Enum_Currency_Notional_Bool_Exp>;
};


export type Subscription_RootEnum_Currency_Premium_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Currency_Premium_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Currency_Premium_Order_By>>;
  where?: InputMaybe<Enum_Currency_Premium_Bool_Exp>;
};


export type Subscription_RootEnum_Instrument_Type_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Instrument_Type_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Instrument_Type_Order_By>>;
  where?: InputMaybe<Enum_Instrument_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Notification_Channel_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Notification_Channel_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Notification_Channel_Order_By>>;
  where?: InputMaybe<Enum_Notification_Channel_Bool_Exp>;
};


export type Subscription_RootEnum_Notification_Type_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Notification_Type_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Notification_Type_Order_By>>;
  where?: InputMaybe<Enum_Notification_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Ptrd_Callput_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Ptrd_Callput_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Ptrd_Callput_Order_By>>;
  where?: InputMaybe<Enum_Ptrd_Callput_Bool_Exp>;
};


export type Subscription_RootEnum_Quote_Side_Type_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Quote_Side_Type_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Quote_Side_Type_Order_By>>;
  where?: InputMaybe<Enum_Quote_Side_Type_Bool_Exp>;
};


export type Subscription_RootEnum_Rfq_Option_Reason_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Rfq_Option_Reason_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Rfq_Option_Reason_Order_By>>;
  where?: InputMaybe<Enum_Rfq_Option_Reason_Bool_Exp>;
};


export type Subscription_RootEnum_Rfq_Status_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Rfq_Status_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Rfq_Status_Order_By>>;
  where?: InputMaybe<Enum_Rfq_Status_Bool_Exp>;
};


export type Subscription_RootEnum_Tenor_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Tenor_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Tenor_Order_By>>;
  where?: InputMaybe<Enum_Tenor_Bool_Exp>;
};


export type Subscription_RootEnum_Tiers_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Tiers_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Tiers_Order_By>>;
  where?: InputMaybe<Enum_Tiers_Bool_Exp>;
};


export type Subscription_RootEnum_Users_Axe_Suspended_State_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Enum_Users_Axe_Suspended_State_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Enum_Users_Axe_Suspended_State_Order_By>>;
  where?: InputMaybe<Enum_Users_Axe_Suspended_State_Bool_Exp>;
};


export type Subscription_RootExpiry_Cuts_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Expiry_Cuts_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Expiry_Cuts_Order_By>>;
  where?: InputMaybe<Expiry_Cuts_Bool_Exp>;
};


export type Subscription_RootFilters_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Filters_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Filters_Order_By>>;
  where?: InputMaybe<Filters_Bool_Exp>;
};


export type Subscription_RootFunds_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Funds_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Funds_Order_By>>;
  where?: InputMaybe<Funds_Bool_Exp>;
};


export type Subscription_RootGive_Up_Requests_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Give_Up_Requests_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Give_Up_Requests_Order_By>>;
  where?: InputMaybe<Give_Up_Requests_Bool_Exp>;
};


export type Subscription_RootGive_Ups_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Give_Ups_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Give_Ups_Order_By>>;
  where?: InputMaybe<Give_Ups_Bool_Exp>;
};


export type Subscription_RootGive_Ups_Organizations_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Give_Ups_Organizations_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Give_Ups_Organizations_Order_By>>;
  where?: InputMaybe<Give_Ups_Organizations_Bool_Exp>;
};


export type Subscription_RootInstrumentReferenceData_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<InstrumentReferenceData_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InstrumentReferenceData_Order_By>>;
  where?: InputMaybe<InstrumentReferenceData_Bool_Exp>;
};


export type Subscription_RootMdpValidationInstruments_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<MdpValidationInstruments_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<MdpValidationInstruments_Order_By>>;
  where?: InputMaybe<MdpValidationInstruments_Bool_Exp>;
};


export type Subscription_RootNodeArgs = {
  id: Scalars['ID']['input'];
};


export type Subscription_RootNotification_Roles_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Notification_Roles_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Roles_Order_By>>;
  where?: InputMaybe<Notification_Roles_Bool_Exp>;
};


export type Subscription_RootNotifications_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootOrganization_Main_Tiering_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Organization_Main_Tiering_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Main_Tiering_Order_By>>;
  where?: InputMaybe<Organization_Main_Tiering_Bool_Exp>;
};


export type Subscription_RootOrganizations_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};


export type Subscription_RootPush_Subscriptions_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Push_Subscriptions_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Push_Subscriptions_Order_By>>;
  where?: InputMaybe<Push_Subscriptions_Bool_Exp>;
};


export type Subscription_RootReportSequenceIds_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<ReportSequenceIds_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ReportSequenceIds_Order_By>>;
  where?: InputMaybe<ReportSequenceIds_Bool_Exp>;
};


export type Subscription_RootRfq_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Rfq_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Order_By>>;
  where?: InputMaybe<Rfq_Bool_Exp>;
};


export type Subscription_RootRfq_Legs_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Rfq_Legs_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Legs_Order_By>>;
  where?: InputMaybe<Rfq_Legs_Bool_Exp>;
};


export type Subscription_RootRfq_Options_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Rfq_Options_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Options_Order_By>>;
  where?: InputMaybe<Rfq_Options_Bool_Exp>;
};


export type Subscription_RootRfq_Options_Legs_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Rfq_Options_Legs_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Options_Legs_Order_By>>;
  where?: InputMaybe<Rfq_Options_Legs_Bool_Exp>;
};


export type Subscription_RootRfq_Public_Queue_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Rfq_Public_Queue_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Rfq_Public_Queue_Order_By>>;
  where?: InputMaybe<Rfq_Public_Queue_Bool_Exp>;
};


export type Subscription_RootSub_Organizations_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Sub_Organizations_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Sub_Organizations_Order_By>>;
  where?: InputMaybe<Sub_Organizations_Bool_Exp>;
};


export type Subscription_RootTrade_Confirms_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Trade_Confirms_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trade_Confirms_Order_By>>;
  where?: InputMaybe<Trade_Confirms_Bool_Exp>;
};


export type Subscription_RootTrades_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Trades_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trades_Order_By>>;
  where?: InputMaybe<Trades_Bool_Exp>;
};


export type Subscription_RootTrades_Leg_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Trades_Leg_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trades_Leg_Order_By>>;
  where?: InputMaybe<Trades_Leg_Bool_Exp>;
};


export type Subscription_RootUser_Main_Tiering_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<User_Main_Tiering_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Main_Tiering_Order_By>>;
  where?: InputMaybe<User_Main_Tiering_Bool_Exp>;
};


export type Subscription_RootUser_Notifications_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};


export type Subscription_RootUser_Tiers_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<User_Tiers_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Tiers_Order_By>>;
  where?: InputMaybe<User_Tiers_Bool_Exp>;
};


export type Subscription_RootUsers_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "trade_confirms" */
export type Trade_Confirms = Node & {
  __typename?: 'trade_confirms';
  createdAt: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['String']['output'];
  role: Scalars['String']['output'];
  /** An object relationship */
  subOrganization?: Maybe<Sub_Organizations>;
  subOrganizationId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};

/** A Relay connection object on "trade_confirms" */
export type Trade_ConfirmsConnection = {
  __typename?: 'trade_confirmsConnection';
  edges: Array<Trade_ConfirmsEdge>;
  pageInfo: PageInfo;
};

export type Trade_ConfirmsEdge = {
  __typename?: 'trade_confirmsEdge';
  cursor: Scalars['String']['output'];
  node: Trade_Confirms;
};

/** Boolean expression to filter rows from the table "trade_confirms". All fields are combined with a logical 'AND'. */
export type Trade_Confirms_Bool_Exp = {
  _and?: InputMaybe<Array<Trade_Confirms_Bool_Exp>>;
  _not?: InputMaybe<Trade_Confirms_Bool_Exp>;
  _or?: InputMaybe<Array<Trade_Confirms_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  subOrganization?: InputMaybe<Sub_Organizations_Bool_Exp>;
  subOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "trade_confirms" */
export enum Trade_Confirms_Constraint {
  /** unique or primary key constraint on columns "email" */
  TradeConfirmsEmailKey = 'trade_confirms_email_key',
  /** unique or primary key constraint on columns "id" */
  TradeConfirmsPkey = 'trade_confirms_pkey'
}

/** input type for inserting data into table "trade_confirms" */
export type Trade_Confirms_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  subOrganization?: InputMaybe<Sub_Organizations_Obj_Rel_Insert_Input>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "trade_confirms" */
export type Trade_Confirms_Mutation_Response = {
  __typename?: 'trade_confirms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Trade_Confirms>;
};

/** on_conflict condition type for table "trade_confirms" */
export type Trade_Confirms_On_Conflict = {
  constraint: Trade_Confirms_Constraint;
  update_columns?: Array<Trade_Confirms_Update_Column>;
  where?: InputMaybe<Trade_Confirms_Bool_Exp>;
};

/** Ordering options when selecting data from "trade_confirms". */
export type Trade_Confirms_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  subOrganization?: InputMaybe<Sub_Organizations_Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trade_confirms */
export type Trade_Confirms_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "trade_confirms" */
export enum Trade_Confirms_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Role = 'role',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "trade_confirms" */
export type Trade_Confirms_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "trade_confirms" */
export enum Trade_Confirms_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Role = 'role',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  UserId = 'userId'
}

export type Trade_Confirms_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trade_Confirms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trade_Confirms_Bool_Exp;
};

/** columns and relationships of "traded_hedges" */
export type Traded_Hedges = {
  __typename?: 'traded_hedges';
  ccy1?: Maybe<Scalars['String']['output']>;
  ccy2?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  deliveryDate?: Maybe<Scalars['date']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  notionalAmountInEUR?: Maybe<Scalars['bigint']['output']>;
  notionalCcy1?: Maybe<Scalars['bigint']['output']>;
  notionalCcy2?: Maybe<Scalars['bigint']['output']>;
  rate?: Maybe<Scalars['float8']['output']>;
  /** An object relationship */
  trade: Trades;
  tradeId: Scalars['uuid']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};

/** aggregated selection of "traded_hedges" */
export type Traded_Hedges_Aggregate = {
  __typename?: 'traded_hedges_aggregate';
  aggregate?: Maybe<Traded_Hedges_Aggregate_Fields>;
  nodes: Array<Traded_Hedges>;
};

export type Traded_Hedges_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Traded_Hedges_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Traded_Hedges_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Traded_Hedges_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Traded_Hedges_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Traded_Hedges_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Traded_Hedges_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Traded_Hedges_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Traded_Hedges_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Traded_Hedges_Aggregate_Bool_Exp_Var_Samp>;
};

export type Traded_Hedges_Aggregate_Bool_Exp_Avg = {
  arguments: Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Traded_Hedges_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Traded_Hedges_Aggregate_Bool_Exp_Corr = {
  arguments: Traded_Hedges_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Traded_Hedges_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Traded_Hedges_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Traded_Hedges_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Traded_Hedges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Traded_Hedges_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Traded_Hedges_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Traded_Hedges_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Traded_Hedges_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Traded_Hedges_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Traded_Hedges_Aggregate_Bool_Exp_Max = {
  arguments: Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Traded_Hedges_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Traded_Hedges_Aggregate_Bool_Exp_Min = {
  arguments: Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Traded_Hedges_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Traded_Hedges_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Traded_Hedges_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Traded_Hedges_Aggregate_Bool_Exp_Sum = {
  arguments: Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Traded_Hedges_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Traded_Hedges_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Traded_Hedges_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "traded_hedges" */
export type Traded_Hedges_Aggregate_Fields = {
  __typename?: 'traded_hedges_aggregate_fields';
  avg?: Maybe<Traded_Hedges_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Traded_Hedges_Max_Fields>;
  min?: Maybe<Traded_Hedges_Min_Fields>;
  stddev?: Maybe<Traded_Hedges_Stddev_Fields>;
  stddev_pop?: Maybe<Traded_Hedges_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Traded_Hedges_Stddev_Samp_Fields>;
  sum?: Maybe<Traded_Hedges_Sum_Fields>;
  var_pop?: Maybe<Traded_Hedges_Var_Pop_Fields>;
  var_samp?: Maybe<Traded_Hedges_Var_Samp_Fields>;
  variance?: Maybe<Traded_Hedges_Variance_Fields>;
};


/** aggregate fields of "traded_hedges" */
export type Traded_Hedges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Traded_Hedges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "traded_hedges" */
export type Traded_Hedges_Aggregate_Order_By = {
  avg?: InputMaybe<Traded_Hedges_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Traded_Hedges_Max_Order_By>;
  min?: InputMaybe<Traded_Hedges_Min_Order_By>;
  stddev?: InputMaybe<Traded_Hedges_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Traded_Hedges_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Traded_Hedges_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Traded_Hedges_Sum_Order_By>;
  var_pop?: InputMaybe<Traded_Hedges_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Traded_Hedges_Var_Samp_Order_By>;
  variance?: InputMaybe<Traded_Hedges_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "traded_hedges" */
export type Traded_Hedges_Arr_Rel_Insert_Input = {
  data: Array<Traded_Hedges_Insert_Input>;
};

/** aggregate avg on columns */
export type Traded_Hedges_Avg_Fields = {
  __typename?: 'traded_hedges_avg_fields';
  notionalAmountInEUR?: Maybe<Scalars['Float']['output']>;
  notionalCcy1?: Maybe<Scalars['Float']['output']>;
  notionalCcy2?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "traded_hedges" */
export type Traded_Hedges_Avg_Order_By = {
  notionalAmountInEUR?: InputMaybe<Order_By>;
  notionalCcy1?: InputMaybe<Order_By>;
  notionalCcy2?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "traded_hedges". All fields are combined with a logical 'AND'. */
export type Traded_Hedges_Bool_Exp = {
  _and?: InputMaybe<Array<Traded_Hedges_Bool_Exp>>;
  _not?: InputMaybe<Traded_Hedges_Bool_Exp>;
  _or?: InputMaybe<Array<Traded_Hedges_Bool_Exp>>;
  ccy1?: InputMaybe<String_Comparison_Exp>;
  ccy2?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  deliveryDate?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isin?: InputMaybe<String_Comparison_Exp>;
  notionalAmountInEUR?: InputMaybe<Bigint_Comparison_Exp>;
  notionalCcy1?: InputMaybe<Bigint_Comparison_Exp>;
  notionalCcy2?: InputMaybe<Bigint_Comparison_Exp>;
  rate?: InputMaybe<Float8_Comparison_Exp>;
  trade?: InputMaybe<Trades_Bool_Exp>;
  tradeId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "traded_hedges" */
export type Traded_Hedges_Inc_Input = {
  notionalAmountInEUR?: InputMaybe<Scalars['bigint']['input']>;
  notionalCcy1?: InputMaybe<Scalars['bigint']['input']>;
  notionalCcy2?: InputMaybe<Scalars['bigint']['input']>;
  rate?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "traded_hedges" */
export type Traded_Hedges_Insert_Input = {
  ccy1?: InputMaybe<Scalars['String']['input']>;
  ccy2?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deliveryDate?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isin?: InputMaybe<Scalars['String']['input']>;
  notionalAmountInEUR?: InputMaybe<Scalars['bigint']['input']>;
  notionalCcy1?: InputMaybe<Scalars['bigint']['input']>;
  notionalCcy2?: InputMaybe<Scalars['bigint']['input']>;
  rate?: InputMaybe<Scalars['float8']['input']>;
  trade?: InputMaybe<Trades_Obj_Rel_Insert_Input>;
  tradeId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Traded_Hedges_Max_Fields = {
  __typename?: 'traded_hedges_max_fields';
  ccy1?: Maybe<Scalars['String']['output']>;
  ccy2?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deliveryDate?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  notionalAmountInEUR?: Maybe<Scalars['bigint']['output']>;
  notionalCcy1?: Maybe<Scalars['bigint']['output']>;
  notionalCcy2?: Maybe<Scalars['bigint']['output']>;
  rate?: Maybe<Scalars['float8']['output']>;
  tradeId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "traded_hedges" */
export type Traded_Hedges_Max_Order_By = {
  ccy1?: InputMaybe<Order_By>;
  ccy2?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isin?: InputMaybe<Order_By>;
  notionalAmountInEUR?: InputMaybe<Order_By>;
  notionalCcy1?: InputMaybe<Order_By>;
  notionalCcy2?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  tradeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Traded_Hedges_Min_Fields = {
  __typename?: 'traded_hedges_min_fields';
  ccy1?: Maybe<Scalars['String']['output']>;
  ccy2?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  deliveryDate?: Maybe<Scalars['date']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  isin?: Maybe<Scalars['String']['output']>;
  notionalAmountInEUR?: Maybe<Scalars['bigint']['output']>;
  notionalCcy1?: Maybe<Scalars['bigint']['output']>;
  notionalCcy2?: Maybe<Scalars['bigint']['output']>;
  rate?: Maybe<Scalars['float8']['output']>;
  tradeId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "traded_hedges" */
export type Traded_Hedges_Min_Order_By = {
  ccy1?: InputMaybe<Order_By>;
  ccy2?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isin?: InputMaybe<Order_By>;
  notionalAmountInEUR?: InputMaybe<Order_By>;
  notionalCcy1?: InputMaybe<Order_By>;
  notionalCcy2?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  tradeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "traded_hedges" */
export type Traded_Hedges_Mutation_Response = {
  __typename?: 'traded_hedges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Traded_Hedges>;
};

/** Ordering options when selecting data from "traded_hedges". */
export type Traded_Hedges_Order_By = {
  ccy1?: InputMaybe<Order_By>;
  ccy2?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isin?: InputMaybe<Order_By>;
  notionalAmountInEUR?: InputMaybe<Order_By>;
  notionalCcy1?: InputMaybe<Order_By>;
  notionalCcy2?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
  trade?: InputMaybe<Trades_Order_By>;
  tradeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** select columns of table "traded_hedges" */
export enum Traded_Hedges_Select_Column {
  /** column name */
  Ccy1 = 'ccy1',
  /** column name */
  Ccy2 = 'ccy2',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  Id = 'id',
  /** column name */
  Isin = 'isin',
  /** column name */
  NotionalAmountInEur = 'notionalAmountInEUR',
  /** column name */
  NotionalCcy1 = 'notionalCcy1',
  /** column name */
  NotionalCcy2 = 'notionalCcy2',
  /** column name */
  Rate = 'rate',
  /** column name */
  TradeId = 'tradeId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "traded_hedges_aggregate_bool_exp_avg_arguments_columns" columns of table "traded_hedges" */
export enum Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Rate = 'rate'
}

/** select "traded_hedges_aggregate_bool_exp_corr_arguments_columns" columns of table "traded_hedges" */
export enum Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Rate = 'rate'
}

/** select "traded_hedges_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "traded_hedges" */
export enum Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Rate = 'rate'
}

/** select "traded_hedges_aggregate_bool_exp_max_arguments_columns" columns of table "traded_hedges" */
export enum Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Rate = 'rate'
}

/** select "traded_hedges_aggregate_bool_exp_min_arguments_columns" columns of table "traded_hedges" */
export enum Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Rate = 'rate'
}

/** select "traded_hedges_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "traded_hedges" */
export enum Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Rate = 'rate'
}

/** select "traded_hedges_aggregate_bool_exp_sum_arguments_columns" columns of table "traded_hedges" */
export enum Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Rate = 'rate'
}

/** select "traded_hedges_aggregate_bool_exp_var_samp_arguments_columns" columns of table "traded_hedges" */
export enum Traded_Hedges_Select_Column_Traded_Hedges_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Rate = 'rate'
}

/** input type for updating data in table "traded_hedges" */
export type Traded_Hedges_Set_Input = {
  ccy1?: InputMaybe<Scalars['String']['input']>;
  ccy2?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  deliveryDate?: InputMaybe<Scalars['date']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isin?: InputMaybe<Scalars['String']['input']>;
  notionalAmountInEUR?: InputMaybe<Scalars['bigint']['input']>;
  notionalCcy1?: InputMaybe<Scalars['bigint']['input']>;
  notionalCcy2?: InputMaybe<Scalars['bigint']['input']>;
  rate?: InputMaybe<Scalars['float8']['input']>;
  tradeId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Traded_Hedges_Stddev_Fields = {
  __typename?: 'traded_hedges_stddev_fields';
  notionalAmountInEUR?: Maybe<Scalars['Float']['output']>;
  notionalCcy1?: Maybe<Scalars['Float']['output']>;
  notionalCcy2?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "traded_hedges" */
export type Traded_Hedges_Stddev_Order_By = {
  notionalAmountInEUR?: InputMaybe<Order_By>;
  notionalCcy1?: InputMaybe<Order_By>;
  notionalCcy2?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Traded_Hedges_Stddev_Pop_Fields = {
  __typename?: 'traded_hedges_stddev_pop_fields';
  notionalAmountInEUR?: Maybe<Scalars['Float']['output']>;
  notionalCcy1?: Maybe<Scalars['Float']['output']>;
  notionalCcy2?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "traded_hedges" */
export type Traded_Hedges_Stddev_Pop_Order_By = {
  notionalAmountInEUR?: InputMaybe<Order_By>;
  notionalCcy1?: InputMaybe<Order_By>;
  notionalCcy2?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Traded_Hedges_Stddev_Samp_Fields = {
  __typename?: 'traded_hedges_stddev_samp_fields';
  notionalAmountInEUR?: Maybe<Scalars['Float']['output']>;
  notionalCcy1?: Maybe<Scalars['Float']['output']>;
  notionalCcy2?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "traded_hedges" */
export type Traded_Hedges_Stddev_Samp_Order_By = {
  notionalAmountInEUR?: InputMaybe<Order_By>;
  notionalCcy1?: InputMaybe<Order_By>;
  notionalCcy2?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Traded_Hedges_Sum_Fields = {
  __typename?: 'traded_hedges_sum_fields';
  notionalAmountInEUR?: Maybe<Scalars['bigint']['output']>;
  notionalCcy1?: Maybe<Scalars['bigint']['output']>;
  notionalCcy2?: Maybe<Scalars['bigint']['output']>;
  rate?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "traded_hedges" */
export type Traded_Hedges_Sum_Order_By = {
  notionalAmountInEUR?: InputMaybe<Order_By>;
  notionalCcy1?: InputMaybe<Order_By>;
  notionalCcy2?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
};

export type Traded_Hedges_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Traded_Hedges_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Traded_Hedges_Set_Input>;
  /** filter the rows which have to be updated */
  where: Traded_Hedges_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Traded_Hedges_Var_Pop_Fields = {
  __typename?: 'traded_hedges_var_pop_fields';
  notionalAmountInEUR?: Maybe<Scalars['Float']['output']>;
  notionalCcy1?: Maybe<Scalars['Float']['output']>;
  notionalCcy2?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "traded_hedges" */
export type Traded_Hedges_Var_Pop_Order_By = {
  notionalAmountInEUR?: InputMaybe<Order_By>;
  notionalCcy1?: InputMaybe<Order_By>;
  notionalCcy2?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Traded_Hedges_Var_Samp_Fields = {
  __typename?: 'traded_hedges_var_samp_fields';
  notionalAmountInEUR?: Maybe<Scalars['Float']['output']>;
  notionalCcy1?: Maybe<Scalars['Float']['output']>;
  notionalCcy2?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "traded_hedges" */
export type Traded_Hedges_Var_Samp_Order_By = {
  notionalAmountInEUR?: InputMaybe<Order_By>;
  notionalCcy1?: InputMaybe<Order_By>;
  notionalCcy2?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Traded_Hedges_Variance_Fields = {
  __typename?: 'traded_hedges_variance_fields';
  notionalAmountInEUR?: Maybe<Scalars['Float']['output']>;
  notionalCcy1?: Maybe<Scalars['Float']['output']>;
  notionalCcy2?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "traded_hedges" */
export type Traded_Hedges_Variance_Order_By = {
  notionalAmountInEUR?: InputMaybe<Order_By>;
  notionalCcy1?: InputMaybe<Order_By>;
  notionalCcy2?: InputMaybe<Order_By>;
  rate?: InputMaybe<Order_By>;
};

/** columns and relationships of "trades" */
export type Trades = Node & {
  __typename?: 'trades';
  axeCustomerUserId: Scalars['String']['output'];
  axeId: Scalars['uuid']['output'];
  /** An array relationship */
  axeOwner: Array<Users>;
  axeOwnerUserId: Scalars['String']['output'];
  /** An aggregate relationship */
  axeOwner_aggregate: Users_Aggregate;
  /** An array relationship connection */
  axeOwner_connection: UsersConnection;
  channel?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['timestamptz']['output'];
  customerSubOrganizationId?: Maybe<Scalars['uuid']['output']>;
  deltaHedge?: Maybe<Scalars['bigint']['output']>;
  fixQuoteId?: Maybe<Scalars['String']['output']>;
  fundId?: Maybe<Scalars['uuid']['output']>;
  giveupId?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['ID']['output'];
  notional: Scalars['bigint']['output'];
  notionalAmountInEUR?: Maybe<Scalars['bigint']['output']>;
  notionalAmountInUSD?: Maybe<Scalars['bigint']['output']>;
  ownerOrganizationId?: Maybe<Scalars['String']['output']>;
  ownerSubOrganizationId?: Maybe<Scalars['uuid']['output']>;
  premiumType?: Maybe<Enum_Axe_Premiumtype_Enum>;
  rfqId: Scalars['uuid']['output'];
  side?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  traded_hedges: Array<Traded_Hedges>;
  /** An aggregate relationship */
  traded_hedges_aggregate: Traded_Hedges_Aggregate;
  /** An array relationship */
  trades_legs: Array<Trades_Leg>;
  /** An aggregate relationship */
  trades_legs_aggregate: Trades_Leg_Aggregate;
  /** An array relationship connection */
  trades_legs_connection: Trades_LegConnection;
  updatedAt: Scalars['timestamptz']['output'];
};


/** columns and relationships of "trades" */
export type TradesAxeOwnerArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "trades" */
export type TradesAxeOwner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "trades" */
export type TradesAxeOwner_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** columns and relationships of "trades" */
export type TradesTraded_HedgesArgs = {
  distinct_on?: InputMaybe<Array<Traded_Hedges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Traded_Hedges_Order_By>>;
  where?: InputMaybe<Traded_Hedges_Bool_Exp>;
};


/** columns and relationships of "trades" */
export type TradesTraded_Hedges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Traded_Hedges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Traded_Hedges_Order_By>>;
  where?: InputMaybe<Traded_Hedges_Bool_Exp>;
};


/** columns and relationships of "trades" */
export type TradesTrades_LegsArgs = {
  distinct_on?: InputMaybe<Array<Trades_Leg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trades_Leg_Order_By>>;
  where?: InputMaybe<Trades_Leg_Bool_Exp>;
};


/** columns and relationships of "trades" */
export type TradesTrades_Legs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trades_Leg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trades_Leg_Order_By>>;
  where?: InputMaybe<Trades_Leg_Bool_Exp>;
};


/** columns and relationships of "trades" */
export type TradesTrades_Legs_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Trades_Leg_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Trades_Leg_Order_By>>;
  where?: InputMaybe<Trades_Leg_Bool_Exp>;
};

/** A Relay connection object on "trades" */
export type TradesConnection = {
  __typename?: 'tradesConnection';
  edges: Array<TradesEdge>;
  pageInfo: PageInfo;
};

export type TradesEdge = {
  __typename?: 'tradesEdge';
  cursor: Scalars['String']['output'];
  node: Trades;
};

/** Boolean expression to filter rows from the table "trades". All fields are combined with a logical 'AND'. */
export type Trades_Bool_Exp = {
  _and?: InputMaybe<Array<Trades_Bool_Exp>>;
  _not?: InputMaybe<Trades_Bool_Exp>;
  _or?: InputMaybe<Array<Trades_Bool_Exp>>;
  axeCustomerUserId?: InputMaybe<String_Comparison_Exp>;
  axeId?: InputMaybe<Uuid_Comparison_Exp>;
  axeOwner?: InputMaybe<Users_Bool_Exp>;
  axeOwnerUserId?: InputMaybe<String_Comparison_Exp>;
  axeOwner_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  channel?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  customerSubOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
  deltaHedge?: InputMaybe<Bigint_Comparison_Exp>;
  fixQuoteId?: InputMaybe<String_Comparison_Exp>;
  fundId?: InputMaybe<Uuid_Comparison_Exp>;
  giveupId?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notional?: InputMaybe<Bigint_Comparison_Exp>;
  notionalAmountInEUR?: InputMaybe<Bigint_Comparison_Exp>;
  notionalAmountInUSD?: InputMaybe<Bigint_Comparison_Exp>;
  ownerOrganizationId?: InputMaybe<String_Comparison_Exp>;
  ownerSubOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
  premiumType?: InputMaybe<Enum_Axe_Premiumtype_Enum_Comparison_Exp>;
  rfqId?: InputMaybe<Uuid_Comparison_Exp>;
  side?: InputMaybe<String_Comparison_Exp>;
  traded_hedges?: InputMaybe<Traded_Hedges_Bool_Exp>;
  traded_hedges_aggregate?: InputMaybe<Traded_Hedges_Aggregate_Bool_Exp>;
  trades_legs?: InputMaybe<Trades_Leg_Bool_Exp>;
  trades_legs_aggregate?: InputMaybe<Trades_Leg_Aggregate_Bool_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "trades" */
export enum Trades_Constraint {
  /** unique or primary key constraint on columns "id" */
  TradesPkey = 'trades_pkey'
}

/** input type for incrementing numeric columns in table "trades" */
export type Trades_Inc_Input = {
  deltaHedge?: InputMaybe<Scalars['bigint']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  notionalAmountInEUR?: InputMaybe<Scalars['bigint']['input']>;
  notionalAmountInUSD?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "trades" */
export type Trades_Insert_Input = {
  axeCustomerUserId?: InputMaybe<Scalars['String']['input']>;
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  axeOwner?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  axeOwnerUserId?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  customerSubOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  deltaHedge?: InputMaybe<Scalars['bigint']['input']>;
  fixQuoteId?: InputMaybe<Scalars['String']['input']>;
  fundId?: InputMaybe<Scalars['uuid']['input']>;
  giveupId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  notionalAmountInEUR?: InputMaybe<Scalars['bigint']['input']>;
  notionalAmountInUSD?: InputMaybe<Scalars['bigint']['input']>;
  ownerOrganizationId?: InputMaybe<Scalars['String']['input']>;
  ownerSubOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  premiumType?: InputMaybe<Enum_Axe_Premiumtype_Enum>;
  rfqId?: InputMaybe<Scalars['uuid']['input']>;
  side?: InputMaybe<Scalars['String']['input']>;
  traded_hedges?: InputMaybe<Traded_Hedges_Arr_Rel_Insert_Input>;
  trades_legs?: InputMaybe<Trades_Leg_Arr_Rel_Insert_Input>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** columns and relationships of "trades_leg" */
export type Trades_Leg = Node & {
  __typename?: 'trades_leg';
  buySell?: Maybe<Enum_Axe_Buysell_Enum>;
  callPut?: Maybe<Enum_Axe_Callput_Enum>;
  ccyPair: Enum_Axe_Ccypair_Enum;
  createdAt: Scalars['timestamptz']['output'];
  cut?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['date']['output']>;
  delta?: Maybe<Scalars['bigint']['output']>;
  /** An object relationship */
  expiryCut?: Maybe<Expiry_Cuts>;
  expiryDate?: Maybe<Scalars['date']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  hedgeType?: Maybe<Enum_Axe_Hedgetype_Enum>;
  id: Scalars['ID']['output'];
  notional?: Maybe<Scalars['bigint']['output']>;
  notionalCurrency?: Maybe<Enum_Currency_Notional_Enum>;
  orderIndex?: Maybe<Scalars['Int']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  premiumCurrency?: Maybe<Enum_Currency_Premium_Enum>;
  premiumDate?: Maybe<Scalars['date']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  product?: Maybe<Enum_Axe_Product_Enum>;
  spot?: Maybe<Scalars['float8']['output']>;
  spotDate?: Maybe<Scalars['date']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  /** An object relationship */
  trade: Trades;
  tradeId: Scalars['uuid']['output'];
  updatedAt: Scalars['timestamptz']['output'];
};

/** A Relay connection object on "trades_leg" */
export type Trades_LegConnection = {
  __typename?: 'trades_legConnection';
  edges: Array<Trades_LegEdge>;
  pageInfo: PageInfo;
};

export type Trades_LegEdge = {
  __typename?: 'trades_legEdge';
  cursor: Scalars['String']['output'];
  node: Trades_Leg;
};

/** aggregated selection of "trades_leg" */
export type Trades_Leg_Aggregate = {
  __typename?: 'trades_leg_aggregate';
  aggregate?: Maybe<Trades_Leg_Aggregate_Fields>;
  nodes: Array<Trades_Leg>;
};

export type Trades_Leg_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Trades_Leg_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Trades_Leg_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Trades_Leg_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Trades_Leg_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Trades_Leg_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Trades_Leg_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Trades_Leg_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Trades_Leg_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Trades_Leg_Aggregate_Bool_Exp_Var_Samp>;
};

export type Trades_Leg_Aggregate_Bool_Exp_Avg = {
  arguments: Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Trades_Leg_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Trades_Leg_Aggregate_Bool_Exp_Corr = {
  arguments: Trades_Leg_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Trades_Leg_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Trades_Leg_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Trades_Leg_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Trades_Leg_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Trades_Leg_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Trades_Leg_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Trades_Leg_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Trades_Leg_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Trades_Leg_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Trades_Leg_Aggregate_Bool_Exp_Max = {
  arguments: Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Trades_Leg_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Trades_Leg_Aggregate_Bool_Exp_Min = {
  arguments: Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Trades_Leg_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Trades_Leg_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Trades_Leg_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Trades_Leg_Aggregate_Bool_Exp_Sum = {
  arguments: Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Trades_Leg_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Trades_Leg_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Trades_Leg_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "trades_leg" */
export type Trades_Leg_Aggregate_Fields = {
  __typename?: 'trades_leg_aggregate_fields';
  avg?: Maybe<Trades_Leg_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Trades_Leg_Max_Fields>;
  min?: Maybe<Trades_Leg_Min_Fields>;
  stddev?: Maybe<Trades_Leg_Stddev_Fields>;
  stddev_pop?: Maybe<Trades_Leg_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Trades_Leg_Stddev_Samp_Fields>;
  sum?: Maybe<Trades_Leg_Sum_Fields>;
  var_pop?: Maybe<Trades_Leg_Var_Pop_Fields>;
  var_samp?: Maybe<Trades_Leg_Var_Samp_Fields>;
  variance?: Maybe<Trades_Leg_Variance_Fields>;
};


/** aggregate fields of "trades_leg" */
export type Trades_Leg_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trades_Leg_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "trades_leg" */
export type Trades_Leg_Aggregate_Order_By = {
  avg?: InputMaybe<Trades_Leg_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Trades_Leg_Max_Order_By>;
  min?: InputMaybe<Trades_Leg_Min_Order_By>;
  stddev?: InputMaybe<Trades_Leg_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Trades_Leg_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Trades_Leg_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Trades_Leg_Sum_Order_By>;
  var_pop?: InputMaybe<Trades_Leg_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Trades_Leg_Var_Samp_Order_By>;
  variance?: InputMaybe<Trades_Leg_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "trades_leg" */
export type Trades_Leg_Arr_Rel_Insert_Input = {
  data: Array<Trades_Leg_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Trades_Leg_On_Conflict>;
};

/** aggregate avg on columns */
export type Trades_Leg_Avg_Fields = {
  __typename?: 'trades_leg_avg_fields';
  delta?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "trades_leg" */
export type Trades_Leg_Avg_Order_By = {
  delta?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "trades_leg". All fields are combined with a logical 'AND'. */
export type Trades_Leg_Bool_Exp = {
  _and?: InputMaybe<Array<Trades_Leg_Bool_Exp>>;
  _not?: InputMaybe<Trades_Leg_Bool_Exp>;
  _or?: InputMaybe<Array<Trades_Leg_Bool_Exp>>;
  buySell?: InputMaybe<Enum_Axe_Buysell_Enum_Comparison_Exp>;
  callPut?: InputMaybe<Enum_Axe_Callput_Enum_Comparison_Exp>;
  ccyPair?: InputMaybe<Enum_Axe_Ccypair_Enum_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  cut?: InputMaybe<String_Comparison_Exp>;
  deliveryDate?: InputMaybe<Date_Comparison_Exp>;
  delta?: InputMaybe<Bigint_Comparison_Exp>;
  expiryCut?: InputMaybe<Expiry_Cuts_Bool_Exp>;
  expiryDate?: InputMaybe<Date_Comparison_Exp>;
  forward?: InputMaybe<Float8_Comparison_Exp>;
  hedgeType?: InputMaybe<Enum_Axe_Hedgetype_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notional?: InputMaybe<Bigint_Comparison_Exp>;
  notionalCurrency?: InputMaybe<Enum_Currency_Notional_Enum_Comparison_Exp>;
  orderIndex?: InputMaybe<Int_Comparison_Exp>;
  premium?: InputMaybe<Int_Comparison_Exp>;
  premiumCurrency?: InputMaybe<Enum_Currency_Premium_Enum_Comparison_Exp>;
  premiumDate?: InputMaybe<Date_Comparison_Exp>;
  pricingVolatility?: InputMaybe<Float8_Comparison_Exp>;
  product?: InputMaybe<Enum_Axe_Product_Enum_Comparison_Exp>;
  spot?: InputMaybe<Float8_Comparison_Exp>;
  spotDate?: InputMaybe<Date_Comparison_Exp>;
  strike?: InputMaybe<Float8_Comparison_Exp>;
  trade?: InputMaybe<Trades_Bool_Exp>;
  tradeId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "trades_leg" */
export enum Trades_Leg_Constraint {
  /** unique or primary key constraint on columns "id" */
  TradesLegPkey = 'trades_leg_pkey'
}

/** input type for incrementing numeric columns in table "trades_leg" */
export type Trades_Leg_Inc_Input = {
  delta?: InputMaybe<Scalars['bigint']['input']>;
  forward?: InputMaybe<Scalars['float8']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  orderIndex?: InputMaybe<Scalars['Int']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
  spot?: InputMaybe<Scalars['float8']['input']>;
  strike?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "trades_leg" */
export type Trades_Leg_Insert_Input = {
  buySell?: InputMaybe<Enum_Axe_Buysell_Enum>;
  callPut?: InputMaybe<Enum_Axe_Callput_Enum>;
  ccyPair?: InputMaybe<Enum_Axe_Ccypair_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  cut?: InputMaybe<Scalars['String']['input']>;
  deliveryDate?: InputMaybe<Scalars['date']['input']>;
  delta?: InputMaybe<Scalars['bigint']['input']>;
  expiryCut?: InputMaybe<Expiry_Cuts_Obj_Rel_Insert_Input>;
  expiryDate?: InputMaybe<Scalars['date']['input']>;
  forward?: InputMaybe<Scalars['float8']['input']>;
  hedgeType?: InputMaybe<Enum_Axe_Hedgetype_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  notionalCurrency?: InputMaybe<Enum_Currency_Notional_Enum>;
  orderIndex?: InputMaybe<Scalars['Int']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  premiumCurrency?: InputMaybe<Enum_Currency_Premium_Enum>;
  premiumDate?: InputMaybe<Scalars['date']['input']>;
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
  product?: InputMaybe<Enum_Axe_Product_Enum>;
  spot?: InputMaybe<Scalars['float8']['input']>;
  spotDate?: InputMaybe<Scalars['date']['input']>;
  strike?: InputMaybe<Scalars['float8']['input']>;
  trade?: InputMaybe<Trades_Obj_Rel_Insert_Input>;
  tradeId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Trades_Leg_Max_Fields = {
  __typename?: 'trades_leg_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  cut?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['date']['output']>;
  delta?: Maybe<Scalars['bigint']['output']>;
  expiryDate?: Maybe<Scalars['date']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  orderIndex?: Maybe<Scalars['Int']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  premiumDate?: Maybe<Scalars['date']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  spot?: Maybe<Scalars['float8']['output']>;
  spotDate?: Maybe<Scalars['date']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  tradeId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "trades_leg" */
export type Trades_Leg_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  cut?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  premiumDate?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  spotDate?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  tradeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Trades_Leg_Min_Fields = {
  __typename?: 'trades_leg_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  cut?: Maybe<Scalars['String']['output']>;
  deliveryDate?: Maybe<Scalars['date']['output']>;
  delta?: Maybe<Scalars['bigint']['output']>;
  expiryDate?: Maybe<Scalars['date']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  orderIndex?: Maybe<Scalars['Int']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  premiumDate?: Maybe<Scalars['date']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  spot?: Maybe<Scalars['float8']['output']>;
  spotDate?: Maybe<Scalars['date']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
  tradeId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "trades_leg" */
export type Trades_Leg_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  cut?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  premiumDate?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  spotDate?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  tradeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "trades_leg" */
export type Trades_Leg_Mutation_Response = {
  __typename?: 'trades_leg_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Trades_Leg>;
};

/** on_conflict condition type for table "trades_leg" */
export type Trades_Leg_On_Conflict = {
  constraint: Trades_Leg_Constraint;
  update_columns?: Array<Trades_Leg_Update_Column>;
  where?: InputMaybe<Trades_Leg_Bool_Exp>;
};

/** Ordering options when selecting data from "trades_leg". */
export type Trades_Leg_Order_By = {
  buySell?: InputMaybe<Order_By>;
  callPut?: InputMaybe<Order_By>;
  ccyPair?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  cut?: InputMaybe<Order_By>;
  deliveryDate?: InputMaybe<Order_By>;
  delta?: InputMaybe<Order_By>;
  expiryCut?: InputMaybe<Expiry_Cuts_Order_By>;
  expiryDate?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  hedgeType?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  notionalCurrency?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  premiumCurrency?: InputMaybe<Order_By>;
  premiumDate?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  product?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  spotDate?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
  trade?: InputMaybe<Trades_Order_By>;
  tradeId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trades_leg */
export type Trades_Leg_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "trades_leg" */
export enum Trades_Leg_Select_Column {
  /** column name */
  BuySell = 'buySell',
  /** column name */
  CallPut = 'callPut',
  /** column name */
  CcyPair = 'ccyPair',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Cut = 'cut',
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  Delta = 'delta',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Forward = 'forward',
  /** column name */
  HedgeType = 'hedgeType',
  /** column name */
  Id = 'id',
  /** column name */
  Notional = 'notional',
  /** column name */
  NotionalCurrency = 'notionalCurrency',
  /** column name */
  OrderIndex = 'orderIndex',
  /** column name */
  Premium = 'premium',
  /** column name */
  PremiumCurrency = 'premiumCurrency',
  /** column name */
  PremiumDate = 'premiumDate',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Product = 'product',
  /** column name */
  Spot = 'spot',
  /** column name */
  SpotDate = 'spotDate',
  /** column name */
  Strike = 'strike',
  /** column name */
  TradeId = 'tradeId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "trades_leg_aggregate_bool_exp_avg_arguments_columns" columns of table "trades_leg" */
export enum Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike'
}

/** select "trades_leg_aggregate_bool_exp_corr_arguments_columns" columns of table "trades_leg" */
export enum Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike'
}

/** select "trades_leg_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "trades_leg" */
export enum Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike'
}

/** select "trades_leg_aggregate_bool_exp_max_arguments_columns" columns of table "trades_leg" */
export enum Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike'
}

/** select "trades_leg_aggregate_bool_exp_min_arguments_columns" columns of table "trades_leg" */
export enum Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike'
}

/** select "trades_leg_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "trades_leg" */
export enum Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike'
}

/** select "trades_leg_aggregate_bool_exp_sum_arguments_columns" columns of table "trades_leg" */
export enum Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike'
}

/** select "trades_leg_aggregate_bool_exp_var_samp_arguments_columns" columns of table "trades_leg" */
export enum Trades_Leg_Select_Column_Trades_Leg_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Forward = 'forward',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Spot = 'spot',
  /** column name */
  Strike = 'strike'
}

/** input type for updating data in table "trades_leg" */
export type Trades_Leg_Set_Input = {
  buySell?: InputMaybe<Enum_Axe_Buysell_Enum>;
  callPut?: InputMaybe<Enum_Axe_Callput_Enum>;
  ccyPair?: InputMaybe<Enum_Axe_Ccypair_Enum>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  cut?: InputMaybe<Scalars['String']['input']>;
  deliveryDate?: InputMaybe<Scalars['date']['input']>;
  delta?: InputMaybe<Scalars['bigint']['input']>;
  expiryDate?: InputMaybe<Scalars['date']['input']>;
  forward?: InputMaybe<Scalars['float8']['input']>;
  hedgeType?: InputMaybe<Enum_Axe_Hedgetype_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  notionalCurrency?: InputMaybe<Enum_Currency_Notional_Enum>;
  orderIndex?: InputMaybe<Scalars['Int']['input']>;
  premium?: InputMaybe<Scalars['Int']['input']>;
  premiumCurrency?: InputMaybe<Enum_Currency_Premium_Enum>;
  premiumDate?: InputMaybe<Scalars['date']['input']>;
  pricingVolatility?: InputMaybe<Scalars['float8']['input']>;
  product?: InputMaybe<Enum_Axe_Product_Enum>;
  spot?: InputMaybe<Scalars['float8']['input']>;
  spotDate?: InputMaybe<Scalars['date']['input']>;
  strike?: InputMaybe<Scalars['float8']['input']>;
  tradeId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Trades_Leg_Stddev_Fields = {
  __typename?: 'trades_leg_stddev_fields';
  delta?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "trades_leg" */
export type Trades_Leg_Stddev_Order_By = {
  delta?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Trades_Leg_Stddev_Pop_Fields = {
  __typename?: 'trades_leg_stddev_pop_fields';
  delta?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "trades_leg" */
export type Trades_Leg_Stddev_Pop_Order_By = {
  delta?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Trades_Leg_Stddev_Samp_Fields = {
  __typename?: 'trades_leg_stddev_samp_fields';
  delta?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "trades_leg" */
export type Trades_Leg_Stddev_Samp_Order_By = {
  delta?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Trades_Leg_Sum_Fields = {
  __typename?: 'trades_leg_sum_fields';
  delta?: Maybe<Scalars['bigint']['output']>;
  forward?: Maybe<Scalars['float8']['output']>;
  notional?: Maybe<Scalars['bigint']['output']>;
  orderIndex?: Maybe<Scalars['Int']['output']>;
  premium?: Maybe<Scalars['Int']['output']>;
  pricingVolatility?: Maybe<Scalars['float8']['output']>;
  spot?: Maybe<Scalars['float8']['output']>;
  strike?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "trades_leg" */
export type Trades_Leg_Sum_Order_By = {
  delta?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
};

/** update columns of table "trades_leg" */
export enum Trades_Leg_Update_Column {
  /** column name */
  BuySell = 'buySell',
  /** column name */
  CallPut = 'callPut',
  /** column name */
  CcyPair = 'ccyPair',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Cut = 'cut',
  /** column name */
  DeliveryDate = 'deliveryDate',
  /** column name */
  Delta = 'delta',
  /** column name */
  ExpiryDate = 'expiryDate',
  /** column name */
  Forward = 'forward',
  /** column name */
  HedgeType = 'hedgeType',
  /** column name */
  Id = 'id',
  /** column name */
  Notional = 'notional',
  /** column name */
  NotionalCurrency = 'notionalCurrency',
  /** column name */
  OrderIndex = 'orderIndex',
  /** column name */
  Premium = 'premium',
  /** column name */
  PremiumCurrency = 'premiumCurrency',
  /** column name */
  PremiumDate = 'premiumDate',
  /** column name */
  PricingVolatility = 'pricingVolatility',
  /** column name */
  Product = 'product',
  /** column name */
  Spot = 'spot',
  /** column name */
  SpotDate = 'spotDate',
  /** column name */
  Strike = 'strike',
  /** column name */
  TradeId = 'tradeId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Trades_Leg_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Trades_Leg_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trades_Leg_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trades_Leg_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Trades_Leg_Var_Pop_Fields = {
  __typename?: 'trades_leg_var_pop_fields';
  delta?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "trades_leg" */
export type Trades_Leg_Var_Pop_Order_By = {
  delta?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Trades_Leg_Var_Samp_Fields = {
  __typename?: 'trades_leg_var_samp_fields';
  delta?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "trades_leg" */
export type Trades_Leg_Var_Samp_Order_By = {
  delta?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Trades_Leg_Variance_Fields = {
  __typename?: 'trades_leg_variance_fields';
  delta?: Maybe<Scalars['Float']['output']>;
  forward?: Maybe<Scalars['Float']['output']>;
  notional?: Maybe<Scalars['Float']['output']>;
  orderIndex?: Maybe<Scalars['Float']['output']>;
  premium?: Maybe<Scalars['Float']['output']>;
  pricingVolatility?: Maybe<Scalars['Float']['output']>;
  spot?: Maybe<Scalars['Float']['output']>;
  strike?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "trades_leg" */
export type Trades_Leg_Variance_Order_By = {
  delta?: InputMaybe<Order_By>;
  forward?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  orderIndex?: InputMaybe<Order_By>;
  premium?: InputMaybe<Order_By>;
  pricingVolatility?: InputMaybe<Order_By>;
  spot?: InputMaybe<Order_By>;
  strike?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "trades" */
export type Trades_Mutation_Response = {
  __typename?: 'trades_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Trades>;
};

/** input type for inserting object relation for remote table "trades" */
export type Trades_Obj_Rel_Insert_Input = {
  data: Trades_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Trades_On_Conflict>;
};

/** on_conflict condition type for table "trades" */
export type Trades_On_Conflict = {
  constraint: Trades_Constraint;
  update_columns?: Array<Trades_Update_Column>;
  where?: InputMaybe<Trades_Bool_Exp>;
};

/** Ordering options when selecting data from "trades". */
export type Trades_Order_By = {
  axeCustomerUserId?: InputMaybe<Order_By>;
  axeId?: InputMaybe<Order_By>;
  axeOwnerUserId?: InputMaybe<Order_By>;
  axeOwner_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  channel?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  customerSubOrganizationId?: InputMaybe<Order_By>;
  deltaHedge?: InputMaybe<Order_By>;
  fixQuoteId?: InputMaybe<Order_By>;
  fundId?: InputMaybe<Order_By>;
  giveupId?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notional?: InputMaybe<Order_By>;
  notionalAmountInEUR?: InputMaybe<Order_By>;
  notionalAmountInUSD?: InputMaybe<Order_By>;
  ownerOrganizationId?: InputMaybe<Order_By>;
  ownerSubOrganizationId?: InputMaybe<Order_By>;
  premiumType?: InputMaybe<Order_By>;
  rfqId?: InputMaybe<Order_By>;
  side?: InputMaybe<Order_By>;
  traded_hedges_aggregate?: InputMaybe<Traded_Hedges_Aggregate_Order_By>;
  trades_legs_aggregate?: InputMaybe<Trades_Leg_Aggregate_Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trades */
export type Trades_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "trades" */
export enum Trades_Select_Column {
  /** column name */
  AxeCustomerUserId = 'axeCustomerUserId',
  /** column name */
  AxeId = 'axeId',
  /** column name */
  AxeOwnerUserId = 'axeOwnerUserId',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerSubOrganizationId = 'customerSubOrganizationId',
  /** column name */
  DeltaHedge = 'deltaHedge',
  /** column name */
  FixQuoteId = 'fixQuoteId',
  /** column name */
  FundId = 'fundId',
  /** column name */
  GiveupId = 'giveupId',
  /** column name */
  Id = 'id',
  /** column name */
  Notional = 'notional',
  /** column name */
  NotionalAmountInEur = 'notionalAmountInEUR',
  /** column name */
  NotionalAmountInUsd = 'notionalAmountInUSD',
  /** column name */
  OwnerOrganizationId = 'ownerOrganizationId',
  /** column name */
  OwnerSubOrganizationId = 'ownerSubOrganizationId',
  /** column name */
  PremiumType = 'premiumType',
  /** column name */
  RfqId = 'rfqId',
  /** column name */
  Side = 'side',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "trades" */
export type Trades_Set_Input = {
  axeCustomerUserId?: InputMaybe<Scalars['String']['input']>;
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  axeOwnerUserId?: InputMaybe<Scalars['String']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  customerSubOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  deltaHedge?: InputMaybe<Scalars['bigint']['input']>;
  fixQuoteId?: InputMaybe<Scalars['String']['input']>;
  fundId?: InputMaybe<Scalars['uuid']['input']>;
  giveupId?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notional?: InputMaybe<Scalars['bigint']['input']>;
  notionalAmountInEUR?: InputMaybe<Scalars['bigint']['input']>;
  notionalAmountInUSD?: InputMaybe<Scalars['bigint']['input']>;
  ownerOrganizationId?: InputMaybe<Scalars['String']['input']>;
  ownerSubOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  premiumType?: InputMaybe<Enum_Axe_Premiumtype_Enum>;
  rfqId?: InputMaybe<Scalars['uuid']['input']>;
  side?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "trades" */
export enum Trades_Update_Column {
  /** column name */
  AxeCustomerUserId = 'axeCustomerUserId',
  /** column name */
  AxeId = 'axeId',
  /** column name */
  AxeOwnerUserId = 'axeOwnerUserId',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CustomerSubOrganizationId = 'customerSubOrganizationId',
  /** column name */
  DeltaHedge = 'deltaHedge',
  /** column name */
  FixQuoteId = 'fixQuoteId',
  /** column name */
  FundId = 'fundId',
  /** column name */
  GiveupId = 'giveupId',
  /** column name */
  Id = 'id',
  /** column name */
  Notional = 'notional',
  /** column name */
  NotionalAmountInEur = 'notionalAmountInEUR',
  /** column name */
  NotionalAmountInUsd = 'notionalAmountInUSD',
  /** column name */
  OwnerOrganizationId = 'ownerOrganizationId',
  /** column name */
  OwnerSubOrganizationId = 'ownerSubOrganizationId',
  /** column name */
  PremiumType = 'premiumType',
  /** column name */
  RfqId = 'rfqId',
  /** column name */
  Side = 'side',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type Trades_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Trades_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trades_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trades_Bool_Exp;
};

/** columns and relationships of "user_main_tiering" */
export type User_Main_Tiering = Node & {
  __typename?: 'user_main_tiering';
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['ID']['output'];
  isRemoved?: Maybe<Scalars['Boolean']['output']>;
  organizationId: Scalars['String']['output'];
  ownerId: Scalars['String']['output'];
  subOrganizationId?: Maybe<Scalars['uuid']['output']>;
  tier: Enum_Tiers_Enum;
  updatedAt: Scalars['timestamptz']['output'];
  /** An object relationship */
  user_main_tiering_organization?: Maybe<Organizations>;
  /** An object relationship */
  user_main_tiering_subOrganization?: Maybe<Sub_Organizations>;
};

/** A Relay connection object on "user_main_tiering" */
export type User_Main_TieringConnection = {
  __typename?: 'user_main_tieringConnection';
  edges: Array<User_Main_TieringEdge>;
  pageInfo: PageInfo;
};

export type User_Main_TieringEdge = {
  __typename?: 'user_main_tieringEdge';
  cursor: Scalars['String']['output'];
  node: User_Main_Tiering;
};

/** aggregated selection of "user_main_tiering" */
export type User_Main_Tiering_Aggregate = {
  __typename?: 'user_main_tiering_aggregate';
  aggregate?: Maybe<User_Main_Tiering_Aggregate_Fields>;
  nodes: Array<User_Main_Tiering>;
};

export type User_Main_Tiering_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Main_Tiering_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Main_Tiering_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Main_Tiering_Aggregate_Bool_Exp_Count>;
};

export type User_Main_Tiering_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Main_Tiering_Select_Column_User_Main_Tiering_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Main_Tiering_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Main_Tiering_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Main_Tiering_Select_Column_User_Main_Tiering_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Main_Tiering_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Main_Tiering_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Main_Tiering_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Main_Tiering_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_main_tiering" */
export type User_Main_Tiering_Aggregate_Fields = {
  __typename?: 'user_main_tiering_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Main_Tiering_Max_Fields>;
  min?: Maybe<User_Main_Tiering_Min_Fields>;
};


/** aggregate fields of "user_main_tiering" */
export type User_Main_Tiering_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Main_Tiering_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_main_tiering" */
export type User_Main_Tiering_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Main_Tiering_Max_Order_By>;
  min?: InputMaybe<User_Main_Tiering_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_main_tiering" */
export type User_Main_Tiering_Arr_Rel_Insert_Input = {
  data: Array<User_Main_Tiering_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Main_Tiering_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_main_tiering". All fields are combined with a logical 'AND'. */
export type User_Main_Tiering_Bool_Exp = {
  _and?: InputMaybe<Array<User_Main_Tiering_Bool_Exp>>;
  _not?: InputMaybe<User_Main_Tiering_Bool_Exp>;
  _or?: InputMaybe<Array<User_Main_Tiering_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isRemoved?: InputMaybe<Boolean_Comparison_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  ownerId?: InputMaybe<String_Comparison_Exp>;
  subOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
  tier?: InputMaybe<Enum_Tiers_Enum_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_main_tiering_organization?: InputMaybe<Organizations_Bool_Exp>;
  user_main_tiering_subOrganization?: InputMaybe<Sub_Organizations_Bool_Exp>;
};

/** unique or primary key constraints on table "user_main_tiering" */
export enum User_Main_Tiering_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserMainTieringPkey = 'user_main_tiering_pkey'
}

/** input type for inserting data into table "user_main_tiering" */
export type User_Main_Tiering_Insert_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isRemoved?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  tier?: InputMaybe<Enum_Tiers_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  user_main_tiering_organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  user_main_tiering_subOrganization?: InputMaybe<Sub_Organizations_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Main_Tiering_Max_Fields = {
  __typename?: 'user_main_tiering_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['String']['output']>;
  subOrganizationId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "user_main_tiering" */
export type User_Main_Tiering_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  ownerId?: InputMaybe<Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Main_Tiering_Min_Fields = {
  __typename?: 'user_main_tiering_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['String']['output']>;
  subOrganizationId?: Maybe<Scalars['uuid']['output']>;
  updatedAt?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "user_main_tiering" */
export type User_Main_Tiering_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  ownerId?: InputMaybe<Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_main_tiering" */
export type User_Main_Tiering_Mutation_Response = {
  __typename?: 'user_main_tiering_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Main_Tiering>;
};

/** on_conflict condition type for table "user_main_tiering" */
export type User_Main_Tiering_On_Conflict = {
  constraint: User_Main_Tiering_Constraint;
  update_columns?: Array<User_Main_Tiering_Update_Column>;
  where?: InputMaybe<User_Main_Tiering_Bool_Exp>;
};

/** Ordering options when selecting data from "user_main_tiering". */
export type User_Main_Tiering_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isRemoved?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  ownerId?: InputMaybe<Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
  tier?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  user_main_tiering_organization?: InputMaybe<Organizations_Order_By>;
  user_main_tiering_subOrganization?: InputMaybe<Sub_Organizations_Order_By>;
};

/** primary key columns input for table: user_main_tiering */
export type User_Main_Tiering_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_main_tiering" */
export enum User_Main_Tiering_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsRemoved = 'isRemoved',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  Tier = 'tier',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** select "user_main_tiering_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_main_tiering" */
export enum User_Main_Tiering_Select_Column_User_Main_Tiering_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsRemoved = 'isRemoved'
}

/** select "user_main_tiering_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_main_tiering" */
export enum User_Main_Tiering_Select_Column_User_Main_Tiering_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsRemoved = 'isRemoved'
}

/** input type for updating data in table "user_main_tiering" */
export type User_Main_Tiering_Set_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isRemoved?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  tier?: InputMaybe<Enum_Tiers_Enum>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "user_main_tiering" */
export enum User_Main_Tiering_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsRemoved = 'isRemoved',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  OwnerId = 'ownerId',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  Tier = 'tier',
  /** column name */
  UpdatedAt = 'updatedAt'
}

export type User_Main_Tiering_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Main_Tiering_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Main_Tiering_Bool_Exp;
};

/** columns and relationships of "user_notifications" */
export type User_Notifications = Node & {
  __typename?: 'user_notifications';
  id: Scalars['ID']['output'];
  /** An object relationship */
  notification: Notifications;
  notificationId: Scalars['uuid']['output'];
  /** An object relationship */
  user: Users;
  userId: Scalars['String']['output'];
};

/** A Relay connection object on "user_notifications" */
export type User_NotificationsConnection = {
  __typename?: 'user_notificationsConnection';
  edges: Array<User_NotificationsEdge>;
  pageInfo: PageInfo;
};

export type User_NotificationsEdge = {
  __typename?: 'user_notificationsEdge';
  cursor: Scalars['String']['output'];
  node: User_Notifications;
};

/** aggregated selection of "user_notifications" */
export type User_Notifications_Aggregate = {
  __typename?: 'user_notifications_aggregate';
  aggregate?: Maybe<User_Notifications_Aggregate_Fields>;
  nodes: Array<User_Notifications>;
};

export type User_Notifications_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Notifications_Aggregate_Bool_Exp_Count>;
};

export type User_Notifications_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Notifications_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_notifications" */
export type User_Notifications_Aggregate_Fields = {
  __typename?: 'user_notifications_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Notifications_Max_Fields>;
  min?: Maybe<User_Notifications_Min_Fields>;
};


/** aggregate fields of "user_notifications" */
export type User_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_notifications" */
export type User_Notifications_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Notifications_Max_Order_By>;
  min?: InputMaybe<User_Notifications_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_notifications" */
export type User_Notifications_Arr_Rel_Insert_Input = {
  data: Array<User_Notifications_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Notifications_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_notifications". All fields are combined with a logical 'AND'. */
export type User_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<User_Notifications_Bool_Exp>>;
  _not?: InputMaybe<User_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<User_Notifications_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification?: InputMaybe<Notifications_Bool_Exp>;
  notificationId?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_notifications" */
export enum User_Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserNotificationsPkey = 'user_notifications_pkey'
}

/** input type for inserting data into table "user_notifications" */
export type User_Notifications_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification?: InputMaybe<Notifications_Obj_Rel_Insert_Input>;
  notificationId?: InputMaybe<Scalars['uuid']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type User_Notifications_Max_Fields = {
  __typename?: 'user_notifications_max_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  notificationId?: Maybe<Scalars['uuid']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "user_notifications" */
export type User_Notifications_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  notificationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Notifications_Min_Fields = {
  __typename?: 'user_notifications_min_fields';
  id?: Maybe<Scalars['uuid']['output']>;
  notificationId?: Maybe<Scalars['uuid']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "user_notifications" */
export type User_Notifications_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  notificationId?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_notifications" */
export type User_Notifications_Mutation_Response = {
  __typename?: 'user_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Notifications>;
};

/** on_conflict condition type for table "user_notifications" */
export type User_Notifications_On_Conflict = {
  constraint: User_Notifications_Constraint;
  update_columns?: Array<User_Notifications_Update_Column>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "user_notifications". */
export type User_Notifications_Order_By = {
  id?: InputMaybe<Order_By>;
  notification?: InputMaybe<Notifications_Order_By>;
  notificationId?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_notifications */
export type User_Notifications_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_notifications" */
export enum User_Notifications_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notificationId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_notifications" */
export type User_Notifications_Set_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  notificationId?: InputMaybe<Scalars['uuid']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_notifications" */
export enum User_Notifications_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notificationId',
  /** column name */
  UserId = 'userId'
}

export type User_Notifications_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Notifications_Bool_Exp;
};

/** columns and relationships of "user_tiers" */
export type User_Tiers = Node & {
  __typename?: 'user_tiers';
  /** An array relationship */
  axe: Array<Axes>;
  axeId: Scalars['uuid']['output'];
  /** An aggregate relationship */
  axe_aggregate: Axes_Aggregate;
  /** An array relationship connection */
  axe_connection: AxesConnection;
  /** An object relationship */
  axe_tier?: Maybe<Axe_Tier_Pricing>;
  createdAt: Scalars['timestamptz']['output'];
  id: Scalars['ID']['output'];
  isRemoved?: Maybe<Scalars['Boolean']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  subOrganizationId: Scalars['uuid']['output'];
  tierId: Scalars['uuid']['output'];
  updatedAt: Scalars['timestamptz']['output'];
  userId: Scalars['String']['output'];
  /** An object relationship */
  user_main_tiering_user?: Maybe<Users>;
  /** An object relationship */
  user_organization?: Maybe<Organizations>;
  /** An object relationship */
  user_sub_organization?: Maybe<Sub_Organizations>;
};


/** columns and relationships of "user_tiers" */
export type User_TiersAxeArgs = {
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};


/** columns and relationships of "user_tiers" */
export type User_TiersAxe_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};


/** columns and relationships of "user_tiers" */
export type User_TiersAxe_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Axes_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Axes_Order_By>>;
  where?: InputMaybe<Axes_Bool_Exp>;
};

/** A Relay connection object on "user_tiers" */
export type User_TiersConnection = {
  __typename?: 'user_tiersConnection';
  edges: Array<User_TiersEdge>;
  pageInfo: PageInfo;
};

export type User_TiersEdge = {
  __typename?: 'user_tiersEdge';
  cursor: Scalars['String']['output'];
  node: User_Tiers;
};

/** Boolean expression to filter rows from the table "user_tiers". All fields are combined with a logical 'AND'. */
export type User_Tiers_Bool_Exp = {
  _and?: InputMaybe<Array<User_Tiers_Bool_Exp>>;
  _not?: InputMaybe<User_Tiers_Bool_Exp>;
  _or?: InputMaybe<Array<User_Tiers_Bool_Exp>>;
  axe?: InputMaybe<Axes_Bool_Exp>;
  axeId?: InputMaybe<Uuid_Comparison_Exp>;
  axe_aggregate?: InputMaybe<Axes_Aggregate_Bool_Exp>;
  axe_tier?: InputMaybe<Axe_Tier_Pricing_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isRemoved?: InputMaybe<Boolean_Comparison_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  subOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
  tierId?: InputMaybe<Uuid_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  userId?: InputMaybe<String_Comparison_Exp>;
  user_main_tiering_user?: InputMaybe<Users_Bool_Exp>;
  user_organization?: InputMaybe<Organizations_Bool_Exp>;
  user_sub_organization?: InputMaybe<Sub_Organizations_Bool_Exp>;
};

/** unique or primary key constraints on table "user_tiers" */
export enum User_Tiers_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserTiersPkey = 'user_tiers_pkey'
}

/** input type for inserting data into table "user_tiers" */
export type User_Tiers_Insert_Input = {
  axe?: InputMaybe<Axes_Arr_Rel_Insert_Input>;
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  axe_tier?: InputMaybe<Axe_Tier_Pricing_Obj_Rel_Insert_Input>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isRemoved?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  tierId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  user_main_tiering_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  user_sub_organization?: InputMaybe<Sub_Organizations_Obj_Rel_Insert_Input>;
};

/** response of any mutation on the table "user_tiers" */
export type User_Tiers_Mutation_Response = {
  __typename?: 'user_tiers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Tiers>;
};

/** input type for inserting object relation for remote table "user_tiers" */
export type User_Tiers_Obj_Rel_Insert_Input = {
  data: User_Tiers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Tiers_On_Conflict>;
};

/** on_conflict condition type for table "user_tiers" */
export type User_Tiers_On_Conflict = {
  constraint: User_Tiers_Constraint;
  update_columns?: Array<User_Tiers_Update_Column>;
  where?: InputMaybe<User_Tiers_Bool_Exp>;
};

/** Ordering options when selecting data from "user_tiers". */
export type User_Tiers_Order_By = {
  axeId?: InputMaybe<Order_By>;
  axe_aggregate?: InputMaybe<Axes_Aggregate_Order_By>;
  axe_tier?: InputMaybe<Axe_Tier_Pricing_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  isRemoved?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
  tierId?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
  user_main_tiering_user?: InputMaybe<Users_Order_By>;
  user_organization?: InputMaybe<Organizations_Order_By>;
  user_sub_organization?: InputMaybe<Sub_Organizations_Order_By>;
};

/** primary key columns input for table: user_tiers */
export type User_Tiers_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_tiers" */
export enum User_Tiers_Select_Column {
  /** column name */
  AxeId = 'axeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsRemoved = 'isRemoved',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  TierId = 'tierId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "user_tiers" */
export type User_Tiers_Set_Input = {
  axeId?: InputMaybe<Scalars['uuid']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isRemoved?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  tierId?: InputMaybe<Scalars['uuid']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "user_tiers" */
export enum User_Tiers_Update_Column {
  /** column name */
  AxeId = 'axeId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsRemoved = 'isRemoved',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  TierId = 'tierId',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

export type User_Tiers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Tiers_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Tiers_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = Node & {
  __typename?: 'users';
  colorSchemePreference?: Maybe<Enum_Color_Scheme_Enum>;
  email: Scalars['String']['output'];
  fullName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  last_seen: Scalars['timestamp']['output'];
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organizationId?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  pushSubscriptions: Array<Push_Subscriptions>;
  /** An aggregate relationship */
  pushSubscriptions_aggregate: Push_Subscriptions_Aggregate;
  /** An array relationship connection */
  pushSubscriptions_connection: Push_SubscriptionsConnection;
  role?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  subOrganization?: Maybe<Sub_Organizations>;
  subOrganizationId?: Maybe<Scalars['uuid']['output']>;
  suspended_state?: Maybe<Enum_Users_Axe_Suspended_State_Enum>;
  /** An array relationship */
  tierings: Array<User_Main_Tiering>;
  /** An aggregate relationship */
  tierings_aggregate: User_Main_Tiering_Aggregate;
  /** An array relationship connection */
  tierings_connection: User_Main_TieringConnection;
};


/** columns and relationships of "users" */
export type UsersPushSubscriptionsArgs = {
  distinct_on?: InputMaybe<Array<Push_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Push_Subscriptions_Order_By>>;
  where?: InputMaybe<Push_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPushSubscriptions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Push_Subscriptions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Push_Subscriptions_Order_By>>;
  where?: InputMaybe<Push_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPushSubscriptions_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<Push_Subscriptions_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Push_Subscriptions_Order_By>>;
  where?: InputMaybe<Push_Subscriptions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTieringsArgs = {
  distinct_on?: InputMaybe<Array<User_Main_Tiering_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Main_Tiering_Order_By>>;
  where?: InputMaybe<User_Main_Tiering_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTierings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Main_Tiering_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Main_Tiering_Order_By>>;
  where?: InputMaybe<User_Main_Tiering_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersTierings_ConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  distinct_on?: InputMaybe<Array<User_Main_Tiering_Select_Column>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Main_Tiering_Order_By>>;
  where?: InputMaybe<User_Main_Tiering_Bool_Exp>;
};

/** A Relay connection object on "users" */
export type UsersConnection = {
  __typename?: 'usersConnection';
  edges: Array<UsersEdge>;
  pageInfo: PageInfo;
};

export type UsersEdge = {
  __typename?: 'usersEdge';
  cursor: Scalars['String']['output'];
  node: Users;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  colorSchemePreference?: InputMaybe<Enum_Color_Scheme_Enum_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  fullName?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  last_seen?: InputMaybe<Timestamp_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organizationId?: InputMaybe<String_Comparison_Exp>;
  pushSubscriptions?: InputMaybe<Push_Subscriptions_Bool_Exp>;
  pushSubscriptions_aggregate?: InputMaybe<Push_Subscriptions_Aggregate_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  subOrganization?: InputMaybe<Sub_Organizations_Bool_Exp>;
  subOrganizationId?: InputMaybe<Uuid_Comparison_Exp>;
  suspended_state?: InputMaybe<Enum_Users_Axe_Suspended_State_Enum_Comparison_Exp>;
  tierings?: InputMaybe<User_Main_Tiering_Bool_Exp>;
  tierings_aggregate?: InputMaybe<User_Main_Tiering_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  colorSchemePreference?: InputMaybe<Enum_Color_Scheme_Enum>;
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_seen?: InputMaybe<Scalars['timestamp']['input']>;
  organization?: InputMaybe<Organizations_Obj_Rel_Insert_Input>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  pushSubscriptions?: InputMaybe<Push_Subscriptions_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Scalars['String']['input']>;
  subOrganization?: InputMaybe<Sub_Organizations_Obj_Rel_Insert_Input>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  suspended_state?: InputMaybe<Enum_Users_Axe_Suspended_State_Enum>;
  tierings?: InputMaybe<User_Main_Tiering_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_seen?: Maybe<Scalars['timestamp']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  subOrganizationId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  email?: InputMaybe<Order_By>;
  fullName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  email?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_seen?: Maybe<Scalars['timestamp']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  subOrganizationId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  email?: InputMaybe<Order_By>;
  fullName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  organizationId?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  colorSchemePreference?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  fullName?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_seen?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organizationId?: InputMaybe<Order_By>;
  pushSubscriptions_aggregate?: InputMaybe<Push_Subscriptions_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
  subOrganization?: InputMaybe<Sub_Organizations_Order_By>;
  subOrganizationId?: InputMaybe<Order_By>;
  suspended_state?: InputMaybe<Order_By>;
  tierings_aggregate?: InputMaybe<User_Main_Tiering_Aggregate_Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['String']['input'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  ColorSchemePreference = 'colorSchemePreference',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Role = 'role',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  SuspendedState = 'suspended_state'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  colorSchemePreference?: InputMaybe<Enum_Color_Scheme_Enum>;
  email?: InputMaybe<Scalars['String']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  last_seen?: InputMaybe<Scalars['timestamp']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  subOrganizationId?: InputMaybe<Scalars['uuid']['input']>;
  suspended_state?: InputMaybe<Enum_Users_Axe_Suspended_State_Enum>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  ColorSchemePreference = 'colorSchemePreference',
  /** column name */
  Email = 'email',
  /** column name */
  FullName = 'fullName',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  OrganizationId = 'organizationId',
  /** column name */
  Role = 'role',
  /** column name */
  SubOrganizationId = 'subOrganizationId',
  /** column name */
  SuspendedState = 'suspended_state'
}

export type Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};
