/* eslint-disable  no-unused-vars */
import { UserProfile } from '@auth0/nextjs-auth0/client'

const rolesKey = (process.env.NEXT_PUBLIC_AUTH0_ENV_KEY as string) + '/roles'

export enum Roles {
  ADMIN = 'Admin',
  SALES_ADMIN = 'SalesAdmin',
  TRADER_ADMIN = 'TraderAdmin',
  TRADER = 'Trader',
  SALES = 'Sales',
  OPTAXE_ADMIN = 'OptAxeAdmin'
}

const hasRole = (
  role: Roles,
  user: UserProfile | undefined,
  clientRolesKey = ''
): boolean => {
  return Boolean(
    user &&
      user[clientRolesKey] &&
      (user[clientRolesKey] as Array<string>).includes(role)
  )
}

export const hasAdminRole = (
  user: UserProfile | undefined,
  clientRolesKey = ''
) =>
  hasRole(Roles.ADMIN, user, clientRolesKey) ||
  hasRole(Roles.SALES_ADMIN, user, clientRolesKey) ||
  hasRole(Roles.TRADER_ADMIN, user, clientRolesKey)

export const hasOptaxeAdminRole = (
  user: UserProfile | undefined,
  clientRolesKey = ''
) => hasRole(Roles.OPTAXE_ADMIN, user, clientRolesKey)

export const hasTraderRole = (
  user: UserProfile | undefined,
  clientRolesKey = ''
) =>
  hasRole(Roles.TRADER, user, clientRolesKey) ||
  hasRole(Roles.TRADER_ADMIN, user, clientRolesKey)

export const hasSalesRole = (
  user: UserProfile | undefined,
  clientRolesKey = ''
) =>
  hasRole(Roles.SALES, user, clientRolesKey) ||
  hasRole(Roles.SALES_ADMIN, user, clientRolesKey)

// To be used Server side only
export const isAdmin = (user: UserProfile): boolean => {
  return (user[rolesKey] as string[]).includes(Roles.ADMIN)
}

export const getRoles = (user: UserProfile): string[] => {
  return user[rolesKey] as string[]
}
